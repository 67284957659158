import styled, { css } from 'styled-components';

type ContainerProps = {
  noBorderRadiusBottom?: boolean;
};

export const Container = styled.div<ContainerProps>`
  overflow-y: hidden;
  overflow-x: auto;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  position: relative;

  ${({ noBorderRadiusBottom }) =>
    noBorderRadiusBottom &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
`;

export const Root = styled.table`
  width: 100%;

  border-spacing: 0;
  overflow: hidden;
`;

export const Thead = styled.thead`
  tr {
    /* vertical-align: bottom; */
    vertical-align: center;
  }
`;

export const Tbody = styled.tbody`
  > tr:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.gray0};
  }
`;

interface TrProps {
  height?: number | string;
  disabled?: boolean;
}
export const Tr = styled.tr<TrProps>`
  height: ${({ height }) => height ?? 'auto'};
  p,
  td {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray4 : theme.colors.gray6};
  }
`;

interface ThProps {
  width?: string | number;
  valueWidthInMediaQueryAuto?: string | number;
  hasBorderLeftLastColl?: boolean;
}

export const Th = styled.th<ThProps>`
  color: ${({ theme }) => theme.colors.gray6} !important;

  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375rem;
  letter-spacing: 0.06rem;
  text-align: center;
  text-transform: uppercase;

  padding: 0.75rem 1rem;
  position: relative;
  width: ${({ width }) => width ?? 'auto'};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.gray0};

  &:last-child {
    border-left: ${({ theme, hasBorderLeftLastColl }) =>
      hasBorderLeftLastColl ? `1px solid ${theme.colors.gray2}` : 'none'};
    width: ${({ hasBorderLeftLastColl }) =>
      hasBorderLeftLastColl ? '5.625rem' : 'auto'};
  }

  ${({ valueWidthInMediaQueryAuto }) => css`
    @media (max-width: ${valueWidthInMediaQueryAuto}) {
      width: auto !important;
    }
  `}
`;

type TdProps = {
  isProjection?: boolean;
  hasBorderLeftLastColl?: boolean;
};

export const Td = styled.td<TdProps>`
  height: 3rem;

  color: ${({ theme, isProjection }) =>
    isProjection ? '#FFABD3' : theme.colors.gray6} !important;
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 1.3125rem;
  padding: 0.75rem 1rem;
  text-align: center;

  &:last-child {
    border-left: ${({ theme, hasBorderLeftLastColl }) =>
      hasBorderLeftLastColl ? `1px solid ${theme.colors.gray2}` : 'none'};
  }
`;

export const Footer = styled.div`
  position: sticky;
  padding: 1.5rem 1rem;
  left: 0;

  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
`;
