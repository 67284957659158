import styled from 'styled-components';

export const ContainerProject = styled.div`
  width: 100%;
  margin-bottom: 2.125rem;

  .css-165luzk-option {
    cursor: not-allowed;
  }
`;

export const ProjectDetails = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 2rem;

  cursor: pointer;

  img {
    width: 3.313rem;
    height: 3.313rem;

    border-radius: 8px;
  }

  div {
    margin-left: 1rem;

    h4 {
      color: ${({ theme }) => theme.colors.gray6};
      font-size: 1rem;
      line-height: 1.688rem;
      font-weight: 600;

      word-break: break-all;
    }

    p {
      color: ${({ theme }) => theme.colors.gray4};
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.375rem;

      margin-top: 0.125rem;
    }
  }
`;

export const WorkspaceMenuButton = styled.button`
  width: 2rem;
  height: 2rem;

  position: absolute;
  top: 1.5rem;
  right: 0.5rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }

  :disabled {
    cursor: not-allowed;
  }
`;
