import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { Pagination } from 'src/components/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { RootState } from 'src/redux/store';
import { arrayIcon, checkIconURL } from 'src/utils/handleProjectIcon';
import Star from 'src/assets/star-yellow.svg';
import LockSimple from 'src/assets/LockSimple.svg';
import api from 'src/feature-store/service/api';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { CheckBoxFeatureStore } from '../GroupCards/styles';
import { Group, GroupFeatureStore, GroupFeatureStoreWithDummy } from '../types';
import {
  Container,
  Description,
  Head,
  ContainerSeriesInfo,
  NameColumn,
  DivPagination,
  TrGroups,
  PremiumDiv,
  DivLock,
  DivInfoSeriesPremium,
} from './styles';

interface ModalSeriesGroupProps {
  group: Group;
  visible: boolean;
  closeModals: () => void;
  setVisible: (value: boolean) => void;
  groupChecked: GroupFeatureStoreWithDummy[];
  setGroupChecked: (value: GroupFeatureStoreWithDummy[]) => void;
  groupFeatureStore: GroupFeatureStore[];
  handleSendVariables?: () => void;
  disableButtonCancel?: boolean;
  checkIfTheCheckBoxIsCheckedGroup: (id: string) => boolean;
  checkIfTheGroupAlreadyIsInFaas: (id: string) => boolean;
}

interface SummaryProps {
  total: number;
  total_default: number;
  total_premium: number;
  total_accessible: number;
  total_inaccessible: number;
}

const QUANTITY_ITEMS_PAGE_SERIES = 8;

export const ModalSeriesGroup: React.FC<ModalSeriesGroupProps> = ({
  visible,
  closeModals,
  setVisible,
  group,
  groupChecked,
  setGroupChecked,
  handleSendVariables,
  groupFeatureStore,
  disableButtonCancel,
  checkIfTheCheckBoxIsCheckedGroup,
  checkIfTheGroupAlreadyIsInFaas,
}) => {
  const [dataSeriesPage, setDataSeriesPage] = useState(1);
  const [summary, setSummary] = useState<SummaryProps>();
  const { t: translate } = useTranslation();

  const { language } = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    const getData = async () => {
      const { data } = await api.get<SummaryProps>(
        `/groups/${group.id}/series/summary`,
      );
      setSummary(data);
    };
    getData();
  }, [group.id]);

  const handleSelectSerie = (
    code: string,
    tag: string,
    isDummy: boolean,
    isAccessible: boolean,
  ) => {
    const groupAux = [...groupChecked];
    const groupIndex = groupAux.findIndex(
      (groupCheckedAux) => groupCheckedAux.id === group.id,
    );

    if (groupIndex !== -1) {
      if (
        groupAux[groupIndex].series.some(
          (serie) =>
            serie.code === code &&
            serie.tag === tag &&
            serie.isAccessible === isAccessible,
        )
      ) {
        groupAux[groupIndex].series = groupAux[groupIndex].series.filter(
          (serie) =>
            serie.code !== code &&
            serie.tag !== tag &&
            serie.isAccessible !== isAccessible,
        );
      } else {
        groupAux[groupIndex].series.push({ code, tag, isDummy, isAccessible });
      }
    } else {
      groupAux.push({
        id: group.id,
        series: [
          {
            code,
            tag,
            isDummy,
            isAccessible,
          },
        ],
      });
    }

    setGroupChecked(groupAux);
  };
  const handleClickSelectedGroup = () => {
    const groupAux = groupChecked.find(
      (groupCheckedAux) =>
        group.id === groupCheckedAux.id &&
        group.series.filter((serie) => serie.is_accessible),
    );
    const seriesOnlyMonthly = group.series.filter(
      (serie) =>
        serie.aggregation['en-us'].includes('Monthly') && serie.is_accessible,
    );

    if (groupAux) {
      if (groupAux.series.length === seriesOnlyMonthly.length) {
        setGroupChecked(
          groupChecked.filter(
            (groupCheckedAux) =>
              groupCheckedAux.id !== group.id &&
              group.series.map((serie) => serie.is_accessible),
          ),
        );
      } else {
        setGroupChecked([
          ...groupChecked.filter(
            (groupCheckedAux) =>
              groupCheckedAux.id !== group.id &&
              group.series.filter((serie) => serie.is_accessible),
          ),
          {
            id: group.id,
            series: seriesOnlyMonthly.map((serie) => ({
              code: serie.code,
              tag: serie.tag,
              isDummy: serie.is_dummy,
              isAccessible: serie.is_accessible,
            })),
          },
        ]);
      }

      return;
    }
    setGroupChecked([
      ...groupChecked,
      {
        id: group.id,
        series: seriesOnlyMonthly.map((serie) => ({
          code: serie.code,
          tag: serie.tag,
          isDummy: serie.is_dummy,
          isAccessible: serie.is_accessible,
        })),
      },
    ]);
  };

  const checkIfTheSerieAlreadyIsInFaasListSeries = (
    code: string,
    tag: string,
  ): boolean => {
    const groupFS = groupFeatureStore.find(
      (groupFSAux) => groupFSAux.id === group.id,
    );

    if (!groupFS) {
      return false;
    }

    if (
      groupFS.series.some((serie) => serie.code === code && serie.tag === tag)
    ) {
      return true;
    }

    return false;
  };

  const checkIfTheCheckBoxIsCheckedListSeries = (
    code: string,
    tag: string,
  ): boolean => {
    const groupFS = groupFeatureStore.find(
      (groupFSAux) => groupFSAux.id === group.id,
    );

    if (
      groupFS?.series.some((serie) => serie.code === code && serie.tag === tag)
    ) {
      return true;
    }

    const groupAux = groupChecked.find(
      (groupCheckedAux) => groupCheckedAux.id === group.id,
    );

    if (!groupAux) {
      return false;
    }

    if (
      groupAux.series.some((serie) => serie.code === code && serie.tag === tag)
    ) {
      return true;
    }

    return false;
  };

  return (
    <Modal visible={visible} setVisible={closeModals} animation={false}>
      <Container>
        <ContainerSeriesInfo>
          <Head>
            <ArrowLeft
              size="2rem"
              onClick={() => setVisible(false)}
              data-testid="close-modal-icon"
              style={{ cursor: 'pointer', zIndex: 2 }}
            />
            <img
              src={
                group.is_predefined
                  ? 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png'
                  : checkIconURL(group.icon_url)
                  ? group.icon_url
                  : arrayIcon[0]
              }
              alt="icon"
            />
            <Description>
              <h2>{group.name}</h2>
              <p>{group.description}</p>
            </Description>
          </Head>

          {summary && summary?.total_inaccessible !== 0 && (
            <DivInfoSeriesPremium>
              <p>
                {translate('someVariables')}
                <img src={Star} alt="star-icon" data-testid="star-icon" />
                <span>Premium</span> {translate('messageAboutSeriePremium')}
              </p>
            </DivInfoSeriesPremium>
          )}

          <Table>
            <Thead>
              <Tr>
                <Th style={{ opacity: 1 }}>
                  <NameColumn isTitle>
                    <CheckBoxFeatureStore
                      label={translate('FeatureStoreIntegrationColumnName')}
                      onChange={() => handleClickSelectedGroup()}
                      data-testid={`checkbox-all-${group.name
                        .replaceAll(' ', '-')
                        .toLowerCase()}`}
                      data-cy={`checkbox-all-${group.name
                        .replaceAll(' ', '-')
                        .toLowerCase()}`}
                      checked={checkIfTheCheckBoxIsCheckedGroup(group.id)}
                      disabled={checkIfTheGroupAlreadyIsInFaas(group.id)}
                    />
                  </NameColumn>
                </Th>
                <Th>{translate('FeatureStoreIntegrationColumnSource')}</Th>
                <Th>{translate('FeatureStoreIntegrationColumnLastUpdated')}</Th>
                <Th>{translate('FeatureStoreIntegrationColumnStartDate')}</Th>
                <Th>{translate('FeatureStoreIntegrationColumnEndDate')}</Th>
                <Th>{translate('FeatureStoreIntegrationColumnRegion')}</Th>
                <Th>{translate('FeatureStoreIntegrationColumnPrimary')}</Th>
                <Th>{translate('FeatureStoreIntegrationColumnSecondary')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {group.series
                .slice(
                  (dataSeriesPage - 1) * QUANTITY_ITEMS_PAGE_SERIES,
                  dataSeriesPage * QUANTITY_ITEMS_PAGE_SERIES,
                )
                .map((serie) => (
                  // eslint-disable-next-line react/jsx-indent
                  <TrGroups
                    key={`tr-list-series-${serie.code}`}
                    disabled={
                      !serie.aggregation['en-us'].includes('Monthly') ||
                      serie.is_accessible === false
                    }
                  >
                    {serie.is_accessible ? (
                      <Td>
                        <NameColumn>
                          <CheckBoxFeatureStore
                            label={serie.name[language] ?? serie.name['en-us']}
                            onChange={() =>
                              handleSelectSerie(
                                serie.code,
                                serie.tag,
                                serie.is_dummy,
                                serie.is_accessible,
                              )
                            }
                            checked={checkIfTheCheckBoxIsCheckedListSeries(
                              serie.code,
                              serie.tag,
                            )}
                            disabled={
                              !serie.aggregation['en-us'].includes('Monthly') ||
                              checkIfTheSerieAlreadyIsInFaasListSeries(
                                serie.code,
                                serie.tag,
                              )
                            }
                          />
                        </NameColumn>
                      </Td>
                    ) : (
                      <DivLock>
                        <img
                          src={LockSimple}
                          alt="serie-locked"
                          data-testid="serie-locked"
                          style={{ width: '17px', height: '17px' }}
                        />
                        <p>{serie.name[language] ?? serie.name['en-us']}</p>
                      </DivLock>
                    )}
                    <Td>{serie.source}</Td>
                    <Td>{format(new Date(serie.last_updated), 'MM/yyyy')}</Td>
                    <Td>{format(new Date(serie.date_start), 'MM/yyyy')}</Td>
                    <Td>{format(new Date(serie.date_end), 'MM/yyyy')}</Td>
                    <Td>{serie.region[language] ?? serie.region['en-us']}</Td>
                    <Td>
                      {serie.primary_transformation[language] ??
                        serie.primary_transformation['en-us']}
                    </Td>
                    {serie.access_type === 'premium' ? (
                      <PremiumDiv>
                        <Td style={{ display: 'flex', alignItems: 'center' }}>
                          {serie.second_transformation[language] ??
                            serie.second_transformation['en-us']}
                          <img
                            src={Star}
                            alt="star-icon"
                            data-testid="star-icon"
                          />
                        </Td>
                      </PremiumDiv>
                    ) : (
                      <Td style={{ paddingLeft: '20px' }}>
                        {serie.second_transformation[language] ??
                          serie.second_transformation['en-us']}
                      </Td>
                    )}
                  </TrGroups>
                ))}
            </Tbody>
          </Table>
          <DivPagination>
            <Pagination
              page={dataSeriesPage}
              setPage={setDataSeriesPage}
              quantityItemsPerPage={QUANTITY_ITEMS_PAGE_SERIES}
              total={group.series.length}
              name={translate('textSeries')}
              style={{
                marginTop: '20px',
                marginBottom: '4px',
              }}
            />
          </DivPagination>
        </ContainerSeriesInfo>
        <ModalFooter>
          <Button
            buttonType="naked"
            onClick={() => closeModals()}
            data-testid="button-back"
            disabled={disableButtonCancel}
          >
            {translate('textButtonCancel')}
          </Button>
          <Button
            buttonType="primary"
            style={{
              maxWidth: '11.563rem',
              transition: 'none',
            }}
            icon={<ArrowRight size="1.125rem" />}
            data-testid="button-send-to-group"
            onClick={handleSendVariables}
            disabled={
              groupChecked.flatMap((groupCheckedAux) => groupCheckedAux.series)
                .length === 0
            }
          >
            {translate('textButtonSendToFaas')}
          </Button>
        </ModalFooter>
      </Container>
    </Modal>
  );
};
