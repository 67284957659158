import styled, { css, keyframes } from 'styled-components';

export const Container = styled.aside`
  position: relative;
  width: 100%;
  min-width: 20rem;
  max-width: 20rem;
  margin-bottom: -1.5rem;

  height: auto;
  background: ${({ theme }) => theme.colors.white};

  border-right: ${({ theme }) => `1px solid ${theme.colors.gray2}`};

  > div {
    position: sticky;
    /* height: calc(100vh - 6rem); // 74header + 24 padding top */
    top: 6rem; // 74header + 24 padding top
    padding: 0 1.5rem 1.5rem;
    overflow-y: scroll;
    margin-top: 1.5rem;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  hr {
    border: 1px solid ${({ theme }) => theme.colors.gray1};
    margin-bottom: 2rem;
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

type ItemProps = {
  dept: number;
  hasSubMenu: boolean;
  isToggled: boolean;
  isActive: boolean;
  disabled?: boolean;
  loading?: boolean;
  isModelProduction?: boolean;
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Item = styled.li<ItemProps>`
  height: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  > span {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    width: 100%;

    display: flex;
    align-items: center;

    font-size: 1rem;
    font-weight: 400;

    transition: all 0.2s ease-in-out;

    > img {
      width: 1.5rem;
      height: 1.5rem;

      margin-right: 1rem;
    }
  }

  ${({ dept, isModelProduction }) =>
    dept === 1 &&
    css`
      > span {
        color: ${({ theme }) =>
          !isModelProduction ? theme.colors.gray5 : theme.colors.secondary};

        /* &:hover {
          font-size: 16.3px;
        } */
      }
    `}

  ${({ hasSubMenu, isActive, isToggled, theme }) =>
    hasSubMenu &&
    css`
      &:after {
        content: '';
        cursor: pointer;

        width: 0;
        height: 0;

        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid
          ${isActive ? theme.colors.primaryDark : theme.colors.gray5};

        transition: border-top 0.2s;
        transform: ${() => (isToggled ? 'rotate(180deg)' : 'rotate(0deg)')};
      }
    `}

  ${({ loading, hasSubMenu, isModelProduction }) =>
    loading &&
    !isModelProduction &&
    !hasSubMenu &&
    css`
      &:after {
        content: '';
        border: 4px solid ${({ theme }) => theme.colors.gray3};
        border-top: 4px solid ${({ theme }) => theme.colors.primaryDark};
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
        animation: ${spin} 0.8s linear infinite;
      }
    `}

  ${({ dept, loading, hasSubMenu, disabled }) =>
    dept > 1 &&
    css`
      height: 1.25rem;

      margin-top: 1rem !important;
      margin-left: ${`${20 * dept}px`};

      &:before {
        width: 0px !important;
        height: 0px !important;
      }

      > span {
        color: ${({ theme }) =>
          (disabled || loading) && !hasSubMenu
            ? theme.colors.gray4
            : theme.colors.gray7};
        font-size: 0.875rem;
        font-weight: 400;
      }
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      &:before {
        content: '';

        width: 0.25rem;
        height: 2rem;

        margin-left: -1.375rem;
        margin-right: 1.125rem;
      }

      > span {
        color: ${({ theme }) =>
          !isActive ? theme.colors.gray5 : theme.colors.primary};
        font-weight: 700;
      }
    `}

  &:hover {
    &:after {
      border-top: ${({ theme }) => ` 4px solid${theme.colors.primaryDark}`};
    }

    > span {
      color: ${({ theme, disabled, isActive }) =>
        !disabled && (!isActive ? theme.colors.gray6 : theme.colors.primary)};
    }
  }
`;
