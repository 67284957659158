import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Info, Plus } from 'phosphor-react';
import { Tooltip } from 'react-tooltip';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card } from 'src/components/Card';
import { Label } from 'src/components/Label';
import { ModalIcons } from 'src/components/Modal/ModalIcons';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Input } from 'src/components/Input';
import { TextArea } from 'src/components/TextArea';
import { Button } from 'src/components/Button';
import SelectProjectsWorkpaceImg from 'src/assets/select-projects-workspace.svg';
import { Head } from 'src/components/Head';
import { Table, Tbody, Th, Thead, Tr } from 'src/components/Table';
import { CheckBox } from 'src/components/CheckBox';
import { Select } from 'src/components/Select';
import * as Yup from 'yup';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { FailedModal } from 'src/components/Modal/Failed';
import { queryClient } from 'src/service/queryClient';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  insert,
  updateReleaseSelected,
} from 'src/models/redux/reducers/Workspace';
import { RootState } from 'src/redux/store';
import { useQuery } from 'react-query';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { Action } from 'history';
import { AxiosError } from 'axios';
import { sleep } from 'src/utils/sleep';
import ms from 'ms';

import {
  ButtonsContainer,
  Container,
  ErrorContainer,
  FormContainer,
  IconButton,
  IconContainer,
  ImageContainer,
  InfoContainer,
  LabelContainer,
  ProjectContent,
  TableContainer,
  VariablesContainer,
  VariablesEmptyContainer,
} from './styles';
import {
  CategorizationOptionsProps,
  CategorizationProps,
  CreateWorkspaceFormProps,
  CreateWorkspaceResponse,
  Filters,
  ModelIdsProps,
  ModelUpdateProps,
  ParamsProps,
  RenamedVariablesProps,
  SelectedVariablesProps,
  StagingArea,
  YFilters,
} from './types';
import { SelectProjectsModal } from './components/Modal/SelectProjects';
import { VariablesTableRow } from './components/VariablesTable/Row';
import { VariableCategorization } from './components/VariableCategorization';
import { useQueryWorkspaceData } from '../hooks/useQueryWorkspaceData';
import { ProjectProps } from './components/Modal/SelectProjects/types';
import { NoPermissionToEditModal } from '../components/NoPermissionToEdit';
import { SessionExpiredModal } from './components/Modal/SessionExpired';

export const CreateWorkspace: React.FC = () => {
  const [showModalIcon, setShowModalIcon] = useState(false);
  const [showSelectProjectModal, setShowSelectProjectModal] = useState(false);
  const [, setIcon] = useState<string | null>(null);
  const [isTypingVariableName, setIsTypingVariableName] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [showOriginalVariableName, setShowOriginalVariableName] =
    useState(false);

  const [notDefinedId, setNotDefinedId] = useState(0);

  const [isEdition, setIsEdition] = useState<boolean>();
  const [loadingEditionInfo, setLoadingEditionInfo] = useState(false);
  const [loadingEditionFilter, setLoadingEditionFilter] = useState(false);
  const [loadingEditionVariables, setLoadingEditionVariables] = useState(false);

  const [showUserEditingModal, setShowUserEditingModal] = useState('');
  const [navigateToControlPanel, setNavigateToControlPanel] = useState(false);

  const [editionExpired, setEditionExpired] = useState(false);
  const [loadingBlockEdition, setLoadingBlockEdition] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const [updateParentId, setUpdateParentId] = useState<RenamedVariablesProps>(
    {},
  );

  const { t: translate } = useTranslation();

  const [project, setProject] = useState({
    value: 'allProjects',
    label: translate('createWorkspaceAllProjects'),
  });

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const navType = useNavigationType();

  const { id: workspaceId } = useParams<ParamsProps>();

  const {
    auth: {
      user: { email },
    },
    workspace,
  } = useSelector((state: RootState) => state);

  const regexNoSpecialCharacters = /^[a-zA-Z0-9_-\s]*$/;
  const regexFirstCharacter = /^[a-zA-Z]/;
  const regexLastCharacter = /[a-zA-Z0-9_-]$/;

  const formSchema = Yup.object().shape({
    iconUrl: Yup.string().required('requiredField'),
    name: Yup.string()
      .matches(regexFirstCharacter, 'projectErrorFirstCharacter')
      .matches(regexNoSpecialCharacters, 'projectErrorNoSpecialCharacters')
      .matches(regexLastCharacter, 'projectErrorLastCharacter')
      .min(3, 'projectErrorMinCharacters')
      .max(50, 'projectErrorMaxCharacters')
      .required('requiredField'),
    description: Yup.string()
      .min(3, 'projectErrorMinCharacters')
      .max(350, 'createWorkspaceDescriptionErrorMaxCharacters')
      .required('requiredField'),
  });

  const {
    control,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    setError,
    watch,
    formState: { errors, isSubmitting },
    resetField,
  } = useForm<CreateWorkspaceFormProps>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      iconUrl: '',
      selectedProjects: [],
      allVariablesSelected: true,
      selectedVariables: {},
      renamedVariables: {},
      modelIds: {},
      categorization: [],
    },
  });

  const selectedProjects = watch('selectedProjects');
  const selectedVar = watch('selectedVariables');
  const allVariablesSelected = watch('allVariablesSelected');
  const modelsIds = watch('modelIds');
  const modelUpdate = watch('modelUpdate');
  const workspaceName = watch('name');
  const workspaceDescription = watch('description');
  const renamedVar = watch('renamedVariables');
  const categorization = watch('categorization');

  const isRepeatedVariableLabelError = (error: string) =>
    error === 'createWorkspaceRepeatedVariable';

  const getProjectVariableId = (key: string) => {
    const split = key.split('-');
    const projectId = split[0];
    const variableId = split.slice(1).join('-');

    return { projectId, variableId };
  };

  const variableErrorMessage = useMemo(() => {
    const keys = Object.keys(errors.renamedVariables ?? {});

    for (const key of keys) {
      const { projectId, variableId } = getProjectVariableId(key);

      const isSelected = selectedVar[`${projectId}-${variableId}`];

      if (
        (isSelected || allVariablesSelected) &&
        errors.renamedVariables?.[key]?.message
      ) {
        return 'createWorkspaceVariableNameError';
      }
    }

    const keysModelId = Object.keys(modelsIds);

    for (const key of keysModelId) {
      const { projectId, variableId } = getProjectVariableId(key);

      const isSelected = selectedVar[`${projectId}-${variableId}`];

      if ((isSelected || allVariablesSelected) && modelsIds[key] === null) {
        return 'createWorkspaceVariableModelIDError';
      }
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(errors.renamedVariables),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(selectedVar),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(modelsIds),
    allVariablesSelected,
  ]);

  const projectFilteredBySelect = useMemo(
    () =>
      project.value === 'allProjects'
        ? selectedProjects
        : selectedProjects.filter(({ id }) => project.value === id),
    [selectedProjects, project.value],
  );

  const handleSelectAllVariables = (select: boolean) => {
    const updatedAllVariablesSelected = { ...selectedVar };

    projectFilteredBySelect.forEach(({ id, ys }) => {
      ys.forEach((y) => {
        if (
          y.status === 'success' &&
          getValues('modelIds')[`${id}-${y.id}`] !== 'error'
        ) {
          updatedAllVariablesSelected[`${id}-${y.id}`] = select;
        }
      });
    });

    setValue('selectedVariables', updatedAllVariablesSelected);
  };

  const checkAllVariablesAreSelected = (
    selectedVariables: SelectedVariablesProps,
  ) => {
    let allVariablesAreSelected = true;

    projectFilteredBySelect.forEach(({ id, ys }) => {
      ys.forEach((y) => {
        if (
          y.status === 'success' &&
          !selectedVariables[`${id}-${y.id}`] &&
          getValues('modelIds')[`${id}-${y.id}`] !== 'error'
        ) {
          allVariablesAreSelected = false;
        }
      });
    });

    const allVarsSelected = getValues('allVariablesSelected');

    if (allVarsSelected && !allVariablesAreSelected) {
      setValue('allVariablesSelected', false, { shouldDirty: true });
    }

    if (!allVarsSelected && allVariablesAreSelected) {
      setValue('allVariablesSelected', true, { shouldDirty: true });
    }
  };

  const existsRepeatedVariableLabel = (
    projectId: string,
    variableId: string,
    newLabel: string,
    selectedVariables: SelectedVariablesProps,
    renamedVariables: RenamedVariablesProps,
  ) => {
    const renamedErrors = errors.renamedVariables ?? {};
    const currentVariableError =
      renamedErrors[`${projectId}-${variableId}`]?.message ?? '';

    for (const { id, ys } of getValues('selectedProjects')) {
      for (const y of ys) {
        const isCurrentVariable = projectId === id && variableId === y.id;

        if (selectedVariables[`${id}-${y.id}`] && !isCurrentVariable) {
          const label = renamedVariables[`${id}-${y.id}`] ?? y.label;

          if (label === newLabel) {
            const variableError = renamedErrors[`${id}-${y.id}`]?.message;

            if (currentVariableError !== 'createWorkspaceRepeatedVariable') {
              setError(`renamedVariables.${projectId}-${variableId}`, {
                message: 'createWorkspaceRepeatedVariable',
              });
            }

            if (variableError !== 'createWorkspaceRepeatedVariable') {
              setError(`renamedVariables.${id}-${y.id}`, {
                message: 'createWorkspaceRepeatedVariable',
              });
            }

            return true;
          }
        }
      }
    }

    if (isRepeatedVariableLabelError(currentVariableError)) {
      setError(`renamedVariables.${projectId}-${variableId}`, {
        message: '',
      });
    }

    return false;
  };

  const checkExistingErrors = useCallback(
    (
      selectedVariables: SelectedVariablesProps,
      renamedVariables: RenamedVariablesProps,
    ) => {
      const renamedVariablesErrors = errors.renamedVariables ?? {};

      const keys = Object.keys(renamedVariablesErrors);

      for (const key of keys) {
        const message = renamedVariablesErrors[key]?.message ?? '';

        if (isRepeatedVariableLabelError(message)) {
          const { projectId, variableId } = getProjectVariableId(key);

          const currentLabel = renamedVariables[`${projectId}-${variableId}`];

          existsRepeatedVariableLabel(
            projectId,
            variableId,
            currentLabel,
            selectedVariables,
            renamedVariables,
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(errors.renamedVariables),
      existsRepeatedVariableLabel,
    ],
  );

  const validateNewLabel = (
    projectId: string,
    variableId: string,
    newLabel: string,
  ) => {
    setIsTypingVariableName(true);

    const selectedVariables = getValues('selectedVariables');
    const renamedVariables = getValues('renamedVariables');

    const variableError =
      errors.renamedVariables?.[`${projectId}-${variableId}`]?.message ?? '';

    const regex = new RegExp('^[áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑA-Za-z0-9_-]*$');

    if (!newLabel?.trim()) {
      if (variableError !== 'requiredField') {
        setError(`renamedVariables.${projectId}-${variableId}`, {
          message: 'requiredField',
        });
      }

      return;
    }

    const allWords = newLabel.split(' ');
    for (const word of allWords) {
      if (!regex.test(word)) {
        if (variableError !== 'projectErrorNoSpecialCharacters') {
          setError(`renamedVariables.${projectId}-${variableId}`, {
            message: 'projectErrorNoSpecialCharacters',
          });
        }

        return;
      }
    }

    if (newLabel.trim().length < 3) {
      if (variableError !== 'projectErrorMinCharacters') {
        setError(`renamedVariables.${projectId}-${variableId}`, {
          message: 'projectErrorMinCharacters',
        });
      }
      return;
    }

    if (newLabel.trim().length > 50) {
      if (variableError !== 'projectErrorMaxCharacters') {
        setError(`renamedVariables.${projectId}-${variableId}`, {
          message: 'projectErrorMaxCharacters',
        });
      }

      return;
    }

    if (
      existsRepeatedVariableLabel(
        projectId,
        variableId,
        newLabel,
        selectedVariables,
        renamedVariables,
      )
    ) {
      return;
    }

    if (variableError) {
      setError(`renamedVariables.${projectId}-${variableId}`, {
        message: '',
      });
    }
  };

  const checkAllVariablesName = useCallback(
    (
      selectedVariables: SelectedVariablesProps,
      renamedVariables: RenamedVariablesProps,
    ) => {
      selectedProjects.forEach(({ id, ys }) => {
        ys.forEach((y) => {
          const errorMessage =
            errors.renamedVariables?.[`${id}-${y.id}`]?.message;

          if (selectedVariables[`${id}-${y.id}`]) {
            const label = renamedVariables[`${id}-${y.id}`] ?? y.label;

            validateNewLabel(id, y.id, label);
          } else if (
            errorMessage &&
            (isRepeatedVariableLabelError(errorMessage ?? '') ||
              errorMessage === 'requiredField')
          ) {
            setError(`renamedVariables.${id}-${y.id}`, {
              message: '',
            });
          }
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedProjects,
      existsRepeatedVariableLabel,
      setError,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(errors.renamedVariables),
    ],
  );

  useEffect(() => {
    const projectExistsInSelectedProject = selectedProjects.find(
      ({ id }) => id === project.value,
    );

    if (!projectExistsInSelectedProject) {
      setProject({
        value: 'allProjects',
        label: translate('createWorkspaceAllProjects'),
      });
    }

    const renamedVariables = getValues('renamedVariables') ?? {};
    const selectedVariables = getValues('selectedVariables') ?? {};
    const modelUpdateAux = getValues('modelUpdate') ?? {};
    const modelIds = getValues('modelIds') ?? {};

    const updatedRenamedVariables: RenamedVariablesProps = {};
    const updatedSelectedVariables: SelectedVariablesProps = {};
    const updatedModelUpdate: ModelUpdateProps = {};
    const modelIdsUpdate: ModelIdsProps = {};

    selectedProjects.forEach(({ id, ys }) => {
      ys.forEach((y) => {
        const key = `${id}-${y.id}`;

        updatedRenamedVariables[key] = renamedVariables[key] ?? y.label;

        updatedSelectedVariables[key] =
          selectedVariables[key] === undefined ? true : selectedVariables[key];

        if (modelUpdateAux[key]) {
          updatedModelUpdate[key] = modelUpdateAux[key];
        }

        if (modelIds[key]) {
          modelIdsUpdate[key] = modelIds[key] ?? null;
        }
      });
    });

    resetField('selectedVariables', {
      defaultValue: updatedSelectedVariables,
    });

    resetField('renamedVariables', {
      keepError: false,
      defaultValue: updatedRenamedVariables,
    });

    resetField('modelUpdate', {
      defaultValue: updatedModelUpdate,
    });

    setValue('modelIds', modelIdsUpdate);

    checkAllVariablesName(updatedSelectedVariables, updatedRenamedVariables);
    checkAllVariablesAreSelected(updatedSelectedVariables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjects, getValues, resetField]);

  useEffect(() => {
    checkAllVariablesAreSelected(getValues('selectedVariables'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    let timer: NodeJS.Timer | undefined;

    if (isTypingVariableName) {
      clearTimeout(timer);

      timer = setTimeout(() => {
        setIsTypingVariableName(false);
        checkExistingErrors(
          getValues('selectedVariables'),
          getValues('renamedVariables'),
        );
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTypingVariableName]);

  const saveWorkspace = async () => {
    const keys = Object.keys(selectedVar);

    const keysSelected = keys.filter((key) => selectedVar[key]);

    const ys = keysSelected.map((key) => {
      const yId = key.split('-')[1];

      const modelUpdated = getValues('modelUpdate')[key];

      return {
        project_id: modelUpdated.value,
        y: modelUpdated.y?.id || yId,
        y_label: getValues('renamedVariables')[key],
        model_id: modelsIds[key],
      };
    });

    const filters = {} as Filters;
    const yFilters = {} as YFilters;

    if (categorization.length) {
      for (let i = 0; i < categorization.length; i++) {
        const options = [];

        for (const option of categorization[i].options) {
          options.push(option.name);

          for (const y of option.yIds) {
            const label = getValues('renamedVariables')[y];

            if (i === 0) {
              yFilters[label] = {
                level_1: option.name,
              };
            } else {
              yFilters[label][`level_${i + 1}`] = option.name;
            }
          }
        }

        filters[`level_${i + 1}`] = {
          name: categorization[i].name,
          options,
        };
      }
    }

    try {
      let response: CreateWorkspaceResponse;
      const params = {
        name: workspaceName,
        description: workspaceDescription,
        icon_url: getValues('iconUrl'),
        data: {
          ys,
          filters,
          y_filters: yFilters,
        },
      };

      if (isEdition) {
        await blockEdition(false);

        const { data } = await apiWorkspace.put<CreateWorkspaceResponse>(
          `/workspaces/${workspaceId}/staging-area`,
          params,
        );

        response = data;
      } else {
        const { data } = await apiWorkspace.post<CreateWorkspaceResponse>(
          '/workspaces',
          params,
        );

        response = data;
      }

      queryClient.removeQueries('workspaces');

      if (isEdition) {
        queryClient.removeQueries([
          'workspace staging area',
          response.id,
          'projects',
        ]);
        queryClient.removeQueries(['workspace staging area', response.id]);
        queryClient.removeQueries(['workspace data', response.id]);
      } else {
        dispatch(
          insert({
            id: response.id,
            name: response.name,
            description: response.description,
            icon: response.icon_url,
            userRole: 'editor',
            createdBy: response.created_by,
            lastUpdated: response.last_updated,
            frequency: null,
            releaseCurrent: null,
            releasePreview: null,
            status: response.status,
          }),
        );

        dispatch(
          updateReleaseSelected({ releaseSelected: null, ySelected: null }),
        );
      }

      navigate(`/models/workspaces/${response.id}/control-panel`);
    } catch (err) {
      const error = err as AxiosError;
      if (
        error.response?.status === 403 &&
        error.response?.data.detail.detail[0].startsWith(
          'workspace is being edited by',
        )
      ) {
        let emailEditing = error.response?.data.detail.detail[0]
          .replace('workspace is being edited by ', '')
          .trim();

        emailEditing =
          emailEditing[emailEditing?.length - 1] === '.'
            ? emailEditing?.slice(0, -1)
            : emailEditing;

        setShowUserEditingModal(emailEditing);
      } else {
        setShowModalError(true);
      }
    }
  };

  const checkIfTheCreateButtonIsDisabled = () => {
    if (variableErrorMessage) return true;

    if (isSubmitting) return true;

    const errorsKeys = Object.keys(errors);

    if (
      errorsKeys.some((errorKey) =>
        //@ts-expect-error:ignora
        errors?.[errorKey]?.message
          ? //@ts-expect-error:ignora
            errors?.[errorKey].message
          : //@ts-expect-error:ignora
            Object.keys(errors?.[errorKey]).some(
              //@ts-expect-error:ignora
              (errorKeyAux) => errors?.[errorKey]?.[errorKeyAux]?.message,
            ),
      )
    ) {
      return true;
    }

    if (!selectedProjects.length) return true;

    if (!Object.keys(selectedVar).some((key) => selectedVar[key])) return true;

    if (!workspaceName || !workspaceDescription) return true;

    const keys = Object.keys(selectedVar);

    const keysSelected = keys.filter((key) => selectedVar[key]);

    if (keysSelected.some((key) => !modelUpdate?.[key]?.y)) return true;

    if (keysSelected.some((key) => !modelsIds[key])) return true;

    return false;
  };

  const closeModalError = () => {
    setShowModalError(false);
  };

  const removeYIdsNotSelected = (
    selectedVariables: string[],
    yIds: string[],
  ) => {
    const updatedYIds: string[] = [];

    yIds.forEach((id) => {
      if (selectedVariables.includes(id)) {
        updatedYIds.push(id);
      }
    });

    return updatedYIds;
  };

  const handleSaveVariableCategorization = (filters: CategorizationProps[]) => {
    const updatedFilters = [...filters];

    const selectedVariables = Object.keys(selectedVar).filter(
      (key) => selectedVar[key],
    );

    updatedFilters.forEach((filter, index) => {
      let notDefinedIndex = -1;

      selectedVariables.forEach((id) => {
        let existsOptionWithVariable = false;

        filter.options.forEach((option, i) => {
          if (option.yIds.includes(id)) {
            existsOptionWithVariable = true;
          }

          if (['Not defined', 'Não definido'].includes(option.name)) {
            notDefinedIndex = i;
          }

          updatedFilters[index].options[i].yIds = removeYIdsNotSelected(
            selectedVariables,
            option.yIds,
          );
        });

        if (!existsOptionWithVariable) {
          if (notDefinedIndex !== -1) {
            updatedFilters[index].options[notDefinedIndex].yIds.push(id);
          } else {
            updatedFilters[index].options.push({
              id: String(notDefinedId),
              name: translate('createFiltersNotDefined'),
              yIds: [id],
            });
          }

          setNotDefinedId(notDefinedId + 1);
        }
      });
    });

    setValue('categorization', updatedFilters);
  };

  const selectedVariablesInfo = useMemo(() => {
    const variablesId = Object.keys(selectedVar).filter(
      (key) => selectedVar[key],
    );

    const yIdLabel = { ...renamedVar };

    Object.keys(renamedVar).forEach((key) => {
      if (!variablesId.includes(key)) {
        delete yIdLabel[key];
      }
    });

    return { ids: variablesId, yIdLabel };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedVar), JSON.stringify(renamedVar)]);

  useEffect(() => {
    handleSaveVariableCategorization(
      JSON.parse(JSON.stringify(getValues('categorization'))),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedVar), getValues]);

  const {
    data: stagingAreaData,
    isLoading: isLoadingStagingArea,
    isFetching: isFetchingStagingArea,
    isError: isErrorStagingArea,
  } = useQuery(
    ['workspace staging area', workspaceId],
    async () => {
      const { data } = await apiWorkspace.get<StagingArea>(
        `/workspaces/${workspaceId}/staging-area`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspaceId,
    },
  );

  const { isLoading, isFetching } = useQueryWorkspaceData(
    workspaceId ?? '',
    !!isEdition && !workspace?.id && !!workspaceId && !!email,
    email ?? '',
  );

  useEffect(() => {
    if (location.pathname.includes('/control-panel/edition')) {
      setLoadingEditionInfo(true);
      setLoadingEditionFilter(true);
      setLoadingEditionVariables(true);
      setIsEdition(true);
    } else {
      setIsEdition(false);
    }
  }, [location]);

  useEffect(() => {
    if (isEdition && workspace?.id) {
      setValue('name', workspace.name ?? '');
      setValue('description', workspace.description ?? '');
      setValue('iconUrl', workspace.icon ?? '');

      setLoadingEditionInfo(false);
    }
  }, [isEdition, workspace, setValue]);

  useEffect(() => {
    const loadProjectData = async (
      projectId: string,
    ): Promise<ProjectProps> => {
      let projectResponse: ProjectProps;

      const response = queryClient.getQueryData<ProjectProps>([
        'workspace project',
        workspaceId,
        projectId,
      ]);

      try {
        if (response) {
          projectResponse = response;
        } else {
          projectResponse = await queryClient.fetchQuery<ProjectProps>(
            ['workspace project', workspaceId, projectId],
            async () => {
              const { data } = await apiWorkspace.get(
                `/workspaces/${workspaceId}/users/projects/${projectId}`,
              );

              return data;
            },
            {
              staleTime: ms('3 min'),
            },
          );
        }
      } catch {
        return {
          id: '',
          name: '',
          icon_url: '',
          parent_id: '',
          last_updated: '',
          created: '',
          status: '',
          app_info: null,
          business: {},
          model_spec: {},
          overview: { columns: [] },
          type: '',
          ys: [],
        };
      }

      return projectResponse;
    };

    const setVariablesData = async (): Promise<RenamedVariablesProps> => {
      if (stagingAreaData) {
        const ys = stagingAreaData.data.ys;
        const allSelectedProjects: ProjectProps[] = [];
        const allProjectIds: string[] = [];
        const allSelectedVariables: SelectedVariablesProps = {};
        const allRenamedVariables: RenamedVariablesProps = {};
        const updateParentIds: RenamedVariablesProps = {};

        for (let i = 0; i < ys.length; i++) {
          let projectId = ys[i].project_id;
          let updatedProject = {} as ProjectProps;
          let yId = ys[i].y;

          if (!allProjectIds.includes(projectId)) {
            let projectData = await loadProjectData(ys[i].project_id);

            if (projectData.parent_id) {
              updatedProject = projectData;
              projectData = await loadProjectData(projectData.parent_id);
            }

            projectId = projectData.id;

            if (updatedProject?.id) {
              yId =
                projectData.ys.find(
                  (y) =>
                    y.id === yId ||
                    (y.id.endsWith(`_${yId}`) && y.id.startsWith('forecast_')),
                )?.id ?? '';

              updateParentIds[updatedProject.id] = projectId;
            } else {
              updateParentIds[projectId] = projectId;
            }

            if (!allProjectIds.includes(projectId)) {
              projectData.ys.forEach((y) => {
                allRenamedVariables[`${projectId}-${y.id}`] = y.name;
                allSelectedVariables[`${projectId}-${y.id}`] = false;
              });

              allSelectedProjects.push(projectData);
              allProjectIds.push(projectId);
            }

            allRenamedVariables[`${projectId}-${yId}`] = ys[i].y_label;
            allSelectedVariables[`${projectId}-${yId}`] = true;
          } else {
            allRenamedVariables[`${projectId}-${ys[i].y}`] = ys[i].y_label;
            allSelectedVariables[`${projectId}-${ys[i].y}`] = true;
          }
        }

        setUpdateParentId(updateParentIds);
        setValue('renamedVariables', allRenamedVariables);
        setValue('selectedVariables', allSelectedVariables);
        setValue('selectedProjects', allSelectedProjects);

        setLoadingEditionVariables(false);

        return allRenamedVariables;
      }

      return {};
    };

    const setCategorizationData = (renamedVariables: RenamedVariablesProps) => {
      if (stagingAreaData) {
        const { filters, y_filters } = stagingAreaData.data;

        const filtersFormatted: CategorizationProps[] = [];

        const filterKeys = Object.keys(filters ?? {});

        for (const key of filterKeys) {
          const filterInfo = filters[key];

          if (filters[key]) {
            const options: CategorizationOptionsProps[] = [];

            filterInfo.options.forEach((option, index) => {
              const ys: string[] = [];

              const yKeys = Object.keys(y_filters ?? {});

              for (const y of yKeys) {
                if (y_filters[y][key] === option) {
                  const renamedVariablesKeys = Object.keys(renamedVariables);

                  for (const projectYId of renamedVariablesKeys) {
                    if (renamedVariables[projectYId] === y) {
                      ys.push(projectYId);
                    }
                  }
                }
              }

              options.push({ id: `${key}-${index}`, name: option, yIds: ys });
            });

            filtersFormatted.push({ name: filterInfo.name, options });
          }
        }

        setValue('categorization', filtersFormatted);

        setLoadingEditionFilter(false);
      }
    };

    const setInformations = async () => {
      const renamedVariables = await setVariablesData();
      setCategorizationData(renamedVariables);
    };

    if (
      isEdition &&
      stagingAreaData &&
      !isLoadingStagingArea &&
      !isFetchingStagingArea
    ) {
      setInformations();
    }
  }, [
    isEdition,
    stagingAreaData,
    isLoadingStagingArea,
    isFetchingStagingArea,
    setValue,
    workspaceId,
  ]);

  const blockEdition = async (sendToControlPanel = true) => {
    setLoadingBlockEdition(true);

    try {
      await apiWorkspace.patch(`/workspaces/${workspace.id}/edit`);

      setCanEdit(true);
    } catch (err) {
      const error = err as AxiosError;
      if (
        error.response?.status === 400 &&
        error.response?.data?.detail?.detail?.startsWith(
          'Workspace already locked for editing by',
        )
      ) {
        if (sendToControlPanel) {
          setNavigateToControlPanel(true);
        }

        setCanEdit(false);
        setShowUserEditingModal(
          error.response?.data.detail.detail.replace(
            'Workspace already locked for editing by ',
            '',
          ),
        );
      }
    }

    setEditionExpired(false);
    setLoadingBlockEdition(false);
  };

  useEffect(() => {
    if (isEdition && navType === Action.Pop && workspace?.id) {
      blockEdition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdition, navType, workspace?.id]);

  useEffect(() => {
    const renewSession = async () => {
      await sleep(ms('5 min'));

      setEditionExpired(true);
    };

    if (!editionExpired && isEdition && canEdit) {
      renewSession();
    }
  }, [editionExpired, isEdition, canEdit]);

  useEffect(() => {
    if (isEdition && workspace?.status === 'publishing') {
      navigate(`/models/workspaces/${workspace.id}/control-panel`);
    }
  }, [isEdition, workspace?.status, workspace?.id, navigate]);

  const editionIsLoading =
    isEdition === undefined ||
    (isEdition && (isLoading || isFetching || loadingEditionInfo));

  const editionFilterIsLoading =
    isEdition &&
    (isLoadingStagingArea || isFetchingStagingArea || loadingEditionFilter);

  return (
    <>
      <Head
        title={
          isEdition
            ? translate('editWorkspaceHeadTitle')
            : translate('createWorkspaceHeadTitle')
        }
      />

      <Container>
        <VariablesContainer className="containerLinear">
          <Card
            textCard={translate('createWorkspaceSelectVariablesTitle')}
            textDescription={translate(
              'createWorkspaceSelectVariablesDescription',
            )}
          />

          {isEdition === undefined ? (
            <ContainerSkeleton />
          ) : isEdition && isErrorStagingArea ? (
            <ContainerMaintenance
              content="content"
              style={{ marginTop: '5rem' }}
              data-testid="staging-area-error"
            />
          ) : (
            <>
              {variableErrorMessage && (
                <ErrorContainer visible data-testid="variable-error">
                  {translate(variableErrorMessage)}
                </ErrorContainer>
              )}

              {!getValues('selectedProjects')?.length && isEdition === false ? (
                <VariablesEmptyContainer data-testid="no-projects-selected">
                  <ImageContainer>
                    <img
                      src={SelectProjectsWorkpaceImg}
                      alt="Select the projects to compose your results"
                    />

                    <div />
                  </ImageContainer>

                  <Button
                    buttonType="tertiary"
                    icon={<Plus weight="bold" />}
                    disabled
                    className="plus-button"
                  />

                  <h2>{translate('createWorkspaceNoProjectSelectedTitle')}</h2>
                  <p>
                    {translate('createWorkspaceNoProjectSelectedDescription')}
                  </p>

                  <Button
                    buttonType="tertiary"
                    onClick={() => setShowSelectProjectModal(true)}
                    data-testid="select-project-button"
                  >
                    {translate('createWorkspaceSelectProjectsButton')}
                  </Button>
                </VariablesEmptyContainer>
              ) : (
                <>
                  {loadingEditionVariables ? (
                    <ContainerSkeleton
                      style={{ height: '52rem' }}
                      data-testid="loading-variables"
                    />
                  ) : (
                    <>
                      <ProjectContent>
                        <div>
                          <Select
                            defaultValue={{
                              value: 'allProjects',
                              label: translate('createWorkspaceAllProjects'),
                            }}
                            value={project}
                            options={[
                              {
                                value: 'allProjects',
                                label: translate('createWorkspaceAllProjects'),
                              },
                              ...getValues('selectedProjects').map(
                                ({ id, name }) => ({
                                  value: id,
                                  label: name,
                                }),
                              ),
                            ]}
                            onChange={(value: any) => {
                              setProject(value);
                            }}
                            dataTestid="select-project"
                          />

                          <Button
                            buttonType="naked"
                            icon={<Plus />}
                            onClick={() => setShowSelectProjectModal(true)}
                            data-testid="add-new-project-button"
                          >
                            {translate('createWorkspaceAddNewProject')}
                          </Button>
                        </div>

                        <ToggleSwitch
                          label={translate(
                            'createWorkspaceShowOriginalVariableName',
                          )}
                          checked={showOriginalVariableName}
                          onChange={(e) =>
                            setShowOriginalVariableName(e.target.checked)
                          }
                          data-testid="show-original-variable-name-toggle"
                        />
                      </ProjectContent>

                      <Tooltip
                        id="workspace-table"
                        className="customTooltipTheme"
                      />

                      <TableContainer id="workspace-table-container">
                        <Table data-testid="variables-info-table">
                          <Thead>
                            <Tr>
                              <Th width="1.5rem">
                                <Controller
                                  name="allVariablesSelected"
                                  control={control}
                                  defaultValue
                                  render={({ field: { onChange, value } }) => (
                                    <CheckBox
                                      checked={value}
                                      onChange={({ target: { checked } }) => {
                                        onChange(checked);

                                        handleSelectAllVariables(checked);

                                        checkAllVariablesName(
                                          getValues('selectedVariables'),
                                          getValues('renamedVariables'),
                                        );
                                      }}
                                      className="checkbox-select-all"
                                      data-testid="select-all-variables"
                                    />
                                  )}
                                />
                              </Th>
                              <Th className="rename-variable-column">
                                {translate('createWorkspaceVariable')}
                              </Th>
                              <Th
                                style={{
                                  minWidth: '9rem',
                                  display: showOriginalVariableName
                                    ? 'table-cell'
                                    : 'none',
                                }}
                              >
                                {translate('createWorkspaceOriginalVariable')}
                              </Th>
                              <Th style={{ minWidth: '9rem' }}>
                                {translate('createWorkspaceProject')}
                              </Th>
                              <Th style={{ minWidth: '8rem' }}>
                                {translate('createWorkspaceModelUpdate')}
                              </Th>
                              <Th style={{ minWidth: '8rem' }}>
                                {translate('createWorkspaceModelId')}
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {projectFilteredBySelect.map((proj) =>
                              proj.ys.map((y) => (
                                <VariablesTableRow
                                  key={`row-${proj.id}-${y.id}`}
                                  project={proj}
                                  y={y}
                                  error={
                                    errors.renamedVariables?.[
                                      `${proj.id}-${y.id}`
                                    ]?.message ?? ''
                                  }
                                  selected={getValues(
                                    `selectedVariables.${proj.id}-${y.id}`,
                                  )}
                                  control={control}
                                  getValues={getValues}
                                  setValue={setValue}
                                  validateNewLabel={validateNewLabel}
                                  checkAllVariablesAreSelected={
                                    checkAllVariablesAreSelected
                                  }
                                  checkAllVariablesName={checkAllVariablesName}
                                  showOriginalVariableName={
                                    showOriginalVariableName
                                  }
                                  defaultModelIdUpdate={
                                    isEdition && stagingAreaData
                                      ? stagingAreaData.data.ys.find(
                                          (value) =>
                                            updateParentId[value.project_id] ===
                                              proj.id && value.y === y.id,
                                        )
                                      : undefined
                                  }
                                />
                              )),
                            )}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </>
                  )}

                  <VariableCategorization
                    ys={selectedVariablesInfo}
                    currentFilters={categorization}
                    handleSaveVariableCategorization={
                      handleSaveVariableCategorization
                    }
                    variableError={
                      variableErrorMessage ===
                      'createWorkspaceVariableNameError'
                    }
                    isLoading={editionFilterIsLoading}
                  />
                </>
              )}
            </>
          )}
        </VariablesContainer>

        <InfoContainer className="containerLinear">
          <Card
            textCard={
              isEdition
                ? translate('editWorkspaceEditTitle')
                : translate('createWorkspaceCreateTitle')
            }
            textDescription={translate('createWorkspaceCreateDescription')}
          />

          <FormContainer onSubmit={handleSubmit(saveWorkspace)}>
            <div>
              <IconContainer>
                <LabelContainer>
                  <Label>{translate('createWorkspaceIcon')}</Label>

                  <Info
                    size="1.125rem"
                    data-tooltip-id="config-workspace-tooltip"
                    data-tooltip-html={translate('createWorkspaceIconTooltip')}
                    data-testid="icon-info"
                  />
                </LabelContainer>

                <IconButton
                  data-testid="workspace-icon-button"
                  onClick={() => setShowModalIcon(true)}
                  disabled={isEdition}
                  type="button"
                >
                  {editionIsLoading || !getValues('iconUrl') ? (
                    <ContainerSkeleton withLoading={false} />
                  ) : (
                    <img
                      data-testid="selected-icon"
                      src={getValues('iconUrl')}
                      alt="Selected icon"
                    />
                  )}
                </IconButton>
              </IconContainer>

              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={translate('createWorkspaceName')}
                    placeholder={translate('createWorkspaceNamePlaceholder')}
                    onChange={({ target: { value: val } }) => {
                      onChange(val);

                      if (
                        val.length <= 50 &&
                        val.match(regexFirstCharacter) &&
                        val.match(regexLastCharacter) &&
                        val.match(regexNoSpecialCharacters) &&
                        !errors.name?.message
                      ) {
                        return;
                      }

                      trigger('name');
                    }}
                    value={value}
                    error={
                      errors.name?.message
                        ? translate(errors.name?.message)
                        : ''
                    }
                    loading={editionIsLoading}
                    disabled={isEdition}
                    data-testid={`name-input${
                      editionIsLoading ? '-loading' : ''
                    }`}
                  />
                )}
              />

              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <TextArea
                    label={translate('createWorkspaceDescription')}
                    placeholder={translate(
                      'createWorkspaceDescriptionPlaceholder',
                    )}
                    onChange={({ target: { value: val } }) => {
                      onChange(val);

                      if (val.length <= 350 && !errors.description?.message) {
                        return;
                      }

                      trigger('description');
                    }}
                    value={value}
                    loading={editionIsLoading}
                    disabled={isEdition}
                    error={
                      errors.description?.message
                        ? translate(errors.description?.message)
                        : ''
                    }
                    data-testid={`description-textarea${
                      editionIsLoading ? '-loading' : ''
                    }`}
                  />
                )}
              />
            </div>

            {isEdition ? (
              <ButtonsContainer>
                <Button
                  buttonType="naked"
                  type="button"
                  onClick={() =>
                    navigate(`/models/workspaces/${workspaceId}/control-panel`)
                  }
                  data-testid="discard-workspace-changes-button"
                >
                  {translate('editWorkspaceDiscardChanges')}
                </Button>
                <Button
                  buttonType="primary"
                  type="submit"
                  onClick={() => handleSubmit(saveWorkspace)}
                  data-testid="save-workspace-changes-button"
                  disabled={checkIfTheCreateButtonIsDisabled()}
                  loading={isSubmitting}
                >
                  {translate('editWorkspaceSaveChanges')}
                </Button>
              </ButtonsContainer>
            ) : (
              <Button
                buttonType="primary"
                type="submit"
                onClick={() => handleSubmit(saveWorkspace)}
                data-testid="save-workspace-button"
                disabled={checkIfTheCreateButtonIsDisabled()}
                loading={isSubmitting}
              >
                {translate('createWorkspaceCreate')}
              </Button>
            )}
          </FormContainer>
        </InfoContainer>

        <Tooltip
          id="config-workspace-tooltip"
          className="customTooltipTheme workspace-tooltip"
        />
      </Container>

      {(isEdition === false ||
        (!isLoading && !isFetching && getValues('iconUrl'))) && (
        <Controller
          name="iconUrl"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ModalIcons
              visible={showModalIcon}
              setIcon={setIcon}
              setVisible={setShowModalIcon}
              onChangHookForm={onChange}
              value={value}
              content={{
                title: translate('createWorkspaceIconModalTitle'),
                description: translate('createWorkspaceIconModalDescription'),
              }}
            />
          )}
        />
      )}

      {showSelectProjectModal && (
        <Controller
          name="selectedProjects"
          control={control}
          defaultValue={[]}
          render={({ field: { onChange, value } }) => (
            <SelectProjectsModal
              visible={showSelectProjectModal}
              setVisible={setShowSelectProjectModal}
              onChangHookForm={onChange}
              value={value}
            />
          )}
        />
      )}

      {showModalError && (
        <FailedModal
          errorInfo={{
            title: translate('createWorkspaceModalErrorTitle'),
            description: translate(
              'createWorkspaceModalErrorDescription',
            ).replace(
              'XXX',
              translate(
                isEdition
                  ? 'createWorkspaceModalErrorDescriptionSaveText'
                  : 'createWorkspaceModalErrorDescriptionCreateText',
              ),
            ),
          }}
          visible
          setVisible={closeModalError}
        />
      )}

      {showUserEditingModal && (
        <NoPermissionToEditModal
          setVisible={() => {
            setShowUserEditingModal('');

            if (navigateToControlPanel) {
              navigate(`/models/workspaces/${workspaceId}/control-panel`);
            }
          }}
          emailEditing={showUserEditingModal}
        />
      )}

      {editionExpired && canEdit && (
        <SessionExpiredModal
          handleBlockEdition={() => blockEdition(false)}
          loadingBlockEdition={loadingBlockEdition}
          workspaceId={workspaceId ?? ''}
        />
      )}
    </>
  );
};
