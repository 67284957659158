import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { format, isValid, parse } from 'date-fns';
import {
  DownloadSimple,
  GearSix,
  Info,
  CaretLeft,
  CaretRight,
  Plus,
} from 'phosphor-react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StylesConfig } from 'react-select';
import { Tooltip } from 'react-tooltip';
import { Head } from 'src/components/Head';
import { Loading } from 'src/components/Loading';
import { ModalLoading } from 'src/components/Modal/Loading';
import { ModalIcons } from 'src/components/Modal/ModalIcons';
import { ModalStatus } from 'src/components/Modal/ModalStatus';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { DataError } from 'src/interface/axios';
import { InputFile } from 'src/models/pages/Modelling/TimeSeries/components/InputFile';
import { RootState } from 'src/redux/store';
import apiv2 from 'src/service/api';
import { queryClient } from 'src/service/queryClient';
import light from 'src/styles/themes/light';
import { getDateFrequency } from 'src/utils/dateFormatHandler';
import {
  getForecastWarning,
  getNWindowsRecommendation,
  getProjectionHorizonInputs,
  updateMaxNWindows,
} from 'src/utils/handleProjectHorizons';
import * as Yup from 'yup';

import { Button } from '../../../../components/Button';
import { Card } from '../../../../components/Card';
import { Input } from '../../../../components/Input';
import { ErrorObject, FailedModal } from '../../../../components/Modal/Failed';
// import { ModalScript } from '../../components/Modal/ModalScript';
import { Select } from '../../../../components/Select';
import {
  insert as insertModelling,
  clear as clearModelling,
} from '../../../redux/reducers/Modelling';
import { clear as clearProject } from '../../../redux/reducers/Project';
import api from '../../../service/api';
import FeatureStore from '../TimeSeries/components/FeatureStore';
import { CardStep3 } from './components/CardStep3';
import { ChartHistoricalForecast } from './components/ChartHistoricalForecast';
import {
  GroupFeatureStore,
  SeriesFeatureStore,
} from './components/FeatureStore/types';
import { ConclusionModal } from './components/Modal/Conclusion';
import { FeatureSelectionModal } from './components/Modal/FeatureSelection';
import { LagsAndFeatureStoreModal } from './components/Modal/LagsAndFeatureStoreWarning';
import { LagsModal } from './components/Modal/LagsModal';
import { ModalAdvanced } from './components/Modal/ModalAdvanced';
import { ModalWarningNWindows } from './components/Modal/ModalWarningNWindows';
import { ModalWarningVariables } from './components/Modal/ModalWarningVariables';
import { ProgressInDots } from './components/ProgressInDots';
import { SliderNSteps } from './components/SliderNSteps';
import {
  Container,
  ContainerComponents,
  ContentIcon,
  ContainerItens,
  ContentInput,
  DivLabel,
  DivIcon,
  ContentButtons,
  ContentItens,
  ContainerSelectVariables,
  Content,
  ContainerFooter,
  AdvancedOptionsButton,
  ContentSelectFormat,
  ContentButtonAdvancedAndModeling,
  ContentStep3,
  ContentInputToggleStep3,
} from './styles';
import {
  FormUpload,
  Variables,
  Validation,
  FileUpload,
  Modeling,
  explanatories,
  ResponseFeatureStore,
  ResponseValidation,
} from './types';
import { concatRemovedVariables } from './utils/concatRemovedVars';
import { formatOptions } from './utils/formatOptions';
import { getValuesToFillCardsThroughFrequency } from './utils/getValuesToFillCardsThroughFrequency';

const regexFirstCaracter = /^[a-zA-Z]/;
const regexInputProjectName = /^[a-zA-Z0-9_-\s]*$/;
const regexLastCaracter = /[a-zA-Z0-9_-]$/;

const initialStateSeriesFeatureStore = {
  seriesGroup: [],
  seriesPickForMe: [],
  junctionWithoutRepetition: [],
};

export const TimeSeriesModelling: React.FC = () => {
  const [step, setStep] = useState(1);
  const [modalVisibleIcons, setModalVisibleIcons] = useState(false);
  const [modalVisibleAdvanced, setModalVisibleAdvanced] = useState(false);
  const [userAlreadyOpenedFineTunning, setUserAlreadyOpenedFineTunning] =
    useState(false);
  // const [modalVisibleScript, setModalVisibleScript] = useState(false);
  const [loadingModalVisible, setLoadingModalVisible] = useState(false);
  const [loadingDataView, setLoadingDataView] = useState(false);
  const [modalVisibleFailed, setModalVisibleFailed] = useState(false);
  const [modalWarningVisible, setModalWarningVisible] = useState(false);
  const [conclusionModalVisible, setConclusionModalVisible] = useState(false);
  const [conclusionModalData, setConclusionModalData] = useState<Modeling>();
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();
  const [lagWarningModalVisible, setLagWarningModalVisible] = useState(false);
  const [featureStoreWarningInfo, setFeatureStoreWarningInfo] =
    useState<ErrorObject>();
  const [warningModalInfo, setWarningModalInfo] = useState<ErrorObject>();
  const [forecastWarningVisible, setForecastWarningVisible] = useState(false);
  const [downloadDatasetLoading, setDownloadDatasetLoading] = useState(false);

  const [modalFeatureSelectionVisible, setModalFeatureSelectionVisible] =
    useState(false);
  const [finalSettingsLoading, setFinalSettingsLoading] = useState(false);
  const [selectVariablesButtonLoading, setSelectVariablesButtonLoading] =
    useState(false);
  const [finalSettingsButtonDisabled, setFinalSettingsButtonDisabled] =
    useState(false);
  const [, setIcon] = useState<string | null>(null);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [frequency, setFrequency] = useState('');
  // const [formattedDates, setFormattedDates] = useState<string[]>([]);
  const [maxNSteps, setMaxNSteps] = useState<number>(1);
  const [maxNWindows, setMaxNWindows] = useState<number>(1);
  const [recommendedNWindows, setRecommendedNWindows] = useState<number>(1);
  const [file, setFile] = useState<File>();
  const [variablesData, setVariablesData] = useState<Variables>();
  const [validationData, setValidationData] = useState<Validation>();
  const [dateIsFormatted, setDateIsFormatted] = useState(false);
  const [lagsModalVisible, setLagsModalVisible] = useState(false);
  const [modalVisibleFeatureStore, setModalVisibleFeatureStore] =
    useState(false);
  const [modalWarningNWindowsVisible, setModalWarningNWindowsVisible] =
    useState(false);
  const [responseIntegrationFS, setResponseIntegrationFS] =
    useState<ResponseFeatureStore>();

  const [lagVariables, setLagVariables] = useState<string[]>([]);
  const [lagVariablesDisabled, setLagVariablesDisabled] = useState(true);
  const [dummiesFromFS, setDummiesFromFS] = useState<string[]>([]);

  const [seriesFeatureStore, setSeriesFeatureStore] =
    useState<SeriesFeatureStore>(initialStateSeriesFeatureStore);

  const [groupFeatureStore, setGroupFeatureStore] = useState<
    GroupFeatureStore[]
  >([]);
  const [customPeriod, setCustomPeriod] = useState(false);
  const [errorCard, setErrorCard] = useState(false);

  const modellingAux = useSelector((state: RootState) => state.modelling);
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const formSchema = Yup.object().shape({
    projectName: Yup.string()
      .matches(regexFirstCaracter, 'projectErrorFirstCharacter')
      .matches(regexInputProjectName, 'projectErrorNoSpecialCharacters')
      .matches(regexLastCaracter, 'projectErrorLastCharacter')
      .max(50, 'projectErrorMaxCharacters')
      .min(3, 'projectErrorMinCharacters')
      .required('requiredField'),
    iconUrl: Yup.string().required('requiredField'),
    file: Yup.object()
      .test('', 'requiredField', () => !!file)
      .test(
        'projectErrorValidFileFormat',
        'projectErrorInvalideFileFormat',
        () => {
          const formatFile = file?.name.split('.')[1];
          return (
            formatFile === 'csv' ||
            formatFile === 'xlsx' ||
            formatFile === 'rds'
          );
        },
      ),
    fileDataSet: Yup.object().shape({
      dataset_id: Yup.string().required('projectErrorInvalidFile'),
      variables: Yup.object().shape({
        dataset_head: Yup.object().required('projectErrorInvalidFile'),
        date_name: Yup.array(Yup.string())
          .min(1, 'projectErrorInvalidFile')
          .required('projectErrorInvalidFile'),
        date_options: Yup.array(Yup.string())
          .min(1, 'projectErrorInvalidFile')
          .required('projectErrorInvalidFile'),
        y_options: Yup.array(Yup.string())
          .min(1, 'projectErrorInvalidFile')
          .required('projectErrorInvalidFile'),
        x_options: Yup.array(Yup.string())
          .min(1, 'projectErrorInvalidFile')
          .required('projectErrorInvalidFile'),
      }),
    }),
    dependVariable: Yup.string().required('requiredField'),
    dateTimeFeature: Yup.string().required('requiredField'),
    format: Yup.string()
      .required('requiredField')
      .test('Success', 'projectErrorDateFormat', (value) =>
        isValid(parse(selectedDates[0], value ?? '', new Date())),
      ),
    explanatoryVariables: Yup.array(Yup.string()).required('requiredField'),
    lagVariables: Yup.array(Yup.string()).min(0),
    lagNumber: Yup.number()
      .typeError('projectErrorSpecifyNumber')
      .integer('projectErrorIntegerNumber')
      .min(1, 'projectErrorMinLags')
      .max(frequency === 'Daily' ? 7 : 3, 'projectErrorMaxLags'),
    forecastHorizon: Yup.number()
      .typeError('projectErrorSpecifyNumber')
      .required('requiredField')
      .min(1, 'projectErrorMinNSteps')
      .max(maxNSteps, 'projectErrorMaxNSteps'),
    NWindows: Yup.number()
      .typeError('projectErrorSpecifyNumber')
      .required('requiredField')
      .min(1, 'projectErrorMinNWindows')
      .max(maxNWindows, 'projectErrorMaxNWindows'),
    applyLog: Yup.boolean().required(),
    seasonalDummies: Yup.boolean().required(),
    informationCriteria: Yup.string().ensure(),
    accuracyCriteria: Yup.string(),
    crossValidationSummary: Yup.string(),
    explCollinearity: Yup.boolean(),
    allowDrift: Yup.boolean(),
    explVarAutoForecast: Yup.boolean(),
    group1: Yup.array(Yup.string()).min(0),
    group2: Yup.array(Yup.string()).min(0),
    group3: Yup.array(Yup.string()).min(0),
    featSelectionLasso: Yup.boolean(),
    featSelectionRandomForest: Yup.boolean(),
    featSelectionCorrelation: Yup.boolean(),
    goldenVariables: Yup.array(Yup.string()).max(
      14,
      'projectErrorGoldenVarsMax',
    ),
  });

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    reset,
    watch,
  } = useForm<FormUpload>({
    resolver: yupResolver(formSchema),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      lagsModalVisible || modalVisibleFeatureStore || modalVisibleAdvanced
        ? (body.style.overflow = 'hidden')
        : (body.style.overflow = 'auto');
    }
  }, [lagsModalVisible, modalVisibleFeatureStore, modalVisibleAdvanced]);

  useEffect(() => {
    if (modellingAux.form && modellingAux.step) {
      setStep(modellingAux.step);
      setFile(modellingAux.file ?? undefined);
      setSeriesFeatureStore(modellingAux.seriesFS);
      setGroupFeatureStore(modellingAux.groupFS);
      Object.keys(modellingAux.form).forEach((key) => {
        const keyAux = key as keyof typeof modellingAux.form;
        if (modellingAux.form !== null && modellingAux.form[keyAux]) {
          setValue(keyAux, modellingAux.form[keyAux]);
        }
      });
      setDummiesFromFS(modellingAux.dummiesFromFS);
      setLagVariables(modellingAux.lagVariables);
      const dateVariable =
        getValues('fileDataSet').variables.dataset_head[
          getValues('dateTimeFeature')
        ];

      setSelectedDates(Object.values(dateVariable));
      if (getValues('format')) {
        formatDatesAndSetFrequency(
          getValues('format'),
          Object.values(dateVariable),
        );
      } else if (getValues('fileDataSet')?.date_info[0]?.inferred === false) {
        setDateIsFormatted(true);
        handleSelectDateFeature(
          getValues('fileDataSet').date_info[0].date_name,
        );

        setLagVariablesDisabled(false);
      } else {
        setDateIsFormatted(false);
      }

      dispatch(clearModelling());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    (async () => {
      if (step === 3) {
        if (await trigger('forecastHorizon')) {
          timer = setTimeout(() => {
            if (getValues('NWindows') > maxNWindows) {
              setValue('NWindows', recommendedNWindows);
              if (userAlreadyOpenedFineTunning) {
                setModalWarningNWindowsVisible(true);
              }
            }
          }, 500);
        }
      }
    })();

    return () => clearTimeout(timer);
  }, [
    getValues,
    maxNWindows,
    recommendedNWindows,
    setValue,
    step,
    trigger,
    userAlreadyOpenedFineTunning,
  ]);

  const explanatoryColorStyles: StylesConfig<explanatories, true> = {
    multiValue: (base, state) => {
      const item = state.options.find(
        // @ts-expect-error tipagem da lib errada
        (option) => option.value === state.data.value,
      );

      // @ts-expect-error problema com tipagem da lib
      return item && item.isFixed
        ? {
            ...base,
            background: `rgba(76, 148, 255, 0.12) !important`,
            borderRadius: '16px',
            padding: '4px 8px',
            opacity: 0.9,
            WebkitTextFillColor: light.colors.primary,
          }
        : base ?? (state.data && base);
    },
    multiValueRemove: (base, state) => {
      const item = state.options.find(
        // @ts-expect-error tipagem da lib errada
        (option) => option.value === state.data.value,
      );

      // @ts-expect-error problema com tipagem da lib
      return item && item.isFixed
        ? { ...base, display: 'none' }
        : base ?? (state.data && base);
    },
  };

  const handleSubmitSelectVariables = async (): Promise<void> => {
    setSelectVariablesButtonLoading(true);

    if (
      seriesFeatureStore.junctionWithoutRepetition.length ||
      lagVariables.length
    ) {
      const auxName = getValues('projectName');
      const iconAux = getValues('iconUrl');
      reset();
      setValue('projectName', auxName);
      setValue('iconUrl', iconAux);
      setSelectedDates([]);
      setFrequency('');
      // setFormattedDates([]);
      setSeriesFeatureStore(initialStateSeriesFeatureStore);
      setGroupFeatureStore([]);
      setDummiesFromFS([]);
    }

    if (
      (
        await Promise.all([
          trigger('projectName'),
          trigger('iconUrl'),
          trigger('file'),
        ])
      ).includes(false)
    ) {
      setSelectVariablesButtonLoading(false);
      return;
    }

    if (file !== undefined) {
      try {
        const fileAux = new FormData();
        fileAux.append('file', file);
        fileAux.append('project_name', getValues('projectName'));
        fileAux.append('icon_url', getValues('iconUrl'));
        const { data } = await api.post<FileUpload>('upload', fileAux);
        if (data) {
          setValue('fileDataSet', {
            dataset_id: data.dataset_id,
            dataset_name: file.name,
            variables: {
              dataset_head: JSON.parse(data.variables.dataset_head),
              date_name: data.variables.date_name,
              date_options: data.variables.date_options,
              x_options: data.variables.x_options,
              y_options: data.variables.y_options,
              lag_options: data.variables.lag_options,
            },
            date_info: data.date_info,
          });

          if (!getValues('dependVariable')) {
            const yFirstOption = data.variables.y_options[0];

            setValue('dependVariable', yFirstOption);
            setValue(
              'explanatoryVariables',
              data.variables.x_options.filter(
                (variable) => variable !== yFirstOption,
              ),
            );
          }
        } else {
          setFailedModalInfo({
            title: translate('requestFailed'),
            description: translate('uploadErrorDescr'),
          });
          setFailedModalVisible(true);
        }
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error.response?.data?.detail?.detail ?? translate('invalidDataset');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: translateSomeMessagesFromBackend(
            errorMessage,
            user.language,
          ),
        });
        setFailedModalVisible(true);
      }
    }

    if (await trigger('fileDataSet')) {
      setStep(2);
      setValue('lagNumber', 1);
      setValue('lagVariables', []);
      setLagVariables([]);

      if (getValues('fileDataSet')?.date_info[0]?.inferred === false) {
        setDateIsFormatted(true);
        handleSelectDateFeature(
          getValues('fileDataSet').date_info[0].date_name,
        );

        if (getValues('fileDataSet')?.variables?.x_options.length > 0) {
          setLagVariablesDisabled(false);
        }
      } else {
        setDateIsFormatted(false);
      }
    }

    setSelectVariablesButtonLoading(false);
  };

  const handleDownloadDatasetButton = async (): Promise<void> => {
    setDownloadDatasetLoading(true);
    setFinalSettingsButtonDisabled(true);

    let hasErrorOnInputs;

    if (dateIsFormatted === true) {
      hasErrorOnInputs = await Promise.all([
        trigger('dependVariable'),
        trigger('dateTimeFeature'),
        trigger('explanatoryVariables'),
      ]);
    } else {
      hasErrorOnInputs = await Promise.all([
        trigger('dependVariable'),
        trigger('dateTimeFeature'),
        trigger('format'),
        trigger('explanatoryVariables'),
      ]);
    }

    if (!hasErrorOnInputs.includes(false)) {
      const dataset = getValues('fileDataSet');

      let responseVariables;

      try {
        const requestBody = dateIsFormatted
          ? {
              var_y: getValues('dependVariable'),
              date_var: getValues('dateTimeFeature'),
              var_x: getValues('explanatoryVariables'),
            }
          : {
              var_y: getValues('dependVariable'),
              date_var: getValues('dateTimeFeature'),
              date_format: getValues('format').toUpperCase(),
              var_x: getValues('explanatoryVariables'),
            };

        if (user.isFeatureStore) {
          responseVariables = await apiv2.post(
            `/variables/${dataset.dataset_id}`,
            requestBody,
          );
        } else {
          responseVariables = await api.post(
            `/variables/${dataset.dataset_id}`,
            requestBody,
          );
        }

        setVariablesData(responseVariables.data);
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error.response?.data?.detail?.detail ??
          error.response?.data?.detail?.description ??
          translate('sendVariablesError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: errorMessage,
        });
        setFailedModalVisible(true);
      }
      if (responseVariables) {
        try {
          const { data } = await api.get(`/download/${dataset.dataset_id}`, {
            responseType: 'blob',
          });

          if (data) {
            const fileURL = window.URL.createObjectURL(
              new Blob([data], { type: 'text/xlsx' }),
            );

            const link = document.createElement('a');
            if (link.download !== undefined) {
              link.setAttribute('href', fileURL);
              link.setAttribute(
                'download',
                `${dataset.dataset_name.replaceAll('.xlsx', '-')}step2.xlsx`,
              );
              link.setAttribute('data-testid', 'download-start');
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
            }
          }
        } catch (err) {
          const error: AxiosError<DataError> | any = err;
          const errorMessage =
            error?.response?.data?.detail?.detail ??
            error?.response?.data?.detail?.description ??
            translate('downloadError');

          setFailedModalInfo({
            title: translate('requestFailed'),
            description: errorMessage,
          });
          setFailedModalVisible(true);
        }
      }
    }

    setDownloadDatasetLoading(false);
    setFinalSettingsButtonDisabled(false);
  };

  const handleSubmitFinalSettings = async (): Promise<void> => {
    let hasErrorOnInputs;

    if (dateIsFormatted === true) {
      hasErrorOnInputs = await Promise.all([
        trigger('dependVariable'),
        trigger('dateTimeFeature'),
        trigger('explanatoryVariables'),
      ]);
    } else {
      hasErrorOnInputs = await Promise.all([
        trigger('dependVariable'),
        trigger('dateTimeFeature'),
        trigger('format'),
        trigger('explanatoryVariables'),
      ]);
    }

    if (!hasErrorOnInputs.includes(false)) {
      setFinalSettingsButtonDisabled(true);
      setFinalSettingsLoading(true);

      const dataset = getValues('fileDataSet');

      let responseVariables;

      try {
        const requestBody = dateIsFormatted
          ? {
              var_y: getValues('dependVariable'),
              date_var: getValues('dateTimeFeature'),
              var_x: getValues('explanatoryVariables'),
            }
          : {
              var_y: getValues('dependVariable'),
              date_var: getValues('dateTimeFeature'),
              date_format: getValues('format').toUpperCase(),
              var_x: getValues('explanatoryVariables'),
            };

        if (user.isFeatureStore) {
          responseVariables = await apiv2.post(
            `/variables/${dataset.dataset_id}`,
            requestBody,
          );
        } else {
          responseVariables = await api.post(
            `/variables/${dataset.dataset_id}`,
            requestBody,
          );
        }

        setVariablesData(responseVariables.data);
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error.response?.data?.detail?.detail ??
          error.response?.data?.detail?.description ??
          translate('sendVariablesError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: translateSomeMessagesFromBackend(
            errorMessage,
            user.language,
          ),
        });
        setFailedModalVisible(true);

        setFinalSettingsButtonDisabled(false);
        setFinalSettingsLoading(false);
      }
      if (responseVariables) {
        try {
          const { data } = await api.get<ResponseValidation>(
            `/validate/${dataset.dataset_id}`,
          );

          if (data) {
            const warningArray = data.info.warning_list
              ? Object.keys(data.info.warning_list)
                  .map((key) => data.info.warning_list?.[key] ?? null)
                  .filter((warning) => warning !== null)
              : [];

            setValidationData({
              info: {
                info_list: data.info.info_list,
                warning_list: data.info.warning_list,
                //@ts-expect-error ignora nao esta reconhecendo o filter de cima
                warningArray,
              },
              status: data.status,
              status_title: data.status_title,
            });

            if (
              data.info?.info_list?.feature_selection &&
              warningArray.length
            ) {
              setModalFeatureSelectionVisible(true);
            } else if (warningArray.length) {
              setModalWarningVisible(true);
            } else if (data.info?.info_list.feature_selection) {
              setModalFeatureSelectionVisible(true);
            }

            const [defaultNSteps, defaultNWindows, maxNStep, maxNWindow] =
              getProjectionHorizonInputs(
                data.info?.info_list.nrows_training,
                data.info?.info_list.freq_num,
                frequency,
              );

            setMaxNWindows(maxNWindow);
            setMaxNSteps(maxNStep);

            const nWindowsRecomendation = getNWindowsRecommendation(
              data.info?.info_list.nrows_training,
              defaultNSteps,
              frequency,
            );

            setRecommendedNWindows(nWindowsRecomendation);

            setValue('forecastHorizon', defaultNSteps);
            setValue('NWindows', defaultNWindows);

            setFinalSettingsButtonDisabled(false);
            setFinalSettingsLoading(false);
            setForecastWarningVisible(false);
            setUserAlreadyOpenedFineTunning(false);

            setStep(3);
            handleResetAdvancedOptions(
              !!data?.info?.info_list.feature_selection,
            );
          }
        } catch (err) {
          const error: AxiosError<DataError> | any = err;
          const errorMessage =
            error.response?.data?.detail?.detail ??
            error.response?.data?.detail?.description ??
            translate('validationError');

          setFailedModalInfo({
            title: translate('requestFailed'),
            description: translateSomeMessagesFromBackend(
              errorMessage,
              user.language,
            ),
          });
          setFailedModalVisible(true);

          setFinalSettingsButtonDisabled(false);
          setFinalSettingsLoading(false);
        }
      }
    }
  };

  const handleResetAdvancedOptions = (
    activeFeatureSelection: boolean,
  ): void => {
    setValue('informationCriteria', 'AIC');
    setValue('accuracyCriteria', 'MAPE');
    setValue('crossValidationSummary', 'Mean');
    setValue('goldenVariables', []);
    setValue('group1', []);
    setValue('group2', []);
    setValue('group3', []);
    setValue('applyLog', true);
    setValue('seasonalDummies', true);
    setValue('explCollinearity', true);
    setValue('allowDrift', true);
    setValue('explVarAutoForecast', false);
    setValue('featSelectionLasso', activeFeatureSelection);
    setValue('featSelectionRandomForest', activeFeatureSelection);
    setValue('featCorrelationFilter', activeFeatureSelection);

    trigger('forecastHorizon');
    trigger('NWindows');
  };

  const handleDataView = async () => {
    let hasErrorOnInputs;
    setLoadingDataView(true);

    if (dateIsFormatted === true) {
      hasErrorOnInputs = await Promise.all([
        trigger('dependVariable'),
        trigger('dateTimeFeature'),
        trigger('explanatoryVariables'),
      ]);
    } else {
      hasErrorOnInputs = await Promise.all([
        trigger('dependVariable'),
        trigger('dateTimeFeature'),
        trigger('format'),
        trigger('explanatoryVariables'),
      ]);
    }

    if (!hasErrorOnInputs.includes(false)) {
      const dataset = getValues('fileDataSet');

      try {
        const requestBody = dateIsFormatted
          ? {
              var_y: getValues('dependVariable'),
              date_var: getValues('dateTimeFeature'),
              var_x: getValues('explanatoryVariables'),
            }
          : {
              var_y: getValues('dependVariable'),
              date_var: getValues('dateTimeFeature'),
              date_format: getValues('format').toUpperCase(),
              var_x: getValues('explanatoryVariables'),
            };

        if (user.isFeatureStore) {
          await apiv2.post(`/variables/${dataset.dataset_id}`, requestBody);
        } else {
          await api.post(`/variables/${dataset.dataset_id}`, requestBody);
        }

        dispatch(
          insertModelling({
            step: 2,
            form: getValues(),
            file: file ?? null,
            seriesFS: seriesFeatureStore,
            groupFS: groupFeatureStore,
            dummiesFromFS,
            lagVariables,
          }),
        );
        dispatch(clearProject());

        queryClient.invalidateQueries('dataview');
        navigate('/models/data-view/overview');
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error.response?.data?.detail?.detail ??
          error.response?.data?.detail?.description ??
          translate('validationError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: errorMessage,
        });
        setFailedModalVisible(true);
      }
    }
    setLoadingDataView(false);
  };

  const handleSelectDateFeature = (selectedDate: string): void => {
    const dateFeature = getValues('fileDataSet').date_info?.find(
      (dateVariable) => dateVariable.date_name === selectedDate,
    );

    if (dateFeature?.inferred === false) {
      setDateIsFormatted(true);
      // setFormattedDates(dateFeature?.formatted);
      setFrequency(dateFeature?.frequency);
      setSelectedDates(dateFeature?.original);
    } else {
      setDateIsFormatted(false);
      // setFormattedDates([]);
      setFrequency('');
      const dateVariable =
        getValues('fileDataSet').variables.dataset_head[
          getValues('dateTimeFeature')
        ];

      setSelectedDates(Object.values(dateVariable));
    }

    setValue('format', '');
    lagVariables.length > 0 && resetLags();
  };

  const formatDatesAndSetFrequency = (
    dateFormat: string,
    arrayDates: string[],
  ): void => {
    const newFormattedDates = arrayDates.map((dateString: string) =>
      format(parse(dateString, dateFormat, new Date()), 'yyyy-MMM-dd'),
    );
    const datesParsed = newFormattedDates.map((formattedDate) =>
      parse(formattedDate, 'yyyy-MMM-dd', new Date()),
    );
    // setFormattedDates(newFormattedDates);
    const frequencyAux = getDateFrequency(datesParsed);
    setFrequency(frequencyAux);

    if (frequencyAux !== 'Monthly') {
      clearFeatureStore();
    }

    if (getValues('explanatoryVariables')?.length > 0) {
      setLagVariablesDisabled(false);
    }

    lagVariables.length > 0 && resetLags();
  };

  const handleDateFormat = async (dateFormat: string): Promise<void> => {
    await trigger('format');
    try {
      formatDatesAndSetFrequency(dateFormat, selectedDates);
    } catch (error) {
      await trigger('format');
    }
  };

  const resetLags = async (withoutErrorModal?: boolean) => {
    const oldLags = lagVariables;
    try {
      const { data } = await api.post(
        `/variables/${getValues('fileDataSet').dataset_id}/lags`,
        {},
      );

      data &&
        (setLagVariables(data),
        setValue('lagVariables', []),
        setValue('lagNumber', 1),
        setValue('explanatoryVariables', [
          ...getValues('explanatoryVariables')
            ?.filter(
              (explVar: string) => !oldLags.some((lag) => lag === explVar),
            )
            .map((variable: string) => variable),
        ]),
        setWarningModalInfo({
          title: translate('resetLagsWarning'),
          description: translate('resetLagsWarnDescription'),
        }),
        setLagWarningModalVisible(true));
    } catch {
      withoutErrorModal
        ? (setWarningModalInfo({
            title: translate('resetLagsWarning'),
            description: translate('resetLagsWarnDescription'),
          }),
          setLagWarningModalVisible(true))
        : (setFailedModalInfo({
            title: translate('requestFailed'),
            description: translate('resetLagsError'),
          }),
          setFailedModalVisible(true));

      setLagVariables([]);
      setValue('lagVariables', []);
      setValue('lagNumber', 1);

      const options = getValues('fileDataSet')
        .variables.x_options.filter(
          (variable) => variable !== getValues('dependVariable'),
        )
        .concat(
          seriesFeatureStore.junctionWithoutRepetition.map(
            (serieFS) => serieFS.tag,
          ),
        );

      setValue(
        'explanatoryVariables',
        options.filter((option) =>
          getValues('explanatoryVariables').some(
            (explVar) => explVar === option,
          ),
        ),
      );
    }
  };

  const handleSelectExplanatoryVariables = async (
    valuesArray: any[],
    { action, removedValue }: any,
  ) => {
    const selectOptions = getValues('fileDataSet')
      .variables.x_options.filter(
        (variable) => variable !== getValues('dependVariable'),
      )
      .map((variable) => ({
        label: variable,
        value: variable,
      }))
      .concat(
        seriesFeatureStore.junctionWithoutRepetition.map((serieFS) => ({
          label: serieFS.tag,
          value: serieFS.tag,
        })),
      )
      .concat(
        lagVariables?.map((lagVariable) => ({
          label: lagVariable,
          value: lagVariable,
          isFixed: true,
        })),
      );

    switch (action) {
      case 'remove-value':
      case 'pop-value': {
        const removedOption = selectOptions.find(
          (option) => option.value === removedValue.value,
        );

        if (
          getValues('lagVariables')?.find(
            (lagVariable) => lagVariable === removedOption?.value,
          )
        ) {
          const oldLagsValues = getValues('lagVariables');

          try {
            setValue(
              'lagVariables',
              getValues('lagVariables').filter(
                (lagVar) => lagVar !== removedOption?.value,
              ),
            );
            const lags = getValues('lagVariables')?.reduce(
              (accumulator: any, actual: string) => ({
                ...accumulator,
                [actual]: getValues('lagNumber'),
              }),
              {},
            );

            const { data } = await api.post<string[]>(
              `/variables/${getValues('fileDataSet').dataset_id}/lags`,
              {
                lags,
              },
            );

            if (data) {
              const options = getValues('fileDataSet')
                .variables.x_options.filter(
                  (variable) => variable !== getValues('dependVariable'),
                )
                .concat(
                  seriesFeatureStore.junctionWithoutRepetition.map(
                    (serieFS) => serieFS.tag,
                  ),
                )
                .concat(data);

              setLagVariables(data);
              return options.filter((option) =>
                valuesArray.some((val) => val.value === option),
              );
            }
          } catch (error) {
            setFailedModalInfo({
              title: translate('requestFailed'),
              description: translate('resetLagsError'),
            });
            setFailedModalVisible(true);
            setValue('lagVariables', oldLagsValues);

            return [...getValues('explanatoryVariables')?.map((val) => val)];
          }
        }

        // @ts-expect-error lib com tipagem errada
        if (removedOption && removedOption.isFixed) {
          return [...getValues('explanatoryVariables')?.map((val) => val)];
        }

        return valuesArray.map((val: any) => val.value);
      }
      case 'clear': {
        lagVariables.length > 0 && resetLags(true);
        return valuesArray.map((val: any) => val.value);
      }
      default: {
        return valuesArray.map((val: any) => val.value);
      }
    }
  };

  function hasOneCardSelected(): boolean {
    if (!customPeriod) {
      const valuesCard = getValuesToFillCardsThroughFrequency(frequency);

      return valuesCard.some(
        (value) => value.nSteps === Number(getValues('forecastHorizon')),
      );
    }
    return false;
  }

  const handleSubmitModeling = async () => {
    if (!customPeriod) {
      if (!hasOneCardSelected()) {
        setErrorCard(true);
        return;
      }
    }

    const hasErrorOnInputs = await Promise.all([
      trigger('forecastHorizon'),
      trigger('NWindows'),
      trigger('applyLog'),
      trigger('seasonalDummies'),
    ]);

    if (!hasErrorOnInputs.includes(false)) {
      setLoadingModalVisible(true);

      const modelingData = {
        n_steps: getValues('forecastHorizon'),
        n_windows: getValues('NWindows'),
        log: getValues('applyLog'),
        seas_d: getValues('seasonalDummies'),
        info_crit: getValues('informationCriteria'),
        accuracy_crit: getValues('accuracyCriteria'),
        exclusions: [
          getValues('group1'),
          getValues('group2'),
          getValues('group3'),
        ],
        lasso: getValues('featSelectionLasso'),
        rf: getValues('featSelectionRandomForest'),
        corr: getValues('featCorrelationFilter'),
        avoid_collinear: getValues('explCollinearity'),
        golden_variables: getValues('goldenVariables'),
        fill_forecast: getValues('explVarAutoForecast'),
        cv_summary: getValues('crossValidationSummary'),
        allowdrift: getValues('allowDrift'),
      };

      try {
        const { data } = await api.post<Modeling>(
          `/modeling/${getValues('fileDataSet').dataset_id}`,
          modelingData,
        );

        if (data) {
          setConclusionModalData(data);
          setConclusionModalVisible(true);
          queryClient.invalidateQueries('projects');
        }
      } catch (err) {
        // const error: AxiosError<DataError> | any = err;
        // const errorMessage =
        //   error.response?.data?.detail?.description ??
        //   error.response?.data?.detail?.detail ??
        //   null;

        setFailedModalInfo({
          title: translate('modelingErrorTitle'),
          description: translate('modelingErrorDescr'),
        });

        setFailedModalVisible(true);
      }
      setLoadingModalVisible(false);
    }
  };

  const explanatoryVariables = watch('explanatoryVariables');

  useEffect(() => {
    const selectExplanatory = document.querySelector(
      '.select-explanatory-variables',
    );
    if (selectExplanatory) {
      const buttonDeleteExplanatoryVariables = selectExplanatory.querySelector(
        '.css-tlfecz-indicatorContainer',
      );

      if (buttonDeleteExplanatoryVariables) {
        buttonDeleteExplanatoryVariables.setAttribute(
          'data-cy',
          'button-delete-explanatory-variables',
        );
      }
    }
  }, [explanatoryVariables]);

  // const clearSeriesPickForMe = () => {
  //   if (!seriesFeatureStore.junctionWithoutRepetition.length) {
  //     return;
  //   }

  //   const seriesFSAux = {
  //     seriesGroup: [...seriesFeatureStore.seriesGroup],
  //     seriesPickForMe: [],
  //     junctionWithoutRepetition: [...seriesFeatureStore.seriesGroup],
  //   };

  //   const onlySeriesOfPickForMe = seriesFeatureStore.seriesPickForMe.filter(
  //     (seriePickForMe) =>
  //       !seriesFeatureStore.seriesGroup.some(
  //         (serieGroup) =>
  //           seriePickForMe.code === serieGroup.code &&
  //           seriePickForMe.tag === serieGroup.tag,
  //       ),
  //   );

  //   setValue('explanatoryVariables', [
  //     ...getValues('explanatoryVariables').filter(
  //       (explanatoryVar) =>
  //         !onlySeriesOfPickForMe.some(
  //           (onlySeriePickForMe) => onlySeriePickForMe.tag === explanatoryVar,
  //         ),
  //     ),
  //   ]);

  //   setFeatureStoreWarningInfo({
  //     title: 'Pick for me variables reseted!',
  //     description:
  //       'It was necessary to clear your Pick for me variables. Please configure again.',
  //   });

  //   setSeriesFeatureStore(seriesFSAux);
  // };

  const clearFeatureStore = () => {
    if (!seriesFeatureStore.junctionWithoutRepetition.length) {
      return;
    }

    const seriesFSAux: SeriesFeatureStore = {
      seriesGroup: [],
      seriesPickForMe: [],
      junctionWithoutRepetition: [],
    };

    setValue('explanatoryVariables', [
      ...getValues('explanatoryVariables').filter(
        (explanatoryVar) =>
          !seriesFeatureStore.junctionWithoutRepetition.some(
            (junctionFS) => junctionFS.tag === explanatoryVar,
          ),
      ),
    ]);

    setFeatureStoreWarningInfo({
      title: translate('featStoreResetWarnTitle'),
      description: translate('featStoreResetWarnDescr'),
    });

    setDummiesFromFS([]);
    setGroupFeatureStore([]);
    setSeriesFeatureStore(seriesFSAux);
  };

  const canUserCreateTimeSeriasAndClaas = (): boolean => {
    if (
      user.isClaaS &&
      user.isFaaS &&
      user.permissions.includes('create:project-claas') &&
      user.permissions.includes('create:projects')
    ) {
      return true;
    }
    return false;
  };

  const redirectToBackPage = () => {
    if (canUserCreateTimeSeriasAndClaas()) {
      navigate('/models/projects/new');
      return;
    }

    navigate('/models/projects');
  };

  const changeNSteps = (value: number): void => {
    setValue('forecastHorizon', value);
    setErrorCard(false);

    if (validationData?.info?.info_list) {
      const maxNWindowsUpdated = updateMaxNWindows(
        validationData?.info?.info_list.nrows_training,
        Number(value),
      );
      setMaxNWindows(maxNWindowsUpdated);

      const recomendedNWindowsUpdated = getNWindowsRecommendation(
        validationData.info?.info_list.nrows_training,
        Number(value),
        frequency,
      );

      setRecommendedNWindows(recomendedNWindowsUpdated);

      validationData?.info?.info_list?.nrows_training &&
        setForecastWarningVisible(
          getForecastWarning(
            validationData?.info?.info_list?.nrows_training,
            Number(value),
            Number(getValues('NWindows')),
          ),
        );
    }
  };

  const backToStep1 = () => {
    setStep(1);
    setLagVariables([]);
    setValue('lagVariables', []);
    setValue('lagNumber', 1);
    setValue('explanatoryVariables', [
      ...getValues('explanatoryVariables')
        ?.filter(
          (explVar: string) => !lagVariables.some((lag) => lag === explVar),
        )
        .map((variable: string) => variable),
    ]);
  };

  const isDisabledLagButton =
    !frequency ||
    !getValues('dependVariable') ||
    !getValues('explanatoryVariables')?.length ||
    lagVariablesDisabled ||
    finalSettingsButtonDisabled;

  return (
    <>
      <Head title={translate('modelingTitleHeader')} />

      <Container>
        <Tooltip
          id="tooltip-modelling-time-series"
          className="customTooltipTheme"
        />

        {step === 1 && (
          <>
            <ContainerComponents>
              <Card
                textCard={translate('step1BeginProject')}
                textDescription={translate('step1BeginProjectDescr')}
              />
              <ContainerItens>
                <ContentIcon>
                  <DivLabel>
                    <p>{translate('step1ProjectIcon')}</p>
                    <Info
                      size="1.125rem"
                      data-tooltip-id="tooltip-modelling-time-series"
                      data-tooltip-content={translate(
                        'step1ProjectIconTootlip',
                      )}
                    />
                  </DivLabel>
                  <DivIcon
                    data-testid="modalIconButton"
                    onClick={() => setModalVisibleIcons(true)}
                  >
                    {getValues('iconUrl') ? (
                      <img
                        data-testid="iconSelected"
                        src={getValues('iconUrl') ?? ''}
                        alt="icon_selected"
                      />
                    ) : (
                      <Loading style={{ marginTop: '16px' }} />
                    )}
                  </DivIcon>
                </ContentIcon>
                <ContentInput>
                  <Controller
                    name="projectName"
                    key="projectName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        data-testid="projectName"
                        label={translate('step1ProjectNameInputLabel')}
                        information={translate('step1ProjectNameInputInfo')}
                        placeholder={translate(
                          'step1ProjectNameInputPlaceholder',
                        )}
                        value={value}
                        onChange={({ target: { value: val } }) => {
                          onChange(val);
                          if (
                            val.length < 3 &&
                            val.match(regexFirstCaracter) &&
                            val.match(regexInputProjectName) &&
                            !errors.projectName?.message
                          ) {
                            return;
                          }
                          trigger('projectName');
                        }}
                        // @ts-expect-error typescript error
                        error={translate(errors.projectName?.message)}
                      />
                    )}
                  />

                  <Controller
                    name="fileDataSet"
                    key="fileDataSet"
                    control={control}
                    render={({ field: { value } }) => (
                      <InputFile
                        label={translate('step1UploadDatasetInputLabel')}
                        title={translate('step1UploadDatasetInputTitle')}
                        description={translate('dragDrop')}
                        information={translate('step1UploadDatasetInputInfo')}
                        file={file}
                        setFile={setFile}
                        value={value}
                        fileFormats={['.xlsx', '.csv', '.rds']}
                        onChange={() => {
                          const auxName = getValues('projectName');
                          const iconAux = getValues('iconUrl');
                          reset();
                          setValue('projectName', auxName);
                          setValue('iconUrl', iconAux);
                          setSelectedDates([]);
                          setFrequency('');
                          // setFormattedDates([]);
                          setSeriesFeatureStore(initialStateSeriesFeatureStore);
                          setGroupFeatureStore([]);
                          setDummiesFromFS([]);
                        }}
                        error={translate(
                          ((errors.file?.message ??
                            errors.fileDataSet?.dataset_id?.message ??
                            errors.fileDataSet?.dataset_name?.message ??
                            errors.fileDataSet?.variables?.date_name?.message ??
                            errors.fileDataSet?.variables?.x_options?.message ??
                            errors.fileDataSet?.variables?.y_options?.message ??
                            errors.fileDataSet?.variables?.dataset_head
                              ?.message) as any) ?? '',
                        )}
                        isDisabled={selectVariablesButtonLoading}
                        style={{ marginTop: '1.5rem' }}
                        dataCy="datasetInput"
                        isTimeSeries
                      />
                    )}
                  />
                </ContentInput>
              </ContainerItens>
              <ContentButtons>
                <Button
                  buttonType="naked"
                  data-testid="step1backbutton"
                  onClick={() => redirectToBackPage()}
                  data-cy="button-back-1"
                  icon={<CaretLeft size="1.125rem" />}
                >
                  {canUserCreateTimeSeriasAndClaas()
                    ? translate('modellingStartTitle')
                    : translate('backButtons')}
                </Button>
                <ProgressInDots step={step} />
                <Button
                  buttonType="primary"
                  data-testid="buttonStepOne"
                  onClick={() => handleSubmitSelectVariables()}
                  data-cy="button-select-variables"
                  disabled={selectVariablesButtonLoading}
                  loading={selectVariablesButtonLoading}
                  iconInvert
                  icon={
                    !selectVariablesButtonLoading ? (
                      <CaretRight size="1.125rem" />
                    ) : undefined
                  }
                >
                  {translate('step1SelectVariablesButton')}
                </Button>
              </ContentButtons>
            </ContainerComponents>
          </>
        )}

        {step === 2 && (
          <>
            <ContainerSelectVariables>
              <Card
                data-testid="step2Renderization"
                textCard={translate('step2SelectVariablesTitle')}
                textDescription={translate('step2SelectVariablesDescr')}
                style={{ marginBottom: '32px' }}
              />
              <ContentItens>
                <div>
                  <Controller
                    name="dependVariable"
                    key="dependVariable"
                    control={control}
                    defaultValue={(() => {
                      const firstOption =
                        getValues('fileDataSet').variables.y_options[0];
                      if (firstOption) {
                        return firstOption;
                      }
                      return undefined;
                    })()}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        data-testid="selectDependVar"
                        label={translate('step2DependentVariableInputLabel')}
                        information={translate(
                          'step2DependentVariableInputTooltip',
                        )}
                        value={{
                          label: value,
                          value,
                        }}
                        onChange={(val: any) => {
                          if (val.value !== getValues('dependVariable')) {
                            const explanatoryOptions = [
                              ...getValues('explanatoryVariables'),
                              getValues('dependVariable'),
                            ].filter((variable) => variable !== val.value);

                            if (
                              explanatoryOptions &&
                              explanatoryOptions.length > 0
                            ) {
                              setLagVariablesDisabled(false);
                            } else {
                              setLagVariablesDisabled(true);
                            }

                            setValue(
                              'explanatoryVariables',
                              explanatoryOptions,
                            );

                            clearFeatureStore();

                            lagVariables.length > 0 && resetLags();
                            onChange(val.value);
                          }
                        }}
                        // @ts-expect-error typescript bug
                        error={translate(errors.dependVariable?.message)}
                        options={getValues(
                          'fileDataSet',
                        ).variables.y_options.map((variable) => ({
                          label: variable,
                          value: variable,
                        }))}
                      />
                    )}
                  />

                  <Controller
                    name="dateTimeFeature"
                    key="dateTimeFeature"
                    control={control}
                    defaultValue={(() => {
                      const dateNames =
                        getValues('fileDataSet').variables.date_name;
                      if (dateNames.length >= 1) {
                        const dateVariable =
                          getValues('fileDataSet').variables.dataset_head[
                            dateNames[0]
                          ];

                        if (!selectedDates.length) {
                          setSelectedDates(Object.values(dateVariable));
                        }
                        return dateNames[0];
                      }
                      return undefined;
                    })()}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label={translate('step2DateTimeFeatureLabel')}
                        value={{
                          label: value,
                          value,
                        }}
                        onChange={(val: any) => {
                          if (val.value !== getValues('dateTimeFeature')) {
                            clearFeatureStore();
                            onChange(val.value);
                            handleSelectDateFeature(val.value);
                          }
                        }}
                        options={getValues(
                          'fileDataSet',
                        ).variables.date_name.map((dateVariable) => ({
                          label: dateVariable,
                          value: dateVariable,
                        }))}
                        // @ts-expect-error typescript bug
                        error={translate(errors.dateTimeFeature?.message)}
                      />
                    )}
                  />

                  {dateIsFormatted === false && (
                    <ContentSelectFormat>
                      <Controller
                        name="format"
                        key="format"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label={translate('step2FormatDateSelectLabel')}
                            information={translate('step2FormatDateSelectInfo')}
                            options={formatOptions}
                            value={{
                              label: value,
                              value,
                            }}
                            onChange={(val: any) => {
                              onChange(val.value);
                              handleDateFormat(val.value);
                            }}
                            // @ts-expect-error typescript bug
                            error={translate(errors.format?.message)}
                          />
                        )}
                      />

                      <span
                        style={{
                          left: user.language === 'en-us' ? '52px' : '60px',
                        }}
                      >
                        {frequency && (
                          <>
                            ({translate('step2Frequency')}
                            <b> {translate(frequency.toLowerCase())}</b>)
                          </>
                        )}
                      </span>
                    </ContentSelectFormat>
                  )}

                  <Controller
                    name="explanatoryVariables"
                    key="explanatoryVariables"
                    control={control}
                    defaultValue={getValues(
                      'fileDataSet',
                    ).variables.x_options.filter(
                      (variable) =>
                        variable !==
                        getValues('fileDataSet').variables.y_options[0],
                    )}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label={translate('step2ExplanatoryVariablesLabel')}
                        information={translate('step2ExplanatoryVariablesInfo')}
                        isMulti
                        placeholder={translate('select')}
                        className="select-explanatory-variables"
                        options={getValues('fileDataSet')
                          .variables.x_options.filter(
                            (variable) =>
                              variable !== getValues('dependVariable'),
                          )
                          .map((variable) => ({
                            label: variable,
                            value: variable,
                          }))
                          .concat(
                            seriesFeatureStore.junctionWithoutRepetition.map(
                              (serieFS) => ({
                                label: serieFS.tag,
                                value: serieFS.tag,
                              }),
                            ),
                          )
                          .concat(
                            lagVariables?.map((lagVariable) => ({
                              label: lagVariable,
                              value: lagVariable,
                              isFixed: true,
                            })),
                          )}
                        value={value.map((val) => ({
                          label: val,
                          value: val,
                        }))}
                        onChange={async (
                          valArray: any,
                          { action, removedValue }: any,
                        ) => {
                          const values = await handleSelectExplanatoryVariables(
                            valArray,
                            { action, removedValue },
                          );

                          values && onChange(values);

                          if (values && values.length > 0) {
                            setLagVariablesDisabled(false);
                          } else {
                            setLagVariablesDisabled(true);
                          }
                        }}
                        // @ts-expect-error typescript bug
                        error={translate(errors?.explanatoryVariables)}
                        // @ts-expect-error tipagem errada na lib
                        styles={explanatoryColorStyles}
                      />
                    )}
                  />
                </div>
              </ContentItens>

              <ContentButtons>
                <Button
                  data-testid="step2BackButton"
                  onClick={backToStep1}
                  disabled={finalSettingsLoading}
                  buttonType="naked"
                  data-cy="button-back-1"
                  icon={<CaretLeft size="1.125rem" />}
                >
                  {translate('step1BeginProject')}
                </Button>
                <ProgressInDots
                  step={step}
                  backStep1={backToStep1}
                  isLoadingAnotherStep={finalSettingsLoading}
                />
                <Button
                  buttonType="primary"
                  data-testid="step2ConfirmationButton"
                  onClick={() => handleSubmitFinalSettings()}
                  data-cy="button-final-settings"
                  loading={finalSettingsLoading}
                  disabled={finalSettingsButtonDisabled}
                  iconInvert
                  icon={
                    !finalSettingsLoading ? (
                      <CaretRight size="1.125rem" />
                    ) : undefined
                  }
                >
                  {translate('step2FinalSettingsButton')}
                </Button>
              </ContentButtons>
            </ContainerSelectVariables>

            {user.isFeatureStore && modalVisibleFeatureStore && (
              <FeatureStore
                visible={modalVisibleFeatureStore}
                setVisible={setModalVisibleFeatureStore}
                datasetId={getValues('fileDataSet').dataset_id}
                seriesFeatureStore={seriesFeatureStore}
                setSeriesFeatureStore={setSeriesFeatureStore}
                getValues={getValues}
                setValue={setValue}
                setLagVariablesDisabled={setLagVariablesDisabled}
                groupFeatureStore={groupFeatureStore}
                setGroupFeatureStore={setGroupFeatureStore}
                setResponseIntegrationFS={setResponseIntegrationFS}
                setDummiesFromFS={setDummiesFromFS}
                dummiesFromFS={dummiesFromFS}
              />
            )}

            <ModalStatus
              visible={!!responseIntegrationFS}
              setVisible={() => setResponseIntegrationFS(undefined)}
              title={translateSomeMessagesFromBackend(
                responseIntegrationFS?.status.type ?? '',
                user.language,
              )}
              message={translateSomeMessagesFromBackend(
                responseIntegrationFS?.status.text ?? '',
                user.language,
              )}
            />

            <LagsModal
              variables={getValues('fileDataSet').variables.lag_options.concat(
                seriesFeatureStore.junctionWithoutRepetition.map(
                  (serieFS) => serieFS.tag,
                ),
              )}
              getValues={getValues}
              setValue={setValue}
              control={control}
              trigger={trigger}
              errors={errors}
              visible={lagsModalVisible}
              setVisible={setLagsModalVisible}
              lagVariables={lagVariables}
              setLagVariables={setLagVariables}
              setFailedModalInfo={setFailedModalInfo}
              setFailedModalVisible={setFailedModalVisible}
              setWarningModalInfo={setWarningModalInfo}
              setWarningModalVisible={setLagWarningModalVisible}
              dummiesFromFS={dummiesFromFS}
              frequency={frequency}
            />

            <LagsAndFeatureStoreModal
              visible={lagWarningModalVisible}
              setVisible={setLagWarningModalVisible}
              warningInfo={warningModalInfo}
            />

            <LagsAndFeatureStoreModal
              visible={featureStoreWarningInfo !== undefined}
              setVisible={() => setFeatureStoreWarningInfo(undefined)}
              warningInfo={featureStoreWarningInfo}
            />

            <ModalLoading
              visible={downloadDatasetLoading}
              message="Downloading..."
            />

            <ContainerFooter>
              <div>
                <Card
                  textCard={translate('step2VisualizeDataTitle')}
                  textDescription={translate('step2VisualizeDataDescr')}
                  style={{ marginBottom: '36px' }}
                />
                <div>
                  <Button
                    buttonType="secondary"
                    data-testid="download-dataset"
                    onClick={handleDownloadDatasetButton}
                  >
                    <DownloadSimple size="1.125rem" />
                    {translate('step2DownloadDatasetButton')}
                  </Button>
                  <Button
                    data-testid="step2VisualizeDataButton"
                    buttonType="primary"
                    onClick={() => handleDataView()}
                    data-cy="button-visualize-data"
                    loading={loadingDataView}
                    disabled={
                      !!(loadingDataView || finalSettingsButtonDisabled)
                    }
                  >
                    {translate('step2DataviewButton')}
                  </Button>
                </div>
              </div>
              <div>
                <Card
                  textCard={translate('step2EnrichYourDataset')}
                  textDescription={translate('step2EnrichYourDatasetDescr')}
                  style={{ marginBottom: '36px' }}
                />
                <div>
                  <div
                    data-tooltip-id="tooltip-modelling-time-series"
                    data-tooltip-content={
                      isDisabledLagButton
                        ? translate('step2LagButtonIsDisabledHover')
                        : undefined
                    }
                  >
                    <Button
                      buttonType="secondary"
                      icon={<Plus size="1.125rem" weight="bold" />}
                      data-testid="button-add-lags"
                      data-cy="button-add-lags"
                      onClick={() => setLagsModalVisible(true)}
                      disabled={isDisabledLagButton}
                    >
                      {translate('step2LagsButton')}
                    </Button>
                  </div>

                  <div
                    data-tooltip-id="tooltip-modelling-time-series"
                    data-tooltip-content={
                      !user.isFeatureStore
                        ? translate(
                            'step2MessageYouDoNotHaveAccessToTheFeatureStore',
                          )
                        : frequency !== 'Monthly'
                        ? translate('step2OnlyAvailableForMonthlyData')
                        : undefined
                    }
                  >
                    <Button
                      data-testid="btnAddVariables"
                      icon={<Plus size="1.125rem" weight="bold" />}
                      buttonType="primary"
                      onClick={() => {
                        setModalVisibleFeatureStore(true);
                      }}
                      data-cy="button-add-variables"
                      disabled={
                        !!(frequency !== 'Monthly') ||
                        finalSettingsButtonDisabled === true ||
                        !user.isFeatureStore
                      }
                    >
                      {translate('step2FSButton')}
                    </Button>
                  </div>
                </div>
              </div>
            </ContainerFooter>
          </>
        )}

        {step === 3 && (
          <>
            <Content>
              <Card
                data-testid="step3Renderization"
                textCard={translate('step3Title')}
                textDescription={translate('step3Descr')}
                style={{ marginBottom: '24px' }}
              />

              <ContentStep3>
                <ContentInputToggleStep3>
                  <p>{translate('step3ForecastOptimizedPeriod')}</p>
                  <ToggleSwitch
                    checked={customPeriod}
                    label={translate('step3CustomPeriod')}
                    data-testid="toggle-switch-custom-period"
                    data-cy="toggle-switch-custom-period"
                    onChange={(e) => setCustomPeriod(e.target.checked)}
                  />
                </ContentInputToggleStep3>
                {!customPeriod ? (
                  <CardStep3
                    frequency={frequency}
                    nSteps={getValues('forecastHorizon')}
                    changeNSteps={changeNSteps}
                    isError={errorCard}
                  />
                ) : (
                  <>
                    <SliderNSteps
                      nSteps={getValues('forecastHorizon')}
                      maxNsteps={maxNSteps}
                      changeNSteps={changeNSteps}
                    />
                  </>
                )}

                {validationData && (
                  <ChartHistoricalForecast
                    historicalDates={
                      validationData.info.info_list?.in_sample_dates
                    }
                    forecastDates={
                      validationData.info.info_list?.out_sample_dates ?? null
                    }
                    frequency={frequency}
                    nsteps={
                      !customPeriod
                        ? hasOneCardSelected()
                          ? getValues('forecastHorizon')
                          : null
                        : getValues('forecastHorizon')
                    }
                  />
                )}
              </ContentStep3>
              <ContentButtons>
                <Button
                  data-testid="modellingBackBtn"
                  onClick={() => setStep(2)}
                  buttonType="naked"
                  data-cy="button-back-2"
                  icon={<CaretLeft size="1.125rem" />}
                >
                  {translate('step2SelectVariablesTitle')}
                </Button>
                {/* <Button
                    data-testid="modellingScriptBtn"
                    onClick={() => setModalVisibleScript(true)}
                    data-cy="button-api-script"
                    icon={<Terminal size="1rem" />}
                    buttonType="secondary"
                  >
                    API Script
                  </Button> */}
                <ProgressInDots
                  step={step}
                  backStep1={() => {
                    setStep(1);
                    setLagVariables([]);
                  }}
                  backStep2={() => setStep(2)}
                />
                <ContentButtonAdvancedAndModeling>
                  <AdvancedOptionsButton
                    icon={
                      <GearSix
                        size="1.25rem"
                        style={{
                          marginRight: '0.5rem',
                          marginLeft: '-1.25rem',
                        }}
                      />
                    }
                    data-testid="modellingAdvOptionsBtn"
                    data-cy="button-advanced-options"
                    onClick={() => {
                      setModalVisibleAdvanced(true);
                      setUserAlreadyOpenedFineTunning(true);
                    }}
                    buttonType="secondary"
                  >
                    {translate('step3FineTunning')}
                  </AdvancedOptionsButton>
                  <Button
                    buttonType="primary"
                    data-testid="modelingConfirmBtn"
                    onClick={handleSubmitModeling}
                    data-cy="button-modeling"
                  >
                    {translate('step3ModelingButton')}
                  </Button>
                </ContentButtonAdvancedAndModeling>
              </ContentButtons>
            </Content>

            {modalWarningNWindowsVisible && (
              <ModalWarningNWindows
                visible
                setVisible={setModalWarningNWindowsVisible}
                onClickValidadeSetting={() => {
                  setModalWarningNWindowsVisible(false);
                  setModalVisibleAdvanced(true);
                }}
                NWindows={getValues('NWindows')}
              />
            )}
          </>
        )}
      </Container>

      <FailedModal
        visible={modalVisibleFailed}
        setVisible={setModalVisibleFailed}
      />

      {(!!validationData?.info?.info_list?.feature_selection &&
        !!validationData?.info.warningArray.length && (
          <>
            <FeatureSelectionModal
              data-testid="featureSelectionModalRenderization"
              visible={modalFeatureSelectionVisible}
              setVisible={setModalFeatureSelectionVisible}
              showNextModal={setModalWarningVisible}
            />

            <ModalWarningVariables
              data-testid="warningModalRenderization"
              visible={modalWarningVisible}
              setVisible={setModalWarningVisible}
              title={translateSomeMessagesFromBackend(
                validationData?.info?.warningArray[0].title ?? '',
                user.language,
              )}
              warningMessages={validationData.info.warningArray}
            />
          </>
        )) ||
        (!!validationData?.info?.info_list?.feature_selection && (
          <FeatureSelectionModal
            data-testid="featureSelectionModalRenderization"
            visible={modalFeatureSelectionVisible}
            setVisible={setModalFeatureSelectionVisible}
          />
        )) ||
        (!!validationData?.info.warningArray.length && (
          <ModalWarningVariables
            data-testid="warningModalRenderization"
            visible={modalWarningVisible}
            setVisible={setModalWarningVisible}
            title={translateSomeMessagesFromBackend(
              validationData.info.warningArray[0].title ?? '',
              user.language,
            )}
            warningMessages={validationData.info.warningArray}
          />
        ))}

      <ModalLoading
        visible={loadingModalVisible}
        message={translate('modellingModalWarmingUpTheEnginesTitle')}
        subMessage={translate('modellingModalWarmingUpTheEnginesDescription')}
      />

      {failedModalVisible && failedModalInfo && (
        <FailedModal
          visible={failedModalVisible}
          setVisible={setFailedModalVisible}
          errorInfo={failedModalInfo}
        />
      )}

      {modalVisibleAdvanced && (
        <ModalAdvanced
          visible
          setVisible={setModalVisibleAdvanced}
          featureSelection={validationData?.info?.info_list.feature_selection}
          control={control}
          getValues={getValues}
          setValue={setValue}
          goldenVariables={variablesData?.status.golden_variables}
          removedVars={concatRemovedVariables([
            validationData?.info?.warning_list?.excluded_ld?.variables,
            validationData?.info?.warning_list?.removed_cols?.variables,
            dummiesFromFS,
          ])}
          validationData={validationData}
          setForecastWarningVisible={setForecastWarningVisible}
          forecastWarningVisible={forecastWarningVisible}
          recommendedNWindows={recommendedNWindows}
          trigger={trigger}
          maxNWindows={maxNWindows}
          errors={errors}
        />
      )}

      {/* {modalVisibleScript && (
        <ModalScript
          visible={modalVisibleScript}
          setVisible={setModalVisibleScript}
          settingsData={getValues()}
          seriesFeatureStore={seriesFeatureStore}
        />
      )} */}

      {conclusionModalVisible && conclusionModalData && (
        <ConclusionModal
          visible={conclusionModalVisible}
          setVisible={setConclusionModalVisible}
          description={conclusionModalData.potential_comb}
          featureSelectionActive={
            !!validationData?.info?.info_list?.feature_selection
          }
        />
      )}

      <Controller
        name="iconUrl"
        control={control}
        render={({ field: { onChange, value } }) => (
          <ModalIcons
            visible={modalVisibleIcons}
            setIcon={setIcon}
            setVisible={setModalVisibleIcons}
            onChangHookForm={onChange}
            value={value}
            content={{
              title: translate('step1IconModalTitle'),
              description: translate('step1IconModalDescr'),
            }}
          />
        )}
      />
    </>
  );
};
