import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Info } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Select } from 'src/components/Select';
import light from 'src/styles/themes/light';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import api from 'src/models/service/api';
import { frequencyLatestData } from 'src/utils/getLatestData';
import { RadioButton } from 'src/components/RadioButton';
import {
  BreakdownFrequency,
  BreakdownModelType,
} from 'src/models/redux/reducers/AIUserSelectionOptions';
import { changeModelExplorerIsLatestDataActive } from 'src/models/redux/reducers/ModelExplorer';
import { sleep } from 'src/utils/sleep';

import {
  Content,
  CardTooltip,
  Frequencies,
  Options,
  ToggleLatestData,
} from './styles';
import { BreakdownChart } from './BreakdownChart';

interface BreakdownError {
  response?: {
    data?: {
      detail?: {
        detail?: string;
        description: string;
      };
    };
  };
}

interface ArimaModelBreakdown {
  last_historical_date: string | null;
  line: {
    data: string[];
    y: number[];
  };
  bar: {
    data: string[];
    y: number[];
    type: string;
  }[];
}

interface BreakdownProps {
  modelId: number;
}

export const Breakdown: React.FC<BreakdownProps> = ({ modelId }) => {
  const [qoqData, setQoQData] = useState<ArimaModelBreakdown>();
  const [yoyData, setYoYData] = useState<ArimaModelBreakdown>();
  const [originalData, setOriginalData] = useState<ArimaModelBreakdown>();

  const [frequency, setFrequency] = useState<BreakdownFrequency>('original');
  const [qoqModelType, setQoQModelType] = useState<BreakdownModelType>('stock');
  const [yoyModelType, setYoYModelType] = useState<BreakdownModelType>('stock');

  const [chartFrequency, setChartFrequency] = useState(frequency);

  useEffect(() => {
    (async () => {
      await sleep(50);
      setChartFrequency(frequency);
    })();
  }, [frequency]);

  const {
    project,
    modelExplorerOptions: {
      modelSpecifics: {
        arima: { isLatestDataActive },
      },
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const {
    data: modelBreakDownData,
    isLoading: modelBreakDownLoading,
    isFetching: modelBreakDownFetching,
    isError: modelBreakDownIsError,
    error: modelBreakdownError,
  } = useQuery<ArimaModelBreakdown, BreakdownError>(
    [
      'model production',
      'model breakdown',
      project.id,
      project.selectedY?.id,
      modelId,
    ],
    async () => {
      const response = await api.get<ArimaModelBreakdown>(
        `/projects/${project.id}/${project.selectedY?.id}/models/arima/${modelId}/breakdown`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!project.id && !!project.selectedY?.id && !!modelId,
    },
  );

  const {
    data: modelBreakDownQoQData,
    isLoading: modelBreakDownQoQLoading,
    isFetching: modelBreakDownQoQFetching,
    isError: modelBreakDownQoQIsError,
    error: modelBreakdownQoQError,
  } = useQuery<ArimaModelBreakdown, BreakdownError>(
    [
      'arima model breakdown QoQ',
      project.id,
      project.selectedY?.id,
      modelId,
      qoqModelType,
    ],
    async () => {
      const response = await api.get<ArimaModelBreakdown>(
        `/projects/${project.id}/${project.selectedY?.id}/models/arima/${modelId}/breakdown_qoq/${qoqModelType}`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!project.id && !!project.selectedY?.id && !!modelId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: modelBreakDownYoYData,
    isLoading: modelBreakDownYoYLoading,
    isFetching: modelBreakDownYoYFetching,
    isError: modelBreakDownYoYIsError,
    error: modelBreakdownYoYError,
  } = useQuery<ArimaModelBreakdown, BreakdownError>(
    [
      'arima model breakdown YoY',
      project.id,
      project.selectedY?.id,
      modelId,
      yoyModelType,
    ],
    async () => {
      const response = await api.get<ArimaModelBreakdown>(
        `/projects/${project.id}/${project.selectedY?.id}/models/arima/${modelId}/breakdown_yoy/${yoyModelType}`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!project.id && !!project.selectedY?.id && !!modelId,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  function verifyError(error?: string | null): string {
    if (error) {
      if (
        error ===
        'Breakdown is not available for daily, weekly or fortnightly data.'
      ) {
        return translate(
          'modelSpecificsArimaBreakdownIsNotAvailableDailyWeeklyFortnightly',
        );
      }
      if (error === 'Only available for monthly data.') {
        return translate(
          'modelSpecificsArimaBreakdownOnlyAvailableForMonthlyData',
        );
      }
      if (
        error === 'Breakdown is only available for the first 3 arima models.'
      ) {
        return translate(
          'modelSpecificsArimaBreakdownIsNotAvailableFirst3Models',
        );
      }
    }

    return translate('modelSpecErrors').replace(
      'XXX',
      translate('modelSpecModelBreakdown'),
    );
  }

  function handleChangeModelType(
    frequencyAux: 'quarterly' | 'yearly',
    value: BreakdownModelType,
  ) {
    if (frequencyAux === 'quarterly') {
      setQoQModelType(value);
    } else {
      setYoYModelType(value);
    }
  }

  function handleSelectFrequency(frequencyAux: BreakdownFrequency) {
    setFrequency(frequencyAux);
  }

  const isLatestDataDisabled = useMemo(() => {
    const data =
      frequency === 'original'
        ? modelBreakDownData
        : frequency === 'quarterly'
        ? modelBreakDownQoQData
        : modelBreakDownYoYData;

    const totalLine = data?.line.data.length ?? 0;
    let totalBar = 0;

    for (const bar of data?.bar ?? []) {
      totalBar = Math.max(bar.y.length);
    }

    const total = Math.max(totalLine, totalBar);

    if (frequency === 'original') {
      return (
        total <=
        frequencyLatestData[
          project.selectedY?.info?.frequency as keyof typeof frequencyLatestData
        ]
      );
    }
    if (frequency === 'quarterly') {
      return total <= frequencyLatestData.quarterly;
    }
    if (frequency === 'yearly') {
      return total <= frequencyLatestData.annual;
    }

    return true;
  }, [
    frequency,
    modelBreakDownData,
    modelBreakDownQoQData,
    modelBreakDownYoYData,
    project.selectedY?.info?.frequency,
  ]);

  function handleActiveLatestData(value: boolean) {
    dispatch(
      changeModelExplorerIsLatestDataActive({
        selection: 'modelSpecifics',
        type: 'arima',
        value,
      }),
    );
  }

  const getLatestData = useCallback(
    (
      data: ArimaModelBreakdown,
      frequencyAux:
        | 'monthly'
        | 'bimonthly'
        | 'quarterly'
        | 'half-year'
        | 'annual',
    ): ArimaModelBreakdown => {
      const quantity = frequencyLatestData[frequencyAux];
      const totalLine = data.line.y.length;
      let totalBar = 0;
      let hasForecast = false;

      for (const bar of data.bar) {
        totalBar = Math.max(bar.y.length);
      }

      if (quantity > totalLine && quantity > totalBar) {
        return data;
      }

      let initialIndex = data.line.data.findIndex((date) => {
        if (frequency === 'yearly') {
          return (
            date ===
            new Date(`${data.last_historical_date}T00:00`)
              .getFullYear()
              .toString()
          );
        }
        return date === data.last_historical_date;
      });

      let forecastLineDate: string[] = [];
      let forecastLineValue: number[] = [];

      if (initialIndex !== -1) {
        hasForecast = true;
        forecastLineDate = data.line.data.slice(
          initialIndex,
          initialIndex + quantity / 3,
        );
        forecastLineValue = data.line.y.slice(
          initialIndex,
          initialIndex + quantity / 3,
        );
      } else {
        initialIndex = data.line.data.length - 1;
      }

      const historicalLineDate = data.line.data.slice(
        initialIndex -
          quantity +
          forecastLineDate.length +
          (hasForecast === false ? +1 : 0),
        initialIndex + (hasForecast === false ? +1 : 0),
      );

      const historicalLineValue = data.line.y.slice(
        initialIndex -
          quantity +
          forecastLineValue.length +
          (hasForecast === false ? +1 : 0),
        initialIndex + (hasForecast === false ? +1 : 0),
      );

      const barData = [];

      for (const bar of data.bar) {
        let forecastBarDate: string[] = [];
        let forecastBarValue: number[] = [];

        if (hasForecast) {
          forecastBarDate = bar.data.slice(
            initialIndex,
            initialIndex + quantity / 3,
          );
          forecastBarValue = bar.y.slice(
            initialIndex,
            initialIndex + quantity / 3,
          );
        }

        const historicalBarDate = bar.data.slice(
          initialIndex -
            quantity +
            forecastBarDate.length +
            (hasForecast === false ? +1 : 0),
          initialIndex + (hasForecast === false ? +1 : 0),
        );

        const historicalBarValue = bar.y.slice(
          initialIndex -
            quantity +
            forecastBarValue.length +
            (hasForecast === false ? +1 : 0),
          initialIndex + (hasForecast === false ? +1 : 0),
        );

        barData.push({
          type: bar.type,
          data: [...historicalBarDate, ...forecastBarDate],
          y: [...historicalBarValue, ...forecastBarValue],
        });
      }

      return {
        line: {
          data: [...historicalLineDate, ...forecastLineDate],
          y: [...historicalLineValue, ...forecastLineValue],
        },
        bar: barData,
        last_historical_date: data.last_historical_date,
      };
    },
    [frequency],
  );

  useEffect(() => {
    if (!isLatestDataActive) {
      if (modelBreakDownData) {
        setOriginalData(modelBreakDownData);
      }
      if (modelBreakDownQoQData) {
        setQoQData(modelBreakDownQoQData);
      }
      if (modelBreakDownYoYData) {
        setYoYData(modelBreakDownYoYData);
      }
    } else {
      if (modelBreakDownData) {
        switch (project.selectedY?.info?.frequency) {
          case 'monthly':
            setOriginalData(getLatestData(modelBreakDownData, 'monthly'));
            break;
          case 'bimonthly':
            setOriginalData(getLatestData(modelBreakDownData, 'bimonthly'));
            break;
          case 'quarterly':
            setOriginalData(getLatestData(modelBreakDownData, 'quarterly'));
            break;
          case 'half-year':
            setOriginalData(getLatestData(modelBreakDownData, 'half-year'));
            break;
          case 'annual':
            setOriginalData(getLatestData(modelBreakDownData, 'annual'));
            break;
          default:
            if (modelBreakDownData) {
              setOriginalData(modelBreakDownData);
            }
            break;
        }
      }
      if (modelBreakDownQoQData) {
        setQoQData(getLatestData(modelBreakDownQoQData, 'quarterly'));
      }
      if (modelBreakDownYoYData) {
        setYoYData(getLatestData(modelBreakDownYoYData, 'annual'));
      }
    }
  }, [
    modelBreakDownData,
    modelBreakDownQoQData,
    modelBreakDownYoYData,
    isLatestDataActive,
    project,
    getLatestData,
  ]);

  useEffect(() => {
    const originalCondition =
      (!modelBreakDownLoading &&
        !modelBreakDownFetching &&
        isLatestDataDisabled) ||
      modelBreakDownIsError;
    const qoqCondition =
      (!modelBreakDownQoQLoading &&
        !modelBreakDownQoQFetching &&
        isLatestDataDisabled) ||
      modelBreakDownQoQIsError;
    const yoyCondition =
      (!modelBreakDownYoYLoading &&
        !modelBreakDownYoYFetching &&
        isLatestDataDisabled) ||
      modelBreakDownYoYIsError;

    if (
      (frequency === 'original' && originalCondition) ||
      (frequency === 'quarterly' && qoqCondition) ||
      (frequency === 'yearly' && yoyCondition)
    ) {
      dispatch(
        changeModelExplorerIsLatestDataActive({
          selection: 'modelSpecifics',
          type: 'arima',
          value: false,
        }),
      );
    }
  }, [
    dispatch,
    isLatestDataDisabled,
    modelBreakDownLoading,
    modelBreakDownQoQLoading,
    modelBreakDownYoYLoading,
    modelBreakDownFetching,
    modelBreakDownQoQFetching,
    modelBreakDownYoYFetching,
    modelBreakDownIsError,
    modelBreakDownQoQIsError,
    modelBreakDownYoYIsError,
    frequency,
  ]);

  let errorMessage: string | null = null;
  let isLoading = true;
  let dataToShow: ArimaModelBreakdown | null = null;

  let modelType: BreakdownModelType | null = null;

  if (frequency === 'original') {
    errorMessage = modelBreakDownIsError
      ? verifyError(
          modelBreakdownError?.response?.data?.detail?.detail ??
            modelBreakdownError?.response?.data?.detail?.description,
        )
      : null;

    isLoading =
      modelBreakDownLoading ||
      modelBreakDownFetching ||
      (!!modelBreakDownData && !originalData);

    dataToShow = originalData || null;
  } else if (frequency === 'quarterly') {
    errorMessage = modelBreakDownQoQIsError
      ? verifyError(
          modelBreakdownQoQError?.response?.data?.detail?.detail ??
            modelBreakdownQoQError?.response?.data?.detail?.description,
        )
      : null;

    isLoading =
      modelBreakDownQoQLoading ||
      modelBreakDownQoQFetching ||
      (!!modelBreakDownQoQData && !qoqData);

    dataToShow = qoqData || null;

    modelType = qoqModelType;
  } else {
    errorMessage = modelBreakDownYoYIsError
      ? verifyError(
          modelBreakdownYoYError?.response?.data?.detail?.detail ??
            modelBreakdownYoYError?.response?.data?.detail?.description,
        )
      : null;

    isLoading =
      modelBreakDownYoYLoading ||
      modelBreakDownYoYFetching ||
      (!!modelBreakDownYoYData && !yoyData);

    dataToShow = yoyData || null;

    modelType = yoyModelType;
  }

  const hasTrimestralAndAnnual = project.appInfo?.engine?.version !== '0.5.0';

  return (
    <div className="containerLinear">
      <CardTooltip>
        <Card
          textCard={translate('modelInProductionExplainingResults')}
          textDescription={translate(
            'modelInProductionExplainingResultsDescription',
          )}
        />
        <Content>
          <Info
            size="1.25rem"
            data-tooltip-id="models-time-series-model-breakdown"
            data-tooltip-html={translate('modelInProductionExplainingInfo')}
            style={{ marginTop: '0.5rem', color: light.colors.gray4 }}
          />
          <Tooltip
            id="models-time-series-model-breakdown"
            className="customTooltipTheme"
          />
        </Content>
      </CardTooltip>

      <Options>
        <Frequencies>
          <span>{translate('frequency')}</span>
          <div>
            <RadioButton
              label="Original"
              onChange={() => handleSelectFrequency('original')}
              checked={frequency === 'original'}
              dataCy="breakdown-radio-original"
            />

            {project.selectedY?.info?.frequency === 'monthly' && (
              <>
                <div
                  data-tooltip-id="models-time-series-model-breakdown"
                  data-tooltip-html={
                    !hasTrimestralAndAnnual
                      ? translate('temporarily_unavailable')
                      : undefined
                  }
                >
                  <RadioButton
                    label={translate('quarterly')}
                    onChange={() => handleSelectFrequency('quarterly')}
                    checked={frequency === 'quarterly'}
                    disabled={!hasTrimestralAndAnnual}
                    dataCy="breakdown-radio-quarterly"
                  />
                </div>

                <div
                  data-tooltip-id="models-time-series-model-breakdown"
                  data-tooltip-html={
                    !hasTrimestralAndAnnual
                      ? translate('temporarily_unavailable')
                      : undefined
                  }
                >
                  <RadioButton
                    label={translate('annual')}
                    onChange={() => handleSelectFrequency('yearly')}
                    checked={frequency === 'yearly'}
                    disabled={!hasTrimestralAndAnnual}
                    dataCy="breakdown-radio-annual"
                  />
                </div>
              </>
            )}
          </div>
        </Frequencies>

        <ToggleLatestData
          label={translate('latestData')}
          checked={isLatestDataActive}
          onChange={(e) => handleActiveLatestData(e.target.checked)}
          disabled={
            isLatestDataDisabled ||
            !!project.projectError ||
            isLoading ||
            !!errorMessage
          }
          data-testid="latest-data-breakdown"
          data-cy="latest-data-breakdown"
        />
      </Options>

      {frequency !== 'original' && modelType ? (
        <Select
          label={translate('modelInProductionDataType')}
          value={{
            label: translate(modelType),
            value: modelType,
          }}
          options={[
            {
              label: translate('stock'),
              value: 'stock',
            },
            {
              label: translate('flow'),
              value: 'flow',
            },
          ]}
          onChange={(option: any) => {
            handleChangeModelType(frequency, option.value);
          }}
          style={{ width: '300px', margin: '16px 0 16px' }}
        />
      ) : null}

      {errorMessage ? (
        <ContainerMaintenance
          text={errorMessage}
          content="chart"
          size="sm"
          data-testid="breakdown-error"
          data-cy="breakdown-error"
        />
      ) : isLoading || !dataToShow || frequency !== chartFrequency ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton />
      ) : (
        <BreakdownChart
          line={dataToShow.line}
          bars={dataToShow.bar}
          isAnnual={frequency === 'yearly'}
          lastHistoricalDate={
            frequency === 'yearly'
              ? new Date(`${dataToShow.last_historical_date}T00:00`)
                  .getFullYear()
                  .toString()
              : dataToShow.last_historical_date
          }
        />
      )}
    </div>
  );
};
