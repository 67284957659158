import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { ChartPloty } from 'src/components/ChartPloty';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { getChartColor } from 'src/utils/getChartColor';
import { Select } from 'src/components/Select';

import { ROCCurve } from '../../ModelExplorer/types';
import { Container } from './styles';

interface ROCCurvesChartProps {
  isFetching: boolean;
  isLoading: boolean;
  isErrored: boolean;
  data: ROCCurve | undefined;
}

export const ROCCurvesChart: React.FC<ROCCurvesChartProps> = ({
  isErrored,
  isLoading,
  isFetching,
  data,
}) => {
  const { t: translate } = useTranslation();

  const [positiveClass, setPositiveClass] = useState<string>();

  useEffect(() => {
    data &&
      !data.some((rocInfo) => rocInfo.class_name === positiveClass) &&
      setPositiveClass(data[0].class_name);
  }, [data, positiveClass]);

  const positiveClassData = useMemo(() => {
    let positiveClassInfo;
    if (data) {
      positiveClassInfo =
        data &&
        data.find((classInfo) => classInfo?.class_name === positiveClass);

      if (!positiveClassInfo) {
        positiveClassInfo = data[0];
      }
    }

    return positiveClassInfo;
  }, [data, positiveClass]);

  return (
    <Container className="containerLinear">
      <Card
        textCard={translate('rocCurveTitle')}
        textDescription={translate('rocCurveDescription')}
      />
      <div>
        {isErrored ? (
          <ContainerMaintenance content="chart" data-testid="roc-curve-fail" />
        ) : isLoading || isFetching ? (
          <ContainerSkeleton data-testid="roc-curve-loading" />
        ) : (
          data && (
            <>
              <div>
                {positiveClass && (
                  <Select
                    label={translate('rocCurvePositiveClassLabel')}
                    value={{
                      label: positiveClass,
                      value: positiveClass,
                    }}
                    onChange={(opt: any) => setPositiveClass(opt.value)}
                    options={data.map((classInfo) => ({
                      label: classInfo.class_name,
                      value: classInfo.class_name,
                    }))}
                    style={{ maxWidth: '250px' }}
                    data-testid="roc-positive-class-select"
                    data-cy="roc-positive-class-select"
                  />
                )}
              </div>
              <ChartPloty
                data={[
                  {
                    x: positiveClassData?.false_positive_rate,
                    y: positiveClassData?.true_positive_rate,
                    name: translate('rocCurveSelectedModel'),
                    type: 'scatter',
                    mode: 'lines+markers',
                    marker: {
                      color: getChartColor(1),
                    },
                    hovertemplate: `<b>${translate(
                      'rocCurveSelectedModel',
                    )}</b><br><b>${translate(
                      'rocCurvePositiveClassLabel',
                    )}: </b>${positiveClassData?.class_name}<br><b>${translate(
                      'rocCurveYChartLabel',
                    )}: </b>%{y}<br><b>${translate(
                      'rocCurveXChartLabel',
                    )}: </b>%{x}`,
                  },
                  {
                    x: [0, 1],
                    y: [0, 1],
                    name: translate('rocCurveRandomModel'),
                    line: {
                      dash: 'dot',
                    },
                    mode: 'lines',
                    marker: {
                      color: getChartColor(0),
                    },
                  },
                ]}
                layout={{
                  title: {
                    text: `${translate(
                      'rocCurveAUCLabel',
                    )}: ${positiveClassData?.auc_score.toFixed(2)}`,
                    xanchor: 'center',
                    yanchor: 'top',
                    font: {
                      size: 14,
                    },
                  },
                  xaxis: {
                    title: translate('rocCurveXChartLabel'),
                    automargin: true,
                  },
                  yaxis: {
                    title: translate('rocCurveYChartLabel'),
                    automargin: true,
                  },
                }}
                dataCy="roc-curve-chart"
                style={{ height: '450px', minWidth: '550px' }}
              />
            </>
          )
        )}
      </div>
    </Container>
  );
};
