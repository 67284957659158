import styled, { css, keyframes } from 'styled-components';

export const SideMenu = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
  position: relative;

  border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};
`;

export const StickyContainer = styled.div`
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;

  position: sticky;
  top: 6.625rem;
  left: 2rem;
  bottom: 10rem;
`;

type MenuItemProps = {
  selected: boolean;
};

export const MenuItem = styled.div<MenuItemProps>`
  width: 5.063rem;
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  transition: 0.6s;

  color: ${({ theme }) => theme.colors.gray4};

  p {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    ${({ selected }) =>
      selected &&
      css`
        color: ${({ theme }) => theme.colors.gray6};
      `}
  }

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.gray0};
      border-radius: 8px;
    `}

  :hover {
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.gray0};
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;

export const MenuDivisor = styled.div`
  width: 32px;
  height: 1px;

  background: ${({ theme }) => theme.colors.gray1};
  color: ${({ theme }) => theme.colors.gray1};
`;

export const MenuChatIA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  bottom: '2rem';

  color: ${({ theme }) => theme.colors.white};

  > button {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 10px;

    border-radius: 8px;
    background: linear-gradient(
        54deg,
        rgba(76, 148, 255, 0.64) 15.35%,
        rgba(242, 125, 180, 0.64) 83.24%
      ),
      #fff;

    img {
      width: 24.004px;
      height: 24px;
    }

    :hover {
      box-shadow: 0px 8px 16px 0px rgba(76, 148, 255, 0.24);

      background: ${({ theme }) => `linear-gradient(
      57deg,
      ${theme.colors.primary}CC 0%,
      ${theme.colors.secondary}CC 100%
    ),
    ${theme.colors.white}`};
    }
  }

  p {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  transition: all 2s;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    /* transform: translateX(-320px); */
    transform: translateX(-20px);

  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

const disappearToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to{
    
    opacity: 0;
    transform: translateX(-320px);
  }
  
`;

interface SidebarProps {
  visible: boolean;
}

export const SideBar = styled.aside<SidebarProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  /* display: flex; */

  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  position: sticky;

  height: calc(100vh - 4.625rem);
  min-width: 300px !important;
  top: 4.625rem;

  overflow-y: auto;

  > div {
    width: 100%;
    padding: ${({ visible }) => (visible ? '1.5rem' : '0')};
  }

  border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};
  /* animation: ${({ visible }) =>
    visible ? appearFromLeft : disappearToLeft} 1s; */
  animation: ${({ visible }) => visible && appearFromLeft} 0.4s;
  /* transition: 0.3s; */
  /* transition: ${({ visible }) => !visible && '0.3s'}; */

  @media (min-width: 1450px) {
    /* min-width: 20rem;
    max-width: 20rem; */
    min-width: ${({ visible }) => (visible ? '20rem' : '0')};
    max-width: ${({ visible }) => (visible ? '20rem' : '0')};
  }

  ${({ visible }) =>
    !visible &&
    css`
      border-right: 0px;

      *,
      button {
        display: none;
      }
    `}

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

export const AIChatContainer = styled.div`
  width: 100%;

  margin-top: 2rem;

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const AIChatButton = styled.button`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: 0.75rem;
  margin: 1rem 0;

  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.white};

  border-radius: 8px;

  background: ${({ theme }) => `linear-gradient(
      54deg,
      ${theme.colors.primary}A3 15.35%,
      ${theme.colors.secondary}A3 83.24%
    ),
    ${theme.colors.white}`};
  box-shadow: 0px 8px 32px 0px rgba(76, 148, 255, 0.24);

  svg {
    width: fit-content;
    height: 1.5rem;

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  transition: all 2s;

  :hover {
    background: ${({ theme }) => `linear-gradient(
      57deg,
      ${theme.colors.primary}CC 0%,
      ${theme.colors.secondary}CC 100%
    ),
    ${theme.colors.white}`};
  }
`;
