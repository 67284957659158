import React, { useEffect } from 'react';

import { WorkspaceProjectionsProvider } from 'src/models/contexts/WorkspaceProjectionsContext';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { ContainerLoadingStatusSuccess } from 'src/models/TimeSeries/AISelection/styles';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { queryClient } from 'src/service/queryClient';
import { sleep } from 'src/utils/sleep';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import ms from 'ms';

import { Container } from './styles';
import { Results } from './components/Results';
import { PotentialImpacts } from './components/PotentialImpacts';
import { AnnualSummary } from './components/AnnualSummary';
import { ExplanatoryVariables } from './components/ExplanatoryVariables';
import { StatusResponse } from './types';
import { Export } from './components/Export';

export const WorkspaceProjections: React.FC = () => {
  const { workspace } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const { data, isLoading, isFetching, isError } = useQuery(
    [
      'workspace',
      'projections',
      'status',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      workspace.ySelected?.model_id,
    ],
    async () => {
      const result = await apiWorkspace.get<StatusResponse>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${workspace.ySelected?.y_label}/models/${workspace.ySelected?.model_id}/business/status`,
      );

      return result.data.status;
    },
    {
      staleTime: ms('1 min'),
      enabled:
        !!workspace.id && !!workspace.releaseSelected && !!workspace.ySelected,
    },
  );

  useEffect(() => {
    const checkStatus = async () => {
      if (
        workspace.id &&
        !isLoading &&
        !isFetching &&
        data !== 'success' &&
        !isError
      ) {
        await sleep(ms('1 min'));

        queryClient.removeQueries([
          'workspace',
          'projections',
          'status',
          workspace.id,
          workspace.releaseSelected?.id,
          workspace.ySelected?.y_label,
          workspace.ySelected?.model_id,
        ]);
      }
    };

    checkStatus();
  }, [workspace, isLoading, isFetching, isError, data]);

  return (
    <WorkspaceProjectionsProvider>
      <Container>
        {isError || data === 'error' ? (
          <ContainerLoadingStatusSuccess className="containerLinear">
            <ContainerMaintenance
              content=""
              data-testid="projections-status-error"
            />
          </ContainerLoadingStatusSuccess>
        ) : data !== 'success' ? (
          <ContainerLoadingStatusSuccess
            className="containerLinear"
            data-testid="loading-processing-projections"
          >
            <ContainerSkeleton style={{ height: '6.25rem' }} />
            <h4>{translate('workspaceProjectionsLoadingTitle')}</h4>
            <p>{translate('workspaceProjectionsLoadingDescription')}</p>
          </ContainerLoadingStatusSuccess>
        ) : (
          <>
            <AnnualSummary />
            <Results />
            <PotentialImpacts />
            <ExplanatoryVariables />
            <Export />
          </>
        )}
      </Container>
    </WorkspaceProjectionsProvider>
  );
};
