import styled from 'styled-components';

export const Container = styled.div`
  width: 30rem;
  height: 14rem;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 2rem 2rem 3rem;

  h3 {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const SubMessage = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray5};

  margin-top: 0.75rem;
`;
