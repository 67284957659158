import React, { Suspense } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { hotjar } from 'react-hotjar';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import * as FullStory from '@fullstory/browser';

import { ContainerSkeleton } from './components/ContainerSkeleton';
import { store } from './redux/store';
import AppRoutes from './routes';
import { queryClient } from './service/queryClient';
import { FeatureStoreSidebarProvider } from './feature-store/components/Layout/Contexts/NavigationContext';
import './i18n/config.ts';

const fullStoryID = process.env.REACT_APP_FULLSTORY_ID;
const fullStoryHost = process.env.REACT_APP_FULLSTORY_HOST;

if (fullStoryID) {
  FullStory.init({
    orgId: fullStoryID,
    host: fullStoryHost,
    script: `${fullStoryHost}/s/fs.js`,
  });
}

function App(): React.ReactElement {
  const hotjarID = process.env.REACT_APP_HOTJAR_ID;
  const hotjarServer = process.env.REACT_APP_HOTJAR_SV;
  if (hotjarID && hotjarServer) {
    hotjar.initialize(Number(hotjarID), Number(hotjarServer));
  }

  const { isLoading } = useAuth0();
  if (isLoading) return <ContainerSkeleton style={{ height: '100vh' }} />;

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback="loading">
          <FeatureStoreSidebarProvider>
            <AppRoutes />
          </FeatureStoreSidebarProvider>
        </Suspense>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
