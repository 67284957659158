import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const firstBreadcumbs: Breadcumbs = {
  category_id: '',
  category_node: 'Feature Store',
  category_name: { 'en-us': 'Feature Store', 'pt-br': 'Feature Store' },
};

type Category = {
  id: string;
  node: string;
  length: number;
  name: {
    'en-us': string;
    'pt-br': string;
  };
  children: Category[];
};

type Breadcumbs = {
  category_id: string;
  category_node: string;
  category_name: {
    'en-us': string;
    'pt-br': string;
  };
};

export interface PayloadIndicatorsPage {
  page: number;
  search: string;
  categories: Category[];
  breadcumbs: Breadcumbs[];
  categoriesData?: Category[];
  searchTimer: number;
  searchTimerActive: boolean;
  requestAllowed: boolean;
  lastSearch: string;
}

const initialState: PayloadIndicatorsPage = {
  page: 1,
  search: '',
  categories: [],
  breadcumbs: [],
  categoriesData: undefined,
  searchTimer: 2000,
  searchTimerActive: false,
  requestAllowed: true,
  lastSearch: '',
};

export const indicatorsPageState = createSlice({
  name: 'indicatorsPage',
  initialState,
  reducers: {
    saveIndicatorsPage: (
      state,
      action: PayloadAction<PayloadIndicatorsPage>,
    ) => {
      state.page = action.payload.page;
      state.search = action.payload.search;
      state.categories = action.payload.categories;
      state.breadcumbs = action.payload.breadcumbs;
    },
    clearIndicatorsPage: (state) => {
      state.page = 1;
      state.search = '';
      state.categories = [];
      state.breadcumbs = [];
      state.categoriesData = undefined;
      state.searchTimer = 2000;
      state.searchTimerActive = false;
      state.requestAllowed = true;
      state.lastSearch = '';
    },
    setBreadcumbs: (state, action: PayloadAction<Breadcumbs[]>) => {
      state.breadcumbs = action.payload;
    },
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setCategoriesData: (
      state,
      action: PayloadAction<Category[] | undefined>,
    ) => {
      state.categoriesData = action.payload;
    },
    setRequestAllowed: (state, action: PayloadAction<boolean>) => {
      state.requestAllowed = action.payload;
    },
    setSearchTimerActive: (state, action: PayloadAction<boolean>) => {
      state.searchTimerActive = action.payload;
    },
    setSearchTimer: (state, action: PayloadAction<number>) => {
      state.searchTimer = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setLastSearch: (state, action: PayloadAction<string>) => {
      state.lastSearch = action.payload;
    },
  },
});

export const {
  saveIndicatorsPage,
  clearIndicatorsPage,
  setBreadcumbs,
  setCategories,
  setPage,
  setCategoriesData,
  setRequestAllowed,
  setSearchTimerActive,
  setSearchTimer,
  setLastSearch,
  setSearch,
} = indicatorsPageState.actions;

export default indicatorsPageState.reducer;
