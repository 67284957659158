import styled from 'styled-components';

export const Content = styled.main`
  overflow-y: auto !important;

  width: 100%;
  height: 100%;

  background: ${({ theme }) => theme.colors.gray0};

  padding: 1.5rem;

  @media (max-width: 400px) {
    padding: 1rem;
  }
`;
