import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Select } from 'src/components/Select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { updateYSelected } from 'src/models/redux/reducers/Workspace';

import { Container } from './styles';
import {
  FilterNames,
  FilterOptions,
  Option,
  OptionYs,
  SelectedOptions,
} from './types';

export const DependentVariable: React.FC = () => {
  const [filterNames, setFilterNames] = useState<FilterNames>({});
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({});
  const [optionYs, setOptionYs] = useState<OptionYs>();
  const [allYOptions, setAllYOptions] = useState<Option[]>([]);
  const [currentYOptions, setCurrentYOptions] = useState<Option[]>([]);
  const [selectedY, setSelectedY] = useState<Option>();

  const [loadingFilters, setLoadingFilters] = useState(true);

  const { releaseSelected, ySelected } = useSelector(
    (state: RootState) => state.workspace,
  );

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const variables = releaseSelected?.data?.ys || [];
  const { filters, y_filters } = releaseSelected?.data ?? {};

  function handleSelectY(y: string) {
    if (y === selectedY?.value) {
      return;
    }

    const ySelectedAux = variables.find((variable) => variable.y_label === y)!;

    setSelectedY({
      label: ySelectedAux.y_label,
      value: ySelectedAux.y_label,
    });

    dispatch(updateYSelected(ySelectedAux));
  }

  useEffect(() => {
    if (ySelected) {
      setSelectedY({
        label: ySelected.y_label,
        value: ySelected.y_label,
      });
    }
  }, [ySelected]);

  useEffect(() => {
    const getAllYOptions = () => {
      const yOptions: Option[] = [];

      releaseSelected?.data?.ys?.forEach(({ y_label }) => {
        yOptions.push({ label: y_label, value: y_label });
      });

      setAllYOptions(yOptions);

      return yOptions;
    };

    const getSelectedFilters = () => {
      if (y_filters) {
        const selectedYFilter = y_filters[ySelected?.y_label ?? ''] ?? {};

        setSelectedOptions(selectedYFilter);

        return selectedYFilter;
      }

      return {};
    };

    const getYOfSpecificFilterOption = (filter: string, option: string) => {
      const ys: string[] = [];

      if (y_filters) {
        const yKeys = Object.keys(y_filters ?? {});

        yKeys.forEach((y) => {
          if (y_filters?.[y][filter] === option) {
            ys.push(y);
          }
        });
      }

      return ys;
    };

    const yOptions = getAllYOptions();

    const selectedYFilter = getSelectedFilters();

    const options: FilterOptions = {};
    const names: FilterNames = {};
    const auxOptionYs: OptionYs = {};
    let auxCurrentYOptions = yOptions;
    let auxYToCurrentYOptions = yOptions;

    if (filters) {
      const keys = Object.keys(filters);

      for (const key of keys) {
        const filter = filters[key];

        if (filter) {
          options[key] = [];
          auxOptionYs[key] = {};
          names[key] = filter.name;

          for (let i = 0; i < filter.options.length; i++) {
            const option = filter.options[i];

            const ys = getYOfSpecificFilterOption(key, option);

            auxOptionYs[key][option] = ys;

            const isFirstFilter = keys[0] === key;

            if (
              isFirstFilter ||
              auxCurrentYOptions.some(({ value }) => ys.includes(value))
            ) {
              options[key].push({ label: option, value: option });
            }

            if (selectedYFilter[key] === option) {
              auxYToCurrentYOptions = auxCurrentYOptions.filter(({ value }) =>
                ys.includes(value),
              );
            }
          }

          auxCurrentYOptions = auxYToCurrentYOptions;
        }
      }

      setCurrentYOptions(auxCurrentYOptions);
      setOptionYs(auxOptionYs);
      setFilterNames(names);
      setFilterOptions(options);

      setLoadingFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseSelected?.data, filters, y_filters]);

  function getNextFilters(
    filterKey: string,
    filterOptionsAux: FilterOptions,
    selectedOptionsAux: SelectedOptions,
    optionSelected: Option,
  ) {
    const keys = Object.keys(filters ?? {});

    const options = { ...filterOptionsAux };
    let auxCurrentYOptions = [...allYOptions];
    let auxYToCurrentYOptions = [...allYOptions];
    const updatedSelectedOptions = { ...selectedOptionsAux };
    updatedSelectedOptions[filterKey] = optionSelected.value;

    let alreadyGetFilterOptionOfFilterChanged = false;
    let hasCurrentOptionSelected = false;

    for (const key of keys) {
      const filter = filters![key];

      if (filter) {
        if (alreadyGetFilterOptionOfFilterChanged) {
          options[key] = [];
        }

        for (let i = 0; i < filter.options.length; i++) {
          const option = filter.options[i];

          const ys = optionYs?.[key]?.[option] ?? [];

          if (
            alreadyGetFilterOptionOfFilterChanged &&
            auxCurrentYOptions.some(({ value }) => ys.includes(value))
          ) {
            options[key].push({ label: option, value: option });

            if (updatedSelectedOptions[key] === option) {
              hasCurrentOptionSelected = true;
            }
          }

          if (updatedSelectedOptions[key] === option) {
            auxYToCurrentYOptions = auxCurrentYOptions.filter(({ value }) =>
              ys.includes(value),
            );
          }
        }

        if (
          !hasCurrentOptionSelected &&
          alreadyGetFilterOptionOfFilterChanged
        ) {
          updatedSelectedOptions[key] = null;
          auxYToCurrentYOptions = [];
        }

        hasCurrentOptionSelected = false;
        auxCurrentYOptions = auxYToCurrentYOptions;
      }

      if (key === filterKey) {
        alreadyGetFilterOptionOfFilterChanged = true;
      }
    }

    return {
      options,
      auxCurrentYOptions,
      updatedSelectedOptions,
    };
  }

  const handleChangeOptionSelected = (
    filterKey: string,
    optionSelected: Option,
  ) => {
    if (filters) {
      if (selectedOptions?.[filterKey] === optionSelected.value) {
        return;
      }

      let filterOptionsAux = { ...filterOptions };
      let optionSelectedAux = { ...optionSelected };
      let selectedOptionsAux = { ...selectedOptions };

      let aux2CurrentYOptions: Option[] = [];
      let filterKeyAux = filterKey;

      let nextIndex = -1;
      let stop = false;

      while (!stop) {
        const { options, auxCurrentYOptions, updatedSelectedOptions } =
          getNextFilters(
            filterKeyAux,
            filterOptionsAux,
            selectedOptionsAux,
            optionSelectedAux,
          );

        nextIndex = Object.keys(updatedSelectedOptions)
          .filter((key) => key.includes('level'))
          .findIndex((key) => updatedSelectedOptions[key] === null);

        const nextKey = `level_${nextIndex + 1}`;

        filterOptionsAux = { ...options };
        aux2CurrentYOptions = [...auxCurrentYOptions];
        selectedOptionsAux = { ...updatedSelectedOptions };

        if (nextIndex !== -1 && filterOptions?.[nextKey]?.length > 0) {
          filterKeyAux = nextKey;
          optionSelectedAux = filterOptionsAux?.[nextKey][0];
        } else {
          stop = true;
        }
      }

      setFilterOptions(filterOptionsAux);
      setCurrentYOptions(aux2CurrentYOptions);
      setSelectedOptions(selectedOptionsAux);

      if (aux2CurrentYOptions.length > 0) {
        handleSelectY(aux2CurrentYOptions[0].label);
      } else {
        setSelectedY(undefined);
      }
    }
  };

  return (
    <Container>
      <h4>{translate('workspaceSideBarDependentVariable')}</h4>

      {Object.keys(filterOptions).map((key) => (
        <Select
          key={key}
          label={filterNames[key]}
          options={filterOptions[key]}
          value={
            !selectedOptions?.[key]
              ? null
              : {
                  label: selectedOptions[key] ?? '',
                  value: selectedOptions[key] ?? '',
                }
          }
          onChange={(option) =>
            handleChangeOptionSelected(key, option as Option)
          }
          dataTestid={`filter-${filterNames[key]
            .replaceAll(' ', '-')
            .toLowerCase()}`}
        />
      ))}

      <Select
        label={translate('variable')}
        options={currentYOptions}
        onChange={(option) => handleSelectY((option as Option).value)}
        value={!selectedY?.value ? null : { ...selectedY }}
        isLoading={loadingFilters}
        dataTestid="dependent-variable"
      />
    </Container>
  );
};
