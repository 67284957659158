export const workspaceProjectionsResultENTranslate = {
  workspaceProjectionsResultTitle: 'Results',
  workspaceProjectionsResultDescription:
    'Select a historical period to see more details about the modeling results.',
  workspaceProjectionsResultFrequency: 'Frequency',
  workspaceProjectionsResultTransformation: 'Transformation',
  workspaceProjectionsResultOriginal: 'Original',
  workspaceProjectionsResultMonthly: 'Monthly',
  workspaceProjectionsResultQuarterly: 'Quarterly',
  workspaceProjectionsResultAnnual: 'Annual',
  workspaceProjectionsResultLevel: 'Level',
  workspaceProjectionsResultVariation: 'Variation',
  workspaceProjectionsResultLatestData: 'Latest data',
  workspaceProjectionsResultsVariationOriginal:
    'Variation (compared to the previous period)',
  workspaceProjectionsResultsVariationMonthlyQuarterly:
    'Variation (compared to the same period in the previous year)',
  workspaceProjectionsResultsVariationAnnual:
    'Variation (compared to the previous year)',
  workspaceProjectionsMonthlyAggregationIsNotAvailable:
    'Monthly aggregation is not available for dataset with missing values.',
  workspaceProjectionsResultsNotEnoughObservationsVariation:
    'Not enough observations to calculate variation.',
  workspaceProjectionsResultsThisFeatureTemporarilyUnavailable:
    'This feature is temporarily unavailable.',
};
