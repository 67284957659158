import React, { useContext } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { FeatureStoreSidebarContext } from 'src/feature-store/components/Layout/Contexts/NavigationContext';

import { TabProps, ParamProps } from './types';
import { Tab } from './styles';

export const Tabs: React.FC<TabProps> = ({ text, index }) => {
  const navigate = useNavigate();
  const { tab } = useParams<ParamProps>();

  const { idFavoriteViewing } = useContext(FeatureStoreSidebarContext);

  return (
    <Tab
      isActive={index === tab}
      onClick={() =>
        navigate(`/feature-store/favorites/${idFavoriteViewing}/data/${index}`)
      }
    >
      {text}
    </Tab>
  );
};
