import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colors.gray5};

    margin-top: 0.5rem;
  }
`;

export const Content = styled.div`
  height: 8rem;

  margin-top: 1.5rem;

  position: relative;

  > div {
    width: 100%;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;

    filter: blur(4px);
  }

  button {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
`;

type ModalContainerProps = {
  visible: boolean;
};

export const ModalContainer = styled.div<ModalContainerProps>`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: start;
  align-items: center;

  z-index: 2001;
`;

const start = keyframes`
  from{
    opacity: 0;
    transform: translate3d(-85%,0,0);
  }
  to{
    opacity:1;
    transform:translate3d(0,0,0);
  }
`;

type ModalContentProps = {
  visible: boolean;
};

export const ModalContent = styled.div<ModalContentProps>`
  width: 85%;
  height: 100vh;

  position: relative;
  display: flex;
  flex-direction: column;

  padding: 1.5rem 0;

  background: ${({ theme }) => theme.colors.white};
  z-index: 2002;

  animation: ${start} 1.5s;

  ${({ visible }) =>
    visible === false &&
    css`
      display: none;
    `};

  .categorization-modal-footer {
    width: calc(100% + 1.5rem);
    border-radius: 0;
  }
`;

export const ActionButtons = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  > button {
    padding: 0;

    height: fit-content;

    svg {
      width: 1.25rem;
      height: 1.25rem;

      margin: 0;
    }
  }
`;

export const CreateFilterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  margin: 5rem 0 3.5rem 0;

  > div {
    width: 25rem;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    text-align: center;

    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 1.5rem;
  }

  img {
    width: 10rem;
  }

  button {
    padding: 0.75rem 3rem;
  }
`;

export const CategorizationContainer = styled.div`
  min-height: calc(100vh - 7.375rem - 4rem - 3rem);

  padding: 0 1.5rem;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  > div + div {
    margin-top: 3rem;

    border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  > div:not(:first-of-type) {
    padding-top: 3rem;
  }
`;

export const ConfirmCloseCategorizationContainer = styled.div`
  width: 30rem;
  padding: 2.313rem 2rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > svg {
    color: ${({ theme }) => theme.colors.yellow4};
    margin-bottom: 1.875rem;
  }

  > h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.75rem;
    margin-top: -0.5rem;
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 3rem;
  }
`;

type CategorizationResultContainerProps = {
  disabled: boolean;
};

export const CategorizationResultContainer = styled.div<CategorizationResultContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;

  margin-top: 1.5rem;

  ${({ disabled }) =>
    disabled &&
    css`
      filter: blur(3px);

      > div {
        user-select: none;
        pointer-events: none;
      }
    `}

  > div {
    flex: 1;

    z-index: 2001;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > button {
    padding: 0;
    height: fit-content;

    color: ${({ theme }) => theme.colors.primary};

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }

    :hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    :disabled {
      color: ${({ theme }) => theme.colors.gray3};
    }
  }
`;
