export const workspaceProjectionsExplanatoryVariablesENTranslate = {
  workspaceProjectionsExplanatoryVariablesTitle:
    'Projection of Explanatory Variables',
  workspaceProjectionsExplanatoryVariablesVariable: 'Variable',
  workspaceProjectionsExplanatoryNoVariables:
    'No explanatory variable to be displayed.',
  workspaceProjectionsExplanatoryVariablesLevel: 'Level',
  workspaceProjectionsExplanatoryVariablesVariation: 'Variation',
  workspaceProjectionsExplanatoryVariablesFrequency: 'Frequency',
  workspaceProjectionsExplanatoryVariablesTransformation: 'Transformation',
  workspaceProjectionsExplanatoryVariablesOriginal: 'Original',
  workspaceProjectionsExplanatoryVariablesMonthly: 'Monthly',
  workspaceProjectionsExplanatoryVariablesQuarterly: 'Quarterly',
  workspaceProjectionsExplanatoryVariablesAnnual: 'Annual',
  workspaceProjectionsExplanatoryVariablesVariationOriginal:
    'Variation (compared to the previous period)',
  workspaceProjectionsExplanatoryVariablesVariationMonthlyQuarterly:
    'Variation (compared to the same period in the previous year)',
  workspaceProjectionsExplanatoryVariablesVariationAnnual:
    'Variation (compared to the previous year)',
  workspaceProjectionsExplanatoryVariablesNotEnoughObservationsVariation:
    'Not enough observations to calculate variation.',
};
