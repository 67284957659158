import React, { useContext, useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { format, sub } from 'date-fns';
import ms from 'ms';
import { Calendar, DownloadSimple, FileText, FileX } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ButtonCopyToClipboard } from 'src/components/ButtonCopyToClipboard';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import { FailedModal } from 'src/components/Modal/Failed';
import { ModalLoading } from 'src/components/Modal/Loading';
import { WarningModal } from 'src/components/Modal/Warning';
import { DateRange, RangeDatePicker } from 'src/components/RangeDatePicker';
import { TableV2 } from 'src/components/TableV2';
import { FeatureStoreSidebarContext } from 'src/feature-store/components/Layout/Contexts/NavigationContext';
import { HeaderContainer } from 'src/feature-store/components/Layout/Favorites/styles';
import api from 'src/feature-store/service/api';
import { DataError } from 'src/interface/axios';
import { RootState } from 'src/redux/store';
import { getDateMonthYear } from 'src/utils/dateFormatHandler';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { getTextWidth } from 'src/utils/getTextWidth';

import { CreateGroupModal } from '../../Groups/components/CreateGroupModal';
import { DeleteGroupModal } from '../../Groups/components/DeleteGroupModal';
import { Group } from '../../Groups/types';
import { DeleteSeriesModal } from '../components/DeleteSeriesModal';
import { SectionButton } from '../components/SectionButton';
import { Tabs } from '../components/Tabs';
import {
  Container,
  HeaderContent,
  IconContainer,
  InfoContainer,
  MenuOverlay,
} from '../styles';
import { IResponseHeadersDownload, SummaryProps } from '../types';
import { ButtonsContainer, ContainerTable, ContainerTabs } from './styles';
import { DataTableProps } from './types';

type ParamsProps = {
  id: string;
  tab: string;
};

export type ErrorObject = {
  title?: string;
  description?: string;
  cloudIcon?: boolean;
};

export const DataTable: React.FC = () => {
  const { id, tab: idTab } = useParams<ParamsProps>();

  const { user } = useSelector((state: RootState) => state.auth);

  // const [serieToDelete, setSerieToDelete] = useState<string>();
  const [serieToDelete] = useState<string>();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [failModalVisible, setFailModalVisible] = useState(false);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [showOptionsDownload, setShowOptionsDownload] =
    useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [summaryModalVisible, setSummaryModalVisible] = useState(false);
  const [dataTable, setDataTable] = useState<DataTableProps>();
  const [dateRange, setDateRange] = useState<DateRange>([
    sub(new Date(), { years: 1 }),
    new Date(),
  ]);

  const { language } = useSelector((state: RootState) => state.auth.user);
  const { t: translate } = useTranslation();

  const {
    openFavoriteFilters,
    setterIdFavoriteViewing,
    modalCreateFavoriteVisible,
    openCreateFavoriteModal,
    favoriteTypeToCreate,
    favoriteToDelete,
    isDeleteFavoriteModalOpen,
    openDeleteFavoriteModal,
    isEditFavoriteModalOpen,
    openEditFavoriteModal,
    favoriteToEdit,
    setterFavoriteType,
  } = useContext(FeatureStoreSidebarContext);

  const QUANTITY_ITEMS_COLUMNS_AS_NAME = 12;

  const {
    data: dataGroups,
    isLoading: isLoadingGroups,
    isError: isGroupsErrored,
  } = useQuery(
    ['data group', id],
    async () => {
      const { data } = await api.get<Group>(`/groups/${id}`);

      return data;
    },
    {
      enabled: !!id,
      staleTime: 5000 * 60,
    },
  );

  const {
    data: dataTableQuery,
    isLoading: isDataTableLoading,
    isError: isDataTableErrored,
  } = useQuery(
    ['data table', id, idTab, dateRange, language],
    async () => {
      const startDate = dateRange[0] && format(dateRange[0], 'yyyy-MM-dd');
      const endDate = dateRange[1] && format(dateRange[1], 'yyyy-MM-dd');

      const { data } = await api.get<DataTableProps>(
        `/groups/${id}/tabs/${idTab}?start_date=${startDate}&end_date=${endDate}`,
      );

      return data;
    },
    {
      enabled: !!id && !!idTab && !!dateRange[0] && !!dateRange[1],
      staleTime: 5000 * 60,
    },
  );

  const { data: summaryData } = useQuery(
    ['groups summary', id],
    async () => {
      const { data } = await api.get<SummaryProps>(
        `/groups/${id}/series/summary`,
      );
      return data;
    },
    {
      staleTime: ms('5 min'),
      enabled: !!id,
    },
  );

  useEffect(() => {
    openFavoriteFilters(true);
    setterFavoriteType('4iFavorite');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      setterIdFavoriteViewing(id);
    }
  }, [setterIdFavoriteViewing, id]);

  useEffect(() => {
    if (dataTableQuery) {
      setDataTable(dataTableQuery);
    }
  }, [dataTableQuery]);

  const checkSummarySeries = () => {
    const hasMaintenanceContent = !!summaryData?.total_maintenance;

    if (hasMaintenanceContent) {
      setSummaryModalVisible(true);
    }
  };

  const handleDownload = async (typeDownload: string) => {
    setDownloadLoading(true);
    setShowOptionsDownload(false);

    if (typeDownload === 'excel') {
      try {
        const { data, headers } = await api.get(`/groups/${id}/download/xlsx`, {
          responseType: 'blob',
        });

        if (data) {
          const fileURL = window.URL.createObjectURL(
            new Blob([data], { type: 'application/gzip' }),
          );

          const contentDisposition = (headers as IResponseHeadersDownload)[
            'content-disposition'
          ];

          const name = contentDisposition.slice(
            contentDisposition.indexOf('filename="') + 10,
            contentDisposition.length - 1,
          );

          const link = document.createElement('a');

          if (link.download !== undefined) {
            link.setAttribute('href', fileURL);
            link.setAttribute('download', name);
            link.setAttribute('data-testid', 'download-start-excel');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
          }
        }

        checkSummarySeries();
        setDownloadLoading(false);
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error?.response?.data?.detail?.detail ??
          error?.response?.data?.detail?.description ??
          translate('preDefinedDownloadError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: errorMessage,
        });
        setDownloadLoading(false);
        setFailedModalVisible(true);
      }
    }

    if (typeDownload === 'csv') {
      setDownloadLoading(true);
      setShowOptionsDownload(false);

      try {
        const { data, headers } = await api.get(`/groups/${id}/download/csv`, {
          responseType: 'blob',
        });

        if (data) {
          const fileURL = window.URL.createObjectURL(
            new Blob([data], { type: 'application/gzip' }),
          );

          const contentDisposition = (headers as IResponseHeadersDownload)[
            'content-disposition'
          ];

          const name = contentDisposition.slice(
            contentDisposition.indexOf('filename="') + 10,
            contentDisposition.length - 1,
          );

          const link = document.createElement('a');
          if (link.download !== undefined) {
            link.setAttribute('href', fileURL);
            link.setAttribute('download', name);
            link.setAttribute('data-testid', 'download-start-csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
          }

          checkSummarySeries();
          setDownloadLoading(false);
        }
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error?.response?.data?.detail?.detail ??
          error?.response?.data?.detail?.description ??
          translate('preDefinedDownloadError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: errorMessage,
        });
        setDownloadLoading(false);
        setFailedModalVisible(true);
      }
    }
  };

  // const showMessageExportLimit = () => {
  //   if (groupSeriesData?.total !== undefined && groupSeriesData?.total > 100) {
  //     return true;
  //   }
  //   return false;
  // };

  function handleRange(value: DateRange) {
    setDateRange(value);
  }

  return (
    <Container>
      <div className="containerLinear">
        {isLoadingGroups || isDataTableLoading ? (
          <>
            <HeaderContainer
              data-testid="header-container"
              style={{ marginTop: '0px' }}
            >
              <HeaderContent data-testid="header-content">
                <IconContainer data-testid="header-icon">
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '64px',
                      height: '64px',
                      borderRadius: '8px',
                    }}
                  />
                </IconContainer>

                <InfoContainer data-testid="info-container">
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: `${Math.random() * (180 - 100) + 100}px`,
                      height: '16px',
                    }}
                  />
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: `${Math.random() * (180 - 100) + 200}px`,
                      height: '16px',
                    }}
                  />
                </InfoContainer>

                <ButtonRounded
                  data-testid="button-export-series"
                  data-cy="button-export-series"
                  onClick={() => {
                    setShowOptionsDownload(true);
                  }}
                  disabled
                  icon={<DownloadSimple size="1.125rem" />}
                />
              </HeaderContent>
            </HeaderContainer>

            <ButtonsContainer>
              <RangeDatePicker
                dateRange={dateRange}
                onChange={handleRange}
                frequency="monthly"
                inputTestId="data-table-range-date-picker"
              />

              <SectionButton />
            </ButtonsContainer>
            <ButtonCopyToClipboard
              isDisabled
              textToCopy=""
              messageBeforeCopy={translate('viewFeatureCopySeriesToClipboard')}
              messageAfterCopy={translate('copied')}
            />
          </>
        ) : (
          <>
            {dataGroups?.series && (
              <HeaderContainer
                data-testid="header-container"
                style={{ marginTop: '0px' }}
              >
                <HeaderContent data-testid="header-content">
                  <IconContainer data-testid="header-icon">
                    <img
                      data-testid="group-image"
                      data-cy="group-image"
                      src="https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png"
                      alt="4i Logo - Favorite"
                    />
                  </IconContainer>

                  <InfoContainer data-testid="info-container">
                    <h4>{dataGroups?.name}</h4>
                    <p>{dataGroups?.description}</p>
                  </InfoContainer>

                  <Tooltip
                    id="feature-store-tooltip-export"
                    className="customTooltipTheme"
                  />
                  <div
                    data-tooltip-id="feature-store-tooltip-export"
                    // data-tooltip-html={
                    //   showMessageExportLimit()
                    //     ? translate('exportSeriesLimitExport')
                    //     : ''
                    // }
                  >
                    <ButtonRounded
                      data-testid="button-export-series"
                      data-cy="button-export-series"
                      onClick={() => {
                        setShowOptionsDownload(true);
                      }}
                      disabled
                      // disabled={
                      //   dataGroups?.series?.length === 0 ||
                      //   dataGroups?.series?.length === undefined ||
                      //   dataGroups?.series?.length >= 100
                      // }
                      icon={<DownloadSimple size="1.125rem" />}
                    />
                  </div>

                  <MenuContainer
                    visible={showOptionsDownload}
                    style={{ top: '3.5rem', right: '3rem' }}
                  >
                    {showOptionsDownload && (
                      <MenuOverlay
                        data-testid="menu-overlay"
                        visible={showOptionsDownload}
                        onClick={() => setShowOptionsDownload(false)}
                      />
                    )}
                    <MenuOption
                      type="button"
                      data-testid="buttonXLXS"
                      onClick={() => handleDownload('excel')}
                      position="start"
                    >
                      <FileX size="1.25rem" />
                      <p>Excel</p>
                    </MenuOption>
                    <MenuOption
                      type="button"
                      data-testid="buttonCSV"
                      onClick={() => handleDownload('csv')}
                      position="end"
                    >
                      <FileText size="1.25rem" />
                      <p>CSV</p>
                    </MenuOption>
                  </MenuContainer>
                </HeaderContent>
              </HeaderContainer>
            )}

            {dataGroups?.tabs && (
              <ButtonsContainer>
                <RangeDatePicker
                  dateRange={dateRange}
                  onChange={handleRange}
                  frequency="monthly"
                  inputTestId="data-table-range-date-picker"
                />

                {dataGroups?.is_predefined && <SectionButton />}
              </ButtonsContainer>
            )}

            {dataGroups?.series &&
              dataGroups?.series.length > 0 &&
              dataGroups.tabs && (
                <ButtonCopyToClipboard
                  isDisabled={false}
                  textToCopy={
                    (idTab &&
                      dataGroups?.tabs &&
                      dataGroups?.tabs[Number(idTab)]?.series.toString()) ??
                    ''
                  }
                  messageBeforeCopy={
                    dataGroups?.series.length > 1
                      ? translate('viewFeatureCopySeriesToClipboard')
                      : translate('viewFeatureCopySerieToClipboard')
                  }
                  messageAfterCopy={translate('copied')}
                />
              )}
          </>
        )}

        {idTab &&
          !isLoadingGroups &&
          !isDataTableLoading &&
          dataGroups?.tabs &&
          dataGroups?.tabs[Number(idTab)]?.columns_as === 'name' && (
            <ContainerTable noBorderRadiusBottom>
              <TableV2.Root data-testid="series-table">
                <TableV2.Thead>
                  <TableV2.Tr>
                    <TableV2.Th>
                      <Calendar size="16px" /> {translate('dataTablePeriod')}
                    </TableV2.Th>
                    {dataTable?.columns.map((column, index) => (
                      <TableV2.Th
                        style={{
                          minWidth: `${getTextWidth(
                            column,
                            '400 12px Inter',
                          )}px`,
                          maxWidth: `${
                            getTextWidth(column, '400 12px Inter') + 50
                          }px`,
                        }}
                        key={`column-${index + 1}`}
                      >
                        {column}
                      </TableV2.Th>
                    ))}
                  </TableV2.Tr>
                </TableV2.Thead>
                <TableV2.Tbody>
                  {dataTable?.rows.map((row) => (
                    <TableV2.Tr key={`tr-${row.stub}`}>
                      <TableV2.Td>
                        {getDateMonthYear(row.stub, user.language)}
                      </TableV2.Td>

                      {row.records.map((td, indexTd) => (
                        <TableV2.Td
                          key={`td-${dataTable.columns[indexTd]}`}
                          isProjection={td.type === 'projection'}
                        >
                          {td?.value === null
                            ? '-'
                            : formatCompactNotation(td?.value)}
                        </TableV2.Td>
                      ))}
                    </TableV2.Tr>
                  ))}

                  {Array.from({
                    length:
                      QUANTITY_ITEMS_COLUMNS_AS_NAME -
                      (dataTable?.rows?.length ?? 0),
                  }).map((_, index) => (
                    <TableV2.Tr
                      key={`tr-empty-${index + 1}`}
                      data-testid="tr-empty"
                      height="24px"
                    >
                      {Array.from({
                        length: dataTable?.columns.length ?? 0,
                      }).map((__, tdIndex) => (
                        <TableV2.Td
                          key={`tr-empty-${index + 1}-td-${tdIndex + 1}`}
                        />
                      ))}
                    </TableV2.Tr>
                  ))}
                </TableV2.Tbody>
              </TableV2.Root>
            </ContainerTable>
          )}

        {idTab &&
          !isLoadingGroups &&
          !isDataTableLoading &&
          dataGroups?.tabs &&
          dataGroups?.tabs[Number(idTab)]?.columns_as === 'date' && (
            <ContainerTable noBorderRadiusBottom>
              <TableV2.Root data-testid="series-table">
                <TableV2.Thead>
                  <TableV2.Tr>
                    <TableV2.Th>{translate('serie')}</TableV2.Th>
                    {dataTable?.columns.map((column, index) => (
                      <TableV2.Th
                        style={{
                          minWidth: `${getTextWidth(
                            column,
                            '400 12px Inter',
                          )}px`,
                          maxWidth: `${
                            getTextWidth(column, '400 12px Inter') + 50
                          }px`,
                        }}
                        key={`column-${index + 1}`}
                      >
                        {getDateMonthYear(column, user.language)}
                      </TableV2.Th>
                    ))}
                  </TableV2.Tr>
                </TableV2.Thead>
                <TableV2.Tbody>
                  {dataTable?.rows.map((row) => (
                    <TableV2.Tr key={`tr-${row.stub}`}>
                      <TableV2.Td
                        style={{
                          minWidth: `${getTextWidth(
                            row.stub,
                            '400 12px Inter',
                          )}px`,
                          maxWidth: `${
                            getTextWidth(row.stub, '400 12px Inter') + 50
                          }px`,
                        }}
                      >
                        {row.stub}
                      </TableV2.Td>

                      {row.records.map((td, indexTd) => (
                        <TableV2.Td
                          key={`td-${dataTable.columns[indexTd]}`}
                          isProjection={td.type === 'projection'}
                        >
                          {td?.value === null
                            ? '-'
                            : formatCompactNotation(td?.value)}
                        </TableV2.Td>
                      ))}
                    </TableV2.Tr>
                  ))}
                </TableV2.Tbody>
              </TableV2.Root>
            </ContainerTable>
          )}

        {isLoadingGroups || isDataTableLoading ? (
          <ContainerTable noBorderRadiusBottom>
            <TableV2.Root data-testid="series-table">
              <TableV2.Thead>
                <TableV2.Tr>
                  {Array.from({
                    length: 8,
                  }).map((_, index) => (
                    <TableV2.Th key={`column-loading-${index + 1}`}>
                      <ContainerSkeleton
                        withLoading={false}
                        style={{ height: '24px', width: '200px' }}
                      />
                    </TableV2.Th>
                  ))}
                </TableV2.Tr>
              </TableV2.Thead>
              <TableV2.Tbody>
                {Array.from({ length: 8 }).map((_, index) => (
                  <TableV2.Tr key={`row-loading-${index + 1}`}>
                    {Array.from({
                      length: 8,
                    }).map((__, indexRow) => (
                      <TableV2.Td key={`${indexRow + 1}`}>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{ height: '24px' }}
                        />
                      </TableV2.Td>
                    ))}
                  </TableV2.Tr>
                ))}
              </TableV2.Tbody>
            </TableV2.Root>
          </ContainerTable>
        ) : (
          (isGroupsErrored ||
            dataGroups?.tabs === null ||
            isDataTableErrored) && (
            <ContainerMaintenance
              content="table"
              text={translate('preDefinedUnableGroup')}
            />
          )
        )}

        {dataGroups?.tabs && (
          <ContainerTabs>
            {dataGroups?.tabs.map((tab, index) => (
              <Tabs
                key={tab.title['en-us']
                  .toLocaleLowerCase()
                  .replaceAll(' ', '-')}
                index={index.toString()}
                text={tab.title[language] ?? tab.title['en-us']}
              />
            ))}
          </ContainerTabs>
        )}
      </div>

      {deleteModalVisible && serieToDelete && (
        <DeleteSeriesModal
          serieCode={serieToDelete}
          visible={deleteModalVisible}
          setVisible={setDeleteModalVisible}
          setError={setFailModalVisible}
        />
      )}

      {failedModalVisible && (
        <FailedModal
          visible={failedModalVisible}
          setVisible={setFailedModalVisible}
          errorInfo={failedModalInfo}
        />
      )}

      {failModalVisible && (
        <FailedModal
          visible={failModalVisible}
          setVisible={setFailModalVisible}
          errorInfo={{
            title: translate('requestFailed'),
            description: translate('preDefinedExcludedError'),
          }}
        />
      )}

      <ModalLoading
        visible={downloadLoading}
        message="Downloading..."
        data-testid="download-start"
      />

      <WarningModal
        visible={summaryModalVisible}
        setVisible={(prevValue) => {
          setSummaryModalVisible(prevValue);
        }}
        errorInfo={{
          description: translate('exportSeriesMaintenance'),
        }}
      />

      {modalCreateFavoriteVisible && (
        <CreateGroupModal
          visible
          setVisible={() => openCreateFavoriteModal(false)}
          predefined={favoriteTypeToCreate === '4iFavorite'}
        />
      )}

      {isDeleteFavoriteModalOpen && (
        <DeleteGroupModal
          visible={isDeleteFavoriteModalOpen}
          setVisible={openDeleteFavoriteModal}
          setErrorVisible={setFailModalVisible}
          setError={() => false}
          group={{
            id: favoriteToDelete.group.id,
            name: favoriteToDelete.group.name,
          }}
        />
      )}

      {isEditFavoriteModalOpen && (
        <CreateGroupModal
          visible={isEditFavoriteModalOpen}
          setVisible={openEditFavoriteModal}
          groupToEdit={favoriteToEdit}
          // setPage={setPage}
          predefined={favoriteToEdit.is_predefined}
        />
      )}
    </Container>
  );
};
