import React, { useContext, useState } from 'react';

import { CaretDown } from 'phosphor-react';
import { Card } from 'src/components/Card';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import api from 'src/models/service/api';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { useTranslation } from 'react-i18next';
import { formatOrdinals } from 'src/utils/formatOrdinals';
import { AISelectionContext } from 'src/models/contexts/AISelectionContext';
import { Tooltip } from 'react-tooltip';
import { translateTransformationText } from 'src/utils/translateTransformationText';

import { ContentTitleAndModelInformation } from './styles';

interface ModelInfo {
  n_steps: number;
  sample: string;
  transformation: string;
  MAPE: number | null;
  WMAPE: number | null;
  MPE: number | null;
  RMSE: number | null;
  MASE: number | null;
  MASEs: number | null;
  selected_model: {
    model?: string;
    model_type: string;
    type: string;
  };
}
export const Information: React.FC = () => {
  const [tableInformationVisible, setTableInformationVisible] = useState(false);

  const {
    auth: { user },
    project: { id, selectedY },
  } = useSelector((state: RootState) => state);

  const { yBusinessStatus } = useContext(AISelectionContext);

  const { t: translate } = useTranslation();

  const { data, isLoading, isFetching, error } = useQuery<ModelInfo>(
    ['AI-selection', 'model-info', id, selectedY?.id],
    async () => {
      const response = await api.get(
        `/projects/${id}/${selectedY?.id}/models/business`,
      );
      return response.data;
    },
    {
      enabled: !!id && !!selectedY?.id,
      staleTime: 1000 * 60 * 20,
    },
  );

  return (
    <ContentTitleAndModelInformation
      tableVisible={tableInformationVisible}
      className="containerLinear"
    >
      <Head title={translate('aiSelectionInformationTitle')} />

      <Card
        textCard={translate('aiSelectionInformationTitle')}
        textDescription={translate('aiSelectionInformationDescription')}
      />
      <div>
        <Tooltip
          id="models-time-series-ai-selection-information"
          className="customTooltipTheme"
        />
        <table>
          <thead>
            <tr>
              <th>{translate('aiSelectionInformationBaseModel')}</th>
              <th>{translate('aiSelectionInformationSample')}</th>
              <th>{translate('aiSelectionInformationTransformation')}</th>
              <th
                data-tooltip-id="models-time-series-ai-selection-information"
                data-tooltip-html={translate('MAPEExplanationTooltip')}
              >
                MAPE (%)
              </th>
              <th
                data-tooltip-id="models-time-series-ai-selection-information"
                data-tooltip-html={translate('WMAPEExplanationTooltip')}
              >
                WMAPE (%)
              </th>
              <th
                data-tooltip-id="models-time-series-ai-selection-information"
                data-tooltip-html={translate('MPEExplanationTooltip')}
              >
                MPE (%)
              </th>
              <th
                data-tooltip-id="models-time-series-ai-selection-information"
                data-tooltip-html={translate('RMSEExplanationTooltip')}
              >
                RMSE
              </th>
              <th
                data-tooltip-id="models-time-series-ai-selection-information"
                data-tooltip-html={translate('MASEExplanationTooltip')}
              >
                MASE
              </th>
              <th
                data-tooltip-id="models-time-series-ai-selection-information"
                data-tooltip-html={translate('MASEsExplanationTooltip')}
              >
                MASEs
              </th>
            </tr>
          </thead>
          <tbody>
            {error ? (
              <tr>
                <td data-testid="selected-model">--</td>
                <td data-testid="selected-model-sample">--</td>
                <td data-testid="selected-model-transformation">--</td>
                <td data-testid="selected-model-mape">--</td>
                <td data-testid="selected-model-wmape">--</td>
                <td data-testid="selected-model-mpe">--</td>
                <td data-testid="selected-model-rmse">--</td>
                <td data-testid="selected-model-mase">--</td>
                <td data-testid="selected-model-mases">--</td>
              </tr>
            ) : isLoading || isFetching || !data ? (
              <tr>
                {Array.from({ length: 9 }, (_, index) => (
                  <td key={`selected-model-loading-${index}`}>
                    <ContainerSkeleton
                      data-testid={`selected-model-loading-${index}`}
                      withLoading={false}
                      style={{
                        width: index < 2 ? '100px' : '30px',
                        height: '16px',
                        margin: '0 auto',
                      }}
                    />
                  </td>
                ))}
              </tr>
            ) : (
              <tr>
                <td data-testid="selected-model">
                  {data.selected_model.model_type === 'ARIMA'
                    ? `${formatOrdinals(
                        Number(data.selected_model.type),
                        user.language,
                      )} ${data.selected_model.model_type}`
                    : data.selected_model.model_type === 'Forecast Combination'
                    ? `${translate(
                        data.selected_model.model_type.replace(' ', '_'),
                      )} - ${data.selected_model.type}`
                    : data?.selected_model.model_type ===
                      'Regularized Regression'
                    ? data?.selected_model.type
                    : data?.selected_model.model_type === 'Random Forest'
                    ? data.selected_model.model_type
                    : data?.selected_model.model_type === 'Elementary' &&
                      data.selected_model.model}
                </td>
                <td data-testid="selected-model-sample">{data.sample}</td>
                <td data-testid="selected-model-transformation">
                  {translateTransformationText(
                    data.transformation || '--',
                    user.language,
                  )}
                </td>
                <td data-testid="selected-model-mape">
                  {data.MAPE?.toFixed(2) || '--'}
                </td>
                <td data-testid="selected-model-wmape">
                  {data.WMAPE?.toFixed(2) || '--'}
                </td>
                <td data-testid="selected-model-mpe">
                  {data.MPE?.toFixed(2) || '--'}
                </td>
                <td data-testid="selected-model-rmse">
                  {data.RMSE?.toFixed(2) || '--'}
                </td>
                <td data-testid="selected-model-mase">
                  {data.MASE?.toFixed(2) || '--'}
                </td>
                <td data-testid="selected-model-mases">
                  {data.MASEs?.toFixed(2) || '--'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <button
        type="button"
        onClick={() => setTableInformationVisible(!tableInformationVisible)}
        disabled={yBusinessStatus !== 'success'}
        data-testid={
          tableInformationVisible
            ? 'button-see-less-information'
            : 'button-see-more-information'
        }
        data-cy={
          tableInformationVisible
            ? 'button-see-less-information'
            : 'button-see-more-information'
        }
      >
        <span>
          {tableInformationVisible
            ? translate('aiSelectionInformationSeeLessInformation')
            : translate('aiSelectionInformationSeeMoreInformation')}
        </span>
        <CaretDown size="1rem" weight="bold" />
      </button>
    </ContentTitleAndModelInformation>
  );
};
