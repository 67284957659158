import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  max-width: 335px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  margin-top: 16px !important;
`;

type ActiveButton = {
  active: boolean;
};

export const FavoriteButton = styled.button<ActiveButton>`
  display: flex;
  padding: 12px 7px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1;

  align-self: stretch;

  border-radius: 5px;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: normal;

  color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.gray4};
  font-weight: ${({ active }) => (active ? '600' : '500')};
  background: ${({ active }) => (active ? 'rgba(76, 148, 255, 0.12)' : 'none')};
  outline: ${({ active, theme }) =>
    active ? 'none' : `1px solid ${theme.colors.gray2}`};

  &:hover {
    outline: ${({ active, theme }) =>
      active ? 'none' : `1px solid ${theme.colors.gray3}`};

    color: ${({ active, theme }) =>
      active ? theme.colors.primary : theme.colors.gray5};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  margin-top: 32px;
`;

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${({ theme }) => theme.colors.gray4};

  display: flex;

  justify-content: center;
  align-items: center;

  p {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 1.12px;

    text-transform: uppercase;
  }
`;

export const NewGroupButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  color: ${({ theme }) => theme.colors.primary};

  cursor: pointer;

  p {
    text-align: center;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const FavoritesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  margin-top: 1rem;
`;

export const NotFoundFavorite = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 3.75rem;

  margin-top: 1.5rem;

  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.438rem;
    color: ${({ theme }) => theme.colors.gray6};

    text-align: center;
  }
`;
