import React from 'react';

import { Warning } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import parseHTML from 'html-react-parser';

import { Modal } from '..';
import Error from '../../../assets/error.svg';
import { Button } from '../../Button';
import { ContainerModal, ContentButton } from './styles';

export type ErrorObject = {
  title?: string;
  description?: string;
  cloudIcon?: boolean;
};

type PropsModal = {
  visible?: boolean;
  setVisible: (value: boolean) => void;
  errorInfo?: ErrorObject;
};

export const WarningModal: React.FC<PropsModal> = ({
  visible,
  setVisible,
  errorInfo,
}) => {
  const { t: translate } = useTranslation();
  return (
    <Modal visible={visible} setVisible={setVisible} style={{ padding: 0 }}>
      <ContainerModal
        data-testid="warning-modal"
        hasImage={errorInfo?.cloudIcon ?? false}
      >
        {errorInfo?.cloudIcon ? (
          <img src={Error} alt="error" data-testid="cloud-error-img" />
        ) : (
          <Warning size="3rem" data-testid="x-circle-icon" />
        )}

        {errorInfo && (
          <>
            <h1 data-testid="warning-modal-title">{errorInfo.title}</h1>
            <p data-testid="warning-modal-description">
              {parseHTML(errorInfo.description ?? '')}
            </p>
          </>
        )}

        <ContentButton>
          <Button
            buttonType="primary"
            data-testid="modalFailedButtonOk"
            onClick={() => setVisible(false)}
            data-cy="button-modal-failed-ok"
          >
            {translate('back')}
          </Button>
        </ContentButton>
      </ContainerModal>
    </Modal>
  );
};
