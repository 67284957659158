import React, { useEffect } from 'react';

import { X } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { sleep } from 'src/utils/sleep';
import { Select } from 'src/components/Select';
import { getTextWidth } from 'src/utils/getTextWidth';
import * as yup from 'yup';

import { Container, EmailsContainer, Tag, Tags, Visualize } from './styles';
import { UserRoleOptionLabel } from '../../../UserRoleOptionLabel';

interface InputUserShareProps {
  emails: string[];
  setEmails: (emails: string[]) => void;
  setInvalidEmail: (error: string) => void;
  permissionType: 'editor' | 'reader';
  setPermissionType: (value: 'editor' | 'reader') => void;
  users: string[];
  inputValue: string;
  setInputValue: (value: string) => void;
}

export type SelectOption = {
  label: string;
  value: 'reader' | 'editor';
};

export const InputUserShare: React.FC<InputUserShareProps> = ({
  emails,
  setEmails,
  setInvalidEmail,
  permissionType,
  setPermissionType,
  users,
  inputValue,
  setInputValue,
}) => {
  const emailSchema = yup.string().email();

  const { t: translate } = useTranslation();

  const userRoleOptions = [
    { label: translate('workspaceControlPanelCanView'), value: 'reader' },
    { label: translate('workspaceControlPanelCanEdit'), value: 'editor' },
  ];

  const focusInput = () => {
    const input = document.getElementById('workspace-share-input');
    if (input) {
      input.focus();
    }
  };

  useEffect(() => {
    focusInput();
  }, []);

  const handleKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    setInvalidEmail('');

    const value = inputValue.trim();

    if ((e.key === 'Enter' || e.key === ' ') && value !== '') {
      emailSchema
        .validate(value)
        .then(() => {
          if (emails.includes(value)) {
            setInvalidEmail(translate('shareWorkspaceRepeatedEmail'));
          } else if (users.includes(value)) {
            setInvalidEmail(translate('shareWorkspaceUserAlreadyInvited'));
          } else {
            setEmails([...emails, value]);
            setInputValue('');
          }
        })
        .catch(() => {
          setInvalidEmail(translate('shareWorkspaceInvalidEmail'));
        });
    }

    await sleep(1);

    const containerToScroll = document.getElementById(
      'input-user-share-container',
    );

    if (containerToScroll) {
      containerToScroll.scrollTo({
        top: containerToScroll.scrollHeight,
      });
    }
  };

  const handleRemoveEmail = (index: number) => {
    const emailsFiltered = emails.filter((_, indexMail) => indexMail !== index);
    setEmails(emailsFiltered);
  };

  const selectedRoleWidth =
    getTextWidth(
      permissionType === 'editor'
        ? translate('workspaceControlPanelCanEdit')
        : translate('workspaceControlPanelCanView'),
      '500 14px Inter',
    ) /
      16 +
    3.75;

  return (
    <Container
      data-testid="input-user-share-container"
      id="input-user-share-container"
    >
      <EmailsContainer
        onClick={focusInput}
        selectedRoleWidth={selectedRoleWidth}
      >
        <Tags
          data-testid="input-user-share-tags"
          style={{ marginBottom: emails.length ? '0.75rem' : '0rem' }}
        >
          {emails.map((email, index) => (
            <Tag
              key={`${email}+${index.toString()}`}
              data-testid={`email-user-${email.replaceAll(' ', '-')}`}
              selectedRoleWidth={selectedRoleWidth}
            >
              <span>{email}</span>
              <button
                type="button"
                onClick={(event) => {
                  handleRemoveEmail(index);
                  event.stopPropagation();
                }}
                data-testid={`remove-email-user-${email.replaceAll(' ', '-')}`}
              >
                <X />
              </button>
            </Tag>
          ))}
        </Tags>

        <input
          id="workspace-share-input"
          data-testid="workspace-share-input"
          value={inputValue}
          onKeyUp={handleKeyUp}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </EmailsContainer>

      <Visualize selectedRoleWidth={selectedRoleWidth}>
        <Select
          value={{
            value: permissionType,
            label:
              permissionType === 'editor'
                ? translate('workspaceControlPanelCanEdit')
                : translate('workspaceControlPanelCanView'),
          }}
          options={userRoleOptions}
          isSearchable={false}
          formatOptionLabel={({ label, value }) =>
            UserRoleOptionLabel({
              label,
              value,
            })
          }
          onChange={(option) => {
            setPermissionType((option as SelectOption).value);
          }}
          dataTestid="workspace-share-role"
        />
      </Visualize>
    </Container>
  );
};
