import React from 'react';

import { ButtonRoundedProps } from './types';
import { Button } from './styles';

export const ButtonRounded: React.FC<ButtonRoundedProps> = ({
  icon,
  disabled,
  onClick,
  ...props
}) => (
  <Button disabled={disabled} onClick={onClick} {...props}>
    {icon}
  </Button>
);
