import React, { ReactElement, createContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { GroupToEdit } from 'src/feature-store/pages/Groups/components/CreateGroupModal/types';

import {
  DeleteFavoriteModalProps,
  FavoriteType,
  IndicatorFilterProps,
} from './types';

interface FeatureStoreSidebarContextProps {
  openIndicatorFilters: (value: boolean) => void;
  indicatorFiltersVisible: boolean;
  searchIndicators: () => void;
  premiumFiltersVisible: boolean;
  openPremiumFilters: (value: boolean) => void;
  favoriteFilterVisible: boolean;
  openFavoriteFilters: (value: boolean) => void;
  firstMyFavoriteId: string[];
  handleMyFavoriteId: (value: string[]) => void;
  openCreateFavoriteModal: (value: boolean) => void;
  modalCreateFavoriteVisible: boolean;
  handleTypeFavoriteCreate: (value: string) => void;
  favoriteTypeToCreate: string;
  handleDeleteFavorite: (value: DeleteFavoriteModalProps) => void;
  favoriteToDelete: DeleteFavoriteModalProps;
  openDeleteFavoriteModal: (value: boolean) => void;
  isDeleteFavoriteModalOpen: boolean;
  isEditFavoriteModalOpen: boolean;
  openEditFavoriteModal: (value: boolean) => void;
  handleEditFavorite: (value: GroupToEdit) => void;
  favoriteToEdit: GroupToEdit;
  idFavoriteViewing: string;
  setterIdFavoriteViewing: (id: string) => void;
  favoriteType: FavoriteType;
  setterFavoriteType: (favoriteType: FavoriteType) => void;
  indicatorFilterType: IndicatorFilterProps;
  setterIndicatorFilterType: (indicatorType: IndicatorFilterProps) => void;
}

export const FeatureStoreSidebarContext = createContext(
  {} as FeatureStoreSidebarContextProps,
);

export const FeatureStoreSidebarProvider: React.FC<{
  children: ReactElement;
}> = ({ children }) => {
  const navigate = useNavigate();

  const [indicatorFiltersVisible, setIndicatorFiltersVisible] = useState(false);
  const [premiumFiltersVisible, setPremiumFiltersVisible] = useState(false);
  const [favoriteFilterVisible, setFavoriteFiltersVisible] = useState(false);
  const [firstMyFavoriteId, setFirstMyFavoriteId] = useState<string[]>(['']);
  const [modalCreateFavoriteVisible, setModalCreateFavoriteVisible] =
    useState(false);
  const [favoriteTypeToCreate, setFavoriteTypeToCreate] =
    useState('myFavorite');
  const [favoriteToDelete, setFavoriteToDelete] =
    useState<DeleteFavoriteModalProps>();
  const [isDeleteFavoriteModalOpen, setIsDeleteFavoriteModalOpen] =
    useState(false);
  const [isEditFavoriteModalOpen, setEditFavoriteModalOpen] = useState(false);
  const [favoriteToEdit, setFavoriteToEdit] = useState<GroupToEdit>();
  const [idFavoriteViewing, setIdFavoriteViewing] = useState<string>('');
  const [favoriteType, setFavoriteType] = useState<FavoriteType>('myFavorite');
  const [indicatorFilterType, setIndicatorFilterType] =
    useState<IndicatorFilterProps>(null);

  function searchIndicators() {
    setPremiumFiltersVisible(false);
    navigate('/feature-store/indicators');
  }

  function openIndicatorFilters(value: boolean) {
    setIndicatorFiltersVisible(value);
  }

  function openPremiumFilters(value: boolean) {
    setPremiumFiltersVisible(value);
  }

  function openFavoriteFilters(value: boolean) {
    setFavoriteFiltersVisible(value);
  }

  function handleMyFavoriteId(value: string[]) {
    setFirstMyFavoriteId(value);
  }

  function openCreateFavoriteModal(value: boolean) {
    setModalCreateFavoriteVisible(value);
  }

  function handleTypeFavoriteCreate(value: string) {
    setFavoriteTypeToCreate(value);
  }

  function handleDeleteFavorite(value: DeleteFavoriteModalProps) {
    setFavoriteToDelete(value);
  }

  function openDeleteFavoriteModal(value: boolean) {
    setIsDeleteFavoriteModalOpen(value);
  }

  function openEditFavoriteModal(value: boolean) {
    setEditFavoriteModalOpen(value);
  }

  function handleEditFavorite(value: GroupToEdit) {
    setFavoriteToEdit(value);
  }

  function setterIdFavoriteViewing(id: string) {
    setIdFavoriteViewing(id);
  }

  function setterFavoriteType(favoriteTypeValue: FavoriteType) {
    setFavoriteType(favoriteTypeValue);
  }

  function setterIndicatorFilterType(indicatorType: IndicatorFilterProps) {
    setIndicatorFilterType(indicatorType);
  }

  return (
    <FeatureStoreSidebarContext.Provider
      value={{
        openIndicatorFilters,
        indicatorFiltersVisible,
        searchIndicators,
        premiumFiltersVisible,
        openPremiumFilters,
        openFavoriteFilters,
        favoriteFilterVisible,
        handleMyFavoriteId,
        firstMyFavoriteId,
        modalCreateFavoriteVisible,
        openCreateFavoriteModal,
        handleTypeFavoriteCreate,
        favoriteTypeToCreate,
        handleDeleteFavorite,
        favoriteToDelete: favoriteToDelete!,
        openDeleteFavoriteModal,
        isDeleteFavoriteModalOpen,
        openEditFavoriteModal,
        isEditFavoriteModalOpen,
        handleEditFavorite,
        favoriteToEdit: favoriteToEdit!,
        idFavoriteViewing,
        setterIdFavoriteViewing,
        setterFavoriteType,
        favoriteType,
        setterIndicatorFilterType,
        indicatorFilterType,
      }}
    >
      {children}
    </FeatureStoreSidebarContext.Provider>
  );
};
