import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { RootState } from 'src/redux/store';
import { getChartColor } from 'src/utils/getChartColor';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import light from 'src/styles/themes/light';

import { ErrorDataView } from '../../types';
import { ContentCharts } from './styles';

interface ReturnStlDecomposition {
  trend:
    | {
        value: number[];
        date: Date[];
      }
    | undefined;
  seasonal:
    | {
        value: number[];
        date: Date[];
      }
    | undefined;
  remainder:
    | {
        value: number[];
        date: Date[];
      }
    | undefined;
}

export const STLDecomposition: React.FC = () => {
  const id = useSelector(
    (state: RootState) =>
      state.modelling.form?.fileDataSet.dataset_id ?? state.project.id,
  );

  const { t: translate } = useTranslation();

  const {
    data: dataSTLDecomposition,
    isLoading: isLoadingSTLDecomposition,
    isFetching: isFetchingSTLDecomposition,
    isError: isErrorSTLDecomposition,
    error: errorSTLDecomposition,
  } = useQuery<ReturnStlDecomposition, ErrorDataView>(
    ['dataview', 'stl-decomposition', id],
    async () => {
      const { data } = await api.get(
        `projects/${id}/dataview/stl-decompositions`,
      );
      return data;
    },
    {
      enabled: !!id,
      staleTime: 1000 * 60 * 20,
    },
  );

  const trendSerie: HChartsSeries = useMemo(
    () => ({
      name: translate('trend'),
      type: 'line',
      showInLegend: false,
      color: getChartColor(0),
      marker: {
        symbol: 'circle',
      },
      data: dataSTLDecomposition?.trend?.date.map((date, dateIndex) => ({
        x: new Date(`${date}T00:00`).getTime(),
        y: dataSTLDecomposition?.trend?.value[dateIndex],
        custom: {
          value: formatCompactNotation(
            dataSTLDecomposition?.trend?.value[dateIndex] || 0,
          ),
        },
      })),
    }),
    [dataSTLDecomposition, translate],
  );

  const seasonalSerie: HChartsSeries = useMemo(
    () => ({
      name: translate('seasonal'),
      type: 'line',
      showInLegend: false,
      color: getChartColor(0),
      marker: {
        symbol: 'circle',
      },
      data: dataSTLDecomposition?.seasonal?.date.map((date, dateIndex) => ({
        x: new Date(`${date}T00:00`).getTime(),
        y: dataSTLDecomposition?.seasonal?.value[dateIndex],
        custom: {
          value: formatCompactNotation(
            dataSTLDecomposition?.seasonal?.value[dateIndex] || 0,
          ),
        },
      })),
    }),
    [dataSTLDecomposition, translate],
  );

  const remainderSerie: HChartsSeries = useMemo(
    () => ({
      name: translate('remainder'),
      type: 'line',
      showInLegend: false,
      color: getChartColor(0),
      marker: {
        symbol: 'circle',
      },
      data: dataSTLDecomposition?.remainder?.date.map((date, dateIndex) => ({
        x: new Date(`${date}T00:00`).getTime(),
        y: dataSTLDecomposition?.remainder?.value[dateIndex],
        custom: {
          value: formatCompactNotation(
            dataSTLDecomposition?.remainder?.value[dateIndex] || 0,
          ),
        },
      })),
    }),
    [dataSTLDecomposition, translate],
  );

  const options: HChartsOptions = {
    chart: {
      marginTop: 24,
      marginBottom: 32,
      height: 170,
    },
    tooltip: {
      pointFormat:
        `<tr><td><b>${translate('date')}:</b> </td>` +
        `<td style="text-align: right">{point.x: ${' %d/%m/%Y'}}</td></tr>` +
        `<tr><td><b>${translate('value')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.value}</td></tr>',
    },
  };

  return (
    <div className="containerLinear" data-cy="stl-decomposition">
      <Card textCard={translate('stl_decomposition')} />
      {isErrorSTLDecomposition ? (
        <ContainerMaintenance
          content="chart"
          text={errorSTLDecomposition?.response?.data?.detail?.detail}
          data-testid="container-error-stl-decomposition"
          data-cy="container-error-stl-decomposition"
        />
      ) : isLoadingSTLDecomposition ||
        isFetchingSTLDecomposition ||
        !dataSTLDecomposition ? (
        // eslint-disable-next-line react/jsx-indent
        <>
          <ContainerSkeleton
            data-testid="container-loading-stl-decomposition-1"
            data-cy="container-loading-stl-decomposition-1"
            style={{ marginBottom: '1.5rem' }}
          />
          <ContainerSkeleton
            data-testid="container-loading-stl-decomposition-2"
            data-cy="container-loading-stl-decomposition-2"
            style={{ marginBottom: '1.5rem' }}
          />
          <ContainerSkeleton
            data-testid="container-loading-stl-decomposition-3"
            data-cy="container-loading-stl-decomposition-3"
          />
        </>
      ) : (
        <ContentCharts>
          {dataSTLDecomposition?.trend && (
            <HCharts
              series={[trendSerie]}
              options={{
                ...options,
                title: {
                  text: translate('trend'),
                  y: -18,
                  style: {
                    color: light.colors.gray6,
                  },
                },
              }}
              dataCy="chart-stl-decompostion-trend"
            />
          )}
          {dataSTLDecomposition?.seasonal ? (
            <HCharts
              series={[seasonalSerie]}
              options={{
                ...options,
                title: {
                  text: translate('seasonal'),
                  y: -18,
                  style: {
                    color: light.colors.gray6,
                  },
                },
              }}
              dataCy="chart-stl-decompostion-seasonal"
            />
          ) : (
            <ContainerMaintenance
              content="chart"
              text="Series without seasonality."
            />
          )}

          {dataSTLDecomposition?.remainder ? (
            <HCharts
              series={[remainderSerie]}
              options={{
                ...options,
                title: {
                  text: translate('remainder'),
                  y: -18,
                  style: {
                    color: light.colors.gray6,
                  },
                },
              }}
              dataCy="chart-stl-decompostion-remainder"
            />
          ) : (
            <ContainerMaintenance
              content="chart"
              text="Series without residues."
            />
          )}
        </ContentCharts>
      )}
    </div>
  );
};
