import React from 'react';

import { useTranslation } from 'react-i18next';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

import { Container, NavButton, PageButton } from './styles';

interface NewPaginationProps extends React.HTMLAttributes<HTMLInputElement> {
  page: number;
  setPage: (value: number) => void;
  total: number;
  quantityItemsPerPage: number;
  name: string;
  firstPageHaveDifferentElement?: boolean;
  isLoading?: boolean;
}

export const PaginationV2: React.FC<NewPaginationProps> = ({
  page,
  setPage,
  total,
  quantityItemsPerPage,
  name,
  firstPageHaveDifferentElement = false,
  isLoading = false,
  ...rest
}) => {
  const { t: translate } = useTranslation();

  if (isLoading) {
    return (
      <Container data-testid="pagination" {...rest}>
        <ContainerSkeleton
          withLoading={false}
          style={{ height: '1rem', width: '6rem' }}
        />

        <ContainerSkeleton
          withLoading={false}
          style={{ height: '1.875rem', width: '10rem' }}
        />
      </Container>
    );
  }

  return (
    <Container data-testid="pagination" {...rest}>
      <div>
        <span>
          {`${
            total > 0
              ? (page - 1) * quantityItemsPerPage +
                (!firstPageHaveDifferentElement ? 1 : page - 1 === 0 ? 1 : 0)
              : 0
          } / ${
            page * quantityItemsPerPage < total
              ? !firstPageHaveDifferentElement
                ? page * quantityItemsPerPage
                : page * quantityItemsPerPage - 1
              : total
          } ${translate('of')} ${total} ${name}`}
        </span>
      </div>

      <div data-testid="div-1-pagination">
        <NavButton
          type="button"
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
          data-testid="page-button-previous"
          data-cy="page-button-previous"
        >
          {translate('previous')}
        </NavButton>
        {page >= 3 && (
          <PageButton
            key="page-button-1-fixed"
            onClick={() => setPage(1)}
            type="button"
            selected={page === 1}
            data-testid="page-button-1-fixed"
          >
            1
          </PageButton>
        )}

        {page - 2 > 1 && (
          <PageButton
            key={`page-button-minus-${page - 2}`}
            onClick={() => setPage(page - 2)}
            type="button"
            data-testid={`page-button-minus-${page - 2}`}
          >
            ...
          </PageButton>
        )}

        {page > 1 && (
          <PageButton
            key={`page-button-minus-${page - 1}`}
            onClick={() => setPage(page - 1)}
            type="button"
            data-testid={`page-button-minus-${page - 1}`}
          >
            {page - 1}
          </PageButton>
        )}
        {page && (
          <PageButton
            key={`page-button-${page}`}
            type="button"
            data-testid={`page-button-${page}`}
            selected
          >
            {page}
          </PageButton>
        )}
        {page + 1 <=
          (total % quantityItemsPerPage !== 0
            ? Math.floor(total / quantityItemsPerPage) + 1
            : Math.floor(total / quantityItemsPerPage)) && (
          // eslint-disable-next-line react/jsx-indent
          <PageButton
            key={`page-button-plus-${page + 1}`}
            onClick={() => setPage(page + 1)}
            type="button"
            data-testid={`page-button-plus-${page + 1}`}
          >
            {page + 1}
          </PageButton>
        )}

        {(() => {
          const pageTotal =
            total % quantityItemsPerPage !== 0
              ? Math.floor(total / quantityItemsPerPage) + 1
              : Math.floor(total / quantityItemsPerPage);

          if (page + 2 >= pageTotal) return <></>;

          return (
            <PageButton
              key={`page-button-plus-${page + 2}`}
              onClick={() => setPage(page + 2)}
              type="button"
              data-testid={`page-button-plus-${page + 2}`}
            >
              ...
            </PageButton>
          );
        })()}

        {(() => {
          const pageTotal =
            total % quantityItemsPerPage !== 0
              ? Math.floor(total / quantityItemsPerPage) + 1
              : Math.floor(total / quantityItemsPerPage);

          if (page + 1 >= pageTotal) return <></>;

          return (
            <PageButton
              key={`page-button-${pageTotal}-fixed`}
              onClick={() => setPage(pageTotal)}
              type="button"
              data-testid={`page-button-${pageTotal}-fixed`}
            >
              {pageTotal}
            </PageButton>
          );
        })()}

        <NavButton
          type="button"
          onClick={() => setPage(page + 1)}
          disabled={page * quantityItemsPerPage >= total}
          data-testid="page-button-next"
        >
          {translate('next')}
        </NavButton>
      </div>
    </Container>
  );
};
