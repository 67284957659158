import React from 'react';

import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';

import { CardInformation, UnderstandingResultCards } from './styles';

interface ResultsCardsProps {
  cards: {
    [key: string]: number;
  };
  loadingResult: boolean;
  type: 'primary' | 'secondary';
  valueYSelected?: number | null;
}

export const ResultsCards: React.FC<ResultsCardsProps> = ({
  loadingResult,
  cards,
  type,
  valueYSelected,
}) => {
  if (loadingResult) {
    return (
      <UnderstandingResultCards type={type}>
        {Array.from({ length: type === 'primary' ? 2 : 3 }, (_, index) => (
          <ContainerSkeleton
            key={`card-loading-${type}-${index}`}
            withLoading={false}
            style={{ height: type === 'primary' ? '107px' : '83px', flex: 1 }}
          />
        ))}
      </UnderstandingResultCards>
    );
  }

  return (
    <UnderstandingResultCards type={type}>
      {Object.keys(cards).map((key, index) => (
        <CardInformation
          key={`results-card-${key}`}
          data-testid={`${type}-results-card-${index}`}
          type={type}
          yValueColor={
            type === 'primary' && index === 0 && valueYSelected
              ? valueYSelected >= 0
                ? 'blue'
                : 'orange'
              : undefined
          }
          percentualValueColor={Number(cards[key]) >= 0 ? 'blue' : 'orange'}
          isFirstPrimary={index === 0 && type === 'primary'}
        >
          <h4>{key}</h4>
          {type === 'primary' && index === 0 ? (
            <h3>
              {valueYSelected &&
                `${formatCompactNotation(Number(valueYSelected))} `}
              <span>
                ({Number(cards[key]) > 0 && '+'}
                {formatCompactNotation(cards[key])}
                %)
              </span>
            </h3>
          ) : type === 'primary' ? (
            <h3>
              <span>
                {Number(cards[key]) > 0 && '+'}
                {formatCompactNotation(cards[key])}p.p
              </span>
            </h3>
          ) : (
            <h3>
              <span>{formatCompactNotation(cards[key])}p.p</span>
            </h3>
          )}
        </CardInformation>
      ))}
    </UnderstandingResultCards>
  );
};
