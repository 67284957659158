import React, { createContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { Menu } from './menus';
import {
  workspacesSideBarProjectsMenus,
  workspacesSideBarResultsMenus,
} from './menus/menus';
import {
  WorkspaceSideBarContainer,
  Item,
  MenuSectionTitleText,
} from './styles';
import { WorkspaceInfo } from './WorkspaceInfo';
import { DependentVariable } from './DependentVariable';
import { ReleaseMenu } from './ReleaseMenu';

interface WorkspaceSideBarContextProps {
  menuReleaseVisible: boolean;
  openMenuSelectRelease: () => void;
  closeMenuSelectRelease: () => void;
}
export const WorkspaceSideBarContext = createContext(
  {} as WorkspaceSideBarContextProps,
);

export const WorkspaceSideBar: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = () => {
  const [menuReleaseVisible, setMenuReleaseVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t: translate } = useTranslation();

  const workspaceId = location.pathname
    .replace('/models/workspaces/', '')
    .slice(0, 24);

  const checkIfSubMenuIsActive = (subMenu: Menu[] | undefined): boolean => {
    if (subMenu) {
      return subMenu.some((page) => {
        if (location.pathname === page.url) {
          return true;
        }
        if (page.submenu) {
          return checkIfSubMenuIsActive(page.submenu);
        }
        return false;
      });
    }
    return false;
  };

  const handleIsActive = (
    url: string | undefined,
    subMenu: Menu[] | undefined,
  ): boolean => {
    if (subMenu) {
      return checkIfSubMenuIsActive(subMenu);
    }

    return location.pathname.replace(workspaceId, ':id') === url;
  };

  const handleMenuClick = (url: string | undefined) => {
    if (url) {
      navigate(url.replace(':id', workspaceId));
    }
  };

  type ListMenuProps = {
    dept: number;
    data: Menu;
    hasSubMenu: Menu[] | undefined;
    menuName: string;
    menuIndex: number;
  };

  const ListMenu = ({ dept, data, hasSubMenu }: ListMenuProps) => {
    const { Icon } = data;
    const isActive = handleIsActive(data?.url, data?.submenu);

    return (
      <>
        <Item
          data-testid={`li-label${data.label
            .toLocaleLowerCase()
            .replaceAll('label', '')}`}
          dept={dept}
          hasSubMenu={!!hasSubMenu}
          isToggled
          isActive={isActive}
          disabled={!!data.isDisabled}
          onClick={() => {
            if (data.isDisabled) {
              return;
            }
            handleMenuClick(data?.url);
          }}
        >
          <span>
            {Icon && <Icon size="1.5rem" style={{ marginRight: '0.5rem' }} />}
            {translate(data.label)}
          </span>
        </Item>
      </>
    );
  };

  function openMenuSelectRelease() {
    setMenuReleaseVisible(true);
  }

  function closeMenuSelectRelease() {
    setMenuReleaseVisible(false);
  }

  return (
    <WorkspaceSideBarContext.Provider
      value={{
        menuReleaseVisible,
        openMenuSelectRelease,
        closeMenuSelectRelease,
      }}
    >
      <WorkspaceSideBarContainer
        className="sidebar"
        data-testid="sidebar-container"
        data-cy="sidebar-container"
      >
        <div>
          <WorkspaceInfo workspaceId={workspaceId} />

          <ul>
            <MenuSectionTitleText>
              {translate('workspaceSideBarProjects')}
            </MenuSectionTitleText>
            {workspacesSideBarProjectsMenus?.map(
              (menus: Menu, index: number) => {
                const dept = +1;
                const menuName = `sidebar-menu-${dept}-${index}`;
                return (
                  <>
                    <ListMenu
                      dept={dept}
                      data={menus}
                      hasSubMenu={menus.submenu}
                      menuName={menuName}
                      key={menuName}
                      menuIndex={index}
                    />
                  </>
                );
              },
            )}
            <MenuSectionTitleText>
              {translate('workspaceSideBarResults')}
            </MenuSectionTitleText>
            {workspacesSideBarResultsMenus?.map((menu: Menu, index: number) => {
              const dept = +1;
              const menuName = `sidebar-menu-${dept}-${index}`;
              return (
                <>
                  <ListMenu
                    dept={dept}
                    data={menu}
                    hasSubMenu={menu.submenu}
                    menuName={menuName}
                    key={menuName}
                    menuIndex={index}
                  />
                </>
              );
            })}
          </ul>

          {location.pathname.includes('/projections') && <DependentVariable />}
        </div>
      </WorkspaceSideBarContainer>
      <ReleaseMenu workspaceId={workspaceId} />
    </WorkspaceSideBarContext.Provider>
  );
};
