import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { RadioButton } from 'src/components/RadioButton';
import { CheckBox } from 'src/components/CheckBox';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import {
  WorkspaceProjectionsFrequency,
  WorkspaceProjectionsTransformation,
} from 'src/models/redux/reducers/WorkspaceProjectionsOptions';

import { Options } from './styles';
import { AISelectionResultsContext } from '..';

export const ResultsOptions: React.FC = () => {
  const { selectFrequency, selectTransformation, dataLevel, dataVariation } =
    useContext(AISelectionResultsContext);

  const { workspace } = useSelector((state: RootState) => state);

  const {
    results: { frequency, transformations },
  } = useSelector((state: RootState) => state.workspaceProjectionsOptions);

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (frequency === 'monthly' && workspace.frequency !== 'daily') {
      selectFrequency('original');
    }
  }, [frequency, selectFrequency, workspace.frequency]);

  function handleSelectFrequency(frequencyAux: WorkspaceProjectionsFrequency) {
    selectFrequency(frequencyAux);
  }

  function handleSelectTransformation(
    transformationAux: WorkspaceProjectionsTransformation,
  ) {
    selectTransformation(transformationAux);
  }

  const isAnnualFrequencyAvailable = workspace.frequency !== 'annual';

  const hasLevel = !!(
    (dataLevel?.historical.date.length ?? 0) +
    (dataLevel?.forecast?.date.length ?? 0)
  );

  const hasVariation = !!(
    (dataVariation?.historical.date.length ?? 0) +
    (dataVariation?.forecast?.date.length ?? 0)
  );

  return (
    <Options>
      <Tooltip id="results-variation-tooltip" className="customTooltipTheme" />
      <div>
        <span>{translate('workspaceProjectionsResultFrequency')}</span>
        <div>
          <RadioButton
            label={translate('workspaceProjectionsResultOriginal')}
            onChange={() => handleSelectFrequency('original')}
            checked={frequency === 'original'}
          />

          {workspace.frequency === 'daily' && (
            <RadioButton
              label={translate('workspaceProjectionsResultMonthly')}
              onChange={() => handleSelectFrequency('monthly')}
              checked={frequency === 'monthly'}
            />
          )}

          {/* {isQuarterlyFrequencyAvailable && (
            <RadioButton
              label={translate('workspaceProjectionsResultQuarterly')}
              onChange={() => handleSelectFrequency('quarterly')}
              checked={frequency === 'quarterly'}
            />
          )} */}

          {isAnnualFrequencyAvailable && (
            <RadioButton
              label={translate('workspaceProjectionsResultAnnual')}
              onChange={() => handleSelectFrequency('yearly')}
              checked={frequency === 'yearly'}
            />
          )}
        </div>
      </div>

      <div>
        <span>{translate('workspaceProjectionsResultTransformation')}</span>
        <div>
          <CheckBox
            label={translate('workspaceProjectionsResultLevel')}
            onChange={() => handleSelectTransformation('Level')}
            checked={transformations.includes('Level')}
            disabled={
              (transformations.includes('Level') &&
                transformations.length === 1) ||
              !hasLevel
            }
          />

          <div
            data-tooltip-id="results-variation-tooltip"
            data-tooltip-html={
              !hasVariation
                ? translate(
                    'workspaceProjectionsResultsNotEnoughObservationsVariation',
                  )
                : undefined
            }
          >
            <CheckBox
              label={translate('workspaceProjectionsResultVariation')}
              onChange={() => handleSelectTransformation('Variation')}
              checked={transformations.includes('Variation')}
              disabled={
                (transformations.includes('Variation') &&
                  transformations.length === 1) ||
                !hasVariation
              }
            />
          </div>
        </div>
      </div>
    </Options>
  );
};
