import React, { useContext, useEffect, useState } from 'react';

import {
  ArrowLeft,
  CaretRight,
  Circle,
  FolderOpen,
  MagnifyingGlass,
  MapPin,
  Tag,
  X,
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Input } from 'src/components/Input';
import {
  setBreadcumbs,
  setCategories,
  setCategoriesData,
  setPage,
  setSearch,
  setSearchTimer,
  setSearchTimerActive,
} from 'src/feature-store/redux/reducers/IndicatorsPage';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';
import { Select } from 'src/components/Select';

import {
  CategoriesBox,
  CategoriesContainer,
  CategoriesTitle,
  CleanFiltersButton,
  ContainerWithoutCategories,
  IndicatorsSearch,
  IndicatorFilterContainer,
  SelectedCategory,
  SubCategory,
  NavigationContainer,
  SelectCountryFilter,
  IndicatorFilterHead,
  IndicatorFilterButtonContainer,
  BusinessButton,
  FreeButton,
} from './styles';
import { FeatureStoreSidebarContext } from '../Contexts/NavigationContext';

interface Category {
  id: string;
  node: string;
  name: {
    'en-us': string;
    'pt-br': string;
  };
  children: Category[];
  length: number;
}

interface CategoriesProps {
  tree: Category[];
}

export const Categories: React.FC = () => {
  const [searchError, setSearchError] = useState('');
  const [country, setCountry] = useState('');

  const { breadcumbs, categories, search, lastSearch } = useSelector(
    (state: RootState) => state.indicatorsPage,
  );

  const { language, isFreemium } = useSelector(
    (state: RootState) => state.auth.user,
  );
  const {
    indicatorFiltersVisible,
    setterIndicatorFilterType,
    indicatorFilterType,
  } = useContext(FeatureStoreSidebarContext);

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const {
    data: categoriesData,
    isLoading: isLoadingCategories,
    isError: isCategoriesErrored,
  } = useQuery(['categories'], async () => {
    const { data } = await api.get<CategoriesProps>('/domains');

    return data.tree;
  });

  useEffect(() => {
    dispatch(setCategoriesData(categoriesData));

    const initialCategory = categoriesData?.find(
      (category) => category.name['en-us'] === 'Brazil',
    );

    !country && setCountry(initialCategory?.id ?? '');
  }, [categoriesData, country, dispatch]);

  useEffect(() => {
    if (categoriesData && !categories.length && breadcumbs.length === 0) {
      const initialCategory = categoriesData?.find(
        (category) => category.name['en-us'] === 'Brazil',
      );
      dispatch(setCategories(initialCategory?.children ?? categoriesData));
      initialCategory &&
        dispatch(
          setBreadcumbs([
            ...breadcumbs,
            {
              category_id: initialCategory.id,
              category_node: initialCategory.node,
              category_name: initialCategory.name,
            },
          ]),
        );
    }
  }, [
    dispatch,
    categoriesData,
    categories.length,
    breadcumbs.length,
    breadcumbs,
  ]);

  useEffect(() => {
    breadcumbs && setCountry(breadcumbs[0]?.category_id ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSearchIndicators(value: string) {
    dispatch(setSearch(value));

    if (
      value.length > 0 &&
      value.length < 3 &&
      value.length < lastSearch.length
    ) {
      setSearchError('indicatorsQuickSearchInputErrorLeast3Characters');

      return;
    }

    if (value.length > 50) {
      setSearchError('indicatorsQuickSearchInputErrorMaxCharacters');
      return;
    }

    setSearchError('');

    if (value !== search) {
      dispatch(setSearchTimer(2000));
      dispatch(setSearchTimerActive(true));

      value.length >= 3 && dispatch(setPage(1));
    }
  }

  function handleSelectCountry(categoryID: string) {
    // handleClearFilters();
    // dispatch(setSearch(''));
    // dispatch(setBreadcumbs([]));
    const categoryInfo = categoriesData?.find(
      (category) => category.id === categoryID,
    );
    // handleSelectCategory(categoryInfo!);
    dispatch(setSearch(''));
    dispatch(setPage(1));

    categoryInfo && dispatch(setCategories(categoryInfo.children));

    categoryInfo &&
      dispatch(
        setBreadcumbs([
          {
            category_id: categoryInfo.id,
            category_node: categoryInfo.node,
            category_name: categoryInfo.name,
          },
        ]),
      );
    setCountry(categoryID);
  }

  function handleSelectCategory(category: Category) {
    dispatch(setSearch(''));
    dispatch(setPage(1));

    dispatch(setCategories(category.children));

    dispatch(
      setBreadcumbs([
        ...breadcumbs,
        {
          category_id: category.id,
          category_node: category.node,
          category_name: category.name,
        },
      ]),
    );
  }

  function handleBacktoUpperCategory() {
    dispatch(setSearch(''));
    if (categoriesData) {
      let categoriesAux = categoriesData;
      for (let i = 0; i <= breadcumbs.length - 1; i++) {
        if (i < breadcumbs.length - 1) {
          categoriesAux =
            categoriesAux.find(
              (category) => category.node === breadcumbs[i].category_node,
            )?.children ?? categoriesAux;
        }
      }
      const newBreadcumbs = breadcumbs.slice(0, breadcumbs.length - 1);
      dispatch(setBreadcumbs(newBreadcumbs));
      dispatch(setCategories(categoriesAux));
    }
  }

  function handleClearFilters() {
    dispatch(setSearch(''));
    // dispatch(setBreadcumbs([]));
    // categoriesData && dispatch(setCategories(categoriesData));
    const initialCategory = categoriesData?.find(
      (category) => category.name['en-us'] === 'Brazil',
    );

    initialCategory &&
      dispatch(
        setBreadcumbs([
          {
            category_id: initialCategory.id,
            category_node: initialCategory.node,
            category_name: initialCategory.name,
          },
        ]),
      );
    initialCategory &&
      (dispatch(setCategories(initialCategory.children)),
      setCountry(initialCategory.id));
  }

  return (
    <>
      <CategoriesBox>
        <Card
          textCard={translate('categoriesSidebarFilters')}
          textDescription={translate('categoriesSidebarDescription')}
          style={{ marginBottom: '0' }}
        />
        <Input
          icon={<MagnifyingGlass size="1rem" />}
          testid="input-search-indicator"
          data-cy="input-search-indicator"
          placeholder={
            breadcumbs.length === 0
              ? translate('indicatorQuickSearchInputDefaultPlaceholders')
              : `${translate(
                  'indicatorQuickSearchInputSelectOneCategoryPlaceholders',
                )} ${
                  breadcumbs[breadcumbs.length - 1]?.category_name[language] ??
                  breadcumbs[breadcumbs.length - 1]?.category_name['en-us'] ??
                  ''
                }`
          }
          style={
            searchError
              ? { width: '100%', height: '2rem', marginBottom: '1.5rem' }
              : { width: '100%', height: '2rem' }
          }
          value={search}
          onChange={({ target: { value } }) => handleSearchIndicators(value)}
          error={translate(searchError)}
        />

        {isFreemium && (
          <IndicatorFilterContainer data-testid="indicator-filter-container">
            <IndicatorFilterHead>
              <Tag size="1rem" />
              <h3>{translate('categoriesSidebarIndicatorType')}</h3>
            </IndicatorFilterHead>

            <IndicatorFilterButtonContainer>
              <FreeButton
                data-testid="free-button-indicator-filter"
                onClick={() => {
                  dispatch(setPage(1));
                  setterIndicatorFilterType(
                    indicatorFilterType === 'free' ? null : 'free',
                  );
                }}
                isActive={indicatorFilterType === 'free'}
              >
                Free
              </FreeButton>
              <BusinessButton
                data-testid="business-button-indicator-filter"
                onClick={() => {
                  dispatch(setPage(1));
                  setterIndicatorFilterType(
                    indicatorFilterType === 'default' ? null : 'default',
                  );
                }}
                isActive={indicatorFilterType === 'default'}
              >
                Business
              </BusinessButton>
            </IndicatorFilterButtonContainer>
          </IndicatorFilterContainer>
        )}

        <SelectCountryFilter>
          <div>
            <MapPin size="1rem" />
            <h3>{translate('categoriesSidebarCountries')}</h3>
          </div>
          <Select
            options={categoriesData?.map((category) => ({
              label: category.name?.[language] ?? category.name?.['en-us'],
              value: category.id,
            }))}
            value={
              categoriesData
                ? {
                    label:
                      categoriesData?.find(
                        (category) => category.id === country,
                      )?.name?.[language] ?? '',
                    value:
                      categoriesData?.find(
                        (category) => category.id === country,
                      )?.id ?? '',
                  }
                : undefined
            }
            onChange={(option: any) => handleSelectCountry(option.value)}
            placeholder={translate('categoriesSidebarSelectCountries')}
            style={{ width: '100%' }}
          />
        </SelectCountryFilter>
      </CategoriesBox>

      <IndicatorsSearch data-testid="container-categories">
        <CategoriesContainer>
          <CategoriesTitle style={{ marginTop: '0.5rem' }}>
            <FolderOpen size="1rem" />
            <h5>{translate('categoriesBoxSidebarTitle')}</h5>
          </CategoriesTitle>
          <div>
            {breadcumbs.length > 1 && (
              <NavigationContainer>
                <SelectedCategory
                  data-testid="button-return-category"
                  data-cy="button-return-category"
                  onClick={handleBacktoUpperCategory}
                  disabled={breadcumbs.length === 1}
                >
                  <>
                    <div>
                      <ArrowLeft size="1rem" />
                    </div>
                    <div>
                      {breadcumbs[breadcumbs.length - 1].category_name[
                        language
                      ] ??
                        breadcumbs[breadcumbs.length - 1].category_name[
                          'en-us'
                        ]}
                    </div>
                  </>
                </SelectedCategory>
                <CleanFiltersButton
                  data-testid="button-clean-filters"
                  data-cy="button-clean-filters"
                  onClick={() => {
                    handleClearFilters();
                    setterIndicatorFilterType(null);
                  }}
                >
                  <div>
                    <X size="1rem" />
                  </div>
                  <p>{translate('indicatorsQuickSearchClearFilters')}</p>
                </CleanFiltersButton>
              </NavigationContainer>
            )}
          </div>
          {isCategoriesErrored || categories === null ? (
            <ContainerWithoutCategories>
              <p>{translate('indicatorsQuickSearchUnableLoadCategories')}</p>
            </ContainerWithoutCategories>
          ) : isLoadingCategories && indicatorFiltersVisible ? (
            <>
              {Array.from({ length: 10 }).map((_, index) => (
                <ContainerSkeleton
                  key={`${index + 1}`}
                  withLoading={false}
                  style={{
                    width: `${Math.random() * (180 - 100) + 100}px`,
                    height: '1.5rem',
                    marginTop: '1.5rem',
                  }}
                />
              ))}
            </>
          ) : categories.length > 0 ? (
            <>
              {categories.map((category) => (
                <SubCategory
                  key={category.id}
                  data-testid={`sub-category-${category.node
                    .toLocaleLowerCase()
                    .replaceAll(' ', '-')}`}
                  data-cy={`sub-category-${category.node
                    .toLocaleLowerCase()
                    .replaceAll(' ', '-')}`}
                  onClick={() => handleSelectCategory(category)}
                  category={
                    breadcumbs[1]?.category_name['en-us']?.toLowerCase() ??
                    category.name['en-us'].toLowerCase()
                  }
                >
                  <div>
                    <Circle size="0.5rem" weight="fill" />
                    <p>{category.name[language] ?? category.name['en-us']} </p>
                  </div>
                  <div>
                    {/* <span
                      data-testid={`sub-category-${category.node
                        .toLocaleLowerCase()
                        .replaceAll(' ', '-')}-quant`}
                    >
                      {category.length < 10
                        ? `(0${category.length})`
                        : `(${category.length})`}
                    </span> */}
                    <CaretRight size="1rem" />
                  </div>
                </SubCategory>
              ))}
            </>
          ) : (
            categories.length === 0 && (
              <ContainerWithoutCategories>
                <p>{translate('indicatorsQuickSearchNoSubCategories')}</p>
              </ContainerWithoutCategories>
            )
          )}
        </CategoriesContainer>
      </IndicatorsSearch>
    </>
  );
};
