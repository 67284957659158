import React, { useState } from 'react';

import { Card } from 'src/components/Card';
import { Button } from 'src/components/Button';
import { DownloadSimple } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { FailedModal } from 'src/components/Modal/Failed';
import apiWorkspace from 'src/models/service/apiWorkspace';

import { Container } from './styles';

interface IResponseHeadersDowload {
  'content-disposition'?: string;
}

interface DownloadError {
  detail?: {
    detail?: string;
    description?: string;
  };
}

interface Error {
  title: string;
  description: string;
}

export const Export: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorInformation, setErrorInformation] = useState<Error | null>(null);

  const {
    id,
    ySelected,
    name: workspaceName,
    releaseSelected,
  } = useSelector((state: RootState) => state.workspace);

  const { t: translate } = useTranslation();

  async function handleDownload() {
    try {
      setLoading(true);
      const { data, headers } = await apiWorkspace.get(
        `/workspaces/${id}/releases/${releaseSelected?.id}/ys/${ySelected?.y_label}/models/${ySelected?.model_id}/business/download`,
        {
          responseType: 'blob',
        },
      );

      if (data) {
        const fileURL = window.URL.createObjectURL(
          new Blob([data], { type: 'text/xlsx' }),
        );

        const contentDisposition = (headers as IResponseHeadersDowload)[
          'content-disposition'
        ];

        const link = document.createElement('a');

        const name =
          contentDisposition?.slice(
            contentDisposition.indexOf('filename=') + 9,
            contentDisposition.length,
          ) ?? `${workspaceName}-${ySelected?.y_label}.xlsx`;

        if (link.download !== undefined) {
          link.setAttribute('href', fileURL);
          link.setAttribute('download', name);
          link.setAttribute('data-testid', 'download-start');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
        }
      }
    } catch (err) {
      const error: AxiosError<DownloadError> | any = err;
      const errorMessage =
        error?.response?.data?.detail?.detail ??
        error?.response?.data?.detail?.description ??
        translate('workspaceProjectionsDownloadError');

      setErrorInformation({
        title: translate('requestFailed'),
        description: errorMessage,
      });
    }
    setLoading(false);
  }

  function closeModal() {
    setErrorInformation(null);
  }

  return (
    <Container className="containerLinear">
      <Card
        textCard={translate('workspaceProjectionsExportTitle')}
        textDescription={translate('workspaceProjectionsExportDescription')}
        style={{ marginBottom: '0' }}
      />
      <Button
        buttonType="secondary"
        icon={<DownloadSimple size="1.125rem" weight="bold" />}
        data-testid="ia-selection-export-button"
        data-cy="ia-selection-export-button"
        onClick={handleDownload}
        loading={loading}
        disabled={loading}
      >
        {translate('workspaceProjectionsExportDownload')}
      </Button>
      {errorInformation && (
        <FailedModal
          errorInfo={{
            title: errorInformation.title,
            description: errorInformation.description,
          }}
          setVisible={closeModal}
          visible
        />
      )}
    </Container>
  );
};
