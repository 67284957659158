import React, { useState } from 'react';

import { Card } from 'src/components/Card';
import { Tooltip } from 'react-tooltip';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { formatOrdinals } from 'src/utils/formatOrdinals';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { translateTransformationText } from 'src/utils/translateTransformationText';
import { ArrowUpRight, CaretDown } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import { NavLink } from 'react-router-dom';

import { ContentTitleAndModelInformation, RedirectToModel } from './styles';
import { SelectedModel } from '../../types';

interface Accuracy {
  type: string;
  accuracy: number[];
  ranking: number[];
  row_id: string[];
}

interface InformationProps {
  selectedModelData: SelectedModel | undefined;
  selectedModelLoading: boolean;
  selectedModelError: boolean;
}

export const Information: React.FC<InformationProps> = ({
  selectedModelData,
  selectedModelLoading,
  selectedModelError,
}) => {
  const [tableInformationVisible, setTableInformationVisible] = useState(false);

  const {
    auth: { user },
    project,
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const firstAccuracy = project.model?.accuracy[0];

  const { data: dataAccuracy } = useQuery(
    ['model-overview-accuracy', project.id, project.selectedY, firstAccuracy],
    async () => {
      const { data } = await api.get<Accuracy[]>(
        `/projects/${project.id}/${project.selectedY?.id}/models/accuracy/${firstAccuracy}`,
      );
      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!project.id && !!project.selectedY && !!firstAccuracy,
    },
  );

  function getModelLink() {
    if (!dataAccuracy) {
      return null;
    }

    const accuracyType = dataAccuracy.find(
      (accuracy) =>
        accuracy.type === selectedModelData?.selected_model.model_type,
    );

    if (!accuracyType) {
      return null;
    }

    const rowIdIndex = accuracyType.row_id.findIndex(
      (rowId) => rowId === selectedModelData?.row_id,
    );

    if (rowIdIndex < 0) {
      return null;
    }

    const type = selectedModelData?.selected_model.model_type
      .replaceAll(' ', '-')
      .toLowerCase();

    if (!type) {
      return null;
    }

    return `/models/time-series/${
      project.id
    }/model-explorer/${type}/specifics?model_number=${rowIdIndex + 1}`;
  }

  const link = getModelLink();

  return (
    <ContentTitleAndModelInformation
      className="containerLinear"
      tableVisible={tableInformationVisible}
    >
      {selectedModelData?.selected_model?.model_type ? (
        <Card
          textCard={`${translate(
            selectedModelData?.selected_model?.model_type.replace(' ', '_'),
          )} - ${translate('modelInProductionTitle')}`}
          textDescription={translate('modelInProductionDescription')}
        />
      ) : (
        <Card
          textCard={translate('modelInProductionTitle')}
          textDescription={translate('modelInProductionDescription')}
        />
      )}
      <div>
        <Tooltip
          id="models-time-series-model-in-production"
          className="customTooltipTheme"
        />
        <table>
          <thead>
            <tr>
              <th>{translate('modelInProductionSelectedModel')}</th>
              <th>{translate('modelInProductionSelectedModelSample')}</th>
              <th>
                {translate('modelInProductionSelectedModelTransformation')}
              </th>
              <th
                data-tooltip-id="models-time-series-model-in-production"
                data-tooltip-html={translate('MAPEExplanationTooltip')}
              >
                MAPE (%)
              </th>
              <th
                data-tooltip-id="models-time-series-model-in-production"
                data-tooltip-html={translate('WMAPEExplanationTooltip')}
              >
                WMAPE (%)
              </th>
              <th
                data-tooltip-id="models-time-series-model-in-production"
                data-tooltip-html={translate('MPEExplanationTooltip')}
              >
                MPE (%)
              </th>
              <th
                data-tooltip-id="models-time-series-model-in-production"
                data-tooltip-html={translate('RMSEExplanationTooltip')}
              >
                RMSE
              </th>
              <th
                data-tooltip-id="models-time-series-model-in-production"
                data-tooltip-html={translate('MASEExplanationTooltip')}
              >
                MASE
              </th>
              <th
                data-tooltip-id="models-time-series-model-in-production"
                data-tooltip-html={translate('MASEsExplanationTooltip')}
              >
                MASEs
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedModelError ? (
              <tr>
                <td data-testid="selected-model">--</td>
                <td data-testid="selected-model-sample">--</td>
                <td data-testid="selected-model-transformation">--</td>
                <td data-testid="selected-model-mape">--</td>
                <td data-testid="selected-model-wmape">--</td>
                <td data-testid="selected-model-mpe">--</td>
                <td data-testid="selected-model-rmse">--</td>
                <td data-testid="selected-model-mase">--</td>
                <td data-testid="selected-model-mases">--</td>
              </tr>
            ) : selectedModelLoading || !selectedModelData ? (
              <tr>
                {Array.from({ length: 9 }, (_, index) => (
                  <td key={`selected-model-loading-${index}`}>
                    <ContainerSkeleton
                      data-testid={`selected-model-loading-${index}`}
                      withLoading={false}
                      style={{
                        width: index < 2 ? '100px' : '30px',
                        height: '16px',
                        margin: '0 auto',
                      }}
                    />
                  </td>
                ))}
              </tr>
            ) : (
              <tr>
                <td data-testid="selected-model">
                  <RedirectToModel isDisabled={!link}>
                    <NavLink to={link || ''} target="_self">
                      {selectedModelData.selected_model.model_type === 'ARIMA'
                        ? `${formatOrdinals(
                            Number(selectedModelData.selected_model.type),
                            user.language,
                          )} ${selectedModelData.selected_model.model_type}`
                        : selectedModelData.selected_model.model_type ===
                          'Forecast Combination'
                        ? `${translate(
                            selectedModelData.selected_model.model_type.replace(
                              ' ',
                              '_',
                            ),
                          )} - ${selectedModelData.selected_model.type}`
                        : selectedModelData?.selected_model.model_type ===
                          'Regularized Regression'
                        ? selectedModelData?.selected_model.type
                        : selectedModelData?.selected_model.model_type ===
                          'Random Forest'
                        ? selectedModelData.selected_model.model_type
                        : selectedModelData?.selected_model.model_type ===
                            'Elementary' &&
                          selectedModelData.selected_model.model}
                      <ArrowUpRight size={16} weight="bold" />
                    </NavLink>
                  </RedirectToModel>
                </td>
                <td data-testid="selected-model-sample">
                  {selectedModelData.sample}
                </td>
                <td data-testid="selected-model-transformation">
                  {translateTransformationText(
                    selectedModelData.transformation || '--',
                    user.language,
                  )}
                </td>
                <td data-testid="selected-model-mape">
                  {selectedModelData.MAPE?.toFixed(2) || '--'}
                </td>
                <td data-testid="selected-model-wmape">
                  {selectedModelData.WMAPE?.toFixed(2) || '--'}
                </td>
                <td data-testid="selected-model-mpe">
                  {selectedModelData.MPE?.toFixed(2) || '--'}
                </td>
                <td data-testid="selected-model-rmse">
                  {selectedModelData.RMSE?.toFixed(2) || '--'}
                </td>
                <td data-testid="selected-model-mase">
                  {selectedModelData.MASE?.toFixed(2) || '--'}
                </td>
                <td data-testid="selected-model-mases">
                  {selectedModelData.MASEs?.toFixed(2) || '--'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <button
        type="button"
        onClick={() => setTableInformationVisible(!tableInformationVisible)}
        data-testid="button-see-more-information"
        data-cy="button-see-more-information"
      >
        <span>{translate('modelInProductionSeeMoreInformation')}</span>
        <CaretDown size="1rem" weight="bold" />
      </button>
    </ContentTitleAndModelInformation>
  );
};
