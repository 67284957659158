export const WorkspaceOverviewENUSTranslation = {
  workspaceOverviewTitle: 'Workspace Overview',
  workspaceOverviewDescription:
    'Scan and compare all variables in your workspace.',
  workspaceProjectionsTitle: 'Projections',
  workspaceProjectionsDescriptions:
    'Access the projections of your variables over time.',
  workspaceProjectionsFrequency: 'Frequency',
  workspaceProjectionsOriginal: 'Original',
  workspaceProjectionsAnnual: 'Annual',
  workspaceProjectionsTransformation: 'Transformation',
  workspaceProjectionsLevel: 'Level',
  workspaceProjectionsVariation: 'Variation (%)',
  workspaceProjectionsHistorical: 'Historical',
  workspaceProjectionsForecast: 'Forecast',
  workspaceProjectionsVariables: 'variables',
  workspaceProjectionsOnlyForMonthlyFrequency: 'Only for monthly frequency',
  workspaceProjectionsTable: 'table',
  workspaceSearchDependentVariablePlaceholder: 'Search dependent variables',

  workspaceOverviewTitleTable: 'Summary',
  workspaceOverviewSearchDependentVariablePlaceholder:
    'Search dependent variables',
  workspaceSearchDependentVariableNotFound: `We couldn't find any dependent variable containing`,
  workspaceOverviewFirstColumn: 'Dependent Variable',
  workspaceOverviewOnly4Variables: 'It is possible to select only 4 variables',
  workspaceOverviewChart: 'chart',
  workspaceOverviewSelectAVariable: 'Select a variable',
  workspaceOverviewSelectAVariableDescription:
    'Select at least one variable to check the comparison of the dependent variables of your project.',
  workspaceOverviewDownloadError: 'There was an error downloading file.',
};
