export const workspaceProjectionsResultPTTranslate = {
  workspaceProjectionsResultTitle: 'Resultados',
  workspaceProjectionsResultDescription:
    'Selecione um período histórico para obter mais detalhes dos resultados da modelagem.',
  workspaceProjectionsResultFrequency: 'Frequência',
  workspaceProjectionsResultTransformation: 'Transformação',
  workspaceProjectionsResultOriginal: 'Original',
  workspaceProjectionsResultMonthly: 'Mensal',
  workspaceProjectionsResultQuarterly: 'Trimestral',
  workspaceProjectionsResultAnnual: 'Anual',
  workspaceProjectionsResultLevel: 'Nível',
  workspaceProjectionsResultVariation: 'Variação',
  workspaceProjectionsResultLatestData: 'Dados recentes',
  workspaceProjectionsResultsVariationOriginal:
    'Variação (comparado ao período anterior)',
  workspaceProjectionsResultsVariationMonthlyQuarterly:
    'Variação (comparado ao mesmo período do ano anterior)',
  workspaceProjectionsResultsVariationAnnual:
    'Variação (comparado ao ano anterior)',
  workspaceProjectionsMonthlyAggregationIsNotAvailable:
    'A agregação de série mensal não está disponível para dados com valores faltantes.',
  workspaceProjectionsResultsNotEnoughObservationsVariation:
    'Não há observações suficientes para calcular a variação.',
  workspaceProjectionsResultsThisFeatureTemporarilyUnavailable:
    'Este recurso está temporariamente indisponível.',
};
