import { transformUppercaseFirstLetter } from './transformUppercaseFirstLetter';

export function translateTransformationText(
  text: string,
  language: string,
): string {
  let textAux = text.trim();

  if (textAux.charAt(0) === '/') {
    textAux = textAux.slice(1).trim();
  }

  textAux = transformUppercaseFirstLetter(textAux);

  if (language === 'pt-br') {
    if (textAux.includes('Seas Diff')) {
      textAux = textAux.replaceAll('Seas Diff', 'Dif Saz');
    }

    if (textAux.includes('Diff')) {
      textAux = textAux.replaceAll('Diff', 'Dif');
    }

    if (textAux === 'Mixed') {
      return 'Mista';
    }
  }

  return textAux;
}
