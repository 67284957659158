/* eslint-disable prettier/prettier */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { Card } from 'src/components/Card';
import api from 'src/feature-store/service/api';
import { useQuery } from 'react-query';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import {
  resetGroupPremium,
  setGroupPremium,
} from 'src/feature-store/redux/reducers/GroupPremiumIndicator';
import { useTranslation } from 'react-i18next';
import { CrownSimple } from 'phosphor-react';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { format } from 'date-fns';
import { checkIconURL } from 'src/utils/handleProjectIcon';

import {
  Container,
  AccessGroupsContainer,
  AccessGroupContainer,
  AccessGroupContent,
  AccessGroupIcon,
  InfoContainer,
  TitleHeader,
  HeaderContainer,
  ListAllIndicatorsButton,
} from './styles';
import { ContainerWithoutCategories } from '../Categories/styles';

export type DataIndicatorsPremium = {
  id: string;
  name: {
    'pt-br': string;
    'en-us': string;
  };
  description: {
    'pt-br': string;
    'en-us': string;
  };
  total_indicators: number;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
  icon_url?: string;
};

export type IndicatorsPremium = {
  limit: number;
  skip: number;
  total: number;
  data: DataIndicatorsPremium[];
};

export const Premium: React.FC = () => {
  const { language } = useSelector((state: RootState) => state.auth.user);
  const translateFormat = useFormatDateLanguage();
  const { groupPremiumId } = useSelector(
    (state: RootState) => state.groupPremium,
  );
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const {
    data: groupsPremium,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['groupsPremium'],
    async () => {
      const { data } = await api.get<IndicatorsPremium>(
        `/access-groups?limit=2000`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
    },
  );

  const handleShowGroupPremium = (
    id: string,
    name: {
      'en-us': string;
      'pt-br': string;
    },
    description: {
      'en-us': string;
      'pt-br': string;
    },
    iconUrl: string,
  ) => {
    dispatch(
      setGroupPremium({
        groupPremiumId: id,
        groupPremiumName: name,
        groupPremiumDescription: description,
        iconUrl,
        page: 1,
      }),
    );
  };

  const handleShowAllPremium = () => {
    dispatch(resetGroupPremium());
  };

  return (
    <Container>
      <Card
        textCard={translate('categoriesSidebarFilters')}
        textDescription={translate('premiumDescription')}
        style={{ height: '112px' }}
      />

      <HeaderContainer>
        <TitleHeader>
          <CrownSimple size="1rem" />
          <p>{translate('groupPremiumTitle')}</p>
        </TitleHeader>
        {groupPremiumId !== '' && (
          <ListAllIndicatorsButton onClick={() => handleShowAllPremium()}>
            {translate('premiumShowAll')}
          </ListAllIndicatorsButton>
        )}
      </HeaderContainer>

      {isError ? (
        <ContainerWithoutCategories data-testid="error-to-load-groups-premium">
          <p>{translate('premiumErrorLoadingGroups')}</p>
        </ContainerWithoutCategories>
      ) : isLoading || isFetching ? (
        <AccessGroupsContainer>
          {Array.from({ length: 10 }).map((_, index) => (
            <AccessGroupContainer
              key={`loading-access-group-${index + 1}`}
              isActive={false}
            >
              <AccessGroupIcon>
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    width: '40px',
                    height: '40px',
                  }}
                />
              </AccessGroupIcon>

              <InfoContainer>
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    width: `${Math.random() * (180 - 100) + 60}px`,
                    height: '24px',
                  }}
                />
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    width: '130px',
                    height: '24px',
                  }}
                />
              </InfoContainer>
            </AccessGroupContainer>
          ))}
        </AccessGroupsContainer>
      ) : (
        <AccessGroupsContainer>
          {groupsPremium?.data.map((group) => (
            <AccessGroupContainer
              key={group.id}
              isActive={groupPremiumId === group.id}
              data-testid={`access-group-${group.id}`}
            >
              <AccessGroupContent
                onClick={() =>
                  handleShowGroupPremium(
                    group.id,
                    group.name,
                    group.description,
                    group.icon_url || '',
                  )
                }
              >
                <AccessGroupIcon>
                  <img
                    data-testid={`access-group-icon-${group.id
                      .replaceAll(' ', '-')
                      .toLowerCase()}`}
                    src={
                      checkIconURL(group.icon_url)
                        ? group.icon_url
                        : 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png'
                    }
                    alt="icon"
                  />
                </AccessGroupIcon>

                <InfoContainer
                  data-testid={`container-info-access-group-${group.id
                    .replaceAll(' ', '-')
                    .toLowerCase()}`}
                >
                  <h5
                    data-testid={`info-access-group-${group.id
                      .replaceAll(' ', '-')
                      .toLowerCase()}`}
                  >
                    {group.name[language] ?? group.name['en-us']}
                  </h5>
                  <p
                    data-testid={`update-access-group-${group.id
                      .replaceAll(' ', '-')
                      .toLowerCase()}`}
                  >
                    {format(
                      new Date(group.updated_at ?? ''),
                      `${translateFormat}, HH:mm`,
                    )}
                  </p>
                </InfoContainer>
              </AccessGroupContent>
            </AccessGroupContainer>
          ))}
        </AccessGroupsContainer>
      )}
    </Container>
  );
};
