import React, { memo, useCallback, useEffect, useState } from 'react';

import { Pencil, Warning, X } from 'phosphor-react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Button } from 'src/components/Button';
import { Td, Tr } from 'src/components/Table';
import { CheckBox } from 'src/components/CheckBox';
import light from 'src/styles/themes/light';
import api from 'src/models/service/api';
import { Select } from 'src/components/Select';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { TextArea } from 'src/components/TextArea';
import { queryClient } from 'src/service/queryClient';
import ms from 'ms';
import { Y } from 'src/models/redux/reducers/Project';
import { useLocation, useParams } from 'react-router-dom';
import apiWorkspace from 'src/models/service/apiWorkspace';

import {
  ModelUpdateContainer,
  RenameVariableAndModelIDContainer,
  WarningIcon,
} from './styles';
import {
  ModelId,
  ModelUpdateProps,
  ModelUpdateSelect,
  ResponseModels,
  VariableTableRowProps,
} from './types';
import { ModelUpdateOptionLabel } from '../../ModelUpdateOptionLabel';
import { ParamsProps } from '../../../types';

export const VariablesTableRow: React.FC<VariableTableRowProps> = memo(
  ({
    project,
    y,
    error,
    selected,
    control,
    setValue,
    getValues,
    validateNewLabel,
    checkAllVariablesAreSelected,
    checkAllVariablesName,
    showOriginalVariableName,
    defaultModelIdUpdate,
  }) => {
    const translateFormat = useFormatDateLanguage();
    const { t: translate } = useTranslation();

    const [modelIdValue, setModelIdValue] = useState('');
    const [modelIdError, setModelIdError] = useState<string | null>(null);
    const [isTypingModelId, setIsTypingModelId] = useState(false);
    const [isLoadingY, setIsLoadingY] = useState(false);
    const [modelUpdatesNotHaveY, setModelUpdatesNotHaveY] = useState<string[]>(
      [],
    );
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const location = useLocation();
    const { id: workspaceId } = useParams<ParamsProps>();

    const isEdition = location.pathname.includes('/control-panel/edition');
    const requestApi = isEdition ? apiWorkspace : api;

    const {
      data: modelUpdateData,
      isLoading: modelUpdateIsLoading,
      isFetching: modelUpdateIsFetching,
    } = useQuery(
      ['project updates', project.id],
      async () => {
        const originalProject = {
          value: project.id,
          label: `Original\n${project.created}`,
          isDisabled: false,
        };

        try {
          const { data } = await requestApi.get<ModelUpdateProps>(
            `${isEdition ? `/workspaces/${workspaceId}/users` : ''}/projects/${
              project.parent_id ?? project.id
            }/updates`,
          );

          return [
            ...data.updates.map((update) => ({
              value: update.id,
              label: `${update.label}\n${update.created}`,
              isDisabled: update.status !== 'success',
            })),
            originalProject,
          ];
        } catch {
          return [originalProject];
        }
      },
      {
        staleTime: 5000 * 60,
        enabled: !isEdition || (isEdition && !!workspaceId),
      },
    );

    const {
      data: modelsIdData,
      isLoading: modelsIdLoading,
      isFetching: modelsIdFetching,
      isError: modelsIdError,
    } = useQuery<ModelId[]>(
      ['project models', project.id, y.id],
      async () => {
        const editionRequest = `/workspaces/${workspaceId}/users/projects/${project.id}/ys/${y.id}/models`;
        const creationRequest = `/projects/${project.id}/${y.id}/models`;

        const { data } = await requestApi.get<ResponseModels>(
          isEdition ? editionRequest : creationRequest,
        );

        if (data.models.every((model) => !model.model_code)) {
          throw new Error();
        }

        return data.models.map((model) => ({
          code: model.model_code,
          isUserSelection: model.user_selection,
        }));
      },
      {
        staleTime: 5000 * 60,
        enabled: !isEdition || (isEdition && !!workspaceId),
      },
    );

    useEffect(() => {
      if (modelsIdData) {
        let modelIdSelected = modelsIdData.find((modelCode) =>
          defaultModelIdUpdate?.model_id
            ? modelCode.code === defaultModelIdUpdate.model_id
            : modelCode.isUserSelection,
        );

        if (!modelIdSelected) {
          modelIdSelected = modelsIdData.find((modelCode) => modelCode.code)!;
        }

        setModelIdValue(modelIdSelected.code);
        setValue('modelIds', {
          ...getValues('modelIds'),
          [`${project.id}-${y.id}`]: modelIdSelected.code,
        });
      }
    }, [
      getValues,
      modelsIdData,
      project.id,
      setValue,
      y.id,
      defaultModelIdUpdate?.model_id,
    ]);

    const checkEmptyVariableWhenUnselect = useCallback(() => {
      const renamedVariables = getValues('renamedVariables');

      if (!renamedVariables[`${project.id}-${y.id}`]) {
        setValue(`renamedVariables.${project.id}-${y.id}`, y.name);
      }
    }, [getValues, project.id, setValue, y.id, y.name]);

    useEffect(() => {
      if (modelsIdError) {
        setModelIdValue('--');
        setValue('modelIds', {
          ...getValues('modelIds'),
          [`${project.id}-${y.id}`]: 'error',
        });

        setValue(`selectedVariables.${project.id}-${y.id}`, false);

        checkEmptyVariableWhenUnselect();
        const selectedVariables = getValues('selectedVariables');

        checkAllVariablesAreSelected(selectedVariables);
        checkAllVariablesName(selectedVariables, getValues('renamedVariables'));
      }
    }, [
      checkAllVariablesAreSelected,
      checkAllVariablesName,
      checkEmptyVariableWhenUnselect,
      getValues,
      modelsIdError,
      project.id,
      setValue,
      y.id,
    ]);

    const isRepeatedVariableLabelOrNameError = (err: string) =>
      err === 'createWorkspaceRepeatedVariable';

    const handleEnableFocusInput = (id: string) => {
      const input = document.getElementById(id);
      if (input) {
        input.focus();
      }
    };

    const handleCheckVariable = (checked: boolean) => {
      if (!checked) {
        checkEmptyVariableWhenUnselect();

        if (!modelIdValue && modelsIdData) {
          let modelIdSelected = modelsIdData.find(
            (modelCode) => modelCode.isUserSelection,
          );

          if (!modelIdSelected) {
            modelIdSelected = modelsIdData[0];
          }

          setModelIdValue(modelIdSelected.code);
          setValue('modelIds', {
            ...getValues('modelIds'),
            [`${project.id}-${y.id}`]: modelIdSelected.code,
          });
        }
      }

      const selectedVariables = getValues('selectedVariables');

      checkAllVariablesAreSelected(selectedVariables);
      checkAllVariablesName(selectedVariables, getValues('renamedVariables'));
      validateNewModelId();
    };

    const getYModelUpdated = useCallback(
      async (value: string) => {
        const key = ['projects', value];

        let ys: Y[] = [];

        const contentQuery = queryClient.getQueryData<Y[]>(key);

        if (contentQuery) {
          ys = contentQuery;
        } else {
          try {
            ys = await queryClient.fetchQuery<Y[]>(
              key,
              async () => {
                const response = await requestApi.get(
                  `${
                    isEdition ? `/workspaces/${workspaceId}/users` : ''
                  }/projects/${value}`,
                );

                return response.data.ys;
              },
              {
                staleTime: ms('1 day'),
              },
            );
          } catch {
            ys = [];
          }
        }

        const yAux =
          ys.find((yAux1) => {
            if (project.id === value) {
              /// esta pegando do projeto original
              return yAux1.id === y.id;
            }

            if (yAux1.id === y.id) return true; /// esta pegando de um model update (q id do model update muda removendo o nome forecast) mas caso nao mude compare com id senao com name

            return yAux1.name === y.name && y.id.includes(yAux1.id);
          }) || null;

        if (!yAux) {
          setModelUpdatesNotHaveY((state) => [...state, value]);
        }

        return yAux;
      },
      [project.id, y, requestApi, isEdition, workspaceId],
    );

    async function handleSelectModelUpdated(
      modelUpdateSelect: ModelUpdateSelect,
    ) {
      setIsLoadingY(true);

      const yAux = await getYModelUpdated(modelUpdateSelect.value);

      if (yAux) {
        setValue('modelUpdate', {
          ...getValues('modelUpdate'),
          [`${project.id}-${y.id}`]: {
            ...modelUpdateSelect,
            y: yAux,
          },
        });
      }
      setIsLoadingY(false);
    }

    useEffect(() => {
      async function loadY() {
        if (modelUpdateData) {
          setIsLoadingY(true);
          let i = 0;
          while (i < modelUpdateData.length) {
            if (
              !modelUpdateData[i].isDisabled &&
              modelUpdateData[i]?.value &&
              (!isEdition ||
                !selected ||
                !defaultModelIdUpdate?.project_id ||
                defaultModelIdUpdate?.project_id === modelUpdateData[i].value)
            ) {
              const yAux = await getYModelUpdated(modelUpdateData[i].value);

              if (yAux) {
                setValue('modelUpdate', {
                  ...getValues('modelUpdate'),
                  [`${project.id}-${y.id}`]: {
                    ...modelUpdateData[i],
                    y: yAux,
                  },
                });
                break;
              }
            }
            i++;
          }
          setIsLoadingY(false);
        }
      }
      loadY();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      getValues,
      getYModelUpdated,
      modelUpdateData,
      project.id,
      setValue,
      y.id,
      defaultModelIdUpdate?.project_id,
      isEdition,
    ]);

    useEffect(() => {
      const scrollToRight = () => {
        const tableContainer = document.querySelector(
          '#workspace-table-container',
        );

        if (tableContainer) {
          tableContainer.scrollLeft = tableContainer.scrollWidth;
        }
      };

      if (selected && modelIdError) {
        scrollToRight();
      }
    }, [modelIdError, selected]);

    const validateNewModelId = useCallback(() => {
      if (!modelIdValue) {
        setModelIdError('createWorkspaceModelIdRequiredField');

        setValue('modelIds', {
          ...getValues('modelIds'),
          [`${project.id}-${y.id}`]: null,
        });

        return;
      }

      if (modelsIdData) {
        if (modelsIdData.some((modelId) => modelId.code === modelIdValue)) {
          setModelIdError(null);
          setValue('modelIds', {
            ...getValues('modelIds'),
            [`${project.id}-${y.id}`]: modelIdValue,
          });
        } else {
          setModelIdError('createWorkspaceModelIdInvalid');

          setValue('modelIds', {
            ...getValues('modelIds'),
            [`${project.id}-${y.id}`]: null,
          });
        }
      }
    }, [getValues, modelIdValue, modelsIdData, project.id, setValue, y.id]);

    useEffect(() => {
      let timer: NodeJS.Timer | undefined;

      if (isTypingModelId) {
        clearTimeout(timer);

        timer = setTimeout(() => {
          setIsTypingModelId(false);
          validateNewModelId();
        }, 1000);
      }

      return () => {
        clearTimeout(timer);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTypingModelId, validateNewModelId]);

    useEffect(() => {
      const onResize = () => {
        setScreenWidth(window.innerWidth);
      };

      window.addEventListener('resize', onResize);

      return () => window.removeEventListener('resize', onResize);
    }, []);

    const resizeVariableInput = useCallback(() => {
      const element = document.getElementById(
        `rename-variable-${project.id}-${y.id}`,
      );

      if (element) {
        element.style.height = '1.5rem'; // Define a altura como automática para recalcular
        element.style.height = `${element?.scrollHeight / 16}rem`; // Ajusta a altura conforme o conteúdo inserido
      }
    }, [project.id, y.id]);

    useEffect(() => {
      resizeVariableInput();
    }, [resizeVariableInput, selected, y.label]);

    useEffect(() => {
      validateNewLabel(
        project.id,
        y.id,
        getValues(`renamedVariables.${project.id}-${y.id}`),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.id, y.id]);

    const adjustNames = screenWidth <= 1400;

    const yNameAdjusted =
      y.name.length > 25 && adjustNames
        ? `${y.name.substring(0, 22)}...`
        : y.name;

    const projectNameAdjusted =
      project.name.length > 25 && adjustNames
        ? `${project.name.substring(0, 22)}...`
        : project.name;

    const modelUpdateDataAdjusted =
      modelUpdateData?.map((modelUpdate) => ({
        ...modelUpdate,
        isDisabled:
          modelUpdate.isDisabled ||
          modelUpdatesNotHaveY.includes(modelUpdate.value),
      })) || [];

    const isDisabled = y.status !== 'success' || !!modelsIdError;

    return (
      <Tr
        key={`${project.id}-${y.id}`}
        disabled={isDisabled}
        data-testid={`row-project-${project.id}-variable-${y.id}`}
      >
        <Td>
          <Controller
            name={`selectedVariables.${project.id}-${y.id}`}
            control={control}
            defaultValue={y.status === 'success'}
            render={({ field: { onChange, value } }) => (
              <CheckBox
                onChange={({ target: { checked } }) => {
                  onChange(checked);
                  handleCheckVariable(checked);
                }}
                checked={value}
                disabled={isDisabled}
                style={{ paddingLeft: '1.125rem' }}
                data-testid={`checkbox-project-${project.id}-variable-${y.id}`}
              />
            )}
          />
        </Td>

        <Td>
          <RenameVariableAndModelIDContainer error={isDisabled}>
            <Controller
              name={`renamedVariables.${project.id}-${y.id}`}
              control={control}
              defaultValue={y.label}
              render={({ field: { onChange, value } }) => (
                <TextArea
                  id={`rename-variable-${project.id}-${y.id}`}
                  onChange={({ target: { value: vl } }) => {
                    onChange(vl);
                    validateNewLabel(project.id, y.id, vl);

                    resizeVariableInput();
                  }}
                  value={value}
                  disabled={isDisabled || !selected}
                  data-testid={`variable-name-project-${project.id}-variable-${y.id}`}
                />
              )}
            />

            <Button
              buttonType="naked"
              icon={<Pencil />}
              onClick={() =>
                handleEnableFocusInput(`rename-variable-${project.id}-${y.id}`)
              }
              disabled={y.status !== 'success' || !selected}
              data-testid={`rename-variable-button-project-${project.id}-variable-${y.id}`}
            />

            {!!error &&
              selected &&
              (!isRepeatedVariableLabelOrNameError(error) ? (
                <WarningIcon
                  data-tooltip-id="config-workspace-tooltip"
                  data-tooltip-html={translate(error ?? '')}
                  data-testid={`rename-variable-error-project-${project.id}-variable-${y.id}`}
                  color={light.colors.red2}
                >
                  <X weight="bold" size="0.875rem" />
                </WarningIcon>
              ) : (
                <WarningIcon
                  data-tooltip-id="config-workspace-tooltip"
                  data-tooltip-html={translate(error ?? '')}
                  data-testid={`rename-variable-warning-project-${project.id}-variable-${y.id}`}
                  color={light.colors.secondary}
                >
                  <Warning weight="fill" size="1rem" />
                </WarningIcon>
              ))}
          </RenameVariableAndModelIDContainer>
        </Td>

        <Td
          data-testid={`original-variable-name-project-${project.id}-variable-${y.id}`}
          data-tooltip-id="workspace-table"
          data-tooltip-html={yNameAdjusted !== y.name ? y.name : undefined}
          style={{ display: showOriginalVariableName ? 'table-cell' : 'none' }}
        >
          {yNameAdjusted}
        </Td>

        <Td
          data-testid={`project-name-project-${project.id}-variable-${y.id}`}
          data-tooltip-id="workspace-table"
          data-tooltip-html={
            projectNameAdjusted !== project.name ? project.name : undefined
          }
        >
          {projectNameAdjusted}
        </Td>

        <Td>
          <ModelUpdateContainer>
            {modelUpdateIsFetching ||
            modelUpdateIsLoading ||
            isLoadingY ||
            !modelUpdateData ||
            (!isDisabled && !getValues(`modelUpdate.${project.id}-${y.id}`)) ? (
              <ContainerSkeleton
                withLoading={false}
                style={{ height: '2.75rem' }}
                data-testid={`model-update-loading-project-${project.id}-variable-${y.id}`}
              />
            ) : (
              <Controller
                name={`modelUpdate.${project.id}-${y.id}`}
                control={control}
                defaultValue={getValues(`modelUpdate.${project.id}-${y.id}`)}
                render={({ field: { value, name } }) => (
                  <Select
                    options={modelUpdateDataAdjusted}
                    value={value || getValues(`${name}`)}
                    onChange={(e) => {
                      if (e) {
                        setValue('modelUpdate', {
                          ...getValues('modelUpdate'),
                          [`${project.id}-${y.id}`]: {
                            ...value,
                            y: null,
                          },
                        });
                        //@ts-expect-error:ignora
                        handleSelectModelUpdated(e);
                      }
                    }}
                    menuPlacement="auto"
                    style={{ width: '9rem' }}
                    dataTestid={`model-update-project-${project.id}-variable-${y.id}`}
                    formatOptionLabel={({ label }) =>
                      ModelUpdateOptionLabel({
                        label,
                        dateFormat: translateFormat,
                      })
                    }
                    disabled={isDisabled || !selected}
                    isDisabled={isDisabled || !selected}
                    className={
                      !isDisabled
                        ? !selected && y.status === 'success'
                          ? 'unselected'
                          : 'selected'
                        : undefined
                    }
                  />
                )}
              />
            )}
          </ModelUpdateContainer>
        </Td>
        <Td>
          {modelsIdLoading || modelsIdFetching ? (
            <ContainerSkeleton
              withLoading={false}
              style={{ height: '1rem' }}
              data-testid={`model-id-loading-project-${project.id}-variable-${y.id}`}
            />
          ) : (
            <RenameVariableAndModelIDContainer error={isDisabled}>
              <TextArea
                id={`model-id-${project.id}-${y.id}`}
                onChange={({ target: { value: vl } }) => {
                  setModelIdValue(vl);
                  setIsTypingModelId(true);

                  const element = document.getElementById(
                    `model-id-${project.id}-${y.id}`,
                  );

                  if (element) {
                    element.style.height = '1.5rem';
                    element.style.height = `${element?.scrollHeight / 16}rem`;
                  }
                }}
                value={modelIdValue}
                disabled={isDisabled || !selected}
                data-testid={`model-id-project-${project.id}-variable-${y.id}`}
              />

              <Button
                buttonType="naked"
                icon={<Pencil />}
                onClick={() =>
                  handleEnableFocusInput(`model-id-${project.id}-${y.id}`)
                }
                disabled={y.status !== 'success' || !selected}
                data-testid={`model-id-button-project-${project.id}-variable-${y.id}`}
              />

              {selected && modelIdError && (
                <WarningIcon
                  data-tooltip-id="config-workspace-tooltip"
                  data-tooltip-html={translate(modelIdError ?? '')}
                  data-testid={`model-id-error-project-${project.id}-variable-${y.id}`}
                  color={light.colors.red2}
                >
                  <X weight="bold" size="0.875rem" />
                </WarningIcon>
              )}
            </RenameVariableAndModelIDContainer>
          )}
        </Td>
      </Tr>
    );
  },
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.y) === JSON.stringify(nextProps.y) &&
    JSON.stringify(prevProps.project) === JSON.stringify(nextProps.project) &&
    prevProps.selected === nextProps.selected &&
    prevProps.error === nextProps.error &&
    prevProps.showOriginalVariableName === nextProps.showOriginalVariableName,
);
