import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PayloadGroupNavigation {
  myFavorites: {
    page: number;
    isBackFromGroup: boolean;
  };
  preDefinedGroups: {
    page: number;
    isBackFromGroup: boolean;
  };
}

const initialState: PayloadGroupNavigation = {
  myFavorites: {
    page: 1,
    isBackFromGroup: false,
  },
  preDefinedGroups: {
    page: 1,
    isBackFromGroup: false,
  },
};

interface SetGroupNavigationInfoProps {
  group: 'myFavorites' | 'preDefinedGroups';
  page: number;
  isBackFromGroup: boolean;
}

export const groupNavigationState = createSlice({
  name: 'group navigation',
  initialState,
  reducers: {
    setGroupNavigationInfo: (
      state,
      action: PayloadAction<SetGroupNavigationInfoProps>,
    ) => {
      state[action.payload.group].page = action.payload.page;
      state[action.payload.group].isBackFromGroup =
        action.payload.isBackFromGroup;
    },
    clearBackFromGroup: (state) => {
      state.myFavorites.page = 1;
      state.myFavorites.isBackFromGroup = false;

      state.preDefinedGroups.page = 1;
      state.preDefinedGroups.isBackFromGroup = false;
    },
  },
});

export const { setGroupNavigationInfo, clearBackFromGroup } =
  groupNavigationState.actions;

export default groupNavigationState.reducer;
