import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Cards, CaretDown, ChartBarHorizontal } from 'phosphor-react';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import parseHTML from 'html-react-parser';
import { useQuery } from 'react-query';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { AxiosError } from 'axios';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';
import { translateSomeMessagesFromBackend } from 'src/i18n';

import {
  ButtonSeeMoreImpacts,
  ChooseViewType,
  ChooseViewTypeButton,
  ContentSelectDate,
  UnderstandingResultContainer,
  UnderstandingResultContent,
  UnderstandingResultContentSkeletons,
  UnderstandingResultError,
  UnderstandingResultExpand,
} from './styles';
import { AISelectionResultsContext } from '..';
import { ResultsWaterfall } from './ResultsWaterfall';
import { ResultsText } from './ResultsText';
import { ResultsCards } from './ResultsCards';

interface Error {
  detail?: {
    description?: string;
  };
}

interface Option {
  label: string;
  value: string;
}

interface ResponseResults {
  title: {
    'en-us': string;
    'pt-br': string;
  };
  description: {
    'en-us': string;
    'pt-br': string;
  };
  y_and_max_var: {
    'en-us': {
      [key: string]: number;
    };
    'pt-br': {
      [key: string]: number;
    };
  };
  highlight_vars: {
    'en-us': {
      [key: string]: number;
    };
    'pt-br': {
      [key: string]: number;
    };
  };
  other_vars: {
    'en-us': {
      [key: string]: number;
    };
    'pt-br': {
      [key: string]: number;
    };
  };
}

type ViewType = 'cards' | 'waterfall';

export const ResultsUnderstanding: React.FC = () => {
  const [seeMoreImpacts, setSeeMoreImpacts] = useState(false);
  const [viewType, setViewType] = useState<ViewType | null>('cards');

  const {
    auth: { user },
    workspaceProjectionsOptions: {
      results: { frequency },
    },
    workspace,
  } = useSelector((state: RootState) => state);

  const {
    xSelected,
    selectX,
    isLoadingChart,
    dataLevel,
    isYFrequencyValid,
    canSelectForecast,
  } = useContext(AISelectionResultsContext);

  const { t: translate } = useTranslation();

  const optionsSelect = useMemo(() => {
    const optionsAux: Option[] = [];

    if (dataLevel?.historical.date.length) {
      dataLevel?.historical.date.forEach((date) => {
        optionsAux.push({
          label: String(date),
          value: String(date),
        });
      });
    }

    if (canSelectForecast && dataLevel?.forecast.date.length) {
      dataLevel.forecast.date.forEach((date) => {
        optionsAux.push({
          label: String(date),
          value: String(date),
        });
      });
    }

    return optionsAux.reverse();
  }, [canSelectForecast, dataLevel]);

  const {
    data: dataResults,
    isLoading: isLoadingResult,
    error,
  } = useQuery<ResponseResults, AxiosError<Error>>(
    [
      'workspace',
      'results',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      workspace.ySelected?.model_id,
      frequency,
      xSelected?.date,
    ],
    async () => {
      const frequencyAux =
        frequency === 'quarterly'
          ? 'qoq'
          : frequency === 'yearly'
          ? 'yoy'
          : null;

      const response = await apiWorkspace.get(
        `workspaces/${workspace.id}/releases/${
          workspace.releaseSelected?.id
        }/ys/${workspace.ySelected?.y_label}/models/${
          workspace.ySelected?.model_id
        }/business/understanding/results?date=${xSelected?.date}${
          frequencyAux ? `&frequency=${frequencyAux}` : ''
        }`,
      );

      return response.data;
    },
    {
      enabled:
        !!workspace.id &&
        !!workspace.ySelected &&
        !!workspace.releaseSelected &&
        !!xSelected?.date,
      staleTime: 1000 * 60 * 20,
    },
  );

  const noInsightAvailable =
    dataResults?.title['en-us'] === 'No insight available for this date' ||
    dataResults?.description['en-us'] === 'No additional insights available';

  const isDisabledCards = !Object.keys(
    dataResults?.highlight_vars[user.language] ?? {},
  ).length;

  const isDisabledWaterfall =
    Object.keys(dataResults?.highlight_vars?.[user.language] ?? {}).length +
      Object.keys(dataResults?.y_and_max_var?.[user.language] ?? {}).length +
      Object.keys(dataResults?.other_vars?.[user.language] ?? {}).length <=
    1;

  useEffect(() => {
    if (noInsightAvailable) {
      setSeeMoreImpacts(false);
    }
  }, [noInsightAvailable]);

  useEffect(() => {
    if (dataResults && isDisabledCards && isDisabledWaterfall) {
      setViewType(null);
      return;
    }
    if (dataResults && isDisabledCards) {
      setViewType('waterfall');
    }
  }, [dataResults, isDisabledCards, isDisabledWaterfall]);

  function handleSeeMoreImpacts() {
    setSeeMoreImpacts((state) => !state);
  }

  function handleSelectDate(date: string) {
    selectX(String(date));
  }

  function handleViewType(value: ViewType) {
    setViewType(value);
  }

  const loadingResult =
    (!dataResults && !error) || isLoadingResult || isLoadingChart;

  const dateSelectedHistoricalIndex = dataLevel?.historical.date.findIndex(
    (date) => xSelected?.x === date,
  );

  const dateSelectedForecastIndex = dataLevel?.forecast.date.findIndex(
    (date) => xSelected?.x === date,
  );

  const valueYSelected =
    dateSelectedHistoricalIndex !== undefined &&
    dateSelectedHistoricalIndex !== -1
      ? dataLevel?.historical.value[dateSelectedHistoricalIndex]
      : dateSelectedForecastIndex !== undefined &&
        dateSelectedForecastIndex !== -1
      ? dataLevel?.forecast.value[dateSelectedForecastIndex]
      : null;

  const frequencyIsOriginalLow = useMemo(
    () =>
      frequency === 'original'
        ? workspace.frequency === 'daily' ||
          workspace.frequency === 'weekly' ||
          workspace.frequency === 'fortnightly'
        : false,

    [frequency, workspace.frequency],
  );

  if (
    (error || !isYFrequencyValid || frequencyIsOriginalLow) &&
    workspace.frequency
  ) {
    return (
      <UnderstandingResultContainer data-cy="ia-selection-results-container">
        <h2 data-cy="ia-selection-results-title">
          {translate('workspaceProjectionsResultsUnderstandingTitle')}
        </h2>
        <UnderstandingResultError data-cy="ia-selection-results-content">
          <p data-cy="ia-selection-results-primary-text">
            {frequencyIsOriginalLow
              ? translate(
                  'AISelectionResultsUnderstandingWhenFrequencyIsOriginalLow',
                )
              : !isYFrequencyValid
              ? translate(
                  'AISelectionResultsUnderstandingWhenYFrequencyIsInvalid',
                )
              : translateSomeMessagesFromBackend(
                  error?.response?.data?.detail?.description ?? '',
                  user.language,
                )}
          </p>
        </UnderstandingResultError>
      </UnderstandingResultContainer>
    );
  }

  return (
    <UnderstandingResultContainer data-cy="ia-selection-results-container">
      <h2 data-cy="ia-selection-results-title">
        {translate('workspaceProjectionsResultsUnderstandingTitle')}
      </h2>
      <ContentSelectDate>
        <ReactSelect
          aria-label="ai-selection-results-select"
          name="ai-selection-results-select"
          inputId="ai-selection-results-select"
          id="ai-selection-results-select"
          instanceId="ai-selection-results-select"
          placeholder={translate('select')}
          noOptionsMessage={() => translate('selectNoOptions')}
          classNamePrefix="select"
          isDisabled={isLoadingChart || !isYFrequencyValid}
          options={optionsSelect}
          value={
            xSelected
              ? {
                  label: String(xSelected.x),
                  value: String(xSelected.x),
                }
              : undefined
          }
          onChange={(option) =>
            option?.value && handleSelectDate(option?.value)
          }
        />
      </ContentSelectDate>

      <UnderstandingResultContent data-cy="ia-selection-results-content">
        {loadingResult ? (
          <UnderstandingResultContentSkeletons data-testid="ia-selection-results-loading-title">
            <ContainerSkeleton withLoading={false} />
            <ContainerSkeleton withLoading={false} />
          </UnderstandingResultContentSkeletons>
        ) : (
          <p data-cy="ia-selection-results-primary-text">
            {parseHTML(dataResults?.title[user.language] ?? '')}
          </p>
        )}

        <ResultsCards
          cards={dataResults?.y_and_max_var[user.language] ?? {}}
          type="primary"
          valueYSelected={valueYSelected}
          loadingResult={loadingResult}
        />

        <UnderstandingResultExpand
          seeMoreImpacts={seeMoreImpacts}
          isCardSelected={viewType === 'cards'}
          data-cy="ia-selection-expand"
        >
          <ResultsText
            loadingResult={loadingResult}
            description={dataResults?.description[user.language] ?? ''}
          />

          <div>
            <ChooseViewType>
              <ChooseViewTypeButton
                type="button"
                onClick={() => handleViewType('cards')}
                selected={viewType === 'cards'}
                data-testid="results-understanding-button-card"
                disabled={isDisabledCards}
              >
                <Cards size="1.5rem" />
                {translate('workspaceProjectionsResultsUnderstandingCards')}
              </ChooseViewTypeButton>
              <ChooseViewTypeButton
                type="button"
                onClick={() => handleViewType('waterfall')}
                selected={viewType === 'waterfall'}
                data-testid="results-understanding-button-waterfall"
                disabled={isDisabledWaterfall}
              >
                <ChartBarHorizontal size="1.5rem" />
                {translate('workspaceProjectionsResultsUnderstandingWaterfall')}
              </ChooseViewTypeButton>
            </ChooseViewType>

            {viewType === 'cards' ? (
              <ResultsCards
                cards={dataResults?.highlight_vars[user.language] ?? {}}
                type="secondary"
                loadingResult={loadingResult}
              />
            ) : viewType === 'waterfall' ? (
              <ResultsWaterfall
                topVars={dataResults?.y_and_max_var[user.language] ?? {}}
                highlighVars={dataResults?.highlight_vars[user.language] ?? {}}
                otherVars={dataResults?.other_vars[user.language] ?? {}}
                loadingResult={loadingResult}
              />
            ) : null}
          </div>
        </UnderstandingResultExpand>

        <ButtonSeeMoreImpacts
          seeMoreImpacts={seeMoreImpacts}
          type="button"
          onClick={handleSeeMoreImpacts}
          data-testid="ai-selection-results-see-more-impacts"
          disabled={noInsightAvailable}
        >
          <span>
            {seeMoreImpacts
              ? translate(
                  'workspaceProjectionsResultsUnderstandingSeeLessImpacts',
                )
              : translate(
                  'workspaceProjectionsResultsUnderstandingSeeMoreImpacts',
                )}
          </span>
          <CaretDown size="1rem" weight="bold" />
        </ButtonSeeMoreImpacts>
      </UnderstandingResultContent>
    </UnderstandingResultContainer>
  );
};
