import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { clear as clearModelling } from 'src/models/redux/reducers/Modelling';
import { RootState } from 'src/redux/store';
import { CaretDown } from 'phosphor-react';

import { DependentVariableOnSidebar } from './DependentVariableOnSidebar';
import { Menu } from './menus';
import {
  menuProject,
  menuDataView,
  menuUserSelection,
  menuResults,
} from './menus/models';
import { ProjectDetailOnSideBar } from './ProjectDetailOnSidebar';
import {
  Container,
  ContentOptionSeeAllUserSelectionOptions,
  Hr,
  Item,
  MenuSectionTitleText,
  ULExpand,
} from './styles';
import { ModelUpdateSidebar } from '../ModelUpdateSidebar';
import { FeatureStoreVariablesProvider } from '../contexts/FeatureStoreVariableContext';
import { FeatureStoreVariables } from '../FeatureStoreVariables';
import { ModelUpdateDocsModal } from '../Modals/ModelUpdateDocsModal';
import { ModelUpdateContext } from '../contexts/ModelUpdateContext';

export const ProjectSideBar: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = () => {
  const [seeAllUserSelectionOptions, setSeeAllUserSelectionOptions] =
    useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { project, modelling } = useSelector((state: RootState) => state);
  const { updateHistory, parentProject } = useContext(ModelUpdateContext);
  const [activeMenus, setActiveMenus] = useState<string[]>([
    'sidebar-menu-1-0',
    'sidebar-menu-1-1',
    'sidebar-menu-1-2',
    'sidebar-menu-1-3',
  ]);

  if ((modelling.form || modelling.step) && project.id) {
    dispatch(clearModelling());
  }

  const checkIfSubMenuIsActive = (subMenu: Menu[] | undefined): boolean => {
    if (subMenu) {
      return subMenu.some((page) => {
        if (location.pathname === page?.url?.replace(':id', project.id ?? '')) {
          return true;
        }
        if (page.submenu) {
          return checkIfSubMenuIsActive(page.submenu);
        }
        return false;
      });
    }
    return false;
  };

  const handleIsActive = (
    url: string | undefined,
    subMenu: Menu[] | undefined,
  ): boolean => {
    if (subMenu) {
      return checkIfSubMenuIsActive(subMenu);
    }

    return location.pathname === url?.replace(':id', project.id ?? '');
  };

  const handleMenuClick = (url: string | undefined) => {
    if (url) {
      navigate(url.replace(':id', project.id ?? ''));
    }
  };

  const handleArrowClick = (menuName: string) => {
    const newActiveMenus = [...activeMenus];

    if (newActiveMenus.includes(menuName)) {
      const index = newActiveMenus.findIndex(
        (activeMenu) => activeMenu === menuName,
      );

      newActiveMenus.splice(index, 1);
      setActiveMenus(newActiveMenus);
    } else {
      const newActiveMenusAux = [
        'sidebar-menu-1-0',
        'sidebar-menu-1-1',
        'sidebar-menu-1-2',
        'sidebar-menu-1-3',
      ];
      if (!newActiveMenusAux.includes(menuName)) {
        newActiveMenusAux.push(menuName);
      }
      setActiveMenus(newActiveMenusAux);
    }
  };

  type ListMenuProps = {
    dept: number;
    data: Menu;
    hasSubMenu: Menu[] | undefined;
    menuName: string;
    menuIndex: number;
  };

  function handleSeeAllUserSelectionOptions() {
    setSeeAllUserSelectionOptions((state) => !state);
  }

  const ListMenu = ({
    dept,
    data,
    hasSubMenu,
    menuName,
    menuIndex,
  }: ListMenuProps) => {
    const { Icon } = data;
    const isActive = handleIsActive(data?.url, data?.submenu);

    const disabled = data.dependents
      ? !project.model?.model_types.some((value) =>
          data.dependents?.includes(value),
        )
      : false;

    const isDisabledAISelection =
      data.label === 'labelAISelection'
        ? project.yBusiness.length === 0 ||
          project.yBusiness.find((yBus) => yBus.y === project.selectedY?.id)
            ?.status === 'error'
        : false;

    if (project.model && !project.projectError && isActive && disabled) {
      if (project.y.filter((y) => y.status === 'success').length > 1) {
        navigate(`/models/time-series/${project.id}/project-overview`);
      } else {
        navigate(`/models/time-series/${project.id}/AI-selection`);
      }
    }

    if (
      data.label === 'labelAISelection' &&
      isDisabledAISelection &&
      isActive
    ) {
      if (project.y.filter((y) => y.status === 'success').length > 1) {
        navigate(`/models/time-series/${project.id}/project-overview`);
      } else {
        navigate(`/models/time-series/${project.id}/user-selection`);
      }
    }

    return (
      <>
        <Item
          data-tooltip-id={
            isDisabledAISelection ? 'AISelection-disabled-tooltip' : ''
          }
          data-tooltip-html={
            isDisabledAISelection ? translate('sideBarAISelectionDisabled') : ''
          }
          data-testid={`li-${data.label
            ?.replaceAll(' ', '-')
            .toLocaleLowerCase()}`}
          dept={dept}
          hasSubMenu={!!hasSubMenu}
          isToggled={activeMenus.includes(menuName)}
          isActive={isActive}
          loading={
            !project.model?.model_types &&
            data.label !== 'labelOverview' &&
            data.label !== 'labelOverview' &&
            data.label !== 'labelCorrelationMatrix' &&
            data.label !== 'labelVariableImportance' &&
            !project.projectError
          }
          isDataView={location.pathname.includes('data-view')}
          disabled={isDisabledAISelection || disabled}
          onClick={() => {
            if (!isDisabledAISelection && !disabled) {
              handleMenuClick(data?.url);
              if (hasSubMenu) {
                handleArrowClick(menuName);
              }
              if (
                data.label === 'labelProjectOverview' ||
                data.label === 'labelAISelection' ||
                data.label === 'labelUserSelection'
              ) {
                setActiveMenus([
                  'sidebar-menu-1-0',
                  'sidebar-menu-1-1',
                  'sidebar-menu-1-2',
                  'sidebar-menu-1-3',
                ]);
              }
            }
          }}
        >
          <span>
            {Icon && <Icon size="1.5rem" />}
            {translate(data.label)}
          </span>
        </Item>

        {hasSubMenu && (
          <SubMenu
            dept={dept}
            data={data.submenu}
            isToggled={activeMenus.includes(menuName)}
            menuIndex={menuIndex}
          />
        )}
      </>
    );
  };

  type SubMenu = {
    dept: number;
    data: Menu[] | undefined;
    isToggled: boolean;
    menuIndex: number;
  };

  const SubMenu = ({ dept, data, isToggled, menuIndex }: SubMenu) => {
    if (!isToggled) {
      return null;
    }

    return (
      <ul>
        {data?.map((menus, index) => {
          const menuName = `sidebar-submenu-${dept + 1}-${menuIndex}-${index}`;

          return (
            <ListMenu
              key={menuName}
              dept={dept + 1}
              data={menus}
              hasSubMenu={menus.submenu}
              menuName={menuName}
              menuIndex={index}
            />
          );
        })}
      </ul>
    );
  };

  if (location.pathname.includes('data-view')) {
    return (
      <Container
        className="sidebar"
        data-testid="sidebar-container"
        data-cy="sidebar-container"
      >
        <div>
          <ul>
            {menuDataView?.map((menus: Menu, index: number) => {
              const dept = +1;
              const menuName = `sidebar-menu-${dept}-${index}`;
              return (
                <ListMenu
                  dept={dept}
                  data={menus}
                  hasSubMenu={menus.submenu}
                  menuName={menuName}
                  key={menuName}
                  menuIndex={index}
                />
              );
            })}
          </ul>
        </div>
      </Container>
    );
  }

  const hasProjectOverview =
    project.y.filter((y) => y.status === 'success').length > 1 ||
    (parentProject?.id !== project?.id &&
      parentProject?.ys.filter((y) => y.status === 'success').length > 1);

  const hasPerformanceModule =
    project.y.some((y) => y.info?.frequency === 'monthly') &&
    !!updateHistory?.updates.filter((y) => y.status === 'success').length &&
    parentProject?.id !== project?.id;

  return (
    <FeatureStoreVariablesProvider>
      <>
        <FeatureStoreVariables />
        <ModelUpdateSidebar />
        <ModelUpdateDocsModal />
        <Container
          className="sidebar"
          data-testid="sidebar-container"
          data-cy="sidebar-container"
        >
          <div>
            <ProjectDetailOnSideBar />
            {(hasProjectOverview || hasPerformanceModule) && (
              <>
                <MenuSectionTitleText>
                  {translate('sideBarProject')}
                </MenuSectionTitleText>

                <ul>
                  {menuProject?.map((menus: Menu, index: number) => {
                    if (
                      ((!updateHistory || !updateHistory.updates.length) &&
                        !menus?.url?.includes('/project-overview')) ||
                      (project.y.filter((y) => y.status === 'success').length <
                        2 &&
                        parentProject?.id === project?.id) ||
                      (parentProject?.id !== project?.id &&
                        (parentProject?.ys.filter((y) => y.status === 'success')
                          .length < 2 ||
                          !parentProject?.ys.filter(
                            (y) => y.status === 'success',
                          ).length) &&
                        menus?.url?.includes('/project-overview'))
                    ) {
                      return;
                    }

                    if (
                      !hasPerformanceModule &&
                      menus?.url?.includes('/overall-performance')
                    ) {
                      return;
                    }

                    const dept = +1;
                    const menuName = `sidebar-menu-${dept}-${index}`;
                    return (
                      <ListMenu
                        dept={dept}
                        data={menus}
                        hasSubMenu={menus.submenu}
                        menuName={menuName}
                        key={menuName}
                        menuIndex={index}
                      />
                    );
                  })}
                </ul>
                {(project.y.filter((y) => y.status === 'success').length > 1 ||
                  (parentProject?.id !== project?.id &&
                    parentProject?.ys.filter((y) => y.status === 'success')
                      .length > 1) ||
                  !!updateHistory?.updates.length) && <Hr />}
              </>
            )}

            <MenuSectionTitleText>
              {translate('sideBarVariable')}
            </MenuSectionTitleText>
            <ul>
              {menuResults?.map((menus: Menu, index: number) => {
                if (
                  (!updateHistory || updateHistory?.updates.length === 0) &&
                  menus?.url?.includes('/variable-performance')
                ) {
                  return;
                }

                if (
                  !hasPerformanceModule &&
                  menus?.url?.includes('/variable-performance')
                ) {
                  return;
                }

                const dept = +1;
                const menuName = `sidebar-menu-${dept}-${index}`;
                return (
                  <ListMenu
                    dept={dept}
                    data={menus}
                    hasSubMenu={menus.submenu}
                    menuName={menuName}
                    key={menuName}
                    menuIndex={index}
                  />
                );
              })}
            </ul>

            <ContentOptionSeeAllUserSelectionOptions
              seeAllUserSelectionOptions={seeAllUserSelectionOptions}
            >
              <button
                type="button"
                onClick={handleSeeAllUserSelectionOptions}
                data-testid="button-model-portfolio"
                data-cy="button-model-portfolio"
              >
                <MenuSectionTitleText>
                  {translate('sideBarUserSelection')}
                </MenuSectionTitleText>
                <CaretDown size="0.75rem" weight="bold" />
              </button>
            </ContentOptionSeeAllUserSelectionOptions>
            <ULExpand expand={seeAllUserSelectionOptions}>
              {menuUserSelection?.map((menus: Menu, index: number) => {
                const dept = +1;
                const menuName = `sidebar-menu-${dept}-${index}`;
                return (
                  <ListMenu
                    dept={dept}
                    data={menus}
                    hasSubMenu={menus.submenu}
                    menuName={menuName}
                    key={menuName}
                    menuIndex={index}
                  />
                );
              })}
            </ULExpand>

            <Hr />

            <DependentVariableOnSidebar />
          </div>
        </Container>
      </>
    </FeatureStoreVariablesProvider>
  );
};
