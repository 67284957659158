import styled, { css, keyframes } from 'styled-components';

export const Container = styled.aside`
  position: relative;
  width: 100%;
  min-width: 20rem;
  max-width: 20rem;
  margin-bottom: 0rem;

  height: auto;
  background: ${({ theme }) => theme.colors.white};

  border-right: ${({ theme }) => `1px solid ${theme.colors.gray2}`};

  z-index: 1;

  > div {
    position: sticky;
    height: calc(100vh - 4.625rem); // 74header
    top: 4.625rem; // 74header
    padding: 1.5rem;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 0.313rem;
      height: 0.313rem;
      padding: 0.063rem;
    }

    ::-webkit-scrollbar-track {
      transition: 0.2s;
      margin: 0.188rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
    }
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

type ItemProps = {
  dept: number;
  hasSubMenu: boolean;
  isToggled: boolean;
  isActive: boolean;
  disabled?: boolean;
  loading?: boolean;
  isDataView: boolean;
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Item = styled.li<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ dept }) =>
    dept === 1
      ? css`
          margin-left: 0;
          margin-top: 1.5rem;
          &:first-child {
            margin-top: 0px;
          }
        `
      : dept === 2
      ? css`
          margin-top: 0.5rem;
          &:first-child {
            margin-top: 1rem;
          }

          margin-left: 2.5rem;
        `
      : dept === 3 &&
        css`
          &:first-child {
            margin-top: 0.5rem;
          }
          margin-left: 2.813rem;

          padding-bottom: 0.5rem;
          &:last-child {
            padding-bottom: 0px;
          }
        `};

  > span {
    width: 100%;

    display: flex;
    align-items: center;

    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 500;

    transition: all 0.2s ease-in-out;

    > svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;
    }

    ${({ theme, dept, isActive, disabled, isDataView }) =>
      dept === 1
        ? css`
            font-size: 1rem;
            font-weight: ${() => (isActive ? 700 : 400)};
            color: ${() =>
              isActive
                ? theme.colors.primary
                : disabled
                ? theme.colors.gray4
                : theme.colors.gray5};
          `
        : dept === 2
        ? css`
            font-size: 0.875rem;
            font-weight: ${() => (isActive ? (!isDataView ? 500 : 700) : 400)};
            color: ${() =>
              !isDataView
                ? theme.colors.gray5
                : isActive
                ? theme.colors.primary
                : theme.colors.gray5};
          `
        : dept > 2 &&
          css`
            font-size: 0.875rem;
            font-weight: ${() => (isActive ? 500 : 400)};
            color: ${() =>
              isActive
                ? theme.colors.primary
                : disabled
                ? theme.colors.gray4
                : theme.colors.gray5};
          `}

    &:hover {
      ${({ isActive, disabled, theme }) =>
        !isActive &&
        !disabled &&
        css`
          color: ${() => theme.colors.gray6};
          &:after {
            box-shadow: 1px -1px 0 1px ${theme.colors.gray6} inset;
          }
        `}
    }

    ${({ hasSubMenu, theme, dept, isActive, loading }) =>
      hasSubMenu
        ? dept === 2 &&
          css`
            &:before {
              content: '';

              min-width: 1rem;
              max-width: 1rem;
              height: 1rem;

              border-radius: 50%;
              border: 2px solid ${() => theme.colors.gray3};

              margin-right: 0.5rem;

              background: ${() =>
                isActive ? theme.colors.gray3 : 'transparent'};

              ${() =>
                loading &&
                css`
                  border-top: 2px solid ${() => theme.colors.primary};
                  animation: ${spin} 0.8s linear infinite;
                `}
            }
          `
        : dept === 3 &&
          css`
            &:before {
              content: '';

              min-width: ${() => (isActive ? '0.5rem' : '0.375rem')};
              max-width: ${() => (isActive ? '0.5rem' : '0.375rem')};
              height: ${() => (isActive ? '0.5rem' : '0.375rem')};

              background: ${() =>
                isActive ? theme.colors.primary : theme.colors.gray3};

              border-radius: 50%;
              margin-right: 1.5rem;

              margin-left: ${() => (isActive ? '0' : '0.063rem')};
              z-index: 2;
            }

            &:not(:first-child) {
              span {
                &:before {
                  content: '';
                  position: absolute;
                  width: 0.125rem;
                  height: 1.5rem;
                  left: 0.156rem;
                  top: -1rem;

                  background: ${() => theme.colors.gray1};
                }
              }
            }
          `}

    ${({ hasSubMenu, theme, dept, isToggled }) =>
      hasSubMenu &&
      isToggled &&
      dept === 2 &&
      css`
        &:after {
          content: '';
          position: absolute;
          right: 0.063rem;
          padding: 0.25rem; /* Arrow size */
          box-shadow: 1px -1px 0 1px ${theme.colors.gray4} inset;
          transition: 0.2s;
          transform: rotate(135deg);
        }
      `}
  }
`;

export const Hr = styled.hr`
  width: 100%;
  height: 0.063rem;
  background-color: ${({ theme }) => theme.colors.gray1};

  margin: 2rem 0;

  border: 0;
`;

export const ButtonCollapse = styled.button`
  position: absolute;
  top: 1.5rem;
  right: -1.188rem;

  height: 2.063rem;
  width: 1.188rem;
  background: ${({ theme }) => theme.colors.white};

  display: flex;
  align-items: center;

  border-radius: 0 8px 8px 0;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
`;

export const MenuSectionTitleText = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375rem;

  color: ${({ theme }) => theme.colors.gray4};

  margin-bottom: 1rem;
`;

interface ContentOptionSeeAllUserSelectionOptionsProps {
  seeAllUserSelectionOptions: boolean;
}

export const ContentOptionSeeAllUserSelectionOptions = styled.div<ContentOptionSeeAllUserSelectionOptionsProps>`
  margin: 1.5rem 0 1rem;

  button {
    display: flex;
    align-items: center;
    align-items: center;
    gap: 0.5rem;

    p {
      margin-bottom: 0;
    }

    color: ${({ theme }) => theme.colors.gray4};

    svg {
      transition: transform 0.5s linear;
      transform: ${({ seeAllUserSelectionOptions }) =>
        seeAllUserSelectionOptions ? 'scaleY(-1)' : 'scaleY(1)'};
    }
  }
`;

interface ULExpandProps {
  expand: boolean;
}

export const ULExpand = styled.ul<ULExpandProps>`
  overflow: hidden;

  transition: max-height 0.5s linear;

  max-height: ${({ expand }) => (expand ? '25rem' : '0px')};

  margin-bottom: 24px;
`;
