import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  padding: 0.5rem;
  align-items: flex-start;
  gap: 0.625rem;

  border-radius: 9999px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  cursor: pointer;

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.6 !important;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.gray5};

    &:disabled {
      background: ${({ theme }) => theme.colors.gray1} !important;

      color: ${({ theme }) => theme.colors.gray5} !important;

      svg {
        color: ${({ theme }) => theme.colors.gray4} !important;
      }
    }
  }

  svg {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    justify-content: center;
    align-items: center;

    color: ${({ theme }) => theme.colors.gray4};

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;
