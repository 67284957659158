export const workspaceProjectionsPTBRTranslate = {
  workspaceProjectionsMessageErrorServerCanNotFind:
    'O servidor não pode encontrar o recurso solicitado.',
  workspaceProjectionsMessageErrorServerRequestedResource:
    'O recurso solicitado não existe.',
  workspaceProjectionsMessageErrorEngineNotSupported:
    'Mecanismo de projeto não suportado.',
  workspaceProjectionsMessageErrorAnInternalError:
    'Ocorreu um erro interno ao processar a solicitação.',
  workspaceProjectionsLoadingTitle: 'Estamos trabalhando nisso!',
  workspaceProjectionsLoadingDescription:
    'Isso pode levar um tempo... Mas não se preocupe, o seu modelo de Projeções está sendo processado e em breve estará pronto!',
  workspaceProjectionsQuarterlySeriesSummaryIsOnlyAvailable:
    'O resumo de série trimestral está disponível apenas para séries com pelo menos 1 trimestre de observação.',
  workspaceProjectionsNOfDays: 'Nº dias',
};
