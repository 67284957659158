import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AIUserSelectionFrequency =
  | 'original'
  | 'monthly'
  | 'quarterly'
  | 'yearly';

export type AIUserSelectionTransformation = 'Variation' | 'Level';

export type BreakdownModelType = 'stock' | 'flow';

export type BreakdownFrequency = 'original' | 'quarterly' | 'yearly';

interface DefaultOptions {
  frequency: AIUserSelectionFrequency;
  transformations: AIUserSelectionTransformation[];
  isLatestDataActive: boolean;
}

interface ExplanatoryVariablesOptions extends DefaultOptions {
  optionSelected: string | null;
}

export interface PayloadAIUserSelectionOptions {
  AISelection: {
    results: DefaultOptions;
    explanatoryVariables: ExplanatoryVariablesOptions;
  };
  userSelection: {
    results: DefaultOptions;
    explanatoryVariables: ExplanatoryVariablesOptions;
    breakdown: {
      frequency: BreakdownFrequency;
      qoqModelType: BreakdownModelType;
      yoyModelType: BreakdownModelType;
      isLatestDataActive: boolean;
    };
  };
}

const initialState: PayloadAIUserSelectionOptions = {
  AISelection: {
    results: {
      frequency: 'original',
      transformations: ['Level'],
      isLatestDataActive: true,
    },
    explanatoryVariables: {
      frequency: 'original',
      transformations: ['Level'],
      isLatestDataActive: true,
      optionSelected: null,
    },
  },
  userSelection: {
    results: {
      frequency: 'original',
      transformations: ['Level'],
      isLatestDataActive: true,
    },
    explanatoryVariables: {
      frequency: 'original',
      transformations: ['Level'],
      isLatestDataActive: true,
      optionSelected: null,
    },
    breakdown: {
      frequency: 'original',
      qoqModelType: 'stock',
      yoyModelType: 'stock',
      isLatestDataActive: true,
    },
  },
};

type Selection = 'AISelection' | 'userSelection';

interface ChangeOption<T> {
  selection: Selection;
  value: T;
}

export const AIUserSelectionOptionsState = createSlice({
  name: 'projectOverviewExportTimeSeries',
  initialState,
  reducers: {
    changeAIUserSelectionResultsFrequency: (
      state,
      action: PayloadAction<ChangeOption<AIUserSelectionFrequency>>,
    ) => {
      state[action.payload.selection].results.frequency = action.payload.value;
    },

    changeAIUserSelectionResultsTransformations: (
      state,
      action: PayloadAction<ChangeOption<AIUserSelectionTransformation[]>>,
    ) => {
      state[action.payload.selection].results.transformations =
        action.payload.value;
    },

    changeAIUserSelectionResultsIsLatestDataActive: (
      state,
      action: PayloadAction<ChangeOption<boolean>>,
    ) => {
      state[action.payload.selection].results.isLatestDataActive =
        action.payload.value;
    },

    changeAIUserSelectionExplanatoryVariablesFrequency: (
      state,
      action: PayloadAction<ChangeOption<AIUserSelectionFrequency>>,
    ) => {
      state[action.payload.selection].explanatoryVariables.frequency =
        action.payload.value;
    },

    changeAIUserSelectionExplanatoryVariablesTransformations: (
      state,
      action: PayloadAction<ChangeOption<AIUserSelectionTransformation[]>>,
    ) => {
      state[action.payload.selection].explanatoryVariables.transformations =
        action.payload.value;
    },
    changeAIUserSelectionExplanatoryVariablesIsLatestDataActive: (
      state,
      action: PayloadAction<ChangeOption<boolean>>,
    ) => {
      state[action.payload.selection].explanatoryVariables.isLatestDataActive =
        action.payload.value;
    },

    changeAIUserSelectionExplanatoryVariablesOptionSelected: (
      state,
      action: PayloadAction<ChangeOption<string | null>>,
    ) => {
      state[action.payload.selection].explanatoryVariables.optionSelected =
        action.payload.value;
    },

    changeUserSelectionBreakdownFrequencySelected: (
      state,
      action: PayloadAction<BreakdownFrequency>,
    ) => {
      state.userSelection.breakdown.frequency = action.payload;
    },

    changeUserSelectionBreakdownQoQModelType: (
      state,
      action: PayloadAction<BreakdownModelType>,
    ) => {
      state.userSelection.breakdown.qoqModelType = action.payload;
    },

    changeUserSelectionBreakdownYoYModelType: (
      state,
      action: PayloadAction<BreakdownModelType>,
    ) => {
      state.userSelection.breakdown.yoyModelType = action.payload;
    },

    changeUserSelectionBreakdownIsLatestDataActive: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.userSelection.breakdown.isLatestDataActive = action.payload;
    },

    resetAIUserSelectionOptions: (state) => {
      state.AISelection.results.frequency = 'original';
      state.AISelection.results.transformations = ['Level'];
      state.AISelection.results.isLatestDataActive = true;

      state.AISelection.explanatoryVariables.frequency = 'original';
      state.AISelection.explanatoryVariables.transformations = ['Level'];
      state.AISelection.explanatoryVariables.isLatestDataActive = true;
      state.AISelection.explanatoryVariables.optionSelected = null;

      state.userSelection.results.frequency = 'original';
      state.userSelection.results.transformations = ['Level'];
      state.userSelection.results.isLatestDataActive = true;

      state.userSelection.explanatoryVariables.frequency = 'original';
      state.userSelection.explanatoryVariables.transformations = ['Level'];
      state.userSelection.explanatoryVariables.isLatestDataActive = true;
      state.userSelection.explanatoryVariables.optionSelected = null;

      state.userSelection.breakdown.frequency = 'original';
      state.userSelection.breakdown.qoqModelType = 'stock';
      state.userSelection.breakdown.yoyModelType = 'stock';
      state.userSelection.breakdown.isLatestDataActive = true;
    },

    resetUserSelectionOptions: (state) => {
      state.userSelection.results.frequency = 'original';
      state.userSelection.results.transformations = ['Level'];
      state.userSelection.results.isLatestDataActive = true;

      state.userSelection.explanatoryVariables.frequency = 'original';
      state.userSelection.explanatoryVariables.transformations = ['Level'];
      state.userSelection.explanatoryVariables.isLatestDataActive = true;
      state.userSelection.explanatoryVariables.optionSelected = null;

      state.userSelection.breakdown.frequency = 'original';
      state.userSelection.breakdown.qoqModelType = 'stock';
      state.userSelection.breakdown.yoyModelType = 'stock';
      state.userSelection.breakdown.isLatestDataActive = true;
    },
  },
});

export const {
  changeAIUserSelectionResultsFrequency,
  changeAIUserSelectionResultsTransformations,
  changeAIUserSelectionResultsIsLatestDataActive,

  changeAIUserSelectionExplanatoryVariablesFrequency,
  changeAIUserSelectionExplanatoryVariablesTransformations,
  changeAIUserSelectionExplanatoryVariablesIsLatestDataActive,
  changeAIUserSelectionExplanatoryVariablesOptionSelected,

  changeUserSelectionBreakdownFrequencySelected,
  changeUserSelectionBreakdownQoQModelType,
  changeUserSelectionBreakdownYoYModelType,
  changeUserSelectionBreakdownIsLatestDataActive,

  resetAIUserSelectionOptions,
  resetUserSelectionOptions,
} = AIUserSelectionOptionsState.actions;
export default AIUserSelectionOptionsState.reducer;
