import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { Head } from 'src/components/Head';
import { Pagination } from 'src/components/Pagination';
import {
  setLastSearch,
  setPage,
  setRequestAllowed,
  setSearchTimer,
  setSearchTimerActive,
} from 'src/feature-store/redux/reducers/IndicatorsPage';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';
import { IndicatorCard } from 'src/feature-store/components/IndicatorCard';
import { FeatureStoreSidebarContext } from 'src/feature-store/components/Layout/Contexts/NavigationContext';

import {
  Container,
  ContainerWithoutIndicators,
  IndicatorsContent,
  ListIndicatorContainer,
} from './styles';
import { IndicatorsProps } from './types';

const QUANTITY_ITEMS_PAGE = 8;

export const Indicators: React.FC = () => {
  const [indicators, setIndicators] = useState<IndicatorsProps>();
  const {
    openIndicatorFilters,
    openPremiumFilters,
    openFavoriteFilters,
    indicatorFilterType,
  } = useContext(FeatureStoreSidebarContext);

  const {
    page,
    search,
    breadcumbs,
    searchTimer,
    searchTimerActive,
    requestAllowed,
    lastSearch,
  } = useSelector((state: RootState) => state.indicatorsPage);

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const {
    data: indicatorsData,
    isFetching: isFetchingIndicators,
    isLoading: isLoadingIndicators,
    isError: isIndicatorsErrored,
  } = useQuery(
    [
      'indicators',
      page,
      breadcumbs[breadcumbs.length - 1]?.category_id,
      search,
      indicatorFilterType,
    ],
    async () => {
      const { data } = await api.get<IndicatorsProps>(
        breadcumbs[breadcumbs.length - 1].category_node !== 'Feature Store' &&
          search.length >= 3
          ? `/indicators?category_id=${
              breadcumbs[breadcumbs.length - 1].category_id
            }&text=${encodeURIComponent(search)}&skip=${
              (page - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}&is_active=true${
              indicatorFilterType === 'default'
                ? '&access_type=default'
                : indicatorFilterType === 'free'
                ? '&access_type=freemium'
                : ''
            }`
          : breadcumbs[breadcumbs.length - 1].category_node !==
              'Feature Store' && search.length < 3
          ? `/indicators?category_id=${
              breadcumbs[breadcumbs.length - 1].category_id
            }&skip=${
              (page - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}&is_active=true${
              indicatorFilterType === 'default'
                ? '&access_type=default'
                : indicatorFilterType === 'free'
                ? '&access_type=freemium'
                : ''
            }`
          : search.length >= 3
          ? `/indicators?text=${encodeURIComponent(search)}&skip=${
              (page - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}&is_active=true${
              indicatorFilterType === 'default'
                ? '&access_type=default'
                : indicatorFilterType === 'free'
                ? '&access_type=freemium'
                : ''
            }`
          : `/indicators?skip=${
              (page - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}&is_active=true${
              indicatorFilterType === 'default'
                ? '&access_type=default'
                : indicatorFilterType === 'free'
                ? '&access_type=freemium'
                : ''
            }`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60,
      enabled:
        (!(breadcumbs.length === 0) && (requestAllowed || search === '')) ||
        !!indicatorFilterType ||
        indicatorFilterType === null,
      onSettled: () => {
        dispatch(setRequestAllowed(false));
        dispatch(setSearchTimerActive(false));
        dispatch(setSearchTimer(1000));
        dispatch(setLastSearch(search));
      },
    },
  );

  useEffect(() => {
    openIndicatorFilters(true);
    openPremiumFilters(false);
    openFavoriteFilters(false);
    if (indicatorsData) {
      setIndicators(indicatorsData);
    }
  }, [
    indicatorsData,
    openFavoriteFilters,
    openIndicatorFilters,
    openPremiumFilters,
  ]);

  useEffect(() => {
    if (searchTimerActive && search.length >= 3) {
      setTimeout(() => {
        if (searchTimer > 0) {
          dispatch(setSearchTimer(searchTimer - 1000));
        } else {
          dispatch(setSearchTimerActive(false));
        }
      }, 1000);
    } else {
      searchTimer === 0 && dispatch(setRequestAllowed(true));
    }
  }, [searchTimer, search, searchTimerActive, dispatch]);

  return (
    <Container>
      <Head title={translate('indicatorsTitle')} />

      <IndicatorsContent className="containerLinear">
        <Card
          textCard={translate('indicatorsTitle')}
          textDescription={translate('indicatorsDescription')}
        />

        {isIndicatorsErrored && breadcumbs.length > 0 ? (
          <ContainerMaintenance
            content="indicator"
            text={translate('indicatorsUnableToLoadIndicators')}
          />
        ) : isFetchingIndicators ||
          isLoadingIndicators ||
          breadcumbs.length === 0 ? (
          // eslint-disable-next-line prettier/prettier, react/jsx-indent
          <>
            <ListIndicatorContainer>
              {Array.from({ length: QUANTITY_ITEMS_PAGE - 1 }).map(
                (_, index) => (
                  <IndicatorCard
                    key={`indicator-loading${index.toString()}`}
                    data-testid={`indicator-loading-${(index + 1).toString()}`}
                    loading
                  />
                ),
              )}
            </ListIndicatorContainer>

            {indicators?.total && (
              <Pagination
                total={indicators?.total}
                page={page}
                setPage={(selectedPage) => {
                  dispatch(setPage(selectedPage));
                  dispatch(setRequestAllowed(true));
                }}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name={translate('indicatorsPaginationName')}
              />
            )}
          </>
        ) : indicators && indicators.total > 0 ? (
          <>
            <ListIndicatorContainer data-cy="list-indicator-container">
              {indicators?.data?.map((indicator) => (
                <IndicatorCard
                  key={`indicator-${indicator.indicator_code}`}
                  data-testid={`card-indicator-${indicator?.name['en-us']
                    .replaceAll(' ', '-')
                    .toLocaleLowerCase()}`}
                  indicator={indicator}
                />
              ))}
            </ListIndicatorContainer>

            {indicators?.total && (
              <Pagination
                total={indicators?.total}
                page={page}
                setPage={(selectedPage) => {
                  dispatch(setPage(selectedPage));
                  dispatch(setRequestAllowed(true));
                }}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name={translate('indicatorsPaginationName')}
              />
            )}
          </>
        ) : (
          <ContainerWithoutIndicators>
            <ContainerMaintenance
              content="indicators"
              text={
                lastSearch.length >= 1
                  ? `${translate('indicatorSearchFail')} "${lastSearch}".`
                  : `${translate('indicatorFindAnyCategory')}`
              }
              description={translate('indicatorTryBrowsingCategoriesOrSearch')}
            />
          </ContainerWithoutIndicators>
        )}
      </IndicatorsContent>
    </Container>
  );
};
