import React from 'react';

import DatePicker from 'react-datepicker';
import { Calendar } from 'phosphor-react';
import { Frequency } from 'src/models/redux/reducers/Project';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';

import { Input } from '../Input';
import { Container } from './styles';

export type DateRange = [Date | null, Date | null];

type Props = {
  dateRange: DateRange;
  label?: string;
  onChange: (value: DateRange) => void;
  frequency: Frequency;
  inputTestId: string;
};

export const RangeDatePicker: React.FC<Props> = ({
  dateRange,
  label,
  onChange,
  frequency,
  inputTestId,
  ...rest
}) => {
  const { language } = useSelector((state: RootState) => state.auth.user);

  function handleDatePicker(valueAux: DateRange) {
    onChange(valueAux);
  }

  function handleValue(e: React.FocusEvent<HTMLInputElement, Element>) {
    e.preventDefault();
  }

  const pickerLanguage = language === 'pt-br' ? ptBR : enUS;

  const [startDate, endDate] = dateRange;

  const yearPicker = frequency === 'annual';

  const dailyPicker = frequency === 'daily' || frequency === 'weekly';

  const monthPicker = !yearPicker && !dailyPicker;

  const dateFormat = monthPicker
    ? 'MM/yyyy'
    : yearPicker
    ? 'yyyy'
    : 'dd/MM/yyyy';

  const width = monthPicker ? '14.5rem' : yearPicker ? '16rem' : '15.125rem';

  return (
    <Container>
      <DatePicker
        selectsRange
        startDate={startDate || null}
        endDate={endDate || null}
        onChange={handleDatePicker}
        dateFormat={dateFormat}
        showMonthYearPicker={monthPicker}
        showYearPicker={yearPicker}
        disabledKeyboardNavigation
        customInput={
          <Input
            icon={<Calendar size={32} />}
            invertedIcon
            label={label}
            testid={inputTestId}
            style={{ width }}
          />
        }
        onChangeRaw={handleValue}
        className="customRangePicker"
        formatWeekDay={(day) => day[0].toUpperCase()}
        locale={pickerLanguage}
        showPopperArrow={false}
        data-testid="range-date-picker"
        {...rest}
      />
    </Container>
  );
};
