export const workspaceProjectionsPotencialImpactsENTranslate = {
  workspaceProjectionsPotencialImpactsTitle: 'Potential Impacts',
  workspaceProjectionsPotencialImpactsDescription:
    'Estimates of the impacts associated with conditioning factors on the variable of interest',
  workspaceProjectionsPotencialImpactsSeeMore: 'See more impacts',
  workspaceProjectionsPotencialImpactsLessMore: 'See less impacts',
  workspaceProjectionsPotencialImpactsUnderstandingImpact:
    'Understanding the Impact',
  workspaceProjectionsImpactsError0Variables:
    'No explanatory variable to be displayed.',
};
