import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import api from 'src/models/service/api';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { getChartColor } from 'src/utils/getChartColor';

import { ErrorDataView } from '../../types';

interface ReturnCorrelation {
  variable: string[];
  value: number[];
}

export const Correlation: React.FC = () => {
  const { t: translate } = useTranslation();

  const id = useSelector(
    (state: RootState) =>
      state.modelling.form?.fileDataSet.dataset_id ?? state.project.id,
  );

  const varY = useSelector(
    (state: RootState) =>
      state.modelling.form?.dependVariable ?? state.project.selectedY,
  );

  const {
    data: dataCorrelation,
    isLoading: isLoadingCorrelation,
    isFetching: isFetchingCorrelation,
    isError: isErrorCorrelation,
    error: errorCorrelation,
  } = useQuery<ReturnCorrelation, ErrorDataView>(
    ['dataview', 'correlation', id],
    async () => {
      const { data } = await api.get(`projects/${id}/dataview/y/correlations`);
      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id,
    },
  );

  const series: HChartsSeries = useMemo(
    () => ({
      type: 'bar',
      name: 'orig',
      showInLegend: false,
      data: dataCorrelation?.value.map((value, valueIndex) => ({
        y: value,
        value,
        color: value >= 0 ? getChartColor(0) : getChartColor(1),
        custom: {
          title: dataCorrelation.variable[valueIndex],
          value: formatCompactNotation(value),
        },
      })),
    }),
    [dataCorrelation],
  );

  const options: HChartsOptions = {
    chart: {
      height: 300,
      marginTop: 0,
    },
    xAxis: {
      labels: {
        y: undefined,
      },
      alignTicks: true,
      minTickInterval: undefined,
      categories: dataCorrelation?.variable,
    },
    yAxis: {
      title: {
        text: (typeof varY === 'string' ? varY : varY?.name) ?? undefined,
      },
    },
    tooltip: {
      headerFormat: undefined,
      pointFormat:
        '<table><tr><th colspan="2">{point.custom.title}</th></tr>' +
        `<tr><td><b>${translate('value')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.value}</td></tr>',
    },
    plotOptions: {
      bar: {
        pointPadding: 0.1,
        groupPadding: 0.1,
        grouping: false,
      },
    },
  };

  return (
    <div className="containerLinear" data-cy="correlation">
      <Card textCard={translate('correlation')} />

      {isErrorCorrelation ? (
        <ContainerMaintenance
          content="chart"
          text={
            errorCorrelation?.response?.data?.detail?.description ??
            errorCorrelation?.response?.data?.detail?.detail
          }
          data-testid="container-error-correlation"
          data-cy="container-error-correlation"
        />
      ) : dataCorrelation?.value?.length === 0 ? (
        <ContainerMaintenance
          content="chart"
          text={translate('overviewNoExplanatoryVariableSelected')}
          data-testid="container-error-correlation-no-data"
          data-cy="container-error-correlation-no-data"
        />
      ) : isLoadingCorrelation || isFetchingCorrelation || !dataCorrelation ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton
          data-testid="div-loading-correlation"
          data-cy="div-loading-correlation"
        />
      ) : (
        <HCharts
          series={[series]}
          options={options}
          dataCy="chart-correlation"
        />
      )}
    </div>
  );
};
