export const WorkspaceOverviewPTBRTranslation = {
  workspaceOverviewTitle: 'Visão Geral do Workspace',
  workspaceOverviewDescription:
    'Explore e compare todas as variáveis em seu workspace.',
  workspaceProjectionsTitle: 'Projeções',
  workspaceProjectionsDescriptions:
    'Acesse as projeções das suas variáveis ao longo do tempo.',
  workspaceProjectionsFrequency: 'Frequência',
  workspaceProjectionsOriginal: 'Original',
  workspaceProjectionsAnnual: 'Anual',
  workspaceProjectionsTransformation: 'Transformação',
  workspaceProjectionsLevel: 'Nível',
  workspaceProjectionsVariation: 'Variação (%)',
  workspaceProjectionsHistorical: 'Histórico',
  workspaceProjectionsForecast: 'Projeção',
  workspaceProjectionsVariables: 'variáveis',
  workspaceProjectionsOnlyForMonthlyFrequency: 'Apenas para frequência mensal',
  workspaceProjectionsTable: 'tabela',
  workspaceSearchDependentVariablePlaceholder: 'Procurar variáveis dependentes',

  workspaceOverviewTitleTable: 'Resumo',
  workspaceOverviewSearchDependentVariablePlaceholder:
    'Procurar variáveis dependentes',
  workspaceSearchDependentVariableNotFound: `Não foi possível encontrar nenhuma variável dependente contendo`,

  workspaceOverviewFirstColumn: 'Variável Dependente',
  workspaceOverviewOnly4Variables: 'É possível selecionar apenas 4 variáveis',
  workspaceOverviewChart: 'gráfico',
  workspaceOverviewSelectAVariable: 'Selecione uma variável',
  workspaceOverviewSelectAVariableDescription:
    'Selecione pelo menos uma variável para verificar a comparação das variáveis dependentes do seu projeto.',
  workspaceOverviewDownloadError: 'Ocorreu um erro ao baixar o arquivo.',
};
