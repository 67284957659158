import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  position: relative;

  margin-bottom: -24px !important;

  @media (min-width: 1140px) {
    justify-content: center !important;
    align-items: center;
  }

  .containerLinear {
    position: relative;
    max-width: 1140px;

    @media (min-width: 1140px) {
      min-width: 1140px;
    }
  }
`;

export const CardFeatures = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
    margin-right: 1.5rem;
    cursor: pointer;
    transition: 0.2s color;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }

  .cardDescription {
    max-width: 61.25rem;
    p {
      word-break: break-word;
    }
  }
`;

export const ContentFeature = styled.div`
  display: flex;
  flex-direction: column;
`;

const appearFromTop = keyframes`
  from {
    opacity: 0;
    font-size:0px;
  }
  to{
    opacity: 1;
    font-size: 1.25rem;
  }
`;

interface DivDescriptionProps {
  visible: boolean;
}

export const DivDescription = styled.div<DivDescriptionProps>`
  display: flex;
  flex-direction: column;

  > p {
    word-break: break-word;
    text-align: justify;
    /* animation: ${appearFromTop} 1s; */
    max-height: ${({ visible }) => (visible ? 'auto' : '0px')};
    transform: ${({ visible }) => (visible ? 'scaleY(1)' : 'scaleY(0)')};
    transform-origin: top;
    transition: 0.2s;
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1rem !important;
    font-weight: normal;
    line-height: 150%;
  }
  svg {
    margin-left: 0.25rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }

  .buttonDescription {
    display: flex;
    align-items: center;
    margin-top: ${({ visible }) => (visible ? '0.938rem' : '0px')};
  }
`;

export const ButtonDescription = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.2s color;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
  }
`;

export const DivSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.875rem 0;
  gap: 1.5rem;
`;

export const OptionContent = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 0.938rem 0.5rem 1rem 0;

  > div {
    display: flex;

    > button {
      & + button {
        margin-left: 1rem;
      }
    }
  }
`;

export const ChartContent = styled.div`
  position: relative;

  margin-top: 1.875rem;

  h4 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  .highcharts-axis-labels.highcharts-xaxis-labels.highcharts-navigator-xaxis {
    display: none;
  }

  .highcharts-tooltip {
    p + p {
      margin-top: 0.25rem;
    }

    b {
      font-weight: 600;
    }
  }

  .highcharts-navigator-outline {
    stroke: ${({ theme }) => theme.colors.gray1};
  }
`;

export const CardsExportSend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  max-width: 1140px;
  .containerLinear {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: unset;
  }
`;

export const ContainerErrorChart = styled.div`
  padding: 1.25rem;
`;

interface MenuProps {
  visible: boolean;
}

export const Menu = styled.div<MenuProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  width: 12.5rem;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 16px 32px rgba(97, 97, 97, 0.16);
  border-radius: 5px;
  bottom: 3.125rem;
  position: absolute;
  z-index: 1001;
`;

interface MenuButtonProps {
  position: 'start' | 'middle' | 'end';
}

export const MenuButton = styled.button<MenuButtonProps>`
  width: 12.5rem;
  height: 3.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  background: ${({ theme }) => theme.colors.white};
  transition: 0.2s;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  z-index: 1001;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  border-radius: ${({ position }) =>
    position === 'start' ? '5px 5px 0 0' : position === 'end' && '0 0 5px 5px'};

  svg {
    margin: 0 1rem;
  }

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.188rem;
    color: ${({ theme }) => theme.colors.gray7};
    margin: 0;

    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  }

  svg {
    color: ${({ theme }) => theme.colors.gray7};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  }

  :hover {
    background: rgba(224, 98, 0, 0.08);

    p {
      color: ${({ theme }) => theme.colors.secondary};
    }

    svg {
      color: ${({ theme }) => theme.colors.secondary};
    }

    ${({ position, disabled }) =>
      !disabled
        ? position === 'start'
          ? css`
              border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
            `
          : position === 'middle'
          ? css`
              border-top: 1px solid ${({ theme }) => theme.colors.secondary};
              border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
            `
          : position === 'end' &&
            css`
              border-top: 1px solid ${({ theme }) => theme.colors.secondary};
            `
        : css`
            background: ${({ theme }) => theme.colors.gray1};

            p {
              color: ${({ theme }) => theme.colors.gray7};
            }

            svg {
              color: ${({ theme }) => theme.colors.gray7};
            }
          `}
  }
`;

interface MenuOverlayProps {
  visible: boolean;
}

export const MenuOverlay = styled.div<MenuOverlayProps>`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const DivTable = styled.div`
  margin-top: 2.5rem;

  display: flex;
  table,
  td,
  th {
    border: 1px solid ${({ theme }) => theme.colors.gray2};
    padding: 0.625rem;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr:first-child {
    th {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray6};

      text-align: center; /* Centraliza o texto dentro do th */

      svg {
        margin-left: 0.938rem;
      }
    }
    th > * {
      display: inline-block; /* Define os elementos filhos do th como blocos inline */
      vertical-align: middle; /* Centraliza os elementos filhos verticalmente */
    }
  }

  tr:nth-child(2) {
    th {
      font-weight: 400;
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const SourceAndProjection = styled.div`
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${({ theme }) => theme.colors.gray5};
    max-width: 14.375rem;
  }

  @media (min-width: 1900px) {
    span {
      max-width: 18.75rem;
    }
  }
`;

interface PropsSourceProjection {
  legendQuantityBoolean: number;
}

export const Source = styled.span<PropsSourceProjection>`
  position: absolute;
  left: 2.188rem;
  left: ${(props) =>
    props.legendQuantityBoolean === 2 ? '2.188rem' : '2.75rem'};
  top: 390px;
`;

export const Projection = styled.span<PropsSourceProjection>`
  position: absolute;
  right: 0;
  top: 390px;
  margin-right: 0.688rem;
`;
