export const workspaceProjectionsExplanatoryVariablesPTTranslate = {
  workspaceProjectionsExplanatoryVariablesTitle:
    'Projeção de Variáveis Explicativas',
  workspaceProjectionsExplanatoryVariablesVariable: 'Variável',
  workspaceProjectionsExplanatoryNoVariables:
    'Nenhuma variável explicativa a ser apresentada.',
  workspaceProjectionsExplanatoryVariablesLevel: 'Nível',
  workspaceProjectionsExplanatoryVariablesVariation: 'Variação',
  workspaceProjectionsExplanatoryVariablesFrequency: 'Frequência',
  workspaceProjectionsExplanatoryVariablesTransformation: 'Transformação',
  workspaceProjectionsExplanatoryVariablesOriginal: 'Original',
  workspaceProjectionsExplanatoryVariablesMonthly: 'Mensal',
  workspaceProjectionsExplanatoryVariablesQuarterly: 'Trimestral',
  workspaceProjectionsExplanatoryVariablesAnnual: 'Anual',
  workspaceProjectionsExplanatoryVariablesVariationOriginal:
    'Variação (comparado ao período anterior)',
  workspaceProjectionsExplanatoryVariablesVariationMonthlyQuarterly:
    'Variação (comparado ao mesmo período do ano anterior)',
  workspaceProjectionsExplanatoryVariablesVariationAnnual:
    'Variação (comparado ao ano anterior)',
  workspaceProjectionsExplanatoryVariablesNotEnoughObservationsVariation:
    'Não há observações suficientes para calcular a variação.',
};
