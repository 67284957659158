import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WorkspaceProjectionsFrequency =
  | 'original'
  | 'monthly'
  | 'quarterly'
  | 'yearly';

export type WorkspaceProjectionsTransformation = 'Variation' | 'Level';

interface DefaultOptions {
  frequency: WorkspaceProjectionsFrequency;
  transformations: WorkspaceProjectionsTransformation[];
  isLatestDataActive: boolean;
}

interface ExplanatoryVariablesOptions extends DefaultOptions {
  optionSelected: string | null;
}

export interface PayloadWorkspaceProjectionsOptions {
  results: DefaultOptions;
  explanatoryVariables: ExplanatoryVariablesOptions;
}

const initialState: PayloadWorkspaceProjectionsOptions = {
  results: {
    frequency: 'original',
    transformations: ['Level'],
    isLatestDataActive: true,
  },
  explanatoryVariables: {
    frequency: 'original',
    transformations: ['Level'],
    isLatestDataActive: true,
    optionSelected: null,
  },
};

export const WorkspaceProjectionsOptionsState = createSlice({
  name: 'workspaceProjectionsOptions',
  initialState,
  reducers: {
    changeWorkspaceProjectionsResultsFrequency: (
      state,
      action: PayloadAction<WorkspaceProjectionsFrequency>,
    ) => {
      state.results.frequency = action.payload;
    },

    changeWorkspaceProjectionsResultsTransformations: (
      state,
      action: PayloadAction<WorkspaceProjectionsTransformation[]>,
    ) => {
      state.results.transformations = action.payload;
    },

    changeWorkspaceProjectionsResultsIsLatestDataActive: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.results.isLatestDataActive = action.payload;
    },

    changeWorkspaceProjectionsExplanatoryVariablesFrequency: (
      state,
      action: PayloadAction<WorkspaceProjectionsFrequency>,
    ) => {
      state.explanatoryVariables.frequency = action.payload;
    },

    changeWorkspaceProjectionsExplanatoryVariablesTransformations: (
      state,
      action: PayloadAction<WorkspaceProjectionsTransformation[]>,
    ) => {
      state.explanatoryVariables.transformations = action.payload;
    },

    changeWorkspaceProjectionsExplanatoryVariablesIsLatestDataActive: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.explanatoryVariables.isLatestDataActive = action.payload;
    },

    changeWorkspaceProjectionsExplanatoryVariablesOptionSelected: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.explanatoryVariables.optionSelected = action.payload;
    },

    resetWorkspaceProjectionsOptions: (state) => {
      state.results.frequency = 'original';
      state.results.transformations = ['Level'];
      state.results.isLatestDataActive = true;

      state.explanatoryVariables.frequency = 'original';
      state.explanatoryVariables.transformations = ['Level'];
      state.explanatoryVariables.isLatestDataActive = true;
      state.explanatoryVariables.optionSelected = null;
    },
  },
});

export const {
  changeWorkspaceProjectionsResultsFrequency,
  changeWorkspaceProjectionsResultsTransformations,
  changeWorkspaceProjectionsResultsIsLatestDataActive,
  changeWorkspaceProjectionsExplanatoryVariablesFrequency,
  changeWorkspaceProjectionsExplanatoryVariablesTransformations,
  changeWorkspaceProjectionsExplanatoryVariablesIsLatestDataActive,
  changeWorkspaceProjectionsExplanatoryVariablesOptionSelected,
} = WorkspaceProjectionsOptionsState.actions;
export default WorkspaceProjectionsOptionsState.reducer;
