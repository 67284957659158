import React, { ReactElement, useEffect } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { SideBarFS } from './SideBar';
import { Content } from './styles';

export const FeatureStoreLayout: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const actualLocation = location.pathname;

  useEffect(() => {
    if (
      actualLocation.includes('/feature-store/indicators') ||
      actualLocation.includes('/feature-store/groups')
    ) {
      return;
    }

    if (
      actualLocation.includes('/feature-store') &&
      !actualLocation.includes('/feature-store/')
    ) {
      navigate('/feature-store/indicators');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isIndicatorPage() {
    const locationSplited = actualLocation.split('/');
    const endsWithSlash =
      locationSplited.length === 5 && locationSplited[4] === '';

    if (
      (locationSplited.length === 4 || endsWithSlash) &&
      locationSplited[2] === 'indicators'
    ) {
      return true;
    }
    return false;
  }

  function isFavoritesPage() {
    const locationSplited = actualLocation.split('/');
    const isFavorite = locationSplited[2] === 'favorites';
    return isFavorite;
  }

  return (
    <>
      {(actualLocation === '/feature-store/indicators/' ||
        actualLocation === '/feature-store/indicators' ||
        actualLocation === '/feature-store/premium' ||
        actualLocation === '/feature-store/premium/' ||
        isIndicatorPage() ||
        isFavoritesPage()) && <SideBarFS />}
      <Content
        data-testid="layout-container-div-feature-store"
        data-cy="layout-container-div-feature-store"
        id="layout-container-div-feature-store"
      >
        {children}
      </Content>
    </>
  );
};
