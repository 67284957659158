import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { Share, X } from 'phosphor-react';
import { Button } from 'src/components/Button';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Spinner } from 'src/components/Button/styles';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import {
  CloseModalButton,
  Container,
  StatusBox,
  UpdateShareList,
} from './styles';
import { ModelUpdateContext } from '../contexts/ModelUpdateContext';

interface ShareUpdateModalProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export const ShareUpdateModal: React.FC<ShareUpdateModalProps> = ({
  visible,
  setVisible,
}) => {
  const { t: translate } = useTranslation();

  const {
    updateShareList,
    handleShareUpdate,
    isUpdateShareListLoading,
    updateShareListError,
    sendingUpdate,
  } = useContext(ModelUpdateContext);

  const updateShareDisabled =
    updateShareList?.projects?.some(
      (share) => share.status === 'processing' || share.status === 'pending',
    ) ||
    updateShareList?.projects?.every((share) => share.status === 'success');

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Container>
        <h3>{translate('shareUpdateTitle')}</h3>
        <p>{translate('shareUpdateDescription')}</p>
        <UpdateShareList>
          {updateShareListError ? (
            <ContainerMaintenance content="table" />
          ) : isUpdateShareListLoading ? (
            <table>
              <thead>
                <tr>
                  <th>{translate('shareUpdateTableUsers')}</th>
                  <th>{translate('shareUpdateTableStatus')}</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 6 }).map((_, index) => (
                  <tr key={`loading-table-${index + 1}`}>
                    <td>
                      <ContainerSkeleton
                        withLoading={false}
                        style={{
                          width: `${Math.random() * (180 - 100) + 100}px`,
                          height: '18px',
                        }}
                      />
                    </td>
                    <td>
                      <ContainerSkeleton
                        withLoading={false}
                        style={{
                          width: '100px',
                          height: '26px',
                          borderRadius: '8px',
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : updateShareList && updateShareList.projects.length === 0 ? (
            <ContainerMaintenance
              content="table"
              text={translate('shareUpdateNoSharesDescription')}
            />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>{translate('shareUpdateTableUsers')}</th>
                  <th>{translate('shareUpdateTableStatus')}</th>
                </tr>
              </thead>
              <tbody>
                {updateShareList?.projects?.map((project, index) => (
                  <tr key={`index-${index + 1}`}>
                    <td>{project.user}</td>
                    <td>
                      <div>
                        <StatusBox status={project.status ?? ''}>
                          <p>
                            {project.status === 'success'
                              ? translate('shareUpdateStatusFinished')
                              : project.status === 'error'
                              ? translate('shareUpdateStatusError')
                              : project.status === 'processing'
                              ? translate('shareUpdateStatusCopying')
                              : translate('shareUpdateStatusNotSent')}
                          </p>
                        </StatusBox>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </UpdateShareList>
        <CloseModalButton
          type="button"
          onClick={() => setVisible(false)}
          data-cy="close-share-update-modal-button"
          data-testid="close-share-update-modal-button"
        >
          <X size="1.25rem" />
        </CloseModalButton>
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => handleShareUpdate()}
          data-cy="send-updates-button"
          data-testid="send-updates-button"
          icon={<Share size="1.125rem" />}
          disabled={
            sendingUpdate ||
            updateShareListError ||
            updateShareList?.projects.length === 0 ||
            updateShareDisabled ||
            isUpdateShareListLoading
          }
        >
          {translate('shareUpdateSendUpdateButton')}
          {sendingUpdate && <Spinner hasIcon spinnerType="secondary" />}
        </Button>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-cy="ok-button"
          data-testid="ok-button"
          disabled={sendingUpdate}
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
