import React from 'react';

import { useTranslation } from 'react-i18next';
import { ReactComponent as InstagramLogo } from 'src/assets/instagram_logo.svg';
import { ReactComponent as LinkedinLogo } from 'src/assets/linkedin_logo.svg';
import logo from 'src/assets/logo.svg';

import { ContainerFooter } from './styles';

type Footer = React.HTMLAttributes<HTMLDivElement>;

export const Footer: React.FC<Footer> = ({ ...rest }) => {
  const { t: translate } = useTranslation();
  return (
    <ContainerFooter {...rest} id="footer">
      <div>
        <img src={logo} alt="logo" data-testid="footer-logo" />
        <p>
          {translate('footerDescription')} <b>{translate('footerEmail')}</b>
        </p>
        <div>
          <p>{translate('footerSocialMedia')}</p>
          <a
            href="https://www.linkedin.com/company/4intelligence-ai/"
            target="_blank"
            rel="noreferrer"
            data-testid="link-to-likedin"
          >
            <LinkedinLogo data-testid="logo-linkedin" />
          </a>
          <a
            href="https://www.instagram.com/4.intelligence.ai/"
            target="_blank"
            rel="noreferrer"
            data-testid="link-to-instagram"
          >
            <InstagramLogo data-testid="logo-instagram" />
          </a>
        </div>
      </div>
    </ContainerFooter>
  );
};
