export const CreateWorkspacePTBRTranslations = {
  createWorkspaceHeadTitle: 'Criar Workspace',
  editWorkspaceHeadTitle: 'Editar Workspace',
  createWorkspaceCreateTitle: 'Crie seu Workspace',
  editWorkspaceEditTitle: 'Edite seu Workspace',
  createWorkspaceCreateDescription: 'Preencha as informações básicas.',
  createWorkspaceSelectVariablesTitle: 'Selecione as Variáveis',
  createWorkspaceSelectVariablesDescription:
    'Selecione os projetos e as variáveis para compor seus resultados.',
  createWorkspaceIcon: 'Ícone',
  createWorkspaceIconTooltip:
    'Esse ícone vai te ajudar a identificar rapidamente o seu Workspace.',
  createWorkspaceIconModalTitle: 'Escolha seu ícone',
  createWorkspaceIconModalDescription:
    'Esse ícone vai te ajudar a identificar rapidamente o seu Workspace.',
  createWorkspaceName: 'Nome',
  createWorkspaceNamePlaceholder: 'Digite o nome do Workspace',
  createWorkspaceDescription: 'Descrição',
  createWorkspaceDescriptionPlaceholder:
    'Escreva uma descrição para seu Workspace',
  createWorkspaceCreate: 'Criar Workspace',
  createWorkspaceDescriptionErrorMaxCharacters:
    'Não deve ter mais do que 350 caracteres',
  createWorkspaceNoProjectSelectedTitle: 'Você não selecionou nenhum projeto',
  createWorkspaceNoProjectSelectedDescription:
    'Clique no botão abaixo e selecione um ou mais projetos que você criou anteriormente.',
  createWorkspaceSelectProjectsButton: 'Selecionar projetos',
  createWorkspaceAllProjects: 'Todos os projetos',
  createWorkspaceAddNewProject: 'Adicionar novo projeto',
  createWorkspaceOriginalVariable: 'Nome Original',
  createWorkspaceVariable: 'Variável',
  createWorkspaceProject: 'Projeto',
  createWorkspaceRepeatedVariable:
    'Essa variável se repete em outro projeto, por favor renomeie-a',
  createWorkspaceVariableNameError:
    'Por favor, verifique se há alguma variável com nome inválido.',
  createWorkspaceVariableModelIDError:
    'Por favor, verifique se há alguma variável com ID do modelo inválido.',
  createWorkspaceModelUpdate: 'Atualização do Modelo',
  createWorkspaceModelId: 'ID do Modelo',
  createWorkspaceModelIdRequiredField: 'Campo obrigatório',
  createWorkspaceModelIdInvalid: 'ID do Modelo inválido',
  createWorkspaceModalErrorTitle: 'Algo deu errado',
  createWorkspaceModalErrorDescription:
    'Ocorreu um erro ao XXX o seu workspace.',
  createWorkspaceVariableCategorization: 'Categorização das variáveis',
  createWorkspaceVariableCategorizationDescription:
    'Categorize suas variáveis em níveis diferentes e veja como ficará os filtros.',
  createWorkspaceCreateVariableCategorization: 'Criar categorização',
  createWorkspaceSaveVariableCategorization: 'Salvar alterações',
  createWorkspaceConfirmCloseCategorizationTitle: 'Você tem certeza?',
  createWorkspaceConfirmCloseCategorizationDescription:
    'Você perderá todas as alterações não salvas',
  createWorkspaceEditVariableCategorization: 'Editar categorização',
  createWorkspaceVariableCategorizationDisabled:
    'É necessário que tenha pelo menos uma variável selecionada e que todas tenham um nome válido.',
  createWorkspaceSaveChangesError:
    'Verifique se tem algum nome de filtro/opção com erro.',
  createWorkspaceShowOriginalVariableName: 'Nome original da variável',
  editWorkspaceSaveChanges: 'Salvar alterações',
  editWorkspaceDiscardChanges: 'Descartar',
  createWorkspaceModalErrorDescriptionCreateText: 'criar',
  createWorkspaceModalErrorDescriptionSaveText: 'salvar',
  createWorkspaceFilterOptionName: 'Digite o nome da opção',
  editWorkspaceEditionExpired:
    'Sua sessão de edição expirou. Gostaria de renová-la por mais 5 minutos?',
  editWorkspaceExitEdition: 'Sair da edição',
};
