import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { Menu } from './menus';
import { classificationMenus } from './menus/menus';
import { ProjectInfo } from './ProjectInfo';
import { Container, Item } from './styles';

export const ClassificationSideBar: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t: translate } = useTranslation();

  const projectId = location.pathname
    .replace('/models/classification/', '')
    .slice(0, 24);

  const [activeMenus, setActiveMenus] = useState<string[]>([
    'sidebar-menu-1-0',
    'sidebar-menu-1-1',
    'sidebar-menu-1-2',
  ]);

  const checkIfSubMenuIsActive = (subMenu: Menu[] | undefined): boolean => {
    if (subMenu) {
      return subMenu.some((page) => {
        if (location.pathname === page.url) {
          return true;
        }
        if (page.submenu) {
          return checkIfSubMenuIsActive(page.submenu);
        }
        return false;
      });
    }
    return false;
  };

  const handleIsActive = (
    url: string | undefined,
    subMenu: Menu[] | undefined,
  ): boolean => {
    if (subMenu) {
      return checkIfSubMenuIsActive(subMenu);
    }

    return location.pathname.replace(projectId, ':id') === url;
  };

  const handleMenuClick = (url: string | undefined) => {
    if (url) {
      navigate(url.replace(':id', projectId));
    }
  };

  const handleArrowClick = (menuName: string) => {
    const newActiveMenus = [...activeMenus];

    if (newActiveMenus.includes(menuName)) {
      const index = newActiveMenus.findIndex(
        (activeMenu) => activeMenu === menuName,
      );

      newActiveMenus.splice(index, 1);
      setActiveMenus(newActiveMenus);
    } else {
      const newActiveMenusAux = [
        'sidebar-menu-1-0',
        'sidebar-menu-1-1',
        'sidebar-menu-1-2',
      ];
      if (!newActiveMenusAux.includes(menuName)) {
        newActiveMenusAux.push(menuName);
      }
      setActiveMenus(newActiveMenusAux);
    }
  };

  type ListMenuProps = {
    dept: number;
    data: Menu;
    hasSubMenu: Menu[] | undefined;
    menuName: string;
    menuIndex: number;
  };

  const ListMenu = ({
    dept,
    data,
    hasSubMenu,
    menuName,
    menuIndex,
  }: ListMenuProps) => {
    const { Icon } = data;
    const isActive = handleIsActive(data?.url, data?.submenu);

    return (
      <>
        <Item
          data-testid={`li-label${data.label
            .toLocaleLowerCase()
            .replaceAll('label', '')}`}
          dept={dept}
          hasSubMenu={!!hasSubMenu}
          isToggled={activeMenus.includes(menuName)}
          isActive={isActive}
          onClick={() => {
            handleMenuClick(data?.url);
            if (hasSubMenu) {
              handleArrowClick(menuName);
            }
            if (data.label === 'labelModelInProduction') {
              setActiveMenus([
                'sidebar-menu-1-0',
                'sidebar-menu-1-1',
                'sidebar-menu-1-2',
              ]);
            }
          }}
        >
          <span>
            {Icon && <Icon size="1.5rem" style={{ marginRight: '0.5rem' }} />}
            {translate(data.label)}
          </span>
        </Item>

        {hasSubMenu && (
          <SubMenu
            dept={dept}
            data={data.submenu}
            isToggled={activeMenus.includes(menuName)}
            menuIndex={menuIndex}
          />
        )}
      </>
    );
  };

  type SubMenu = {
    dept: number;
    data: Menu[] | undefined;
    isToggled: boolean;
    menuIndex: number;
  };

  const SubMenu = ({ dept, data, isToggled, menuIndex }: SubMenu) => {
    if (!isToggled) {
      return null;
    }

    return (
      <ul>
        {data?.map((menus, index) => {
          const menuName = `sidebar-submenu-${dept + 1}-${menuIndex}-${index}`;

          return (
            <ListMenu
              key={menuName}
              dept={dept + 1}
              data={menus}
              hasSubMenu={menus.submenu}
              menuName={menuName}
              menuIndex={index}
            />
          );
        })}
      </ul>
    );
  };

  return (
    <Container
      className="sidebar"
      data-testid="sidebar-container"
      data-cy="sidebar-container"
    >
      <div>
        <ProjectInfo projectId={projectId} />
        <hr />
        <ul>
          {classificationMenus?.map((menus: Menu, index: number) => {
            const dept = +1;
            const menuName = `sidebar-menu-${dept}-${index}`;
            return (
              <ListMenu
                dept={dept}
                data={menus}
                hasSubMenu={menus.submenu}
                menuName={menuName}
                key={menuName}
                menuIndex={index}
              />
            );
          })}
        </ul>
      </div>
    </Container>
  );
};
