import React, { useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { getChartColor } from 'src/utils/getChartColor';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { CheckBox } from 'src/components/CheckBox';
import { Tooltip } from 'react-tooltip';

import { Options } from './styles';

interface ErrorPercentage {
  data: string[][2];
  type: 'MAPE' | 'WMAPE' | 'MPE' | 'RMSE';
  x: number[];
  y: number[];
}

interface LevelErrorMetricProps {
  type:
    | 'arima'
    | 'regularized-regression'
    | 'forecast-combination'
    | 'random-forest';
  modelId: number;
  isLoadingModelInfo: boolean;
  isErrorModelInfo: boolean;
  isRMSEDisabled: boolean;
}

export const LevelErrorMetric: React.FC<LevelErrorMetricProps> = ({
  type,
  modelId,
  isLoadingModelInfo,
  isErrorModelInfo,
  isRMSEDisabled,
}) => {
  const [accuracySelected, setAccuracySelected] = useState<'RMSE'[]>([]);

  const { project } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (accuracySelected.length === 0) {
      if (!isRMSEDisabled) {
        setAccuracySelected(['RMSE']);
      }
    }
  }, [accuracySelected.length, isRMSEDisabled]);

  const { t: translate } = useTranslation();

  const {
    data: dataErrorPercentageRMSE,
    isLoading: isLoadingErrorPercentageRMSE,
    isFetching: isFetchingErrorPercentageRMSE,
    isError: isErrorErrorPercentageRMSE,
  } = useQuery(
    [
      `${type} percentage error`,
      project.id,
      project.selectedY?.id,
      modelId,
      'RMSE',
    ],
    async () => {
      const { data } = await api.get<ErrorPercentage>(
        `/projects/${project.id}/${project.selectedY?.id}/models/${type}/${modelId}/accuracy/RMSE`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  useEffect(() => {
    if (isRMSEDisabled || isErrorErrorPercentageRMSE) {
      setAccuracySelected((state) =>
        state.filter((accuracy) => accuracy !== 'RMSE'),
      );
    }
  }, [isErrorErrorPercentageRMSE, isRMSEDisabled]);

  const serie: HChartsSeries = useMemo(
    () => ({
      type: 'line',
      name: 'RMSE',
      color: getChartColor(0),
      showInLegend: true,
      data: dataErrorPercentageRMSE?.x.map((x, xIndex) => ({
        x,
        y: dataErrorPercentageRMSE.y[xIndex],

        custom: {
          start: format(
            new Date(`${dataErrorPercentageRMSE.data[xIndex][0]}T00:00`),
            project.selectedY?.info?.frequency === 'annual'
              ? 'yyyy'
              : 'dd/MM/yyyy',
          ),
          end: format(
            new Date(`${dataErrorPercentageRMSE.data[xIndex][1]}T00:00`),
            project.selectedY?.info?.frequency === 'annual'
              ? 'yyyy'
              : 'dd/MM/yyyy',
          ),
          value: formatCompactNotation(dataErrorPercentageRMSE.y[xIndex]),
        },
      })),
      marker: {
        symbol: 'Circle',
      },
    }),
    [dataErrorPercentageRMSE, project.selectedY?.info?.frequency],
  );

  const options: HChartsOptions = {
    chart: {
      height: 300,
    },
    xAxis: {
      type: 'linear',
      minTickInterval: undefined,
      title: {
        text: translate('crossValidationXAXISTitleWindow'),
      },
    },
    tooltip: {
      pointFormat:
        `<tr><td><b>${translate('start')}:</b> </td>` +
        `<td style="text-align: right">{point.custom.start}</td></tr>` +
        `<tr><td><b>${translate('end')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.end}</td></tr>' +
        `<tr><td><b>${translate('value')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.value}</td></tr>',
    },
  };

  return (
    <div
      className="containerLinear"
      data-testid="level-error-metric-container"
      data-cy="level-error-metric-container"
    >
      <Tooltip id="level-error-metric-tooltip" className="customTooltipTheme" />

      <Card
        textCard={translate('crossValidationLevelErrorMetric')}
        textDescription={translate(
          'crossValidationLevelErrorMetricDescription',
        )}
      />

      <Options>
        <span>{translate('crossValidationMetrics')}</span>
        <div>
          <div
            data-tooltip-id="level-error-metric-tooltip"
            data-tooltip-html={
              isRMSEDisabled || isErrorErrorPercentageRMSE
                ? translate('notAvailable')
                : undefined
            }
          >
            <CheckBox
              label="RMSE"
              disabled
              checked={accuracySelected.includes('RMSE')}
              data-cy="level-error-metric-checkbox-rmse"
              data-testid="level-error-metric-checkbox-rmse"
            />
          </div>
        </div>
      </Options>

      {isErrorErrorPercentageRMSE ||
      project.projectError ||
      isErrorModelInfo ? (
        <ContainerMaintenance
          content="chart"
          data-testid="level-error-metric-error"
          data-cy="level-error-metric-error"
        />
      ) : isLoadingModelInfo ||
        isLoadingErrorPercentageRMSE ||
        isFetchingErrorPercentageRMSE ||
        !dataErrorPercentageRMSE ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton
          data-testid="level-error-metric-loading"
          data-cy="level-error-metric-loading"
        />
      ) : (
        <HCharts
          series={[serie]}
          options={options}
          dataCy="level-error-metric-chart"
        />
      )}
    </div>
  );
};
