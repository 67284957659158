import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Tooltip } from 'react-tooltip';
import { Pagination } from 'src/components/Pagination';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import {
  CardBody,
  CardContainer,
  CardContent,
} from 'src/components/ProjectCard/styles';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

import { WorkspaceCard } from '../../WorkspaceCard';
import { ActionsContainer, DivSearchBox, List } from '../styles';
import { Workspace } from './types';
import { WorkspaceContainer, WorkspaceHead } from '../../WorkspaceCard/styles';

const QUANTITY_ITEMS_PER_PAGE = 6;

interface ListWorkspacesProps {
  canUserCreateWorkspace: boolean | null;
}

export const ListWorkspaces: React.FC<ListWorkspacesProps> = ({
  canUserCreateWorkspace,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const [page, setPage] = useState(1);
  const [workspaceTotal, setWorkspaceTotal] = useState<number>();

  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  const userHasPermissionCreateWorkspace = user.permissions.find(
    (permission) => permission === 'create:workspaces',
  );

  const {
    data: workspaceData,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['workspaces', page],
    async () => {
      const { data } = await apiWorkspace.get<Workspace>(
        `/workspaces?skip=${
          (page - 1) * QUANTITY_ITEMS_PER_PAGE
        }&limit=${QUANTITY_ITEMS_PER_PAGE}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60,
    },
  );

  useEffect(() => {
    if (workspaceData) {
      const total = workspaceData.total;

      setWorkspaceTotal(total);

      if (!!total && Math.ceil(total / 6) < page) {
        setPage(Math.ceil(total / 6));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  return (
    <>
      <DivSearchBox>
        <Tooltip id="workspace-button-create" className="customTooltipTheme" />
        <ActionsContainer>
          {userHasPermissionCreateWorkspace && (
            <div
              data-tooltip-id="workspace-button-create"
              data-tooltip-html={
                canUserCreateWorkspace === false
                  ? translate('workspaceCreateNewProjectToStart')
                  : undefined
              }
            >
              <Button
                buttonType="primary"
                onClick={() => navigate('/models/workspaces/new')}
                data-cy="button-create-workspace"
                data-testid="button-create-workspace"
                disabled={!canUserCreateWorkspace}
              >
                <p>{translate('myProjectsCreateWorkspace')}</p>
              </Button>
            </div>
          )}
        </ActionsContainer>
        <Tooltip
          id="models-my-projects-create-project"
          className="customTooltipTheme"
        />
      </DivSearchBox>

      {isError ? (
        <ContainerMaintenance
          content="projects"
          text={translate('fetchWorkspacesError')}
          data-testid="workspace-error"
        />
      ) : isLoading || isFetching || !workspaceData ? (
        // eslint-disable-next-line react/jsx-indent
        <>
          <List>
            {Array.from({ length: 6 }, (_, number) => number).map((number) => (
              <CardContainer
                key={`loading-${number}`}
                data-testid={`loading-workspace-${number}`}
              >
                <WorkspaceContainer>
                  <CardContent>
                    <WorkspaceHead>
                      <div>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '4rem',
                            height: '4rem',
                          }}
                        />

                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '80%',
                            height: '1.688rem',
                          }}
                        />
                      </div>
                    </WorkspaceHead>

                    <CardBody className="workspace-body">
                      <span className="workspace-description-title">
                        {translate('description')}
                      </span>
                      <ContainerSkeleton
                        withLoading={false}
                        style={{
                          width: '90%',
                          height: '1.313rem',
                          marginTop: '0.5rem',
                        }}
                      />
                      <ContainerSkeleton
                        withLoading={false}
                        style={{
                          width: '45%',
                          height: '1.313rem',
                          marginTop: '0.5rem',
                        }}
                      />

                      <span>{translate('projectHeadLastUpdated')}</span>
                      <ContainerSkeleton
                        withLoading={false}
                        style={{
                          width: '65%',
                          height: '1.313rem',
                          marginTop: '0.5rem',
                        }}
                      />
                    </CardBody>
                  </CardContent>
                </WorkspaceContainer>
              </CardContainer>
            ))}
          </List>
          {workspaceTotal && (
            <Pagination
              name="workspaces"
              page={page}
              setPage={setPage}
              total={workspaceTotal}
              quantityItemsPerPage={QUANTITY_ITEMS_PER_PAGE}
            />
          )}
        </>
      ) : workspaceData.total > 0 ? (
        <>
          <List>
            {workspaceData.records.map((card) => (
              <WorkspaceCard card={card} key={card.id} />
            ))}
          </List>

          <Pagination
            name="workspaces"
            page={page}
            setPage={setPage}
            total={workspaceData.total}
            quantityItemsPerPage={QUANTITY_ITEMS_PER_PAGE}
          />
        </>
      ) : (
        <ContainerMaintenance
          content="projects"
          text={translate('workspaceErrorNoWorkspaceFound')}
          data-testid="container-without-workspaces"
          style={{ height: '25rem' }}
        />
      )}
    </>
  );
};
