import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MagnifyingGlass, Share } from 'phosphor-react';
import { format } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Pagination } from 'src/components/Pagination';
import { RadioButton } from 'src/components/RadioButton';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { Input } from 'src/components/Input';
import { transformUppercaseFirstLetter } from 'src/utils/transformUppercaseFirstLetter';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { escapeRegExp } from 'src/utils/escapeRegExp';
import apiWorkspace from 'src/models/service/apiWorkspace';
import { WorkspaceY } from 'src/models/redux/reducers/Workspace';
import { Button } from 'src/components/Button';
import { FailedModal } from 'src/components/Modal/Failed';

import {
  Legend,
  ContentTable,
  Td,
  ProjectionTdFixed,
  OptionsTable,
  ThYearly,
  ThMonthly,
  TrComplete,
  FooterTable,
  ActionsContainer,
  WorkspaceProjectionsContainer,
} from './styles';

interface Dates {
  value: string;
  isProjection: boolean;
}

interface Result {
  date: string;
  value: string;
  isProjection: boolean;
}

interface Serie {
  name: string;
  results: Result[];
}

interface ProjectionsAnnualProps {
  hasData: boolean;
  series: Serie[];
}

interface Projections {
  historical: {
    date: string[];
    value: number[];
  };
  forecast: {
    date: string[];
    value: number[];
  };
}

interface ExtractYearFromDates extends Dates {
  numberOfMonthsThisYear: number;
}

interface ErrorProps {
  message: string;
  quantityLetters: number;
}

interface AllDatesProps {
  original: {
    level: Dates[];
    variation: Dates[];
  };
  yearly: {
    level: Dates[];
    variation: Dates[];
  };
}

interface IResponseHeadersDowload {
  'content-disposition'?: string;
}

interface Error {
  title: string;
  description: string;
}

type Frequency = 'original' | 'yearly';

type Transformation = 'level' | 'variation';

const QUANTITY_ITEM_PER_PAGE = 10;

export const WorkspaceProjections: React.FC = () => {
  const [frequency, setFrequency] = useState<Frequency>('yearly');
  const [transformation, setTransformation] = useState<Transformation>('level');
  const [page, setPage] = useState<number>(1);

  const [searchValue, setSearchValue] = useState('');
  const [searchTimer, setSearchTimer] = useState(250);
  const [searchError, setSearchError] = useState<ErrorProps>();
  const [searchAllowed, setSearchAllowed] = useState(false);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [lastSearch, setLastSearch] = useState('');

  const [dependentVariables, setDependentVariables] = useState<WorkspaceY[]>(
    [],
  );

  const [loadingFirstTime, setLoadingFirstTime] = useState(true);

  const [allDates, setAllDates] = useState<AllDatesProps>({
    original: {
      level: [],
      variation: [],
    },
    yearly: {
      level: [],
      variation: [],
    },
  });

  const [loadingDownload, setLoadingDownload] = useState(false);
  const [downloadError, setDownloadError] = useState<Error>({} as Error);

  const {
    auth: { user },
    workspace: {
      id,
      name: workspaceName,
      releaseSelected,
      frequency: workspaceFrequency,
    },
  } = useSelector((state: RootState) => state);

  const [projections, setProjections] = useState<ProjectionsAnnualProps>({
    hasData: false,
    series: [],
  });

  const { t: translate } = useTranslation();

  const loadData = useCallback(
    async (index: number): Promise<Projections> => {
      let dataAux: Projections;

      const key = [
        'workspace overview',
        'projections',
        id,
        releaseSelected?.id,
        dependentVariables[index].y_label,
        dependentVariables[index].model_id,
        frequency,
        transformation,
      ];

      const contentQuery = queryClient.getQueryData<Projections>(key);

      try {
        if (contentQuery) {
          dataAux = contentQuery;
        } else {
          dataAux = await queryClient.fetchQuery<Projections>(
            key,
            async () => {
              const { data } = await apiWorkspace.get<Projections>(
                `/workspaces/${id}/releases/${releaseSelected?.id}/ys/${dependentVariables[index].y_label}/models/${dependentVariables[index].model_id}/data/variables/${dependentVariables[index].y_label}?frequency=${frequency}&transformation=${transformation}`,
              );

              return {
                historical: {
                  date: data.historical.date,
                  value: data.historical?.value,
                },
                forecast: {
                  date: data.forecast?.date ?? [],
                  value: data.forecast?.value ?? [],
                },
              };
            },
            {
              staleTime: 1000 * 60 * 20,
            },
          );
        }
      } catch (err) {
        return {
          historical: {
            date: [],
            value: [],
          },
          forecast: {
            date: [],
            value: [],
          },
        };
      }
      return dataAux;
    },
    [dependentVariables, frequency, id, releaseSelected, transformation],
  );

  const sortByDate = useCallback(
    (dates: Dates[]) => {
      if (frequency === 'yearly') {
        return dates.sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
      }

      return dates.sort((a, b) => {
        if (new Date(`${a.value}`) < new Date(`${b.value}`)) {
          return -1;
        }
        if (new Date(`${a.value}`) > new Date(`${b.value}`)) {
          return 1;
        }
        return 0;
      });
    },
    [frequency],
  );

  useEffect(() => {
    let canLoad = true;

    async function load() {
      const initialIndex = (page - 1) * QUANTITY_ITEM_PER_PAGE;
      const finalIndex =
        page * QUANTITY_ITEM_PER_PAGE < dependentVariables.length
          ? page * QUANTITY_ITEM_PER_PAGE
          : dependentVariables.length;

      if (dependentVariables.length) setLoadingFirstTime(false);

      setProjections({ hasData: false, series: [] });

      for (let i = initialIndex; i < finalIndex; i++) {
        const dataAux = await loadData(i);

        if (!canLoad) {
          return;
        }

        setAllDates((state) => {
          let dates: Dates[] = [];

          dates = [...state[frequency][transformation]];

          dataAux.historical.date.forEach((date) => {
            if (!dates.some((dateAux) => dateAux.value === date)) {
              dates.push({
                value: date,
                isProjection: false,
              });
            }
          });

          dataAux.forecast.date.forEach((date) => {
            if (!dates.some((dateAux) => dateAux.value === date)) {
              dates.push({
                value: date,
                isProjection: true,
              });
            }
          });

          return {
            ...state,
            [frequency]: {
              ...state[frequency],
              [transformation]: sortByDate(dates),
            },
          };
        });

        setProjections((state) => {
          let hasData = state.hasData;

          const serie: Serie = {
            name: dependentVariables[i].y_label,
            results: [],
          };

          dataAux.historical.value.forEach((y, index) => {
            hasData = true;
            serie.results.push({
              isProjection: false,
              value: formatCompactNotation(y),
              date: dataAux.historical.date[index] ?? '--',
            });
          });

          dataAux.forecast.value.forEach((y, index) => {
            hasData = true;
            serie.results.push({
              isProjection: true,
              value: formatCompactNotation(y),
              date: dataAux.forecast.date[index] ?? '--',
            });
          });

          return {
            hasData,
            series: [...state.series, serie],
          };
        });
      }
    }

    load();

    return () => {
      canLoad = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    frequency,
    dependentVariables,
    dependentVariables.length,
    transformation,
    page,
  ]);

  function handleSelectFrequency(frequencyClicked: Frequency): void {
    setFrequency(frequencyClicked);

    setProjections({
      hasData: false,
      series: [],
    });
  }

  function handleSelectTransformation(
    transformationClicked: Transformation,
  ): void {
    setTransformation(transformationClicked);

    setProjections({
      hasData: false,
      series: [],
    });
  }

  function extractYears(dates: Dates[]): ExtractYearFromDates[] {
    const onlyYears: Dates[] = dates.map((date) => ({
      isProjection: date.isProjection,
      value: format(new Date(`${date.value}`), 'yyyy'),
    }));

    const yearsWithoutRepeating: ExtractYearFromDates[] = [];

    onlyYears.forEach((year) => {
      const searchIndex = yearsWithoutRepeating.findIndex(
        (yearAux) => yearAux.value === year.value,
      );

      if (searchIndex === -1) {
        yearsWithoutRepeating.push({
          value: year.value,
          isProjection: year.isProjection,
          numberOfMonthsThisYear: 1,
        });
      } else {
        yearsWithoutRepeating.splice(searchIndex, 1, {
          isProjection: year.isProjection,
          value: year.value,
          numberOfMonthsThisYear:
            yearsWithoutRepeating[searchIndex].numberOfMonthsThisYear + 1,
        });
      }
    });
    return yearsWithoutRepeating;
  }

  const adjustSerieValueAccordingToDates = useCallback(
    (serieValues: Result[]): Result[] => {
      const results: Result[] = [];

      allDates[frequency][transformation].forEach((date) => {
        const index = serieValues.findIndex(
          (serieValue) => serieValue.date === date.value,
        );
        if (index !== -1) {
          results.push(serieValues[index]);
        } else {
          results.push({
            date: date.value,
            isProjection:
              date.isProjection === false && results.length > 0
                ? results[results.length - 1].isProjection
                : date.isProjection,
            value: '--',
          });
        }
      });

      return results;
    },
    [allDates, frequency, transformation],
  );

  function handleChangePage(pageAux: number): void {
    setPage(pageAux);

    setProjections({
      hasData: false,
      series: [],
    });
  }

  const numberOfSeriesWillLoad = useMemo(
    () =>
      page * QUANTITY_ITEM_PER_PAGE >= dependentVariables.length
        ? dependentVariables.length - (page - 1) * QUANTITY_ITEM_PER_PAGE
        : QUANTITY_ITEM_PER_PAGE,
    [page, dependentVariables.length],
  );

  function handleSearchDependentVariable(value: string) {
    setSearchValue(value);

    if (value.length > 50) {
      setSearchError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });
      return;
    }

    setSearchError({
      message: '',
      quantityLetters: 0,
    });

    if (value !== searchValue) {
      setSearchTimer(250);
      setTimeOutActive(true);
    }
  }

  useEffect(() => {
    if (timeOutActive) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 250);
        } else {
          setTimeOutActive(false);
        }
      }, 250);
    } else {
      searchTimer === 0 && setSearchAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  useEffect(() => {
    if (!searchError?.message && searchAllowed) {
      setPage(1);

      setProjections({
        hasData: false,
        series: [],
      });

      const regex = new RegExp(escapeRegExp(searchValue), 'i');

      const updatedVariables = searchValue.length
        ? releaseSelected?.data.ys.filter(({ y_label }) => regex.test(y_label))
        : releaseSelected?.data.ys;

      setDependentVariables(updatedVariables || []);

      setSearchAllowed(false);
      setTimeOutActive(false);
      setSearchTimer(250);
      setLastSearch(searchValue);
    }
  }, [searchError, searchAllowed, searchValue, releaseSelected?.data.ys]);

  useEffect(() => {
    setDependentVariables(releaseSelected?.data.ys || []);
  }, [releaseSelected?.data.ys]);

  async function handleDownload() {
    setLoadingDownload(true);
    setDownloadError({} as Error);
    try {
      const { data, headers } = await apiWorkspace.get(
        `/workspaces/${id}/releases/${releaseSelected?.id}/data/export`,
        {
          responseType: 'blob',
        },
      );

      if (data) {
        const fileURL = window.URL.createObjectURL(
          new Blob([data], { type: 'text/xlsx' }),
        );

        const contentDisposition = (headers as IResponseHeadersDowload)[
          'content-disposition'
        ];

        const link = document.createElement('a');

        const name =
          contentDisposition?.slice(
            contentDisposition.indexOf('filename=') + 10,
            contentDisposition.length - 1,
          ) ?? `${workspaceName}.xlsx`;

        if (link.download !== undefined) {
          link.setAttribute('href', fileURL);
          link.setAttribute('download', name);
          link.setAttribute('data-testid', 'download-start');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
        }
      }
    } catch (err) {
      setDownloadError({
        title: translate('requestFailed'),
        description: translate('workspaceOverviewDownloadError'),
      });
    }
    setLoadingDownload(false);
  }

  const loading =
    page * QUANTITY_ITEM_PER_PAGE >= dependentVariables.length
      ? (page - 1) * QUANTITY_ITEM_PER_PAGE + projections?.series.length !==
        dependentVariables.length
      : projections?.series.length !== QUANTITY_ITEM_PER_PAGE;

  const showLoading =
    (loading && !projections.hasData) ||
    (allDates[frequency][transformation].length &&
      !projections.hasData &&
      !searchValue) ||
    loadingFirstTime;

  return (
    <>
      <WorkspaceProjectionsContainer className="containerLinear">
        <Card
          textCard={translate('workspaceProjectionsTitle')}
          textDescription={translate('workspaceProjectionsDescriptions')}
        />

        <ActionsContainer>
          <OptionsTable>
            <div>
              <span>{translate('workspaceProjectionsFrequency')}</span>

              <div>
                <Tooltip
                  id="radio-button-tooltip"
                  className="customTooltipTheme"
                />
                <RadioButton
                  label={translate('workspaceProjectionsOriginal')}
                  dataCy="projections-radio-button-original"
                  onChange={() => handleSelectFrequency('original')}
                  checked={frequency === 'original'}
                  dataTip={
                    !workspaceFrequency
                      ? translate('loading')
                      : workspaceFrequency !== 'monthly'
                      ? translate('workspaceProjectionsOnlyForMonthlyFrequency')
                      : undefined
                  }
                  disabled={workspaceFrequency !== 'monthly'}
                />
                <RadioButton
                  label={translate('workspaceProjectionsAnnual')}
                  dataCy="projections-radio-button-yearly"
                  onChange={() => handleSelectFrequency('yearly')}
                  checked={frequency === 'yearly'}
                />
              </div>
            </div>

            <div>
              <span>{translate('workspaceProjectionsTransformation')}</span>
              <div>
                <RadioButton
                  label={translate('workspaceProjectionsLevel')}
                  dataCy="projections-radio-button-level"
                  onChange={() => handleSelectTransformation('level')}
                  checked={transformation === 'level'}
                />
                <RadioButton
                  label={translate('workspaceProjectionsVariation')}
                  dataCy="projections-radio-button-variation"
                  onChange={() => handleSelectTransformation('variation')}
                  checked={transformation === 'variation'}
                />
              </div>
            </div>
          </OptionsTable>

          <Input
            icon={<MagnifyingGlass size="1.25rem" />}
            testid="search-dependent-variables-projection"
            placeholder={translate(
              'workspaceSearchDependentVariablePlaceholder',
            )}
            onChange={(event) => {
              handleSearchDependentVariable(event.target.value);
            }}
            error={
              searchError?.message &&
              translate(searchError.message).replace(
                'XX',
                String(searchError.quantityLetters),
              )
            }
          />
        </ActionsContainer>

        {showLoading ? (
          <ContainerSkeleton data-testid="projections-loading" />
        ) : !projections.hasData &&
          searchValue.length >= 1 &&
          !dependentVariables.length ? (
          // eslint-disable-next-line react/jsx-indent
          <ContainerMaintenance
            content="projections"
            text={`${translate(
              'workspaceSearchDependentVariableNotFound',
            )} "${lastSearch}".`}
            data-testid="projection-variable-not-found"
          />
        ) : !projections.hasData &&
          !allDates[frequency][transformation].length ? (
          // eslint-disable-next-line react/jsx-indent
          <ContainerMaintenance
            content={translate('workspaceProjectionsTable')}
            data-testid="projections-container-maintenance"
          />
        ) : (
          <>
            <ContentTable
              hasMonths={frequency === 'original'}
              data-testid="projections-content-table"
            >
              <table>
                <thead>
                  <tr>
                    <ThYearly
                      isProjection={false}
                      hasMonths={frequency === 'original'}
                    >
                      {frequency === 'yearly'
                        ? translate('workspaceOverviewFirstColumn')
                        : ''}
                    </ThYearly>

                    {frequency === 'yearly'
                      ? // eslint-disable-next-line react/jsx-indent-props
                        allDates[frequency][transformation].map(
                          (date, index) => (
                            <ThYearly
                              isProjection={false}
                              hasMonths={false}
                              key={`th-annual-${
                                date.value
                              }-${index.toString()}`}
                            >
                              {new Date(date.value).getFullYear()}
                            </ThYearly>
                          ),
                        )
                      : // eslint-disable-next-line react/jsx-indent-props
                        extractYears(
                          allDates[frequency][transformation] ?? [],
                        ).map((date, index) => (
                          <ThYearly
                            isProjection={false}
                            hasMonths
                            colSpan={date.numberOfMonthsThisYear}
                            key={`th-annual-${date.value}-${index.toString()}`}
                          >
                            {date.value}
                          </ThYearly>
                        ))}
                  </tr>
                  {frequency === 'original' && (
                    <tr>
                      <ThMonthly isProjection={false} separateYear={false}>
                        {translate('workspaceOverviewFirstColumn')}
                      </ThMonthly>
                      {allDates[frequency][transformation].map((date) => (
                        <ThMonthly
                          isProjection={date.isProjection}
                          key={`th-${date.value}`}
                          separateYear={
                            format(new Date(`${date.value}`), 'MMM') === 'Dec'
                          }
                        >
                          <div>
                            {transformUppercaseFirstLetter(
                              format(new Date(`${date.value}`), 'MMM', {
                                locale: user.language === 'en-us' ? enUS : ptBR,
                              }),
                            )}
                          </div>
                        </ThMonthly>
                      ))}
                    </tr>
                  )}
                </thead>
                <tbody>
                  <>
                    {projections?.series.map((serie) => (
                      <tr key={`td-${serie.name}`}>
                        <ProjectionTdFixed
                          data-cy={`projections-${serie.name
                            .toLowerCase()
                            .replaceAll(' ', '-')}-variable`}
                          data-testid={`projections-${serie.name
                            .toLowerCase()
                            .replaceAll(' ', '-')}-variable`}
                        >
                          {serie.name}
                        </ProjectionTdFixed>
                        {adjustSerieValueAccordingToDates(serie.results).map(
                          (result, index) => (
                            <Td
                              key={`td-${serie.name}-${
                                result.value
                              }-${index.toString()}`}
                              isProjection={result.isProjection}
                            >
                              <div>
                                <span>{result.value}</span>
                              </div>
                            </Td>
                          ),
                        )}
                      </tr>
                    ))}

                    {Array.from(
                      {
                        length:
                          numberOfSeriesWillLoad - projections.series.length,
                      },
                      (_, index) => (
                        <tr
                          key={`tr-loading-${index.toString()}`}
                          data-testid={`tr-loading-${index.toString()}`}
                        >
                          {Array.from(
                            {
                              length:
                                allDates[frequency][transformation].length + 1,
                            },
                            (__, indexAux) => (
                              <Td
                                key={`td-loading-${index.toString()}-${indexAux.toString()}`}
                                isProjection={false}
                              >
                                <ContainerSkeleton
                                  withLoading={false}
                                  style={{
                                    height: '28px',
                                  }}
                                />
                              </Td>
                            ),
                          )}
                        </tr>
                      ),
                    )}

                    {Array.from(
                      {
                        length: QUANTITY_ITEM_PER_PAGE - numberOfSeriesWillLoad,
                      },
                      (_, index) => (
                        <TrComplete key={`tr-complete-${index.toString()}`} />
                      ),
                    )}
                  </>
                </tbody>
              </table>
            </ContentTable>

            <Legend>
              <div>
                <div />
                <span>{translate('workspaceProjectionsHistorical')}</span>
              </div>

              <div>
                <div />
                <span>{translate('workspaceProjectionsForecast')}</span>
              </div>
            </Legend>

            <FooterTable>
              <Button
                buttonType="secondary"
                icon={<Share size="1.125rem" weight="bold" />}
                onClick={handleDownload}
                loading={loadingDownload}
                disabled={loadingDownload}
                data-testid="button-export"
                data-cy="button-export"
              >
                {translate('export')}
              </Button>

              <Pagination
                name={translate('workspaceProjectionsVariables')}
                page={page}
                quantityItemsPerPage={QUANTITY_ITEM_PER_PAGE}
                total={dependentVariables.length}
                setPage={handleChangePage}
              />
            </FooterTable>
          </>
        )}
      </WorkspaceProjectionsContainer>

      {downloadError.title && (
        <FailedModal
          errorInfo={{
            title: downloadError.title,
            description: downloadError.description,
          }}
          setVisible={() => setDownloadError({} as Error)}
          visible
        />
      )}
    </>
  );
};
