import React from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { arrayIcon, checkIconURL } from 'src/utils/handleProjectIcon';

import { GroupSelectedProps } from '../SendToGroups';
import { CardBody, CardHead, Container, Content } from './styles';

interface GroupCardProps {
  id: string;
  iconUrl: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  predefined: boolean;
  groupSelected?: GroupSelectedProps;
  setGroupSelected?: (group: GroupSelectedProps | undefined) => void;
}

export const GroupCard: React.FC<GroupCardProps> = ({
  id,
  iconUrl,
  name,
  createdAt,
  updatedAt,
  predefined,
  groupSelected,
  setGroupSelected,
}) => {
  const { t: translate } = useTranslation();
  const handleClickSelectedGroup = () => {
    if (id && setGroupSelected) {
      if (id === groupSelected?.id) {
        setGroupSelected(undefined);
      } else {
        setGroupSelected({ id, name, is4ifavoritesGroup: predefined });
      }
    }
  };

  return (
    <>
      <Container
        key={name}
        data-testid={`group-card-${name.replaceAll(' ', '-').toLowerCase()}`}
      >
        <Content
          data-testid={`group-access-${name
            .replaceAll(' ', '-')
            .toLowerCase()}`}
          onClick={handleClickSelectedGroup}
          active={groupSelected?.id === id}
        >
          <img
            data-testid={`group-img-${name.toLowerCase()}`}
            data-cy={`group-img-${name.replaceAll(' ', '-').toLowerCase()}`}
            src={
              predefined
                ? 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png'
                : checkIconURL(iconUrl)
                ? iconUrl
                : arrayIcon[0]
            }
            alt="icon"
          />
          <CardHead active={groupSelected?.id === id} />
          <CardBody active={groupSelected?.id === id}>
            <p
              data-testid={`group-name-${name}`}
              data-cy={`group-name-${name.replaceAll(' ', '-').toLowerCase()}`}
            >
              {name}
            </p>
            <span>{translate('createdAt')}</span>
            <p
              data-testid={`group-created-at-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              data-cy={`group-created-at-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            >
              {format(new Date(createdAt), 'MM/dd/yyyy, HH:mm')}
            </p>
            <span>{translate('lastUpdated')}</span>
            <p
              data-testid={`group-last-update-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              data-cy={`group-last-update-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            >
              {format(new Date(updatedAt), 'MM/dd/yyyy, HH:mm')}
            </p>
          </CardBody>
        </Content>
      </Container>
    </>
  );
};
