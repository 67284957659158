import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 4.625rem);

  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  .workspace-tooltip {
    border-radius: 8px !important;
    background: #fff !important;

    padding: 0.5rem 1rem !important;

    font-size: 0.875rem !important;
    font-weight: 500 !important;
    line-height: 1.375rem !important;
    color: ${({ theme }) => theme.colors.gray5} !important;
  }

  #workspace-table {
    max-width: unset !important;
  }

  @media (max-height: 1028px) {
    height: calc(100vh + 21.5rem);
  }

  @media (max-height: 590px) {
    height: calc(100vh + 30rem);
  }

  @media (max-height: 450px) {
    height: calc(100vh + 40rem);
  }

  @media (max-height: 330px) {
    height: calc(100vh + 50rem);
  }
`;

export const VariablesContainer = styled.div`
  width: 80% !important;
  height: calc(100% - 1.5rem);

  display: flex;
  flex-direction: column;

  .checkbox-select-all {
    padding-left: 1.125rem;

    > span {
      bottom: 0.125rem;
    }
  }
`;

export const TableContainer = styled.div`
  overflow-y: auto;
  overflow-x: auto;

  width: 100%;
  height: 100%;

  padding-right: 0.5rem;

  table {
    margin: 0;
    border-spacing: 0rem;

    th {
      position: sticky;
      top: 0;

      background: ${({ theme }) => theme.colors.white};
      z-index: 1;

      opacity: 1;

      color: ${({ theme }) => `${theme.colors.gray7}CC`} !important;
    }

    td {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }

  ::-webkit-scrollbar-track {
    margin-top: 2.5rem;
  }

  ::-webkit-scrollbar {
    width: 0.4rem;
  }

  .rename-variable-column {
    min-width: 12rem;

    width: 23rem;

    @media (max-width: 1366px) {
      width: 15rem;
    }
  }
`;

export const VariablesEmptyContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  flex: 1;

  padding: 0 10rem;

  .plus-button {
    height: fit-content;

    margin-top: -2rem;
    padding: 1rem;

    border-radius: 100%;

    z-index: 1001;

    cursor: default;

    svg {
      width: 2rem;
      height: 2rem;

      margin: 0px;
    }
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray6};

    margin-top: 2rem;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};

    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
`;

export const ImageContainer = styled.div`
  width: 16.625rem;
  height: 17.0625rem;

  position: relative;

  > div,
  > img {
    width: 100%;
    height: 100%;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;

    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 53.85%);
  }
`;

export const InfoContainer = styled.div`
  width: 20% !important;

  display: flex;
  flex-direction: column;

  @media (max-width: 1366px) {
    width: 26% !important;
  }
`;

export const FormContainer = styled.form`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    > div:nth-child(3) {
      height: 22rem;

      @media (max-height: 768px) {
        height: 25rem;
      }
    }
  }
`;

export const IconContainer = styled.div`
  width: 8.25rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.5rem;

  color: ${({ theme }) => theme.colors.gray5};
`;

export const IconButton = styled.button`
  width: 8.25rem;
  height: 8.25rem;

  border-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.colors.gray2}`};
  background-color: ${({ theme }) => theme.colors.gray3};

  transition: all 0.2s;

  img {
    width: 100%;
    height: 100%;

    border-radius: 8px;

    &:hover {
      border-radius: 7px;
    }
  }

  div {
    width: 100%;
    height: 100%;

    border-radius: 7px;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.gray3};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ProjectContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  margin-bottom: 1.5rem;

  > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;

    > div {
      width: 15rem;
    }

    > button {
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        color: ${({ theme }) => theme.colors.primaryDark};
      }
    }
  }

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: end;

    p {
      margin-right: 0 !important;
    }
  }
`;

const ErrorAppearanceAnimation = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity:1;
  }
`;

type ErrorContainerProps = {
  visible: boolean;
};

export const ErrorContainer = styled.div<ErrorContainerProps>`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  width: 22rem;

  padding: 1rem;

  border-radius: 5px;

  background: ${({ theme }) => `${theme.colors.red2}14`};

  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.red2};

  ${({ visible }) =>
    visible &&
    css`
      animation: ${ErrorAppearanceAnimation} 0.7s;
    `}

  @media (max-width: 1366px) {
    width: 17rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: end;
  gap: 1.5rem;

  > button {
    height: fit-content;

    > div {
      > div {
        margin: 0px -0.5rem 0px 0.5rem;
      }
    }
  }

  > button:last-of-type {
    padding: 0.75rem 1.45rem;
  }
`;
