import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setDefaultParams } from 'src/feature-store/service/api';
import { setDefaultHeaderLanguage } from 'src/models/service/api';

export type Language = 'en-us' | 'pt-br';
export const languages: string[] = ['en-us', 'pt-br'];

type PayloadDataUser = {
  id: string | number | null;
  email: string | null;
  roles: string[];
  permissions: string[];
  is4i: boolean;
  isOCB: boolean;
  isEditor: boolean;
  isFaaS: boolean;
  isAdmin: boolean;
  isFeatureStore: boolean;
  isFsAdmin: boolean;
  isClaaS: boolean;
  isSupport: boolean;
  isFreemium: boolean;
  managerClientId: string | null;
};

type User = {
  language: Language;
} & PayloadDataUser;

export type AuthState = {
  user: User;
  isAuthenticated: boolean;
};

const getLanguageFromLocalStorage = (): Language => {
  const lng = localStorage.getItem('i18nextLng');

  if (
    lng?.toLocaleLowerCase() !== 'pt-br' &&
    lng?.toLocaleLowerCase() !== 'en-us'
  ) {
    const lngDetected = navigator.language;

    if (languages.includes(lngDetected.toLowerCase())) {
      setDefaultParams('language', lngDetected.toLowerCase());
      return lngDetected.toLowerCase() as Language;
    }
    setDefaultParams('language', 'en-us');
    return 'en-us';
  }
  setDefaultParams('language', lng.toLowerCase());
  setDefaultHeaderLanguage(lng.toLowerCase());
  return lng.toLowerCase() as Language;
};

const initialState: AuthState = {
  user: {
    id: null,
    email: null,
    language: getLanguageFromLocalStorage(),
    roles: [],
    permissions: [],
    is4i: false,
    isOCB: false,
    isEditor: false,
    isFaaS: false,
    isAdmin: false,
    isFeatureStore: false,
    isFsAdmin: false,
    isClaaS: false,
    isSupport: false,
    isFreemium: false,
    managerClientId: null,
  },
  isAuthenticated: false,
};

export const authState = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<PayloadDataUser>) => {
      state.user.id = action.payload.id;
      state.user.email = action.payload.email;
      state.user.roles = action.payload.roles;
      state.user.permissions = action.payload.permissions;
      state.user.is4i = action.payload.is4i;
      state.user.isOCB = action.payload.isOCB;
      state.user.isFaaS = action.payload.isFaaS;
      state.user.isFeatureStore = action.payload.isFeatureStore;
      state.user.isEditor = action.payload.isEditor;
      state.user.isAdmin = action.payload.isAdmin;
      state.user.isFsAdmin = action.payload.isFsAdmin;
      state.user.isClaaS = action.payload.isClaaS;
      state.user.isSupport = action.payload.isSupport;
      state.user.isFreemium = action.payload.isFreemium;
      state.user.managerClientId = action.payload.managerClientId;
      state.isAuthenticated = true;
    },
    update: (state, action: PayloadAction<PayloadDataUser>) => {
      state.user.id = action.payload.id;
      state.user.email = action.payload.email;
      state.user.roles = action.payload.roles;
      state.user.permissions = action.payload.permissions;
      state.user.is4i = action.payload.is4i;
      state.user.isOCB = action.payload.isOCB;
      state.user.isFaaS = action.payload.isFaaS;
      state.user.isFeatureStore = action.payload.isFeatureStore;
      state.user.isEditor = action.payload.isEditor;
      state.user.isAdmin = action.payload.isAdmin;
      state.user.isFsAdmin = action.payload.isFsAdmin;
      state.user.isClaaS = action.payload.isClaaS;
      state.user.isSupport = action.payload.isSupport;
      state.user.isFreemium = action.payload.isFreemium;
      state.user.managerClientId = action.payload.managerClientId;
    },
    logout: (state) => {
      state.user.id = null;
      state.user.email = null;
      state.user.roles = [];
      state.user.permissions = [];
      state.user.is4i = false;
      state.user.isOCB = false;
      state.user.isEditor = false;
      state.user.isFaaS = false;
      state.user.isFeatureStore = false;
      state.user.isFsAdmin = false;
      state.user.isAdmin = false;
      state.user.isClaaS = false;
      state.user.isSupport = false;
      state.user.isFreemium = false;
      state.user.managerClientId = null;
      state.isAuthenticated = false;
    },
    changeLanguage: (state, action: PayloadAction<Language>) => {
      state.user.language = action.payload;
      setDefaultParams('language', action.payload);
      setDefaultHeaderLanguage(action.payload);
    },
  },
});

export const { login, logout, update, changeLanguage } = authState.actions;

export default authState.reducer;
