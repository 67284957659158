import styled from 'styled-components';

type MenuContainerProps = {
  visible: boolean;
};

export const MenuContainer = styled.div<MenuContainerProps>`
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 12.5rem;

  border-radius: 5px;
  box-shadow: 0px 16px 32px 0px rgba(45, 55, 72, 0.16);
  background: ${({ theme }) => theme.colors.white};

  display: ${({ visible }) => (visible ? 'flex' : 'none')};

  z-index: 1001;
`;

interface MenuOptionProps {
  position: 'start' | 'middle' | 'end' | 'alone';
  color?: string;
  selected?: boolean;
}

export const MenuOption = styled.button<MenuOptionProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  text-align: start;
  gap: 1rem;

  padding: 1rem;

  z-index: 1001;

  background: ${({ selected, theme }) =>
    selected ? `${theme.colors.primary}14` : theme.colors.white};

  border-radius: ${({ position }) =>
    position === 'start'
      ? '5px 5px 0 0'
      : position === 'end'
      ? '0 0 5px 5px'
      : position === 'alone' && '5px'};

  transition: all 0.2s;

  cursor: pointer !important;

  p {
    color: ${({ selected, theme }) =>
      selected ? theme.colors.primary : theme.colors.gray5} !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 150% !important;

    opacity: 1 !important;
  }

  svg {
    width: 1.25rem !important;
    height: 1.25rem !important;

    color: ${({ selected, theme, color }) =>
      selected ? color || theme.colors.primary : theme.colors.gray4} !important;

    opacity: 1 !important;
  }

  &:disabled {
    cursor: not-allowed !important;

    p,
    svg {
      opacity: 0.6 !important;
    }
  }

  &:hover {
    background: ${({ color, theme }) =>
      `${color || theme.colors.primary}14`} !important;

    p,
    svg {
      color: ${({ color, theme }) => color || theme.colors.primary} !important;
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.gray1} !important;

      p {
        color: ${({ theme }) => theme.colors.gray5} !important;
      }

      svg {
        color: ${({ theme }) => theme.colors.gray4} !important;
      }
    }
  }
`;
