import React, { useContext, useState } from 'react';

import { format } from 'date-fns';
import { DotsThreeVertical, NotePencil, Trash } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { RootState } from 'src/redux/store';
import { arrayIcon, checkIconURL } from 'src/utils/handleProjectIcon';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import { CardMenuOverlay } from 'src/components/ProjectCard/styles';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { FeatureStoreSidebarContext } from 'src/feature-store/components/Layout/Contexts/NavigationContext';

import {
  ActionDots,
  FavoriteContainer,
  FavoriteContent,
  FavoriteIcon,
  InfoContainer,
} from './styles';

interface GroupCardProps {
  id: string;
  iconUrl: string;
  name: string;
  updatedAt: string;
  predefined?: boolean;
  isLoading?: boolean;
  description: string;
}

export const GroupCard: React.FC<GroupCardProps> = ({
  id,
  iconUrl,
  name,
  updatedAt,
  predefined,
  isLoading = false,
  description,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const translateFormat = useFormatDateLanguage();
  const {
    idFavoriteViewing,
    handleDeleteFavorite,
    openDeleteFavoriteModal,
    openEditFavoriteModal,
    handleEditFavorite,
    setterIdFavoriteViewing,
  } = useContext(FeatureStoreSidebarContext);

  const [menuVisible, setMenuVisible] = useState(false);

  function handleMenuVisible() {
    if (menuVisible === false) {
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  }

  if (isLoading) {
    return (
      <FavoriteContainer isActive={false}>
        <FavoriteIcon>
          <ContainerSkeleton
            withLoading={false}
            style={{
              width: '40px',
              height: '40px',
            }}
          />
        </FavoriteIcon>

        <InfoContainer style={{ marginLeft: '0.5rem' }}>
          <ContainerSkeleton
            withLoading={false}
            style={{
              width: `${Math.random() * (180 - 100) + 60}px`,
              height: '1.5rem',
            }}
          />
          <ContainerSkeleton
            withLoading={false}
            style={{
              width: '8.125rem',
              height: '1.5rem',
            }}
          />
        </InfoContainer>
      </FavoriteContainer>
    );
  }

  return (
    <>
      <FavoriteContainer
        isActive={id === idFavoriteViewing}
        key={id}
        data-testid={`favorite-card-${name.replaceAll(' ', '-').toLowerCase()}`}
      >
        <FavoriteContent
          onClick={() => {
            navigate(`/feature-store/favorites/${id}`);
            setterIdFavoriteViewing(id);
          }}
        >
          <FavoriteIcon>
            <img
              data-testid={`favorite-img-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              src={
                predefined
                  ? 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png'
                  : checkIconURL(iconUrl)
                  ? iconUrl
                  : arrayIcon[0]
              }
              alt="icon"
            />
          </FavoriteIcon>

          <InfoContainer
            data-testid={`favorite-info-card-${name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
          >
            <h5
              data-testid={`favorite-name-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            >
              {name}
            </h5>
            <p
              data-testid={`favorite-last-update-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            >
              {format(new Date(updatedAt), `${translateFormat}, HH:mm`)}
            </p>
          </InfoContainer>
        </FavoriteContent>

        {((predefined && user.isFsAdmin) || !predefined) && (
          <>
            <ActionDots
              onClick={handleMenuVisible}
              data-testid={`button-menu-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            >
              <DotsThreeVertical size="32px" />
            </ActionDots>

            <MenuContainer visible={menuVisible} style={{ left: '1.5625rem' }}>
              {menuVisible && (
                <CardMenuOverlay
                  data-testid="menu-overlay"
                  visible={menuVisible}
                  onClick={() => setMenuVisible(false)}
                />
              )}
              <MenuOption
                position="start"
                type="button"
                data-testid={`menu-button-edit-${name
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
                data-cy={`menu-button-edit-${name
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
                onClick={() => {
                  openEditFavoriteModal(true);
                  handleEditFavorite({
                    id,
                    name,
                    icon_url: iconUrl,
                    is_predefined: !!predefined,
                    description,
                  });
                }}
              >
                <NotePencil size="1.125rem" />
                <p>{translate('edit')}</p>
              </MenuOption>
              <MenuOption
                position="end"
                type="button"
                data-testid={`menu-button-delete-${name
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
                data-cy={`menu-button-delete-${name
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
                onClick={() => {
                  handleDeleteFavorite({ group: { id, name } });
                  openDeleteFavoriteModal(true);
                  setMenuVisible(false);
                }}
              >
                <Trash size="1.125rem" />
                <p>{translate('delete')}</p>
              </MenuOption>
            </MenuContainer>
          </>
        )}
      </FavoriteContainer>
    </>
  );
};
