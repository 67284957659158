import React from 'react';

import { ArrowSquareIn } from 'phosphor-react';
import { ReactComponent as AnbimaIcon } from 'src/assets/anbima-icon.svg';
import { ReactComponent as AnbimaTitle } from 'src/assets/anbima-title.svg';

import { BigNumbersProps, DataProps } from './types';
import {
  Card,
  CardDescription,
  CardQuestion,
  Container,
  Header,
  HeaderTitle,
  ReferenceDate,
} from './styles';

export const BigNumbers: React.FC<BigNumbersProps> = ({
  setVisible,
  bigNumbersData,
  handleSendQuestion,
}) => (
  <Container className="ai-chat-container">
    <Header>
      <HeaderTitle>
        <AnbimaIcon />

        <AnbimaTitle />
      </HeaderTitle>

      <ArrowSquareIn
        className="close-bigNumbers-button"
        data-testid="close-bigNumbers-button"
        size={22}
        onClick={() => setVisible(false)}
      />
    </Header>

    <ReferenceDate>
      Data de referência: {bigNumbersData[0]?.last_update}
    </ReferenceDate>

    {bigNumbersData.map((data: DataProps) => (
      <Card
        key={`card-${data.value}`}
        data-testid={`card-${data.value}`}
        onClick={() =>
          handleSendQuestion({
            aiChatQuestion: data.question,
          })
        }
      >
        <span>{data.value}</span>
        <CardDescription>{data.description}</CardDescription>
        <hr />
        <CardQuestion>{data.question}</CardQuestion>
      </Card>
    ))}
  </Container>
);
