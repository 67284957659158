import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { getChartColor } from 'src/utils/getChartColor';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { frequencyLatestData } from 'src/utils/getLatestData';

import { ContentLatestData } from './styles';

interface ModelFitResponse {
  data_tidy: string[];
  y: number[];
  type: string;
}

interface IActualVSModelFit {
  historical: {
    date: string[];
    value: number[];
  };
  modelFit: {
    date: string[];
    value: number[];
  };
}

interface ActualVSModelFitProps {
  modelId: number;
  type:
    | 'arima'
    | 'regularized-regression'
    | 'forecast-combination'
    | 'random-forest'
    | 'elementary';
}

export const ActualVSModelFit: React.FC<ActualVSModelFitProps> = ({
  modelId,
  type,
}) => {
  const { project } = useSelector((state: RootState) => state);

  const [isLatestDataActive, setIsLatestDataActive] = useState<boolean>(true);
  const [isLatestDataDisabled, setIsLatestDataDisabled] = useState(false);

  const { t: translate } = useTranslation();

  const {
    data: actualVsModelFitData,
    isLoading: actualVsModelFitLoading,
    isError: actualVsModelFitError,
  } = useQuery<IActualVSModelFit>(
    [
      `${type} model actualVsModelFit`,
      project.id,
      project.selectedY?.id,
      modelId,
    ],
    async () => {
      const response = await api.get<ModelFitResponse[]>(
        `/projects/${project.id}/${project.selectedY?.id}/models/${type}/${modelId}/actual_model_fit`,
      );

      const historical = response.data.length >= 1 ? response.data[0] : null;
      const modelFit = response.data.length >= 2 ? response.data[1] : null;

      return {
        historical: {
          date: historical?.data_tidy || [],
          value: historical?.y || [],
        },
        modelFit: {
          date: modelFit?.data_tidy || [],
          value: modelFit?.y || [],
        },
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  useEffect(() => {
    if (actualVsModelFitError) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    if (!actualVsModelFitData || !project.selectedY) {
      return;
    }

    if (!project.selectedY?.info?.frequency) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    const historicalLength = actualVsModelFitData.historical.date.length;

    const modelFitLength = actualVsModelFitData.modelFit.date.length;

    const quantity = frequencyLatestData[project.selectedY.info.frequency];

    if (quantity > historicalLength && quantity > modelFitLength) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    setIsLatestDataDisabled(false);
  }, [actualVsModelFitData, actualVsModelFitError, project]);

  function handleActiveLatestData(value: boolean) {
    setIsLatestDataActive(value);
  }

  const getLatestData = (
    data: IActualVSModelFit | undefined,
    frequency:
      | 'daily'
      | 'weekly'
      | 'fortnightly'
      | 'monthly'
      | 'bimonthly'
      | 'quarterly'
      | 'half-year'
      | 'annual',
  ): IActualVSModelFit => {
    if (data) {
      const quantity = frequencyLatestData[frequency];

      const historicalLength = data.historical.date.length;

      const modelFitLength = data.modelFit.date.length;

      if (quantity > historicalLength && quantity > modelFitLength) {
        return data;
      }

      const historicalDate = data.historical.date.slice(
        historicalLength - quantity,
        historicalLength,
      );

      const historicalValue = data.historical.value.slice(
        historicalLength - quantity,
        historicalLength,
      );

      const initialIndexModelFit = data.modelFit.date.findIndex(
        (date) => date === historicalDate[0],
      );

      const lastIndexSeasonally = data.historical.date.findIndex(
        (date) => date === historicalDate.at(-1),
      );

      let modelFitDate = [];
      let modelFitValue = [];

      if (initialIndexModelFit !== -1 && lastIndexSeasonally !== -1) {
        modelFitDate = data.modelFit.date.slice(
          initialIndexModelFit,
          lastIndexSeasonally + 1,
        );
        modelFitValue = data.modelFit.value.slice(
          initialIndexModelFit,
          lastIndexSeasonally + 1,
        );
      } else if (initialIndexModelFit !== -1) {
        modelFitDate = data.modelFit.date.slice(
          initialIndexModelFit,
          initialIndexModelFit + quantity,
        );
        modelFitValue = data.modelFit.value.slice(
          initialIndexModelFit,
          initialIndexModelFit + quantity,
        );
      } else if (lastIndexSeasonally !== -1) {
        modelFitDate = data.modelFit.date.slice(
          lastIndexSeasonally - quantity,
          lastIndexSeasonally + 1,
        );
        modelFitValue = data.modelFit.value.slice(
          lastIndexSeasonally - quantity,
          lastIndexSeasonally + 1,
        );
      } else {
        modelFitDate = data.modelFit.date.slice(
          data.modelFit.date.length - 1 - quantity,
          data.modelFit.date.length,
        );
        modelFitValue = data.modelFit.value.slice(
          data.modelFit.date.length - 1 - quantity,
          data.modelFit.date.length,
        );
      }

      return {
        historical: {
          date: historicalDate,
          value: historicalValue,
        },
        modelFit: {
          date: modelFitDate,
          value: modelFitValue,
        },
      };
    }

    return {
      historical: {
        date: [],
        value: [],
      },
      modelFit: {
        date: [],
        value: [],
      },
    };
  };

  const series: HChartsSeries[] = useMemo(() => {
    const actualVsModelFitAdjusted =
      project.selectedY?.info?.frequency && isLatestDataActive
        ? getLatestData(actualVsModelFitData, project.selectedY.info.frequency)
        : actualVsModelFitData;

    const seriesAux: HChartsSeries[] = [];

    if (actualVsModelFitAdjusted?.historical.date.length) {
      seriesAux.push({
        name: translate('Historical'),
        type: 'line',
        marker: {
          symbol: 'circle',
        },
        color: getChartColor(0),
        dashStyle: 'Solid',
        data: actualVsModelFitAdjusted.historical.date.map(
          (date, dateIndex) => ({
            x: new Date(`${date}T00:00`).getTime(),
            y: actualVsModelFitAdjusted.historical.value[dateIndex] || null,
            custom: {
              value: formatCompactNotation(
                actualVsModelFitAdjusted.historical.value[dateIndex],
              ),
            },
          }),
        ),
      });
    }

    if (actualVsModelFitAdjusted?.modelFit.date.length) {
      seriesAux.push({
        name: translate('model_fit'),
        type: 'line',
        marker: {
          symbol: 'circle',
        },
        color: getChartColor(1),
        dashStyle: 'Dash',
        data: actualVsModelFitAdjusted.modelFit.date.map((date, dateIndex) => ({
          x: new Date(`${date}T00:00`).getTime(),
          y: actualVsModelFitAdjusted.modelFit.value[dateIndex] || null,
          custom: {
            value: formatCompactNotation(
              actualVsModelFitAdjusted.modelFit.value[dateIndex],
            ),
          },
        })),
      });
    }

    return seriesAux;
  }, [actualVsModelFitData, isLatestDataActive, project, translate]);

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: ${
            project.selectedY?.info?.frequency === 'annual' ? '%Y' : ' %d/%m/%Y'
          }}</td></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
    }),
    [project.selectedY?.info?.frequency, translate],
  );

  const error = project.projectError || actualVsModelFitError;

  const isLoadingChart = !actualVsModelFitData || actualVsModelFitLoading;

  return (
    <div className="containerLinear">
      <Card textCard={translate('modelSpecModelFitTitle')} />
      <ContentLatestData>
        <ToggleSwitch
          label={translate('latestData')}
          checked={isLatestDataActive}
          onChange={(e) => handleActiveLatestData(e.target.checked)}
          disabled={isLatestDataDisabled || !!error || !!isLoadingChart}
          data-cy="explanatory-variables-latest-data"
        />
      </ContentLatestData>
      {error ? (
        <ContainerMaintenance content="chart" />
      ) : isLoadingChart ? (
        <ContainerSkeleton />
      ) : (
        <HCharts
          series={series}
          options={options}
          dataCy="chart-actual-model-fit-series"
          frequency={project.selectedY?.info?.frequency}
        />
      )}
    </div>
  );
};
