import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { CaretRight, MagnifyingGlass, Pencil, Trash, X } from 'phosphor-react';
import light from 'src/styles/themes/light';
import { Button } from 'src/components/Button';
import { CheckBox } from 'src/components/CheckBox';
import { Tag } from 'src/components/Tag';
import { RadioButton } from 'src/components/RadioButton';
import { Input } from 'src/components/Input';
import { getTextWidth } from 'src/utils/getTextWidth';
import { escapeRegExp } from 'src/utils/escapeRegExp';
import Maintenance from 'src/assets/maintenance.svg';

import { ConfigFiltersProps, ErrorProps, Filters, Option } from './types';
import {
  ButtonsContent,
  Container,
  CreateFilterOptionContainer,
  FilterActions,
  FilterContainer,
  FilterOption,
  FilterOptionInfo,
  FilterOptionsContainer,
  FilterOptionsContent,
  FormError,
  InputErrorContainer,
  NoOptionContainer,
  OptionContainer,
  OptionVariables,
  SelectAllContainer,
  VariableAndOptionContainer,
  VariableContainer,
  VariableContent,
  VariableQtty,
} from './styles';
import { WarningIcon } from '../../VariablesTable/Row/styles';
import { DeleteFilterModal } from '../../Modal/DeleteFilter';

export const ConfigFilters: React.FC<ConfigFiltersProps> = ({
  filterLevel,
  filters,
  setFilters,
  yIds,
  yIdLabel,
}) => {
  const [newFilterOption, setNewFilterOption] = useState<string>();

  const [showCreateOptionPlaceholder, setShowCreateOptionPlaceholder] =
    useState(true);

  const [variablesToSelect, setVariablesToSelect] = useState<string[]>([]);

  const [selectAllVariables, setSelectAllVariables] = useState(false);
  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);

  const [variablesSearched, setVariablesSearched] = useState<string[]>([]);
  const [lastVariableSearch, setLastVariableSearch] = useState('');
  const [searchInfo, setSearchInfo] = useState({
    timeoutId: null as NodeJS.Timeout | null,
    error: {} as ErrorProps,
  });

  const [showTooltipButton, setShowTooltipButton] = useState(false);

  const [selectedOption, setSelectedOption] = useState('');
  const [optionIds, setOptionIds] = useState(0);

  const [deleteFilterLevel, setDeleteFilterLevel] = useState<number>();

  const { t: translate } = useTranslation();

  const handleEnableFocusInput = (id: string) => {
    const input = document.getElementById(id);
    if (input) {
      input.focus();
    }
  };

  const checkAllFilterNames = (updatedFilters: Filters) => {
    for (let i = 0; i < updatedFilters.length; i++) {
      let repeatedName = false;
      const errorMessage = updatedFilters[i].error;

      if (!errorMessage || errorMessage === 'nameAlreadyExists') {
        for (let j = 0; j < updatedFilters.length; j++) {
          if (i !== j && updatedFilters[i].name === updatedFilters[j].name) {
            repeatedName = true;

            updatedFilters[i].error = 'nameAlreadyExists';
            updatedFilters[j].error = 'nameAlreadyExists';
          }
        }
      }

      if (!repeatedName && errorMessage === 'nameAlreadyExists') {
        updatedFilters[i].error = '';
      }
    }

    return updatedFilters;
  };

  const checkNewFilterName = (name: string) => {
    let errorMessage = '';

    if (!name) {
      errorMessage = 'requiredField';
    } else if (name.length > 50) {
      errorMessage = 'searchMaxCharactersError';
    }

    let updatedFilters = [...filters];
    updatedFilters[filterLevel].error = errorMessage;

    updatedFilters = checkAllFilterNames(updatedFilters);

    setFilters(updatedFilters);
  };

  const handleChangeFilterName = (name: string) => {
    const updatedFilters = [...filters];
    updatedFilters[filterLevel].name = name;

    setFilters(updatedFilters);

    checkNewFilterName(name);
  };

  const handleDeleteFilter = () => {
    if (deleteFilterLevel !== undefined) {
      const updatedFilters = [...filters];

      updatedFilters.splice(deleteFilterLevel, 1);

      setFilters(updatedFilters);

      setDeleteFilterLevel(undefined);
    }
  };

  const newOptionNameIsRepeated = () => {
    const allOptions = [...filters[filterLevel].options];

    return allOptions.some(
      ({ name }) => name === newFilterOption && name !== '',
    );
  };

  const handleChangeNewFilterOptionName = (name: string) => {
    setNewFilterOption(name);
  };

  const sortedOptions = (options: Option[]) => {
    let updatedFilterOptions = [...options];

    updatedFilterOptions = updatedFilterOptions.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : 0,
    );

    return updatedFilterOptions;
  };

  const handleAddNewFilterOption = () => {
    if (
      !!newFilterOption &&
      newFilterOption.length <= 50 &&
      !newOptionNameIsRepeated()
    ) {
      const updatedFilters = [...filters];
      const options = [...updatedFilters[filterLevel].options];

      updatedFilters[filterLevel].options = sortedOptions([
        ...options,
        { id: String(optionIds), name: newFilterOption, yIds: [] },
      ]);

      setNewFilterOption('');
      setFilters(updatedFilters);
      setOptionIds(optionIds + 1);
    }
  };

  const checkAllFilterOptions = (options: Option[]) => {
    for (let i = 0; i < options.length; i++) {
      let repeatedName = false;
      const errorMessage = options[i].error;

      for (let j = 0; j < options.length; j++) {
        if (i !== j && options[i].name === options[j].name) {
          repeatedName = true;

          options[i].error = 'nameAlreadyExists';
          options[j].error = 'nameAlreadyExists';
        }
      }

      if (!repeatedName && errorMessage === 'nameAlreadyExists') {
        options[i].error = '';
      }
    }

    return options;
  };

  const handleRenameFilterOption = (id: string, name: string) => {
    const updatedFilters = [...filters];

    const filterOptions = [...updatedFilters[filterLevel].options];

    const index = filterOptions.findIndex((option) => option.id === id);

    if (index !== -1) {
      let error = '';

      if (!name) {
        error = 'requiredField';
      } else if (name.length > 50) {
        error = 'searchMaxCharactersError';
      }

      updatedFilters[filterLevel].options[index].error = error;
      updatedFilters[filterLevel].options[index].name = name;

      updatedFilters[filterLevel].options = checkAllFilterOptions(
        updatedFilters[filterLevel].options,
      );

      setFilters(updatedFilters);
    }
  };

  const handleDeleteOption = (id: string) => {
    const updatedFilters = [...filters];

    updatedFilters[filterLevel].options = updatedFilters[
      filterLevel
    ].options.filter((option) => option.id !== id);

    updatedFilters[filterLevel].options = checkAllFilterOptions(
      updatedFilters[filterLevel].options,
    );

    setFilters(updatedFilters);
  };

  const sortVariables = (ids: string[]) =>
    ids.sort((a, b) =>
      yIdLabel[a] > yIdLabel[b] ? 1 : yIdLabel[a] < yIdLabel[b] ? -1 : 0,
    );

  const checkAllVariablesAreSelected = (ids: string[]) => {
    let allVariablesAreSelected = true;

    for (const id of ids) {
      if (!selectedVariables.includes(id)) {
        allVariablesAreSelected = false;
        break;
      }
    }

    if (!allVariablesAreSelected || !ids.length) {
      setSelectAllVariables(false);
    } else setSelectAllVariables(true);
  };

  const performSearch = (value: string, ids?: string[]) => {
    const regex = new RegExp(escapeRegExp(value), 'i');

    const allVariables = ids ?? variablesToSelect;

    const updatedIds =
      (value.length
        ? allVariables?.filter((id) => regex.test(yIdLabel[id]))
        : allVariables) ?? [];

    setLastVariableSearch(value);
    checkAllVariablesAreSelected(updatedIds);
    setVariablesSearched(updatedIds);
  };

  const handleSearchVariable = (value: string) => {
    if (value.length > 50) {
      setSearchInfo({
        ...searchInfo,
        error: {
          message: 'searchMaxCharactersError',
          quantityLetters: 50,
        },
      });

      return;
    }

    if (searchInfo.timeoutId) {
      clearTimeout(searchInfo.timeoutId);
    }

    setSearchInfo({
      ...searchInfo,
      error: {
        message: '',
        quantityLetters: 0,
      },
      timeoutId: setTimeout(() => {
        performSearch(value);
      }, 250),
    });
  };

  const handleSelectAllVariables = useCallback(() => {
    const selected = !selectAllVariables;
    let allVariablesSelected = new Set(selectedVariables);

    if (selected) {
      variablesSearched.forEach((id) => {
        allVariablesSelected.add(id);
      });
    } else if (variablesSearched.length === variablesToSelect?.length) {
      allVariablesSelected = new Set([]);
    } else {
      for (const id of variablesSearched) {
        allVariablesSelected.delete(id);
      }
    }

    setSelectedVariables(Array.from(allVariablesSelected));
    setSelectAllVariables(selected);
  }, [
    variablesSearched,
    selectAllVariables,
    selectedVariables,
    variablesToSelect,
  ]);

  const handleCheckVariable = useCallback(
    (id: string) => {
      const variables = [...selectedVariables];

      if (variables.includes(id)) {
        const index = variables.findIndex((varId) => varId === id);

        variables.splice(index, 1);
      } else {
        variables.push(id);
      }

      if (variables.length === variablesSearched.length) {
        setSelectAllVariables(true);
      } else {
        setSelectAllVariables(false);
      }

      setSelectedVariables(variables);
    },
    [selectedVariables, variablesSearched],
  );

  const removeVariablesToSelect = () => {
    let projectVariables = [...variablesToSelect];

    projectVariables = projectVariables.filter(
      (id) => !selectedVariables.includes(id),
    );

    setVariablesToSelect(projectVariables);

    const updateVariableSearched = variablesSearched.filter((id) =>
      projectVariables.includes(id),
    );
    setVariablesSearched(updateVariableSearched);
  };

  const handleSendVariablesToFilterOption = () => {
    setShowTooltipButton(false);

    const optionIndex = filters[filterLevel].options.findIndex(
      ({ name }) => name === selectedOption,
    );

    const updatedFilterOption = [...filters[filterLevel].options];

    if (optionIndex !== -1) {
      const ids = [...updatedFilterOption[optionIndex].yIds];

      selectedVariables.forEach((y) => {
        ids.push(y);
      });

      updatedFilterOption[optionIndex] = {
        ...updatedFilterOption[optionIndex],
        yIds: sortVariables(ids),
      };

      const updatedFilters = [...filters];
      updatedFilters[filterLevel].options = updatedFilterOption;

      setFilters(updatedFilters);

      removeVariablesToSelect();

      setSelectedVariables([]);
      setSelectAllVariables(false);
    }
  };

  const handleRemoveFilterVariable = (id: string) => {
    const updatedVariablesId = sortVariables([...variablesToSelect, id]);

    setVariablesToSelect(updatedVariablesId);

    const optionIndex = filters[filterLevel].options.findIndex(
      ({ name }) => name === selectedOption,
    );

    const updatedFilterOption = [...filters[filterLevel].options];

    const ids = updatedFilterOption[optionIndex].yIds.filter(
      (option) => option !== id,
    );

    updatedFilterOption[optionIndex] = {
      ...updatedFilterOption[optionIndex],
      yIds: ids,
    };

    const updatedFilters = [...filters];
    updatedFilters[filterLevel].options = updatedFilterOption;

    setFilters(updatedFilters);

    performSearch(lastVariableSearch, updatedVariablesId);
  };

  useEffect(() => {
    if (filters.length > 1) {
      setFilters(checkAllFilterNames([...filters]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.length]);

  useEffect(() => {
    if (yIdLabel) {
      let ids = [...yIds];

      for (const option of filters[filterLevel].options) {
        ids = ids.filter((id) => !option.yIds.includes(id));
      }

      const sortedIdByLabels = sortVariables(ids);

      setVariablesToSelect(sortedIdByLabels);
      performSearch(lastVariableSearch, ids);
      setSelectAllVariables(false);
      setSelectedVariables([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yIdLabel, JSON.stringify(filters[filterLevel].options)]);

  useEffect(() => {
    if (!showTooltipButton) setShowTooltipButton(true);
  }, [showTooltipButton]);

  return (
    <>
      <Container key={filterLevel}>
        <Tooltip id="config-filters-tooltip" className="customTooltipTheme" />

        <FilterContainer>
          <FilterActions>
            <InputErrorContainer>
              <Input
                id={`filter-${filterLevel + 1}-name`}
                placeholder={translate('configFiltersFilterName')}
                value={filters[filterLevel].name}
                onChange={({ target: { value } }) => {
                  handleChangeFilterName(value);
                }}
                data-testid={`filter-${filterLevel + 1}-name`}
                style={{
                  width: `${
                    getTextWidth(
                      filters[filterLevel].name !== ''
                        ? filters[filterLevel].name
                        : translate('configFiltersFilterName'),
                      '600 18px Inter',
                    ) /
                      16 +
                    0.5
                  }rem`,
                }}
              />

              {!!filters[filterLevel].error && (
                <WarningIcon
                  data-tooltip-id="config-workspace-tooltip"
                  data-tooltip-html={translate(
                    filters[filterLevel].error ?? '',
                  ).replace('XX', '50')}
                  data-testid={`filter-${filterLevel + 1}-name-error`}
                  color={light.colors.red2}
                >
                  <X weight="bold" size="0.875rem" />
                </WarningIcon>
              )}
            </InputErrorContainer>

            <ButtonsContent>
              <Button
                buttonType="naked"
                icon={<Pencil />}
                onClick={() =>
                  handleEnableFocusInput(`filter-${filterLevel + 1}-name`)
                }
                data-testid={`filter-${filterLevel + 1}-rename`}
              />

              <Button
                buttonType="naked"
                icon={<Trash />}
                onClick={() => setDeleteFilterLevel(filterLevel)}
                data-testid={`filter-${filterLevel + 1}-delete`}
              />
            </ButtonsContent>
          </FilterActions>

          <FilterOptionsContainer>
            <p className="label">{translate('configFiltersOptions')}</p>

            <FilterOptionsContent>
              {filters[filterLevel].options.map((option, index) => (
                <FilterOption
                  key={`filter-${filterLevel + 1}-${option.id}`}
                  data-testid={`filter-${filterLevel + 1}-option-${index}`}
                >
                  <FilterOptionInfo>
                    <div>
                      <VariableQtty
                        data-testid={`filter-${
                          filterLevel + 1
                        }-filter-option-${index}-variables-qtty`}
                      >
                        {option.yIds?.length ?? 0}
                      </VariableQtty>

                      <InputErrorContainer>
                        <Input
                          id={`filter-${filterLevel + 1}-input-option-${index}`}
                          placeholder={translate('configFiltersOptionName')}
                          value={filters[filterLevel].options[index].name}
                          onChange={(input) => {
                            handleRenameFilterOption(
                              option.id,
                              input.target.value,
                            );
                          }}
                          onBlur={() => {
                            const updatedFilters = [...filters];
                            const options = updatedFilters[filterLevel].options;

                            updatedFilters[filterLevel].options =
                              sortedOptions(options);

                            setFilters(updatedFilters);
                          }}
                          data-testid={`filter-${
                            filterLevel + 1
                          }-input-option-${index}`}
                          style={{
                            width: `${
                              getTextWidth(
                                filters[filterLevel].options[index].name !== ''
                                  ? filters[filterLevel].options[index].name
                                  : translate('configFiltersOptionName'),
                                '400 14px Inter',
                              ) /
                                16 +
                              0.5
                            }rem`,
                          }}
                        />

                        {!!filters[filterLevel].options[index].error && (
                          <WarningIcon
                            data-tooltip-id="config-workspace-tooltip"
                            data-tooltip-html={translate(
                              filters[filterLevel].options[index].error ?? '',
                            ).replace('XX', '50')}
                            data-testid={`filter-${
                              filterLevel + 1
                            }-option-${index}-error`}
                            color={light.colors.red2}
                          >
                            <X weight="bold" size="0.875rem" />
                          </WarningIcon>
                        )}
                      </InputErrorContainer>
                    </div>

                    <ButtonsContent>
                      <Button
                        buttonType="naked"
                        icon={<Pencil />}
                        onClick={() =>
                          handleEnableFocusInput(
                            `filter-${filterLevel + 1}-input-option-${index}`,
                          )
                        }
                        data-testid={`filter-${
                          filterLevel + 1
                        }-rename-option-${index}`}
                      />

                      <Button
                        buttonType="naked"
                        icon={<Trash />}
                        onClick={() => handleDeleteOption(option.id)}
                        data-testid={`filter-${
                          filterLevel + 1
                        }-delete-option-${index}`}
                      />
                    </ButtonsContent>
                  </FilterOptionInfo>
                </FilterOption>
              ))}
            </FilterOptionsContent>

            <CreateFilterOptionContainer>
              <Input
                onChange={({ target: { value } }) => {
                  handleChangeNewFilterOptionName(value);
                }}
                value={newFilterOption ?? ''}
                placeholder={
                  showCreateOptionPlaceholder
                    ? translate('configFiltersAdd')
                    : translate('createWorkspaceFilterOptionName')
                }
                onKeyDown={(e) =>
                  e.key === 'Enter' && handleAddNewFilterOption()
                }
                onFocus={() => setShowCreateOptionPlaceholder(false)}
                onBlur={() => {
                  if (!newFilterOption) {
                    setShowCreateOptionPlaceholder(true);
                  }
                }}
                className={
                  showCreateOptionPlaceholder ? '' : 'option-placeholder'
                }
                data-testid={`filter-${filterLevel + 1}-create-option-input`}
                style={{ width: '25rem' }}
              />

              {!!newFilterOption && (
                <Button
                  type="submit"
                  buttonType="naked"
                  onClick={handleAddNewFilterOption}
                  data-testid={`filter-${filterLevel + 1}-create-option-button`}
                >
                  {translate('configFiltersAdd')}
                </Button>
              )}
            </CreateFilterOptionContainer>

            {((newFilterOption && newFilterOption?.length > 50) ||
              newOptionNameIsRepeated()) && (
              <FormError
                data-testid={`filter-${filterLevel + 1}-option-name-error`}
              >
                {newFilterOption && newFilterOption.length > 50
                  ? translate('searchMaxCharactersError').replace('XX', '50')
                  : translate('nameAlreadyExists')}
              </FormError>
            )}
          </FilterOptionsContainer>
        </FilterContainer>

        <VariableAndOptionContainer>
          <h2>{translate('configFiltersGroupVariablesTitle')}</h2>
          <p>
            {translate('configFiltersFilter')}: {filters[filterLevel].name}
          </p>

          <div>
            <VariableContainer>
              <Input
                placeholder={translate(
                  'configFiltersSearchVariablePlaceholder',
                )}
                icon={<MagnifyingGlass className="search-icon" />}
                onChange={(input) => handleSearchVariable(input.target.value)}
                error={
                  searchInfo.error?.message &&
                  translate(searchInfo.error.message).replace(
                    'XX',
                    String(searchInfo.error.quantityLetters),
                  )
                }
                data-testid={`filter-${filterLevel + 1}-search-variable`}
              />

              <SelectAllContainer>
                <CheckBox
                  label={translate('configFiltersSelectAllVariables')}
                  onChange={handleSelectAllVariables}
                  checked={selectAllVariables}
                  data-testid={`filter-${filterLevel + 1}-select-all-variables`}
                />
              </SelectAllContainer>
              <VariableContent>
                {variablesSearched.map((id) => (
                  <Tag
                    key={`filter-${filterLevel + 1}-${id}`}
                    label={yIdLabel[id] ?? ''}
                    hasCheckbox
                    checked={selectedVariables.includes(id)}
                    onClickCheckbox={() => handleCheckVariable(id)}
                    data-testid={`filter-${filterLevel + 1}-tag-${yIdLabel[id]
                      ?.replaceAll(' ', '-')
                      ?.toLowerCase()}`}
                  />
                ))}
              </VariableContent>
            </VariableContainer>

            <Button
              buttonType="naked"
              icon={<CaretRight />}
              onClick={handleSendVariablesToFilterOption}
              disabled={!selectedVariables.length || !selectedOption}
              data-tooltip-id="config-filters-tooltip"
              data-tooltip-html={
                showTooltipButton && !selectedVariables.length
                  ? translate('configFiltersSelectAtLeastOneVariable')
                  : showTooltipButton && !selectedOption
                  ? translate('configFiltersSelectAtLeastOneOption')
                  : ''
              }
              data-testid={`filter-${
                filterLevel + 1
              }-send-variables-to-filter-option`}
              className="button-caret-down"
            />

            <OptionContainer>
              {filters[filterLevel].options.length ? (
                filters[filterLevel].options.map((option, index) => (
                  <FilterOption
                    key={`filter-${filterLevel + 1}-${option.id}`}
                    data-testid={`filter-${
                      filterLevel + 1
                    }-variables-option-${index}`}
                  >
                    <FilterOptionInfo
                      className={!option.name ? 'empty-option-name' : ''}
                    >
                      <RadioButton
                        label={
                          option.name || translate('configFiltersOptionName')
                        }
                        checked={selectedOption === option.name}
                        onClick={() =>
                          setSelectedOption(
                            selectedOption === option.name ? '' : option.name,
                          )
                        }
                        data-testid={`filter-${
                          filterLevel + 1
                        }-radio-variables-option-${index}`}
                      />

                      <VariableQtty
                        data-testid={`filter-${
                          filterLevel + 1
                        }-option-${index}-variables-qtty`}
                      >
                        {option.yIds.length}
                      </VariableQtty>
                    </FilterOptionInfo>

                    {selectedOption === option.name && (
                      <OptionVariables>
                        {option.yIds.map((id) => (
                          <Tag
                            key={`filter-${filterLevel + 1}-${id}`}
                            label={yIdLabel[id] ?? ''}
                            hasRemoveButton
                            onClickRemoveButton={() =>
                              handleRemoveFilterVariable(id)
                            }
                            data-testid={`filter-${
                              filterLevel + 1
                            }-tag-${yIdLabel[id]
                              ?.replaceAll(' ', '-')
                              ?.toLowerCase()}-option-${index}`}
                          />
                        ))}
                      </OptionVariables>
                    )}
                  </FilterOption>
                ))
              ) : (
                <NoOptionContainer>
                  <img src={Maintenance} alt="No option created" />
                  <p>{translate('configFiltersNoOptionCreated')}</p>
                </NoOptionContainer>
              )}
            </OptionContainer>
          </div>
        </VariableAndOptionContainer>

        {deleteFilterLevel !== undefined && (
          <DeleteFilterModal
            setVisible={() => setDeleteFilterLevel(undefined)}
            filterLevel={deleteFilterLevel}
            filterName={filters[deleteFilterLevel].name}
            handleDeleteFilter={handleDeleteFilter}
          />
        )}
      </Container>
    </>
  );
};
