import React, { useContext } from 'react';

import { Table, WarningCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { FeatureStoreSidebarContext } from 'src/feature-store/components/Layout/Contexts/NavigationContext';

import { ButtonSection, SectionButtonContainer } from './styles';
import { SectionButtonProps } from './types';

export const SectionButton: React.FC<SectionButtonProps> = ({
  isLoading = false,
}) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { idFavoriteViewing } = useContext(FeatureStoreSidebarContext);

  const isDataPath = pathname.includes('data');

  return (
    <SectionButtonContainer>
      <ButtonSection
        active={!isDataPath}
        data-testid="button-information-section"
        disabled={isLoading}
        onClick={() =>
          navigate(`/feature-store/favorites/${idFavoriteViewing}`)
        }
      >
        <WarningCircle />
        {translate('sectionButtonFavoriteInformation')}
      </ButtonSection>
      <ButtonSection
        active={isDataPath}
        data-testid="button-data-section"
        disabled={isLoading}
        onClick={() =>
          navigate(`/feature-store/favorites/${idFavoriteViewing}/data/0`)
        }
      >
        <Table />
        {translate('sectionButtonFavoriteData')}
      </ButtonSection>
      {/* <ButtonSection active={false} data-testid="button-dashboard-section">
        <ChartLine />
        {translate('sectionButtonFavoriteDashboard')}
      </ButtonSection> */}
    </SectionButtonContainer>
  );
};
