import React, { useEffect, useMemo, useState } from 'react';

import { Card } from 'src/components/Card';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Select } from 'src/components/Select';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { getChartColor } from 'src/utils/getChartColor';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { frequencyLatestData } from 'src/utils/getLatestData';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import light from 'src/styles/themes/light';

import {
  ContainerAggregateProjectionBestModel,
  ContentLatestData,
  Options,
} from './styles';

interface AggregateBestModel {
  data_tidy: string[];
  model_number: string;
  y: number[];
}

export const ForecastDispersion: React.FC = () => {
  const [selectedQuantityAggregate, setSelectedQuantityAggregate] =
    useState<number>();

  const { project } = useSelector((state: RootState) => state);

  const [isLatestDataActive, setIsLatestDataActive] = useState<boolean>(true);
  const [isLatestDataDisabled, setIsLatestDataDisabled] = useState(false);

  const { t: translate } = useTranslation();

  const {
    data: dataAggregateBestModels,
    isLoading: isLoadingAggregateBestModels,
    isFetching: isFetchingAggregateBestModels,
    isError: isErrorAggregateBestModels,
  } = useQuery(
    ['model-overview-aggregate-best-model', project.id, project.selectedY],
    async () => {
      const { data } = await api.get<AggregateBestModel[]>(
        `/projects/${project.id}/${project.selectedY?.id}/models/projections/10`,
      );
      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!project.id && !!project.selectedY?.id,
    },
  );

  useEffect(() => {
    if (dataAggregateBestModels?.length) {
      if (dataAggregateBestModels.length - 1 === 0) {
        setSelectedQuantityAggregate(0);
      } else {
        setSelectedQuantityAggregate(dataAggregateBestModels.length - 1);
      }
    }
  }, [dataAggregateBestModels]);

  function handleActiveLatestData(value: boolean) {
    setIsLatestDataActive(value);
  }

  useEffect(() => {
    if (isErrorAggregateBestModels) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    if (!dataAggregateBestModels?.length || !project.selectedY) {
      return;
    }

    if (!project.selectedY?.info?.frequency) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    const historicalLength = dataAggregateBestModels[0].data_tidy.length;

    const modelsLength =
      dataAggregateBestModels.length > 1
        ? dataAggregateBestModels[1].data_tidy.length
        : 0;

    const quantity = historicalLength + modelsLength;

    if (quantity <= frequencyLatestData[project.selectedY.info.frequency]) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    setIsLatestDataDisabled(false);
  }, [dataAggregateBestModels, isErrorAggregateBestModels, project]);

  const getLatestData = (
    data: AggregateBestModel[] | undefined,
    frequency:
      | 'daily'
      | 'weekly'
      | 'fortnightly'
      | 'monthly'
      | 'bimonthly'
      | 'quarterly'
      | 'half-year'
      | 'annual',
  ): AggregateBestModel[] => {
    if (data) {
      const quantity = frequencyLatestData[frequency];

      if (!data.length) return data;

      const historicalLength = data[0].data_tidy.length;

      const modelsLength = data.length > 1 ? data[1].data_tidy.length : 0;

      if (quantity > historicalLength && quantity > modelsLength) {
        return data;
      }

      let lastModelsIndex = 0;
      if (modelsLength >= quantity / 3) {
        lastModelsIndex = quantity / 3;
      } else if (data.length > 1 && modelsLength !== 0) {
        lastModelsIndex = modelsLength - 1;
      }

      const historicalDate = data[0].data_tidy.slice(
        historicalLength - quantity + lastModelsIndex,
        historicalLength,
      );

      const historicalValue = data[0].y.slice(
        historicalLength - quantity + lastModelsIndex,
        historicalLength,
      );

      return [
        {
          data_tidy: historicalDate,
          model_number: data[0].model_number,
          y: historicalValue,
        },
      ]
        .concat(
          data
            .filter((_, index) => index !== 0)
            .map((modelData) => ({
              data_tidy: modelData.data_tidy.slice(0, quantity / 3 + 1),
              model_number: modelData.model_number,
              y: modelData.y.slice(0, quantity / 3 + 1),
            })),
        )
        .flat();
    }

    return [];
  };

  const series: HChartsSeries[] = useMemo(() => {
    const dispersionsAdjusted =
      project.selectedY?.info?.frequency && isLatestDataActive
        ? getLatestData(
            dataAggregateBestModels,
            project.selectedY?.info?.frequency,
          )
        : dataAggregateBestModels;

    return dispersionsAdjusted
      ? dispersionsAdjusted
          .filter((_, index) => index <= (selectedQuantityAggregate ?? 0))
          .map((bestModel, index) => {
            const name =
              index === 0
                ? translate('Historical')
                : translate('model') + bestModel.model_number.split('Model')[1];

            return {
              type: 'line',
              name,
              showInLegend:
                dispersionsAdjusted.length === 1 ? true : index !== 0,
              color: getChartColor(index),
              data: bestModel.y.map((y, yIndex) => ({
                x: new Date(`${bestModel.data_tidy[yIndex]}T00:00`).getTime(),
                y,
                custom: {
                  value: formatCompactNotation(y),
                },
              })),
              marker: {
                symbol: 'circle',
              },
            };
          })
      : [];
  }, [
    dataAggregateBestModels,
    isLatestDataActive,
    project.selectedY?.info?.frequency,
    selectedQuantityAggregate,
    translate,
  ]);

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
        events: {
          render() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const chart = this;

            const legend = chart.legend;

            //@ts-expect-error:ignora
            if (legend?.nav) {
              //@ts-expect-error:ignora
              const legendWidth = legend.legendWidth;

              //@ts-expect-error:ignora
              const nav = legend.nav;
              const y = nav.translateY;
              const navWidth = nav.getBBox().width;

              const x = legendWidth / 2 + nav.translateX - navWidth / 2 + 3;

              nav.attr({
                style: `transform: translate(${x}px, ${y}px)`,
              });
            }
          },
        },
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: ${
            project.selectedY?.info?.frequency === 'annual' ? '%Y' : ' %d/%m/%Y'
          }}</td></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        navigation: {
          enabled: true,
          inactiveColor: light.colors.gray3,
          arrowSize: 12,
          activeColor: light.colors.gray4,
          style: {
            textAlign: 'center',
            paddingLeft: '12px',
            padding: '12px',
            color: light.colors.gray6,
          },
        },
      },
    }),
    [project.selectedY?.info?.frequency, translate],
  );

  return (
    <ContainerAggregateProjectionBestModel
      className="containerLinear"
      data-testid="container-aggregate-projection-best-model"
    >
      <Card
        textCard={translate('modelOverviewForecastDispersion')}
        textDescription={translate(
          'modelOverviewForecastDispersionDescription',
        )}
      />

      <Options>
        <Select
          label={translate('modelOverviewShowNBestModels')}
          // information="texto aqui"
          value={
            selectedQuantityAggregate
              ? {
                  label: selectedQuantityAggregate,
                  value: selectedQuantityAggregate,
                }
              : undefined
          }
          onChange={(option: any) => setSelectedQuantityAggregate(option.value)}
          options={Array.from(
            { length: (dataAggregateBestModels?.length ?? 0) - 1 },
            (_, number) => ({
              label: number + 1,
              value: number + 1,
            }),
          )}
          isDisabled={
            selectedQuantityAggregate === 0 ||
            !dataAggregateBestModels ||
            isErrorAggregateBestModels ||
            project.projectError ||
            isLoadingAggregateBestModels ||
            selectedQuantityAggregate === undefined
          }
          style={{ width: '320px' }}
        />
        <ContentLatestData>
          <ToggleSwitch
            label={translate('latestData')}
            checked={isLatestDataActive}
            onChange={(e) => handleActiveLatestData(e.target.checked)}
            disabled={
              isLatestDataDisabled ||
              !!isErrorAggregateBestModels ||
              project.projectError ||
              !project.model?.model_list ||
              !dataAggregateBestModels
            }
            data-cy="explanatory-variables-latest-data"
          />
        </ContentLatestData>
      </Options>

      {isErrorAggregateBestModels || project.projectError ? (
        <ContainerMaintenance content="chart" />
      ) : isLoadingAggregateBestModels ||
        isFetchingAggregateBestModels ||
        !dataAggregateBestModels ||
        selectedQuantityAggregate === undefined ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton
          data-testid="container-aggregate-projection-best-model-loading"
          style={{ height: '394px' }}
        />
      ) : (
        <>
          <HCharts
            series={series}
            options={options}
            frequency={project.selectedY?.info?.frequency}
            dataCy="container-aggregate-projection-best-model-chart"
          />
        </>
      )}
    </ContainerAggregateProjectionBestModel>
  );
};
