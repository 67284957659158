export const performanceByPeriodTableENUSTranslations = {
  performanceByPeriodTitle: 'Performance by period',
  performanceByPeriodDescription:
    'Analyze the performance of the <b>User Selection</b> models for the project variables.',
  performanceByPeriodDepVariable: 'Dependent variable',
  performanceByPeriodSearchPlaceholder: 'Search dependent variable',
  performanceByPeriodCriterion: 'Criterion',
  performanceByPeriodTooltip:
    'Each column reflects the mean performance of all models that have sufficient out-of-sample dates for the referred period.',
  perforamnceByPeriodVariableTooltip: 'Variable not updated.',
  performanceByPeriodNoResults: `We couldn't find any variable containing "SEARCH".`,
};
