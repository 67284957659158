const workspaceSideBarPTBRTranslations = {
  workspaceSideBarOverviewLabel: 'Visão Geral',
  workspaceSideBarPerformanceLabel: 'Performance',
  workspaceSideBarProjectionsLabel: 'Projeções',
  workspaceSideBarProjects: 'PROJETOS',
  workspaceSideBarResults: 'RESULTADOS',
  workspaceSelectAnotherProject: 'Clique para selecionar outro workspace',
  workspaceSidebarUpdateTitle: 'Histórico de Atualização',
  workspaceSidebarUpdateDescription:
    'Veja o histórico de atualizações e a versão original do seu workspace.',
  workspaceSideBarDependentVariable: 'Variável Dependente',
  workspaceSideBarLatestVersion: 'última versão',
};

export default workspaceSideBarPTBRTranslations;
