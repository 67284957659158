import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'src/components/Layout';
import { ExportProjectOverview } from 'src/models/TimeSeries/ProjectOverview/components/ExportProjectOverview';
import NotFound from 'src/pages/404';
import Admin from 'src/pages/Admin';
import { Home } from 'src/pages/Home';
import { AdminPanel } from 'src/pages/UserModule/AdminPanel';
import { CustomersList } from 'src/pages/UserModule/CustomersList';
import { UnauthorizedSSO } from 'src/pages/UnauthorizedSSO';

import { Landing } from '../pages/Landing';
import FeatureStoreRoutes from './approutes/featureStoreRoutes';
import ModelsRoutes from './approutes/modelsRoutes';
import { PrivateRoutes } from './PrivateRoute';

const AppRoutes = (): React.ReactElement => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <Layout>
        <Routes>
          {isAuthenticated ? (
            <Route
              path="/"
              element={<PrivateRoutes path="/" component={Home} />}
            />
          ) : (
            <Route path="/" element={<Landing />} />
          )}

          <Route
            caseSensitive
            path="/admin"
            element={
              <PrivateRoutes
                path="/admin"
                component={Admin}
                roles={['isAdmin']}
              />
            }
          />
          <Route
            caseSensitive
            path="/models/*"
            element={<PrivateRoutes path="/models" component={ModelsRoutes} />}
          />
          <Route
            caseSensitive
            path="/feature-store/*"
            element={
              <PrivateRoutes
                path="/feature-store"
                component={FeatureStoreRoutes}
              />
            }
          />
          <Route
            caseSensitive
            path="/user-module/customers-list"
            element={
              <PrivateRoutes
                path="/user-module/customers-list"
                component={CustomersList}
                roles={['isSupport']}
              />
            }
          />
          <Route
            caseSensitive
            path="/user-module/customers-list/:client_id"
            element={
              <PrivateRoutes
                path="/user-module/customers-list/:client_id"
                component={AdminPanel}
              />
            }
          />

          <Route
            caseSensitive
            path="/unauthorized-sso"
            element={<UnauthorizedSSO />}
          />

          <Route element={<NotFound />} path="*" />
        </Routes>
      </Layout>
      {isAuthenticated && <ExportProjectOverview />}
    </>
  );
};

export default AppRoutes;
