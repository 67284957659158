import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import parseHTML from 'html-react-parser';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import Logo4i from 'src/assets/logo-4i.svg';
import LogoName from 'src/assets/logo-name.svg';
import { Button } from 'src/components/Button';
import { Head } from 'src/components/Head';
import { LanguageMenu } from 'src/components/LanguageMenu';

import { Container, Content, Div4i, HeaderLanding, LogoDiv } from './styles';

export const Landing: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  if (process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_SV) {
    //caso nao tenha colocado a env do hotjar, senao ira quebrar o codigo
    hotjar.stateChange('/');
  }

  const { t: translate } = useTranslation();
  const { language } = useSelector((state: RootState) => state.auth.user);

  return (
    <Container>
      <Head title="4intelligence" />
      <HeaderLanding>
        <LogoDiv>
          <img
            data-testid="logo-4i"
            src={Logo4i}
            alt="icon-logo-4i"
            height={56}
            width={58}
          />
          <img
            data-testid="logo-4i"
            src={LogoName}
            alt="icon-logo-4i"
            width={232}
            height={38}
            style={{ marginTop: '0.5rem' }}
          />
        </LogoDiv>
        <LanguageMenu style={{ marginRight: 0 }} />
      </HeaderLanding>
      <Div4i>
        <p>{translate('landing')}</p>
        <hr />
        <p>4intelligence</p>
      </Div4i>
      <Content>
        <h1>{parseHTML(translate('landingBranding'))}</h1>
        <p>{translate('landingDescription')}</p>
        <Button
          buttonType="primary"
          style={{ width: '17.5rem' }}
          onClick={() => loginWithRedirect({ ui_locales: language })}
        >
          {translate('landingSignIn')}
        </Button>
      </Content>
    </Container>
  );
};
