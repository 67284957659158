/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useMemo, useState } from 'react';

import { Card } from 'src/components/Card';
import light from 'src/styles/themes/light';
import { CaretDown } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import api from 'src/models/service/api';
import { useQuery } from 'react-query';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { useTranslation } from 'react-i18next';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { AxiosError } from 'axios';
import parseHTML from 'html-react-parser';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { truncateStrings } from 'src/utils/truncateStrings';
import { sleep } from 'src/utils/sleep';
import { getTextWidth } from 'src/utils/getTextWidth';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';

import {
  ButtonSeeMoreImpacts,
  ContentChart,
  ContentLoadingImpacts,
  PotentialImpactsContainer,
} from './styles';

interface Variable {
  name: string;
  value: number;
}

interface VariableOptions extends Variable {
  label: string;
}

interface ResponseVariables {
  variables: Variable[];
}

interface Error {
  detail?: {
    description?: string;
  };
}

interface Impacts {
  title: {
    'pt-br': string;
    'en-us': string;
  };
  description: {
    'pt-br': string;
    'en-us': string;
  };
}

export const PotentialImpacts: React.FC = () => {
  const [seeMore, setSeeMore] = useState(false);
  const [canChange, setCanChange] = useState(false);

  const {
    auth: { user },
    project: { id, selectedY },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const {
    data: variables,
    isLoading: isLoadingVariables,
    isFetching: isFetchingVariables,
    error: errorVariables,
  } = useQuery<VariableOptions[], AxiosError<Error>>(
    ['AI-selection', 'potencial-impacts', 'variables', id, selectedY?.id],
    async () => {
      const response = await api.get<ResponseVariables>(
        `/projects/${id}/${selectedY?.id}/models/business/impact/variables`,
      );

      return response.data.variables.map((variable) => {
        if (Math.abs(variable.value) >= 0.01) {
          return {
            name: variable.name,
            value: Number(variable.value.toFixed(2)),
            label: variable.value.toFixed(2),
          };
        }

        return {
          name: variable.name,
          value: variable.value >= 0 ? 0.01 : -0.01,
          label: variable.value >= 0 ? '< 0.01' : '< -0.01',
        };
      });
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id && !!selectedY?.id,
    },
  );

  const {
    data: dataImpacts,
    isLoading: isLoadingImpacts,
    isFetching: isFetchingImpacts,
    error: errorImpacts,
  } = useQuery<Impacts, AxiosError<Error>>(
    ['AI-selection', 'potencial-impacts', 'impacts', id, selectedY?.id],
    async () => {
      const topVars = variables?.slice(0, 3).map((variable) => variable.name);

      let queryTopVars = '';

      topVars?.forEach((topVar, index) => {
        queryTopVars = queryTopVars.concat(`top_vars=${topVar}`);
        if (index !== topVars.length - 1) {
          queryTopVars = queryTopVars.concat('&');
        }
      });

      const response = await api.get(
        `/projects/${id}/${selectedY?.id}/models/business/understanding/impacts?${queryTopVars}`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id && !!selectedY?.id && !!variables,
    },
  );

  async function handleSeeMore() {
    setSeeMore((state) => !state);
  }

  useEffect(() => {
    (async () => {
      if (!seeMore) {
        await sleep(500);
        setCanChange(false);
      } else {
        setCanChange(true);
      }
    })();
  }, [seeMore]);

  const X = useMemo(
    () =>
      (variables?.map((variable) => variable.value) ?? []).slice(
        0,
        canChange ? undefined : 3,
      ),
    [canChange, variables],
  );

  const Y = useMemo(
    () =>
      (variables?.map((variable) => variable.name) ?? []).slice(
        0,
        canChange ? undefined : 3,
      ),
    [canChange, variables],
  );

  const chartText = useMemo(
    () =>
      (variables?.map((variable) => variable.label) ?? []).slice(
        0,
        canChange ? undefined : 3,
      ),
    [canChange, variables],
  );

  const serie: HChartsSeries = useMemo(() => {
    const YsColumn = truncateStrings(
      (variables?.map((variable) => variable.name) ?? []).slice(
        0,
        canChange ? undefined : 3,
      ),
    );

    const chartColors = X.map((x) =>
      x >= 0 ? `${light.colors.primary}3d` : `${light.colors.red4}3d`,
    );

    return {
      type: 'bar',
      data: X.map((value, index) => ({
        name: YsColumn[index],
        color: chartColors[index],
        y: value,
        value,
        borderWidth: 0,
        custom: {
          key: Y[index],
          value: chartText[index],
        },
        dataLabels: {
          enabled: true,
          borderWidth: 0,
          verticalAlign: 'middle',
          inside: false,
          format: chartText[index], // Formato do texto a ser exibido
          style: {
            color: value >= 0 ? light.colors.primary : light.colors.red4,
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: "'Inter', sans-serif",
            textOutline: '0',
          },
        },
      })),
    };
  }, [X, Y, canChange, chartText, variables]);

  const options: HChartsOptions = useMemo(() => {
    const chartTextWidth = chartText
      .map((text) => getTextWidth(text, '400 14px Inter'))
      .reduce((previous, current) => {
        if (previous > current) {
          return previous;
        }
        return current + 50;
      }, 0);

    return {
      chart: {
        type: 'bar',
        height: 60 * Y.length + 8 > 500 ? '508px' : `${60 * Y.length + 8}px`,
        marginTop: 0,
        marginBottom: 8,
        zooming: {
          type: undefined,
        },
        animation: false,
        events: {
          render() {
            const series = this.series[0];
            series.points.forEach((point) => {
              if (typeof point.y === 'number') {
                //@ts-expect-error:ignora
                const pointBox = point.graphic.getBBox();

                const x =
                  point.y < 0
                    ? this.plotWidth -
                      pointBox.y -
                      pointBox.height -
                      //@ts-expect-error:ignora
                      point.dataLabel.width
                    : this.plotWidth - pointBox.y;
                //@ts-expect-error:ignora
                point.dataLabels[0].attr({
                  x,
                });
              }
            });
          },
        },
      },
      xAxis: {
        type: 'category',
        minTickInterval: undefined,
        grid: {
          enabled: false,
        },
        labels: {
          y: undefined,
        },
        gridLineWidth: 0,
        lineWidth: 0,
      },
      yAxis: {
        tickPositioner() {
          const positions: number[] = []; // Inicia com a posição 0

          // Adiciona outras posições, se necessário
          let max =
            //@ts-expect-error:ignora
            this.chart.series[0]?.yData.reduce((maxAux, value) => {
              if (maxAux < value) {
                return value;
              }
              return maxAux;
            }, Number.MIN_VALUE);

          if (!max) {
            max = this.chart.series[0].dataMax ?? 0;
          }

          let min =
            //@ts-expect-error:ignora
            this.chart.series[0]?.yData.reduce((minAux, value) => {
              if (minAux > value) {
                return value;
              }
              return minAux;
            }, Number.MAX_VALUE);

          if (!min) {
            min = this.chart.series[0].dataMin ?? 0;
          }

          const quantity = Math.abs(max - min) + 1;

          const width = this.chart.plotWidth;

          const widthByPosition = width / quantity;

          const decrementMin = chartTextWidth / widthByPosition;

          const incrementMax = chartTextWidth / widthByPosition;

          if (min < 0) {
            min -= decrementMin;
            positions.push(min);
          }

          positions.push(0);

          if (max > 0) {
            max += incrementMax;
            positions.push(max);
          }

          return positions;
        },
        visible: false,
        title: {
          text: '',
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '',
        pointFormat:
          `<table><tr><th colspan="2">{point.custom.key}</th></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
        useHTML: true,
      },
      plotOptions: {
        bar: {
          borderWidth: 0,
          pointPadding: 0.0,
          groupPadding: 0.05,
        },
      },
    };
  }, [chartText, Y.length, translate]);

  const loadingVariables =
    isLoadingVariables || isFetchingVariables || !variables;

  const loadingImpacts =
    isLoadingImpacts || isFetchingImpacts || loadingVariables || !dataImpacts;

  const buttonSeeMoreDisable =
    loadingVariables ||
    !!errorVariables ||
    variables?.length === 0 ||
    variables.length <= 3;

  return (
    <PotentialImpactsContainer className="containerLinear">
      <Card
        textCard={translate('IASelectionPotencialImpactsTitle')}
        textDescription={translate('IASelectionPotencialImpactsDescription')}
      />
      {errorVariables || variables?.length === 0 ? (
        <ContainerMaintenance
          content="chart"
          text={
            variables?.length === 0
              ? translate('IASelectionImpactsError0Variables')
              : translateSomeMessagesFromBackend(
                  errorVariables?.response?.data?.detail?.description ?? '',
                  user.language,
                )
          }
          data-testid="potential-impacts-chart-error"
          size="sm"
        />
      ) : loadingVariables ? (
        <ContainerSkeleton
          style={{ height: '204px' }}
          data-testid="potential-impacts-chart-loading"
        />
      ) : (
        <ContentChart
          quantityVariables={variables.slice(0, seeMore ? undefined : 3).length}
        >
          <HCharts
            series={[serie]}
            options={options}
            dataCy="potential-impacts-chart"
          />
        </ContentChart>
      )}

      {!errorVariables &&
        variables?.length !== 0 &&
        (errorImpacts ? (
          <p>{translate('defaultTitle')}.</p>
        ) : loadingImpacts ? (
          <ContentLoadingImpacts data-testid="potential-impacts-text-loading">
            <ContainerSkeleton withLoading={false} />
            <ContainerSkeleton withLoading={false} />
          </ContentLoadingImpacts>
        ) : (
          <p>
            {parseHTML(
              !seeMore
                ? dataImpacts?.title[user.language] ?? ''
                : dataImpacts?.description[user.language] ?? '',
            )}
          </p>
        ))}

      <ButtonSeeMoreImpacts
        type="button"
        seeMoreImpacts={seeMore}
        onClick={handleSeeMore}
        data-testid="potential-impacts-button-see-more"
        disabled={buttonSeeMoreDisable}
      >
        <span>
          {seeMore
            ? translate('IASelectionPotencialImpactsLessMore')
            : translate('IASelectionPotencialImpactsSeeMore')}
        </span>
        <CaretDown size="1.125rem" weight="bold" />
      </ButtonSeeMoreImpacts>
    </PotentialImpactsContainer>
  );
};
