import styled from 'styled-components';

export const SectionButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  flex-wrap: wrap;
`;

type ButtonSectionProps = {
  active: boolean;
};

export const ButtonSection = styled.button<ButtonSectionProps>`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  flex: 1;

  align-self: stretch;

  border-radius: 5px;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: normal;

  color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.gray4};
  font-weight: ${({ active }) => (active ? '600' : '500')};
  background: ${({ active }) => (active ? 'rgba(76, 148, 255, 0.12)' : 'none')};
  outline: ${({ active, theme }) =>
    active ? 'none' : `1px solid ${theme.colors.gray2}`};

  &:hover {
    outline: ${({ active, theme }) =>
      active ? 'none' : `1px solid ${theme.colors.gray3}`};

    color: ${({ active, theme }) =>
      active ? theme.colors.primary : theme.colors.gray5};

    &:disabled {
      background: ${({ theme }) => theme.colors.gray1} !important;

      color: ${({ theme }) => theme.colors.gray5} !important;

      svg {
        color: ${({ theme }) => theme.colors.gray4} !important;
      }
    }
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.6 !important;
  }
`;
