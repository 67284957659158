import styled from 'styled-components';

export const CategoriesBox = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  position: relative;

  background: ${({ theme }) => theme.colors.white};

  p {
    color: ${({ theme }) => theme.colors.gray4};

    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
  }

  svg:first-child {
    margin-left: 0.125rem !important;
    color: ${({ theme }) => theme.colors.gray4} !important;
  }
`;

export const IndicatorFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const IndicatorFilterHead = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  h3 {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 1.12px;
    text-transform: uppercase;
  }
`;

export const IndicatorFilterButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

type IndicatorFilterProps = {
  isActive: boolean;
};

const IndicatorFilterButton = styled.button<IndicatorFilterProps>`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: 8px;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;

  border: 1px solid
    ${({ isActive, theme }) => (isActive ? 'none' : theme.colors.gray2)};

  padding: ${({ isActive }) => (isActive ? '9px' : '8px')};
`;

export const FreeButton = styled(IndicatorFilterButton)`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : theme.colors.gray4};

  background: ${({ isActive }) =>
    isActive ? 'rgba(76, 148, 255, 0.12)' : 'none'};
`;

export const BusinessButton = styled(IndicatorFilterButton)`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.teal4 : theme.colors.gray4};

  background: ${({ isActive }) =>
    isActive ? 'rgba(56, 178, 172, 0.12)' : 'none'};
`;

export const SelectCountryFilter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100% !important;
  gap: 0.5rem;
  align-self: stretch;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  h3 {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 1.12px;
    text-transform: uppercase;
  }
`;

export const IndicatorsSearch = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-top: -4rem;

  svg:first-child {
    margin-left: 0.125rem !important;
    color: ${({ theme }) => theme.colors.gray4} !important;
  }
`;

export const CategoriesContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;

  hr {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.gray1};
    margin-top: 1rem;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 15.9375rem !important;
`;

interface SelectedCategoryProps {
  disabled: boolean;
}

export const SelectedCategory = styled.button<SelectedCategoryProps>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;

  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray4};
  text-align: left;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
    margin-right: 1rem;
  }

  > div:first-child {
    max-width: 2rem;
  }
`;

export const CleanFiltersButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: 0.7s !important;
  margin-left: 0.5rem;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  margin-top: 1rem;
  text-align: right;

  > div:first-child {
    width: 1rem;
  }

  * {
    color: ${({ theme }) => theme.colors.primary};
  }

  > svg {
    margin-right: 0.5rem;
  }

  :hover {
    * {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

export const CategoriesTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  gap: 0.5rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }

  h5 {
    color: ${({ theme }) => theme.colors.gray4};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.07rem;
  }
`;

export const ContainerWithoutCategories = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray7};
    text-align: center;
  }
`;

interface SubCategory {
  category:
    | 'capital and financial markets'
    | 'economic activity'
    | 'controls'
    | 'credit'
    | 'climatic data'
    | 'public finance'
    | 'environmental data'
    | 'external sector'
    | string;
}

export const SubCategory = styled.button<SubCategory>`
  /* width: 19.1844rem !important; */
  width: 100% !important;

  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-top: 1.5rem;

  > div:first-child {
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    gap: 1rem;

    > svg {
      color: ${({ theme, category }) =>
        category === 'capital and financial markets'
          ? theme.colors.teal4
          : category === 'economic activity'
          ? theme.colors.green4
          : category === 'controls'
          ? theme.colors.blue4
          : category === 'credit'
          ? theme.colors.red4
          : category === 'climatic data'
          ? theme.colors.yellow4
          : category === 'public finance'
          ? theme.colors.purple4
          : category === 'external sector'
          ? theme.colors.cyan4
          : category === 'environmental data'
          ? theme.colors.orange4
          : theme.colors.primary} !important;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-align: left;
  }

  > div:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;

    span {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      color: ${({ theme }) => theme.colors.gray4};
    }

    svg {
      color: ${({ theme }) => theme.colors.gray4};
    }
  }
  /* display: flex;
  align-items: center;
  justify-content: space-between !important;
  height: 1.5rem;
  background: red;

  > svg {
    color: ${({ theme }) => theme.colors.gray8};
    margin-right: 1rem;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: center;
    height: 1.5rem;

    > svg {
      color: ${({ theme }) => theme.colors.green4};
    }

    p {
      margin-top: 1.5rem;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};
      text-align: left;
    } */
  /* } */

  /* p { */
  /* margin-top: 1.5rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray5};
  text-align: left; */
  /* } */
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  span {
    color: ${({ theme }) => theme.colors.gray6};
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
  }

  p {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  button {
    display: flex;
    width: 256px;
    padding: 12px 0px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    border-radius: 5px;
    background: rgba(76, 148, 255, 0.12);

    color: ${({ theme }) => theme.colors.primary};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    :hover {
      background: rgba(76, 148, 255, 0.15);
    }
  }
`;

export const AIChatContainer = styled(FilterContainer)`
  button {
    border-radius: 8px;
    background: linear-gradient(
        54deg,
        rgba(76, 148, 255, 0.64) 15.35%,
        rgba(242, 125, 180, 0.64) 83.24%
      ),
      #fff;
    box-shadow: 0px 8px 32px 0px rgba(76, 148, 255, 0.24);

    color: ${({ theme }) => theme.colors.white};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    :hover {
      background: linear-gradient(
          54deg,
          rgba(76, 148, 255, 0.64) 15.35%,
          rgba(242, 125, 180, 0.64) 83.24%
        ),
        #fff;
      box-shadow: 0px 8px 32px 0px rgba(76, 148, 255, 0.4);
    }
  }
`;
