import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 0 0 19.688rem;
  min-height: 18.375rem;
  max-height: 20.188rem;
  position: relative;
  width: 19.688rem;

  @media (max-width: 1460px) {
    flex: 0 0 18.75rem;
    width: 18.75rem;
  }

  @media (max-width: 1350px) {
    flex: 0 0 17.5rem;
    width: 17.5rem;
  }

  @media (max-width: 1259px) {
    width: 17.5rem;
  }

  @media (max-width: 837px) {
    max-width: 100%;
  }
`;

type ContentProps = {
  active: boolean;
};

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;
  min-height: 18.375rem;
  max-height: 20.188rem;
  border: ${({ active, theme }) =>
    active
      ? `3px solid ${theme.colors.primary}}`
      : `1px solid ${theme.colors.gray2}}`};
  box-sizing: border-box;
  border-radius: 8px;
  word-break: break-word;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 6.4px;

    position: absolute;
    top: 1.5rem;
    left: 1rem;

    background-color: white;
  }

  span {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};
    margin-top: 1rem;
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray7};
    margin-top: 0.25rem;
  }

  transition: 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

interface CardHead {
  active: boolean;
}

export const CardHead = styled.div<CardHead>`
  width: 100%;
  background: rgba(76, 148, 255, 0.2);
  border-radius: 8px 8px 0px 0px;

  ${({ active }) =>
    active
      ? css`
          padding: 1.375rem 1.375rem 0 0;
          min-height: 3.875rem;
        `
      : css`
          padding: 1.5rem 1.5rem 0 0;
          min-height: 4rem;
        `}

  transition: 0.3s ease-in-out;
`;

interface CardBody {
  active: boolean;
}

export const CardBody = styled.div<CardBody>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  transition: 0.3s ease-in-out;

  ${({ active }) =>
    active
      ? css`
          padding: 1.875rem 0.875rem 0.875rem 0.875rem;
        `
      : css`
          padding: 1.875rem 1rem 1rem 1rem;
        `}

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  span {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray4};
    margin-top: 0.75rem;
  }

  div {
    display: flex;
    flex-direction: row;
  }

  > p:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
    margin-top: 0.5rem;
    margin-right: 0.25rem;
  }
`;
