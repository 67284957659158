import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useTranslation } from 'react-i18next';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { getChartColor } from 'src/utils/getChartColor';
import light from 'src/styles/themes/light';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { SeriesClickEventObject } from 'highcharts';
import { format, subDays } from 'date-fns';
import HighchartsReact from 'highcharts-react-official';
import { convertQuarterlyToDate } from 'src/utils/convertQuarterlyToDate';
import { convertDateToQuarterly } from 'src/utils/convertDateToQuarterly';
import { AIUserSelectionFrequency } from 'src/models/redux/reducers/AIUserSelectionOptions';

import { AISelectionResultsContext } from '..';
import { ContainerChart } from './styles';

interface ResultsChartProps {
  chartFrequency: AIUserSelectionFrequency;
}

export const ResultsChart: React.FC<ResultsChartProps> = ({
  chartFrequency,
}) => {
  const {
    xSelected: xAndYSelected,
    selectX,
    isLoadingChart,
    dataLevel,
    dataVariation,
    canSelectForecast,
  } = useContext(AISelectionResultsContext);

  const workspace = useSelector((state: RootState) => state.workspace);

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const {
    results: { transformations },
  } = useSelector((state: RootState) => state.workspaceProjectionsOptions);

  const { t: translate } = useTranslation();

  const handleChartClick = useCallback(
    (event: SeriesClickEventObject) => {
      if (event.point.x) {
        let x = '';
        if (chartFrequency === 'yearly') {
          x = format(new Date(event.point.x), 'yyyy');
        } else if (chartFrequency === 'quarterly') {
          x = convertDateToQuarterly(new Date(event.point.x));
        } else {
          x = format(new Date(event.point.x), 'yyyy-MM-dd');
        }

        if (
          dataLevel?.historical.date.includes(x) ||
          (canSelectForecast && dataLevel?.forecast.date.includes(x))
        ) {
          selectX(x);
        }
      }
    },
    [canSelectForecast, dataLevel, chartFrequency, selectX],
  );

  const selectBarAndVariation = useCallback(() => {
    if (xAndYSelected?.date) {
      chartRef.current?.chart.series.forEach((serie) => {
        let indexAux = -1;

        serie.points?.forEach((point, index) => {
          if ((point as any)?.borderColor) {
            point.update({
              /// resetar
              borderColor: undefined,
              borderWidth: undefined,
              marker: {
                fillColor: undefined,
                lineColor: undefined,
              },
            });
          }

          if (chartFrequency === 'yearly') {
            if (xAndYSelected.x === format(new Date(point.x), 'yyyy')) {
              indexAux = index;
            }
          }
          if (chartFrequency === 'quarterly') {
            if (xAndYSelected.x === convertDateToQuarterly(new Date(point.x))) {
              indexAux = index;
            }
          }

          if (xAndYSelected.x === format(new Date(point.x), 'yyyy-MM-dd')) {
            indexAux = index;
          }
        });

        if (indexAux !== undefined && indexAux !== -1) {
          serie.points[indexAux].update({
            borderColor: light.colors.secondaryLight,
            borderWidth: 2,
            marker: {
              fillColor: light.colors.secondaryLight,
              lineColor: light.colors.secondaryLight,
            },
          });
        }
      });
    }
  }, [chartFrequency, xAndYSelected]);

  useEffect(() => {
    selectBarAndVariation();
  }, [
    xAndYSelected,
    dataLevel,
    chartFrequency,
    transformations,
    selectBarAndVariation,
  ]);

  useEffect(() => {
    if (xAndYSelected?.x) {
      selectX(xAndYSelected?.x);
    }
  }, [dataLevel, chartFrequency, transformations, xAndYSelected?.x, selectX]);

  const dataChart = useMemo(() => {
    const dataChartAux: HChartsSeries[] = [];

    function getVariationLegendNameThroughFrequency() {
      const dailyWeeklyFortnightlyArr = ['daily', 'weekly', 'fortnightly'];

      const isOriginal = chartFrequency === 'original';
      const isOriginalFrequencyAnnual = workspace.frequency === 'annual';
      const originalFrequencyIsDailyWeeklyFortnightly =
        dailyWeeklyFortnightlyArr.includes(workspace.frequency ?? '');

      if (
        (isOriginal && isOriginalFrequencyAnnual) ||
        chartFrequency === 'yearly'
      ) {
        return translate('workspaceProjectionsResultsVariationAnnual');
      }

      if (
        (isOriginal && originalFrequencyIsDailyWeeklyFortnightly) ||
        dailyWeeklyFortnightlyArr.includes(chartFrequency)
      ) {
        return translate('workspaceProjectionsResultsVariationOriginal');
      }

      return translate('workspaceProjectionsResultsVariationMonthlyQuarterly');
    }

    if (transformations.includes('Level') && dataLevel) {
      dataChartAux.push(
        {
          data: dataLevel.historical.date.map((date, index) => ({
            x:
              chartFrequency !== 'quarterly'
                ? new Date(`${date}T00:00`).getTime()
                : convertQuarterlyToDate(String(date)).getTime(),
            y: dataLevel.historical.value[index],
            custom: {
              tooltipTitle: translate('workspaceProjectionsResultLevel'),
              keyValue: translate('Historical'),
              value: `${formatCompactNotation(
                dataLevel.historical.value[index],
              )}`,
              date:
                chartFrequency !== 'quarterly' && chartFrequency !== 'yearly'
                  ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                  : date,
            },
          })),
          name: translate('workspaceProjectionsResultLevel'),
          type: 'column',
          yAxis: 0,
          showInLegend: true,
          color: getChartColor(0),
          zIndex: -1,
          cursor: 'pointer',
        },
        {
          data: dataLevel.forecast.date.map((date, index) => ({
            x:
              chartFrequency !== 'quarterly'
                ? new Date(`${date}T00:00`).getTime()
                : convertQuarterlyToDate(String(date)).getTime(),
            y: dataLevel.forecast.value[index],
            custom: {
              tooltipTitle: translate('workspaceProjectionsResultLevel'),
              keyValue: translate('Forecast'),
              value: `${formatCompactNotation(
                dataLevel.forecast.value[index],
              )}`,
              date:
                chartFrequency !== 'quarterly' && chartFrequency !== 'yearly'
                  ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                  : date,
            },
          })),
          name: translate('workspaceProjectionsResultLevel'),
          type: 'column',
          dashStyle: 'Solid',
          yAxis: 0,
          showInLegend: false,
          color: `${getChartColor(0)}7E`,
          zIndex: -1,
          cursor: canSelectForecast ? 'pointer' : undefined,
        },
      );
    }

    if (transformations.includes('Variation') && dataVariation) {
      dataChartAux.push(
        {
          data: dataVariation.historical.date.map((date, index) => ({
            x:
              chartFrequency !== 'quarterly'
                ? new Date(`${date}T00:00`).getTime()
                : convertQuarterlyToDate(String(date)).getTime(),
            y: dataVariation.historical.value[index],
            custom: {
              tooltipTitle: translate('workspaceProjectionsResultVariation'),
              keyValue: translate('Historical'),
              value: `${formatCompactNotation(
                dataVariation.historical.value[index],
              )}%`,
              date:
                chartFrequency !== 'quarterly' && chartFrequency !== 'yearly'
                  ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                  : date,
            },
          })),
          name: getVariationLegendNameThroughFrequency(),
          type: 'line',
          showInLegend: true,
          yAxis: 1,
          color: light.colors.secondaryLight,
          marker: {
            enabledThreshold: 2,
            radius: 4,
            symbol: 'circle',
            fillColor: light.colors.white,
            lineWidth: 3,
            lineColor: light.colors.secondaryLight,
          },
          cursor: 'pointer',
        },
        {
          data: dataVariation.forecast.date.map((date, index) => ({
            x:
              chartFrequency !== 'quarterly'
                ? new Date(`${date}T00:00`).getTime()
                : convertQuarterlyToDate(String(date)).getTime(),
            y: dataVariation.forecast.value[index],
            custom: {
              tooltipTitle: translate('workspaceProjectionsResultVariation'),
              keyValue: translate('Forecast'),
              value: `${formatCompactNotation(
                dataVariation.forecast.value[index],
              )}%`,
              date:
                chartFrequency !== 'quarterly' && chartFrequency !== 'yearly'
                  ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                  : date,
            },
          })),
          name: getVariationLegendNameThroughFrequency(),
          type: 'line',
          showInLegend: false,
          yAxis: 1,
          color: light.colors.secondaryLight,
          dashStyle: 'Dash',
          marker: {
            enabledThreshold: 2,
            radius: 4,
            symbol: 'circle',
            fillColor: light.colors.white,
            lineWidth: 3,
            lineColor: light.colors.secondaryLight,
          },
          cursor: canSelectForecast ? 'pointer' : undefined,
        },
      );
    }

    return dataChartAux;
  }, [
    canSelectForecast,
    dataLevel,
    dataVariation,
    chartFrequency,
    transformations,
    translate,
    workspace.frequency,
  ]);

  const options: HChartsOptions = useMemo(() => {
    const quarterlyXaxis =
      chartFrequency === 'quarterly'
        ? dataLevel?.historical.date
            .map((date) => String(date))
            .concat(dataLevel.forecast.date.map((date) => String(date)))
        : [];

    return {
      xAxis:
        chartFrequency === 'quarterly'
          ? {
              min: quarterlyXaxis?.length
                ? convertQuarterlyToDate(quarterlyXaxis[0]).getTime()
                : undefined,
              labels: {
                formatter() {
                  return convertDateToQuarterly(
                    // eslint-disable-next-line react/no-this-in-sfc
                    subDays(new Date(this.value), 1),
                  );
                },
              },
            }
          : {
              min: undefined,
              labels: {
                formatter: undefined,
              },
            },
      yAxis: [
        {
          visible: transformations.includes('Level'),
          lineWidth: 0,
          gridLineWidth: 1,
          gridLineColor: light.colors.gray2,
          showLastLabel: true,
          labels: {
            format: '{text}',
            style: {
              fontSize: '12px',
              fontFamily: "'Inter', sans-serif",
            },
          },
          title: {
            text: '',
          },
          opposite: false,
          zIndex: 0,
        },
        {
          visible: transformations.includes('Variation'),
          lineWidth: 0,
          gridLineWidth: 1,
          gridLineColor: light.colors.gray2,
          showLastLabel: true,
          labels: {
            format: '{text}%',
            style: {
              fontSize: '12px',
              fontFamily: "'Inter', sans-serif",
            },
          },
          title: {
            text: '',
          },
          opposite: true,
        },
      ],
      tooltip: {
        headerFormat: '',
        pointFormat:
          '<table><tr><th colspan="2">{point.custom.tooltipTitle}</th></tr>' +
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.custom.date}</td></tr>` +
          `<tr><td><b>{point.custom.keyValue}:</b> </td>` +
          `<td style="text-align: right">{point.custom.value}</td></tr>`,
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: (event) => {
              const yAxisIndex =
                event.target.name ===
                translate('workspaceProjectionsResultLevel')
                  ? 0
                  : 1;
              chartRef.current?.chart.series.forEach((serie) => {
                if (serie.name === event.target.name) {
                  if (serie.visible) {
                    //@ts-expect-error:ignora
                    serie.update({ visible: false }, true);
                    chartRef.current?.chart.yAxis[yAxisIndex].update({
                      visible: false,
                    });
                  } else {
                    //@ts-expect-error:ignora
                    serie.update({ visible: true }, true);
                    chartRef.current?.chart.yAxis[yAxisIndex].update({
                      visible: true,
                    });
                  }
                }
              });
              selectBarAndVariation();

              return false; // Impede o comportamento padrão do clique na legenda
            },
          },
          point: {
            events: {
              click: (event) => handleChartClick(event),
            },
          },
        },
      },
    };
  }, [
    dataLevel?.forecast.date,
    dataLevel?.historical.date,
    chartFrequency,
    handleChartClick,
    selectBarAndVariation,
    transformations,
    translate,
  ]);

  return (
    <>
      {isLoadingChart ? (
        <ContainerSkeleton data-testid="result-loading" />
      ) : (
        <ContainerChart
          data-testid="result-container-chart"
          className="result-container-chart"
        >
          <div>
            <h4>
              {transformations.includes('Level')
                ? translate('workspaceProjectionsResultLevel')
                : ''}
            </h4>

            {transformations.includes('Variation') && (
              <h4>{translate('workspaceProjectionsResultVariation')}</h4>
            )}
          </div>

          <HCharts
            series={dataChart}
            options={options}
            ref={chartRef}
            frequency={
              chartFrequency === 'original'
                ? workspace.frequency!
                : chartFrequency
            }
            immutable={false}
            dataCy="results-chart"
          />
        </ContainerChart>
      )}
    </>
  );
};
