const exportSeriesPTBR = {
  exportSeriesErrorDownload: 'Ocorreu um erro ao baixar o arquivo.',
  exportSeries: 'Quando finalizar, clique em “Exportar” para exportar a série.',
  exportSeriesLimitExport:
    'Não é permitido a exportação de mais de 100 séries ao mesmo tempo.',
  exportSeriesMaintenance:
    'Uma ou mais séries do conjunto selecionado para exportação, não estarão disponíveis por estar em manutenção.',
  warningToUpgrade:
    'Você não possui acesso a todas as séries. <strong>Faça upgrade</strong> para desbloquear.',
};

export default exportSeriesPTBR;
