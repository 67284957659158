import styled from 'styled-components';

export const ContainerDependentVariable = styled.div`
  position: relative;

  margin-bottom: 24px !important;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    > div:first-child {
      margin-bottom: 0;
    }
  }
`;

export const ContentTableDependentVariables = styled.div`
  position: relative;

  height: 25rem;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    margin-top: 2.375rem;
  }

  > table {
    > thead {
      > tr {
        position: sticky;
        top: 0px;
        z-index: 99;
        background: ${({ theme }) => theme.colors.white};

        > th {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors.gray5} !important;

          > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            > p {
              text-align: center;
            }

            > button {
              width: fit-content;
              height: 1rem;
            }
          }
        }
      }
    }

    > tbody {
      > tr {
        > td {
          div:first-child {
            display: flex;
            align-items: center;
            gap: 1rem;

            font-weight: 500;
          }
        }
      }
    }
  }
`;

interface MenuOverlayProps {
  visible: boolean;
}

export const MenuOverlay = styled.div<MenuOverlayProps>`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

interface ButtonIcon {
  color: string;
  disabled: boolean;
}

export const ButtonIcon = styled.button<ButtonIcon>`
  display: flex;
  align-items: center;
  color: ${({ color, disabled, theme }) =>
    !disabled ? color : theme.colors.gray3};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const ContentChart = styled.div`
  margin-top: 1.5rem;
`;

export const SelectVariable = styled.div`
  padding: 0.5rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 12.5rem;
    height: 9.375rem;

    margin-bottom: 2rem;
  }

  h4 {
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;
  }

  p {
    width: 60%;

    text-align: center;

    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

interface TableSortButtonProps {
  active: boolean;
}

export const TableSortButton = styled.button<TableSortButtonProps>`
  width: 1.5rem;
  height: 1.5rem;

  :disabled {
    cursor: wait;
  }

  svg {
    color: ${({ active, theme }) =>
      active ? theme.colors.primary : theme.colors.gray4};
  }
`;
