export const CreateWorkspaceENUSTranslations = {
  createWorkspaceHeadTitle: 'Create Workspace',
  editWorkspaceHeadTitle: 'Edit Workspace',
  createWorkspaceCreateTitle: 'Create your Workspace',
  editWorkspaceEditTitle: 'Edit your Workspace',
  createWorkspaceCreateDescription: 'Fill in the basic information.',
  createWorkspaceSelectVariablesTitle: 'Select the Variables',
  createWorkspaceSelectVariablesDescription:
    'Select the projects and variables to compose your results.',
  createWorkspaceIcon: 'Icon',
  createWorkspaceIconTooltip:
    'This icon will help you easily identify your Workspace further on.',
  createWorkspaceIconModalTitle: 'Choose your icon',
  createWorkspaceIconModalDescription:
    'This icon will help you easily identify your Workspace further on.',
  createWorkspaceName: 'Name',
  createWorkspaceNamePlaceholder: 'Enter the Workspace name',
  createWorkspaceDescription: 'Description',
  createWorkspaceDescriptionPlaceholder:
    'Enter a description for your Workspace',
  createWorkspaceCreate: 'Create Workspace',
  createWorkspaceDescriptionErrorMaxCharacters:
    'Must not be more than 350 characters',
  createWorkspaceNoProjectSelectedTitle: "You haven't selected any project",
  createWorkspaceNoProjectSelectedDescription:
    'Click the button below and select one or more projects that you created previously.',
  createWorkspaceSelectProjectsButton: 'Select projects',
  createWorkspaceAllProjects: 'All projects',
  createWorkspaceAddNewProject: 'Add new project',
  createWorkspaceOriginalVariable: 'Original Name',
  createWorkspaceVariable: 'Variable',
  createWorkspaceProject: 'Project',
  createWorkspaceRepeatedVariable:
    'This variable is duplicated in another project, please rename it',
  createWorkspaceVariableNameError:
    'Please check if there is any variable with invalid name.',
  createWorkspaceVariableModelIDError:
    'Please check if there is any variable with invalid Model ID.',
  createWorkspaceModelUpdate: 'Model Update',
  createWorkspaceModelId: 'Model ID',
  createWorkspaceModelIdRequiredField: 'Required field',
  createWorkspaceModelIdInvalid: 'Invalid Model ID',
  createWorkspaceModalErrorTitle: 'Something went wrong',
  createWorkspaceModalErrorDescription:
    'An error occurred while XXX your workspace.',
  createWorkspaceVariableCategorization: 'Variable Categorization',
  createWorkspaceVariableCategorizationDescription:
    'Categorize your variables into different levels and see how the filters will look.',
  createWorkspaceCreateVariableCategorization: 'Create categorization',
  createWorkspaceSaveVariableCategorization: 'Save changes',
  createWorkspaceConfirmCloseCategorizationTitle: 'Are you sure?',
  createWorkspaceConfirmCloseCategorizationDescription:
    'You will lose all unsaved changes',
  createWorkspaceEditVariableCategorization: 'Edit categorization',
  createWorkspaceVariableCategorizationDisabled:
    "It's necessary to have at least one variable selected and for all of them to have a valid name.",
  createWorkspaceSaveChangesError:
    'Check if there are any filter/option names with errors.',
  createWorkspaceShowOriginalVariableName: 'Original variable name',
  editWorkspaceSaveChanges: 'Save changes',
  editWorkspaceDiscardChanges: 'Discard',
  createWorkspaceModalErrorDescriptionCreateText: 'creating',
  createWorkspaceModalErrorDescriptionSaveText: 'saving',
  createWorkspaceFilterOptionName: 'Enter the option name',
  editWorkspaceEditionExpired:
    'Your editing session has expired. Would you like to renew it for another 5 minutes?',
  editWorkspaceExitEdition: 'Exit editing',
};
