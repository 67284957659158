import styled from 'styled-components';

interface Container {
  disabled?: boolean;
}

export const Container = styled.label<Container>`
  position: relative;
  display: flex;
  align-items: center;

  width: fit-content;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  padding-left: 1.5rem;

  p {
    font-size: 1rem;
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.gray3 : theme.colors.gray5};
    font-weight: 400;
    word-break: break-word;
  }

  input {
    display: none;

    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  span {
    display: inline-block;
    position: absolute;

    left: 0;
    top: 0.1rem;

    width: 1rem;
    height: 1rem;

    background: white;

    border: 2px solid ${({ theme }) => theme.colors.gray3};
    border-radius: 100px;
  }

  input:checked + span {
    border: 5px solid ${({ theme }) => theme.colors.primary};
  }

  input:indeterminate + span {
    border: 2px solid ${({ theme }) => theme.colors.gray3};
  }

  input:checked:disabled + span {
    border: 5px solid ${({ theme }) => theme.colors.gray3};
  }

  input:disabled + span {
    border: 2px solid ${({ theme }) => theme.colors.gray3};
  }
`;
