import styled, { keyframes } from 'styled-components';

const start = keyframes`
  from{
    opacity: 0;
    backdrop-filter: blur(0);
    transform: translate3d(0,-60px,0);
  }
  to{
    opacity:1;
    backdrop-filter: blur(8px);
    transform:translate3d(0,0,0);
  }
`;

type ContainerProps = {
  visible: boolean;
};

type ContentProps = {
  bigNumbersVisible: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 100000;

  display: ${({ visible }) => (visible ? 'flex' : 'none')};

  animation: ${start} 0.75s;

  .ai-chat-container {
    background: ${({ theme }) => theme.colors.white};
    border: 4px solid ${({ theme }) => `${theme.colors.gray4}3D`};
    border-radius: 16px;
  }
`;

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 100001;

  background: ${({ theme }) => `${theme.colors.gray2}66`};
  backdrop-filter: blur(8px);
`;

export const Content = styled.div<ContentProps>`
  width: ${({ bigNumbersVisible }) =>
    bigNumbersVisible ? '75rem' : '62.5rem'};
  height: 85vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  z-index: 100002;

  @media (max-width: 1366px) {
    height: 91vh;
    width: ${({ bigNumbersVisible }) => (bigNumbersVisible ? '100%' : '80%')};

    gap: 1rem;
  }

  @media (max-width: 950px) {
    width: ${({ bigNumbersVisible }) => (bigNumbersVisible ? '100%' : '80%')};
  }

  transition: all 0.2s;
`;

export const WelcomeContent = styled.div`
  width: 100%;
  min-height: 30.75rem;
  max-height: 30.75rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: relative;

  z-index: 100003;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ai-chat-background {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    z-index: -1;
  }

  .ai-chat-background-logo {
    height: 14.5rem;

    margin-top: 5rem;
  }

  @media (max-width: 1366px) {
    .ai-chat-background-logo {
      margin-top: 5rem;
    }
  }

  @media (max-width: 600px) {
    .ai-chat-background-logo {
      height: 8rem;
    }
  }
`;

export const WelcomeText = styled.div`
  width: 30.5rem;

  text-align: center;

  margin-bottom: 2.5rem;

  h2 {
    color: ${({ theme }) => theme.colors.gray6};

    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;

    margin-bottom: 1rem;
  }

  p {
    color: ${({ theme }) => theme.colors.gray4};

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const QuestionForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;

  padding: 1rem;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;

    flex: 1;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.red2};
    }
  }

  > svg {
    width: 3rem;
    height: 3rem;
  }

  > button {
    width: 3rem;
    height: 3rem;

    padding: 0;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      margin: 0;
    }
  }

  @media (max-width: 1366px) {
    > svg {
      width: 2rem;
      height: 2rem;
    }

    > button {
      width: 2rem;
      height: 2rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: auto;

  flex: 1;

  font-size: 1.125rem !important;
  font-weight: 500;
  line-height: 1.6875rem !important;

  color: ${({ theme }) => theme.colors.gray6};

  border: none;
  resize: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray4};
  }

  :disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.white};
  }

  :focus {
    outline: none;
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }

  @media (max-width: 1366px) {
    font-size: 1rem !important;
  }
`;

export const ResponseContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  max-height: calc(85vh - 5.063rem);

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
    left: 100px;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }

  @media (max-width: 1366px) {
    max-height: calc(91vh - 5.063rem);

    gap: 1rem;
  }
`;

export const StatusContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingDots = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  @keyframes loading {
    0%,
    80%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }

  div {
    display: inline-block;

    width: 0.5rem;
    height: 0.5rem;

    background-color: ${({ theme }) => theme.colors.gray4};
    border-radius: 50%;
    animation: loading 1.5s infinite ease-in-out;
  }

  div:nth-child(1) {
    animation-delay: 0.2s;
  }

  div:nth-child(2) {
    animation-delay: 0.6s;
  }

  div:nth-child(3) {
    animation-delay: 1s;
  }
`;

export const ChatContainer = styled.div`
  width: 100%;

  display: flex;
  gap: 1.5rem;
`;

export const GridContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;

  gap: 1.5rem;

  z-index: 100002;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  margin-bottom: 6.25rem;

  div {
    display: flex;
    flex-direction: column;

    gap: 0.5rem;
  }

  button:first-child {
    margin-top: calc(-1rem - 4px);
  }

  @media (max-width: 1366px) {
    margin-bottom: 5.25rem;

    button:first-child {
      margin-top: -4px;
    }
  }
`;

export const IconButton = styled.button`
  display: flex;
  padding: 0.75rem;

  gap: 0.625rem;

  background: ${({ theme }) => theme.colors.white};
  border: 4px solid ${({ theme }) => `${theme.colors.gray4}3D`};
  border-radius: 50%;

  transition: all 0.2s;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }

  &:hover {
    border: 4px solid ${({ theme }) => `${theme.colors.gray4}66`};
  }
`;
