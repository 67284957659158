import React, { createContext } from 'react';

import { Card } from 'src/components/Card';
import { useTranslation } from 'react-i18next';
import { WorkspaceColumn } from 'src/models/redux/reducers/Workspace';

import { WorkspaceOverviewContainer } from './styles';
import { WorkspaceProjections } from './components/Projections';
import { WorkspaceSummary } from './components/Summary';

export const sortedColumn: WorkspaceColumn[] = [
  'Model',
  'MAPE',
  'WMAPE',
  'MPE',
  'RMSE',
  'MASE',
  'MASEs',
  'Transformation',
];

interface WorkspacesOverviewContextType {
  openModalEditColumns: () => void;
  closeModalEditColumns: () => void;
}

export const WorkspaceOverviewContext = createContext(
  {} as WorkspacesOverviewContextType,
);

export const WorkspaceOverview: React.FC = () => {
  const { t } = useTranslation();

  return (
    <WorkspaceOverviewContainer>
      <Card
        textCard={t('workspaceOverviewTitle')}
        textDescription={t('workspaceOverviewDescription')}
        className="containerLinear"
      />

      <WorkspaceProjections />
      <WorkspaceSummary />
    </WorkspaceOverviewContainer>
  );
};
