import React from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { ChartPloty } from 'src/components/ChartPloty';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import light from 'src/styles/themes/light';
import { getChartColor } from 'src/utils/getChartColor';

// import { Container } from './styles';

interface FeatureImportanceProps {
  isFetching: boolean;
  isLoading: boolean;
  isErrored: boolean;
  data:
    | {
        name: string;
        value: number;
      }[]
    | undefined;
}

export const FeatureImportanceChart: React.FC<FeatureImportanceProps> = ({
  data,
  isLoading,
  isFetching,
  isErrored,
}) => {
  const { t: translate } = useTranslation();

  return (
    <div className="containerLinear">
      <Card
        textCard={translate('featureImportanceTitle')}
        textDescription={translate('featureImportanceDescr')}
      />

      {isErrored ? (
        <ContainerMaintenance
          content="chart"
          text={translate('featureImportanceAlert')}
          data-testid="feature-importance-fail"
        />
      ) : isLoading || isFetching || !data ? (
        <ContainerSkeleton />
      ) : (
        data && (
          <ChartPloty
            data={[
              {
                x: data?.map((feature) => feature.value.toFixed(2)),
                y: data?.map((feature) => feature.name),
                type: 'bar',
                orientation: 'h',
                marker: {
                  color: getChartColor(0),
                },
                opacity: 0.8,
                text: data?.map((feature) => `${feature.value.toFixed(2)}  `),
                hoverinfo: 'skip',
                textposition: 'inside',
                textfont: {
                  color: light.colors.white,
                  size: 26,
                },
              },
            ]}
            layout={{
              showlegend: false,
              font: {
                size: 14,
                family: 'Inter, sans-serif',
                color: light.colors.gray6,
              },
              yaxis: {
                color: light.colors.gray6,
                autorange: 'reversed',
                automargin: true,
              },
              xaxis: {
                zeroline: false,
                automargin: true,
                visible: false,
              },
            }}
            style={{ height: '360px', marginLeft: '25px' }}
            dataCy="chart-feature-importance"
          />
        )
      )}
    </div>
  );
};
