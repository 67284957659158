import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { Admin } from 'src/feature-store/pages/Admin';
import { GroupPremium } from 'src/feature-store/pages/Admin/GroupPremium';
import { ListIndicatorsFromGroupPremium } from 'src/feature-store/pages/Admin/GroupPremium/ListIndicatorsFromGroupPremium';
import { ListUsersFromGroupPremium } from 'src/feature-store/pages/Admin/GroupPremium/ListUsersFromGroupPremium';
import { IndicatorSeries } from 'src/feature-store/pages/Admin/IndicatorSeries';
import { PremiumGroup } from 'src/feature-store/pages/Groups/PremiumGroup';
import { GroupView } from 'src/feature-store/pages/GroupView';
import { Indicators } from 'src/feature-store/pages/Indicators';
import { VisualizeFeatures } from 'src/feature-store/pages/VisualizeFeatures';
import { DataTable } from 'src/feature-store/pages/GroupView/DataTable';
import NotFound from 'src/pages/404';

import { PrivateRoutes } from '../PrivateRoute';

const FeatureStoreRoutes = (): React.ReactElement => (
  <Routes>
    <Route
      caseSensitive
      path="/favorites/:id"
      element={
        <PrivateRoutes
          path="/feature-store/favorites/:id"
          component={GroupView}
          roles={['isFeatureStore']}
        />
      }
    />
    <Route
      caseSensitive
      path="/favorites/:id/data/:tab?"
      element={
        <PrivateRoutes
          path="/feature-store/favorites/:id/data/:tab?"
          component={DataTable}
          roles={['isFeatureStore']}
        />
      }
    />
    <Route
      caseSensitive
      path="/premium"
      element={
        <PrivateRoutes
          path="/feature-store/premium"
          component={PremiumGroup}
          roles={['isFeatureStore']}
        />
      }
    />
    <Route
      caseSensitive
      path="/indicators/:id"
      element={
        <PrivateRoutes
          path="/feature-store/indicators/:id"
          component={VisualizeFeatures}
          roles={['isFeatureStore']}
        />
      }
    />
    <Route
      caseSensitive
      path="/indicators"
      element={
        <PrivateRoutes
          path="/feature-store/indicators"
          component={Indicators}
          roles={['isFeatureStore']}
        />
      }
    />
    <Route
      caseSensitive
      path="/admin"
      element={
        <PrivateRoutes
          path="/feature-store/admin"
          component={Admin}
          roles={['isFsAdmin']}
        />
      }
    />
    <Route
      caseSensitive
      path="/admin/groups-premium"
      element={
        <PrivateRoutes
          path="/feature-store/admin/groups-premium"
          component={GroupPremium}
          permissions={['edit:access-groups', 'read:access-groups']}
        />
      }
    />
    <Route
      caseSensitive
      path="/admin/groups-premium/:id/users"
      element={
        <PrivateRoutes
          path="/feature-store/admin/groups-premium/:id/users"
          component={ListUsersFromGroupPremium}
          permissions={['edit:access-groups']}
        />
      }
    />
    <Route
      caseSensitive
      path="/admin/groups-premium/:id/indicators"
      element={
        <PrivateRoutes
          path="/feature-store/admin/groups-premium/:id/indicators"
          component={ListIndicatorsFromGroupPremium}
          permissions={['edit:access-groups']}
        />
      }
    />
    <Route
      caseSensitive
      path="/admin/:id"
      element={
        <PrivateRoutes
          path="/feature-store/admin/:id"
          component={IndicatorSeries}
          permissions={['edit:access-groups']}
        />
      }
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default FeatureStoreRoutes;
