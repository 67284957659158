import { Binoculars } from 'phosphor-react';

import { Menu } from '.';
import { ReactComponent as logo } from '../../../../../assets/logo-sidebar.svg';

export const workspacesSideBarProjectsMenus: Menu[] = [
  {
    label: 'workspaceSideBarOverviewLabel',
    Icon: Binoculars,
    url: '/models/workspaces/:id/overview',
  },
  // {
  //   label: 'workspaceSideBarPerformanceLabel',
  //   Icon: Target,
  //   url: '/models/workspaces/:id/performance',
  //   isDisabled: true,
  // },
];

export const workspacesSideBarResultsMenus: Menu[] = [
  {
    label: 'workspaceSideBarProjectionsLabel',
    Icon: logo,
    url: '/models/workspaces/:id/projections',
  },
];
