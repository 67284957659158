import React, { useState } from 'react';

import { AxiosError } from 'axios';
import {
  ChartLine,
  DotsThreeVertical,
  DownloadSimple,
  GitFork,
  Trash,
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { ModalLoading } from 'src/components/Modal/Loading';
import { DataError } from 'src/interface/axios';
import { clearPredictRunning } from 'src/models/redux/reducers/PredictStatus';
import {
  Column,
  insert,
  LevelFilters,
  Y,
} from 'src/models/redux/reducers/Project';
import { changeIsFinish } from 'src/models/redux/reducers/ProjectOverviewExportTimeSeries';
import api from 'src/models/service/api';
import { arrayIcon, checkIconURL } from 'src/utils/handleProjectIcon';
import { format } from 'date-fns';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { resetAIUserSelectionOptions } from 'src/models/redux/reducers/AIUserSelectionOptions';
import { queryClient } from 'src/service/queryClient';
import { resetModelExplorerOptions } from 'src/models/redux/reducers/ModelExplorer';
import {
  UpdateHistoryData,
  getLatestUpdateID,
} from 'src/models/components/Layout/contexts/ModelUpdateContext';
import { RootState } from 'src/redux/store';
import {
  CardBody,
  CardContainer,
  CardContent,
  CardHead,
  CardMenuOverlay,
  CardOptionsButton,
  CardProjectTypeContent,
} from 'src/components/ProjectCard/styles';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import light from 'src/styles/themes/light';

import { DeleteProjectModal } from '../ModalExclusion';

interface ProjectCardProps {
  id: string;
  iconUrl: string;
  name: string;
  dependentVar: Y[];
  updatedAt: string;
  created: string;
  parentID: string;
  type: string;
  status: string;
  columns: Column[];
  accuracyCrit: string | null;
  businessYsStatus: {
    [key: string]: 'processing' | 'success' | 'error';
  };
  filters?: LevelFilters;
  appInfo: { engine: { name: string; version: string } } | null;
  saveSearchAndPage: () => void;
}

type DownloadingProps = {
  loading: boolean;
  update: string | undefined;
};

export const ProjectCard: React.FC<ProjectCardProps> = ({
  id,
  iconUrl,
  name,
  type,
  status,
  dependentVar,
  updatedAt,
  created,
  parentID,
  columns,
  accuracyCrit,
  businessYsStatus,
  saveSearchAndPage,
  filters,
  appInfo,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translateFormat = useFormatDateLanguage();

  const { t: translate } = useTranslation();

  const [menuVisible, setMenuVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [failModalInfo, setFailModalInfo] = useState<ErrorObject>();
  const [loadingModalVisible, setLoadingModalVisible] =
    useState<DownloadingProps>({ loading: false, update: undefined });

  const { user } = useSelector((state: RootState) => state.auth);

  async function handleAccessProject() {
    saveSearchAndPage();

    if (type !== 'classification') {
      const yBusinessAdjusted = Object.keys(businessYsStatus).map((key) => ({
        y: key,
        status: businessYsStatus[key],
      }));

      dispatch(
        insert({
          id,
          name,
          icon: iconUrl,
          y: dependentVar,
          selectedY: dependentVar[0],
          columns,
          accuracyCrit,
          yBusiness: yBusinessAdjusted,
          updatedAt,
          parentID,
          createdAt: created,
          filters,
          selectedFilters: {},
          appInfo,
        }),
      );
      dispatch(changeIsFinish(false));
      dispatch(resetAIUserSelectionOptions());
      dispatch(resetModelExplorerOptions());
      queryClient.invalidateQueries('update history');

      const latestProjectVersionID = await getLatestUpdateID(id);
      if (
        dependentVar.filter((variable) => variable.status === 'success')
          .length > 1
      ) {
        navigate(
          `/models/time-series/${latestProjectVersionID}/project-overview`,
        );
      } else {
        const isDisabledAISelection =
          yBusinessAdjusted.length === 0 ||
          yBusinessAdjusted.find((yBus) => yBus.y === dependentVar[0].id)
            ?.status === 'error';

        if (!isDisabledAISelection) {
          navigate(
            `/models/time-series/${latestProjectVersionID}/AI-selection`,
          );
        } else {
          navigate(
            `/models/time-series/${latestProjectVersionID}/user-selection`,
          );
        }
      }
    } else {
      dispatch(clearPredictRunning());
      navigate(`/models/classification/${id}/model-in-production`);
    }
  }

  async function handleDownloadProject() {
    setLoadingModalVisible({ loading: true, update: undefined });

    try {
      let updatesData;
      try {
        const { data } = await api.get<UpdateHistoryData>(
          `/projects/${id}/updates`,
        );

        updatesData = data;
        // eslint-disable-next-line no-empty
      } catch {}

      let response;
      if (updatesData && updatesData?.updates?.length) {
        const latestSuccesfulUpdate = updatesData.updates
          ?.sort(
            (a, b) => Number(new Date(b.created)) - Number(new Date(a.created)),
          )
          ?.find((update) => update.status === 'success');
        setLoadingModalVisible({
          loading: true,
          update: latestSuccesfulUpdate?.label,
        });

        const { data: downloadData } = await api.get(
          `/projects/${latestSuccesfulUpdate?.id}/download`,
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        response = downloadData;
      } else {
        const { data: downloadData } = await api.get(
          `/projects/${id}/download`,
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        response = downloadData;
      }

      if (response) {
        const fileURL = window.URL.createObjectURL(
          new Blob([response], { type: 'application/gzip' }),
        );

        const link = document.createElement('a');
        if (link.download !== undefined) {
          link.setAttribute('href', fileURL);
          link.setAttribute(
            'download',
            updatesData?.updates?.length
              ? `Forecastpack_${name}_${
                  updatesData.updates
                    ?.sort(
                      (a, b) =>
                        Number(new Date(b.created)) -
                        Number(new Date(a.created)),
                    )
                    ?.find((update) => update.status === 'success')?.label
                }.zip`
              : `Forecastpack_${name}.zip`,
          );
          link.setAttribute('data-testid', 'download-start');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
        }
      }
      setLoadingModalVisible({ loading: false, update: undefined });
    } catch (err) {
      const error: AxiosError<DataError> | any = err;
      const errorMessage =
        error?.response?.data?.detail?.detail ??
        error?.response?.data?.detail?.description ??
        translate('downloadError');

      setFailModalInfo({
        title: translate('requestFailed'),
        description: errorMessage,
      });
      setLoadingModalVisible({ loading: false, update: undefined });
    }
  }

  return (
    <>
      <CardContainer
        disabled={!(status === 'success' || status === 'partial_success')}
      >
        <CardContent
          onClick={() =>
            (status === 'success' || status === 'partial_success') &&
            handleAccessProject()
          }
          data-testid={`access-project-${name
            .replaceAll(' ', '-')
            .toLowerCase()}`}
          data-cy={`access-project-${name.replaceAll(' ', '-').toLowerCase()}`}
          disabled={!(status === 'success' || status === 'partial_success')}
        >
          <img
            src={checkIconURL(iconUrl) ? iconUrl : arrayIcon[0]}
            alt="project-icon"
          />
          <CardHead projectType={type}>
            <CardProjectTypeContent projectType={type}>
              {type === 'classification' ? (
                <GitFork size="1rem" />
              ) : (
                <ChartLine size="1rem" />
              )}
              <p>
                {translate(
                  type === 'classification' ? 'classification' : 'timeSeries',
                )}
              </p>
            </CardProjectTypeContent>
          </CardHead>

          <CardBody>
            <h5>{name}</h5>
            <span>{translate('projectHeadDependVar')}</span>
            <div
              data-testid={`project-${name.replaceAll(
                ' ',
                '-',
              )}-dependent-variable`}
            >
              {dependentVar.length > 1 ? (
                (() => (
                  // eslint-disable-next-line react/jsx-indent
                  <>
                    <p>
                      {`${
                        dependentVar[0].name.length <= 50
                          ? dependentVar[0].name
                          : `${dependentVar[0].name.slice(0, 47)}...`
                      } `}
                      <span
                        data-tooltip-id="models-my-projects-dependent-variables"
                        data-tooltip-content={dependentVar
                          .slice(1, dependentVar.length)
                          .map((y) => y.name)
                          .toString()}
                      >
                        <Tooltip
                          id="models-my-projects-dependent-variables"
                          className="customTooltipTheme customTooltipMyProjects"
                        />
                        {`+${dependentVar.length - 1} ${translate(
                          'projectTooltip',
                        )}`}
                      </span>
                    </p>
                  </>
                ))()
              ) : dependentVar[0].name.length <= 50 ? (
                <p>{dependentVar[0].name}</p>
              ) : (
                <p>{`${dependentVar[0].name.slice(0, 47)}...`}</p>
              )}
            </div>
            <span>{translate('projectHeadLastUpdated')}</span>
            <p
              data-testid={format(
                new Date(updatedAt),
                `${translateFormat}, HH:mm`,
              ).slice(0, 10)}
            >
              {format(new Date(updatedAt), `${translateFormat}, HH:mm`)}
            </p>
          </CardBody>
        </CardContent>

        <CardOptionsButton
          data-testid={`options-button-${name
            .replaceAll(' ', '-')
            .toLowerCase()}`}
          data-cy={`options-button-${name.replaceAll(' ', '-').toLowerCase()}`}
          projectType={type}
          onClick={() => setMenuVisible(true)}
        >
          <DotsThreeVertical size="1.25rem" />
        </CardOptionsButton>
        <MenuContainer
          visible={menuVisible}
          style={{ top: '3rem', right: '1.5rem' }}
          data-testid="actions-menu"
        >
          {menuVisible && (
            <CardMenuOverlay
              data-testid="menu-overlay"
              visible={menuVisible}
              onClick={() => setMenuVisible((state) => !state)}
            />
          )}
          {!type.includes('classification') && (
            <MenuOption
              position="start"
              color={
                type === 'classification'
                  ? light.colors.secondary
                  : light.colors.primary
              }
              type="button"
              data-testid={`menu-button-download-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              data-cy={`menu-button-download-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              onClick={() => {
                handleDownloadProject();
                setMenuVisible(false);
              }}
              disabled={
                !(status === 'success' || status === 'partial_success') ||
                loadingModalVisible.loading ||
                type === 'classification'
              }
            >
              <DownloadSimple size="1.125rem" />
              <p>{translate('download')}</p>
            </MenuOption>
          )}
          <MenuOption
            position={type.includes('classification') ? 'alone' : 'end'}
            color={
              type === 'classification'
                ? light.colors.secondary
                : light.colors.primary
            }
            type="button"
            data-testid={`menu-button-delete-${name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            data-cy={`menu-button-delete-${name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            onClick={() => {
              setDeleteModalVisible(true);
              setMenuVisible(false);
            }}
            disabled={
              !(
                status === 'success' ||
                status === 'partial_success' ||
                status === 'error'
              ) ||
              loadingModalVisible.loading ||
              !user.permissions.includes('create:projects')
            }
          >
            <Trash size="1.125rem" />
            <p>{translate('delete')}</p>
          </MenuOption>
        </MenuContainer>
      </CardContainer>

      <ModalLoading
        visible={loadingModalVisible.loading}
        message={translate('myProjectsDownloadProject')}
        subMessage={
          loadingModalVisible?.update && translate('myProjectsDownloadUpdate')
        }
      />

      {deleteModalVisible && (
        <DeleteProjectModal
          project={{
            id,
            name,
            type,
          }}
          visible={deleteModalVisible}
          setVisible={setDeleteModalVisible}
          setError={setFailModalInfo}
        />
      )}

      {failModalInfo && (
        <FailedModal
          visible={!!failModalInfo}
          setVisible={(value) => value === false && setFailModalInfo(undefined)}
          errorInfo={failModalInfo}
        />
      )}
    </>
  );
};
