import React from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { arrayIcon, checkIconURL } from 'src/utils/handleProjectIcon';
import Star from 'src/assets/star-yellow.svg';

import { Group, GroupFeatureStoreWithDummy } from '../types';
import {
  CheckBoxFeatureStore,
  Container,
  Content,
  DivCheckBox,
  PremiumDiv,
} from './styles';

interface GroupCardProps {
  group: Group;
  groupChecked: GroupFeatureStoreWithDummy[];
  setGroupChecked: (value: GroupFeatureStoreWithDummy[]) => void;
  onClick: () => void;
  disabled: boolean;
  checkboxChecked: boolean;
  checkboxDisabled: boolean;
}

export const GroupCard: React.FC<GroupCardProps> = ({
  group,
  groupChecked,
  setGroupChecked,
  onClick,
  disabled,
  checkboxChecked,
  checkboxDisabled,
}) => {
  const { t: translate } = useTranslation();

  const translateFormat = useFormatDateLanguage();

  const handleClickSelectedGroup = () => {
    const groupAux = groupChecked.find(
      (groupCheckedAux) => group.id === groupCheckedAux.id,
    );

    const seriesOnlyMonthly = group.series.filter(
      (serie) =>
        serie.aggregation['en-us'].includes('Monthly') && serie.is_accessible,
    );
    if (groupAux) {
      if (groupAux.series.length === seriesOnlyMonthly.length) {
        setGroupChecked(
          groupChecked.filter(
            (groupCheckedAux) => groupCheckedAux.id !== group.id,
          ),
        );
      } else {
        setGroupChecked([
          ...groupChecked.filter(
            (groupCheckedAux) => groupCheckedAux.id !== group.id,
          ),
          {
            id: group.id,
            series: seriesOnlyMonthly.map((serie) => ({
              code: serie.code,
              tag: serie.tag,
              isDummy: serie.is_dummy,
            })),
          },
        ]);
      }

      return;
    }
    setGroupChecked([
      ...groupChecked,
      {
        id: group.id,
        series: seriesOnlyMonthly.map((serie) => ({
          code: serie.code,
          tag: serie.tag,
          isDummy: serie.is_dummy,
        })),
      },
    ]);
  };

  return (
    <>
      <Container
        key={group.name}
        data-testid={`group-card-${group.name
          .replaceAll(' ', '-')
          .toLowerCase()}`}
        disabled={disabled}
      >
        <DivCheckBox>
          <CheckBoxFeatureStore
            label=""
            onChange={() => handleClickSelectedGroup()}
            checked={checkboxChecked}
            data-testid={`checkbox-${group.name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            data-cy={`checkbox-${group.name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            disabled={checkboxDisabled || disabled}
          />
        </DivCheckBox>
        <Content
          data-testid={`group-access-${group.name
            .replaceAll(' ', '-')
            .toLowerCase()}`}
          onClick={onClick}
        >
          <img
            data-testid={`group-img-${group.name.toLowerCase()}`}
            data-cy={`group-img-${group.name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            src={
              group.is_predefined
                ? 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png'
                : checkIconURL(group.icon_url)
                ? group.icon_url
                : arrayIcon[0]
            }
            alt="icon"
          />
          <span>{translate('nameGroup')}</span>
          <p
            data-testid={`group-name-${group.name}`}
            data-cy={`group-name-${group.name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
          >
            {group.name}
          </p>
          <span>{translate('createdAtGroup')}</span>
          <p
            data-testid={`group-created-at-${group.name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            data-cy={`group-created-at-${group.name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
          >
            {format(new Date(group.created_at), `${translateFormat}, HH:mm`)}
          </p>
          <span>{translate('lastUpdatedGroup')}</span>
          {group.series.some((serie) => serie.access_type === 'premium') ? (
            <PremiumDiv>
              <p
                data-testid={`group-last-update-${group.name
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
                data-cy={`group-last-update-${group.name
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
              >
                {format(new Date(group.update_at), `${translateFormat}, HH:mm`)}
              </p>
              <img src={Star} alt="star-icon" data-testid="star-icon" />
            </PremiumDiv>
          ) : (
            <p
              data-testid={`group-last-update-${group.name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              data-cy={`group-last-update-${group.name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            >
              {format(new Date(group.update_at), `${translateFormat}, HH:mm`)}
            </p>
          )}
        </Content>
      </Container>
    </>
  );
};
