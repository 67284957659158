import React, { useContext, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { getChartColor } from 'src/utils/getChartColor';
import light from 'src/styles/themes/light';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { format, subDays } from 'date-fns';
import HighchartsReact from 'highcharts-react-official';
import { convertQuarterlyToDate } from 'src/utils/convertQuarterlyToDate';
import { convertDateToQuarterly } from 'src/utils/convertDateToQuarterly';

import { ResultsContext } from '..';
import { ContainerChart } from './styles';

export const ResultsChart: React.FC = () => {
  const { isLoading, dataResults } = useContext(ResultsContext);

  const { selectedY } = useSelector((state: RootState) => state.project);

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const {
    project: { appInfo },
    AIUserSelectionOptions: {
      userSelection: {
        results: { frequency, transformations },
      },
    },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const shouldShowNOfDay = useMemo(
    () => selectedY?.info?.frequency === 'daily' && frequency !== 'original',
    [frequency, selectedY?.info?.frequency],
  );

  const dataChart = useMemo(() => {
    const dataChartAux: HChartsSeries[] = [];

    function getVariationLegendNameThroughFrequency() {
      const versionNumber = Number(
        appInfo?.engine?.version?.replaceAll('.', ''),
      );

      const dailyWeeklyFortnightlyArr = ['daily', 'weekly', 'fortnightly'];

      const isOriginal = frequency === 'original';
      const isOriginalFrequencyAnnual = selectedY?.info?.frequency === 'annual';
      const originalFrequencyIsDailyWeeklyFortnightly =
        dailyWeeklyFortnightlyArr.includes(selectedY?.info?.frequency ?? '');

      if ((isOriginal && isOriginalFrequencyAnnual) || frequency === 'yearly') {
        return translate('userSelectionResultsVariationAnnual');
      }

      if (versionNumber <= 50) {
        if (isOriginal) {
          return translate('userSelectionResultsVariationOriginal');
        }
        if (frequency === 'monthly' || frequency === 'quarterly') {
          return translate('userSelectionResultsVariationMonthlyQuarterly');
        }
      } else {
        if (
          (isOriginal && originalFrequencyIsDailyWeeklyFortnightly) ||
          dailyWeeklyFortnightlyArr.includes(frequency)
        ) {
          return translate('userSelectionResultsVariationOriginal');
        }

        return translate('userSelectionResultsVariationMonthlyQuarterly');
      }
    }

    if (transformations.includes('Level') && dataResults?.level) {
      dataChartAux.push(
        {
          data: dataResults.level.historical.x.map((date, index) => ({
            x:
              frequency !== 'quarterly'
                ? new Date(`${date}T00:00`).getTime()
                : convertQuarterlyToDate(String(date)).getTime(),
            y: dataResults.level.historical.y[index],
            custom: {
              tooltipTitle: translate('userSelectionResultLevel'),
              keyValue: translate('Historical'),
              value: `${formatCompactNotation(
                dataResults.level.historical.y[index],
              )}`,
              date:
                frequency !== 'quarterly' && frequency !== 'yearly'
                  ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                  : date,
              nOfDays:
                shouldShowNOfDay &&
                dataResults.level.historical.aggregation_count?.length
                  ? dataResults.level.historical.aggregation_count[index]
                  : null,
            },
          })),
          name: translate('userSelectionResultLevel'),
          type: 'column',
          yAxis: 0,
          showInLegend: true,
          color: getChartColor(0),
          zIndex: -1,
          cursor: 'pointer',
        },
        {
          data: dataResults.level.forecast?.x.map((date, index) => ({
            x:
              frequency !== 'quarterly'
                ? new Date(`${date}T00:00`).getTime()
                : convertQuarterlyToDate(String(date)).getTime(),
            y: dataResults.level.forecast?.y[index],
            custom: {
              tooltipTitle: translate('userSelectionResultLevel'),
              keyValue: translate('Forecast'),
              value: `${formatCompactNotation(
                dataResults.level.forecast?.y[index] as number,
              )}`,
              date:
                frequency !== 'quarterly' && frequency !== 'yearly'
                  ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                  : date,
              nOfDays:
                shouldShowNOfDay &&
                dataResults.level.forecast?.aggregation_count?.length
                  ? dataResults.level.forecast.aggregation_count[index]
                  : null,
            },
          })),
          name: translate('userSelectionResultLevel'),
          type: 'column',
          dashStyle: 'Solid',
          yAxis: 0,
          showInLegend: false,
          color: getChartColor(0),
          opacity: 0.7,
          zIndex: -1,
        },
      );
    }

    if (transformations.includes('Variation') && dataResults?.variation) {
      dataChartAux.push(
        {
          data: dataResults?.variation.historical.x.map((date, index) => ({
            x:
              frequency !== 'quarterly'
                ? new Date(`${date}T00:00`).getTime()
                : convertQuarterlyToDate(String(date)).getTime(),
            y: dataResults?.variation?.historical.y[index],
            custom: {
              tooltipTitle: translate('userSelectionResultVariation'),
              keyValue: translate('Historical'),
              value: `${formatCompactNotation(
                dataResults?.variation?.historical.y[index] as number,
              )}%`,
              date:
                frequency !== 'quarterly' && frequency !== 'yearly'
                  ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                  : date,
              nOfDays:
                shouldShowNOfDay &&
                dataResults?.variation?.historical.aggregation_count?.length
                  ? dataResults?.variation.historical.aggregation_count[index]
                  : null,
            },
          })),
          name: getVariationLegendNameThroughFrequency(),
          type: 'line',
          showInLegend: true,
          yAxis: 1,
          color: light.colors.secondaryLight,
          marker: {
            enabledThreshold: 2,
            radius: 4,
            symbol: 'circle',
            fillColor: light.colors.white,
            lineWidth: 3,
            lineColor: light.colors.secondaryLight,
          },
          cursor: 'pointer',
        },
        {
          data: dataResults.variation?.forecast?.x.map((date, index) => ({
            x:
              frequency !== 'quarterly'
                ? new Date(`${date}T00:00`).getTime()
                : convertQuarterlyToDate(String(date)).getTime(),
            y: dataResults.variation?.forecast?.y[index],
            custom: {
              tooltipTitle: translate('userSelectionResultVariation'),
              keyValue: translate('Forecast'),
              value: `${formatCompactNotation(
                dataResults.variation?.forecast?.y[index] as number,
              )}%`,
              date:
                frequency !== 'quarterly' && frequency !== 'yearly'
                  ? format(new Date(`${date}T00:00`), 'dd/MM/yyyy')
                  : date,
              nOfDays:
                shouldShowNOfDay &&
                dataResults.variation?.forecast?.aggregation_count?.length
                  ? dataResults.variation?.forecast.aggregation_count[index]
                  : null,
            },
          })),
          name: getVariationLegendNameThroughFrequency(),
          type: 'line',
          showInLegend: false,
          yAxis: 1,
          color: light.colors.secondaryLight,
          dashStyle: 'Dash',
          marker: {
            enabledThreshold: 2,
            radius: 4,
            symbol: 'circle',
            fillColor: light.colors.white,
            lineWidth: 3,
            lineColor: light.colors.secondaryLight,
          },
        },
      );
    }

    return dataChartAux;
  }, [
    dataResults?.level,
    dataResults?.variation,
    frequency,
    shouldShowNOfDay,
    transformations,
    translate,
    appInfo?.engine?.version,
    selectedY?.info?.frequency,
  ]);

  const options: HChartsOptions = useMemo(() => {
    const quarterlyXaxis =
      frequency === 'quarterly'
        ? dataResults?.level?.historical.x
            .map((date) => String(date))
            .concat(
              dataResults.level?.forecast?.x.map((date) => String(date)) ?? [],
            )
        : [];

    return {
      xAxis:
        frequency === 'quarterly'
          ? {
              min: quarterlyXaxis?.length
                ? convertQuarterlyToDate(quarterlyXaxis[0]).getTime()
                : undefined,
              labels: {
                formatter() {
                  return convertDateToQuarterly(
                    // eslint-disable-next-line react/no-this-in-sfc
                    subDays(new Date(this.value), 1),
                  );
                },
              },
            }
          : {
              min: undefined,
              labels: {
                formatter: undefined,
              },
            },
      yAxis: [
        {
          visible: transformations.includes('Level'),
          lineWidth: 0,
          gridLineWidth: 1,
          gridLineColor: light.colors.gray2,
          showLastLabel: true,
          labels: {
            format: '{text}',
            style: {
              fontSize: '12px',
              fontFamily: "'Inter', sans-serif",
            },
          },
          title: {
            text: '',
          },
          opposite: false,
          zIndex: 0,
        },
        {
          visible: transformations.includes('Variation'),
          lineWidth: 0,
          gridLineWidth: 1,
          gridLineColor: light.colors.gray2,
          showLastLabel: true,
          labels: {
            format: '{text}%',
            style: {
              fontSize: '12px',
              fontFamily: "'Inter', sans-serif",
            },
          },
          title: {
            text: '',
          },
          opposite: true,
        },
      ],
      tooltip: {
        headerFormat: '',
        pointFormat:
          '<table><tr><th colspan="2">{point.custom.tooltipTitle}</th></tr>' +
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.custom.date}</td></tr>` +
          `<tr><td><b>{point.custom.keyValue}:</b> </td>` +
          `<td style="text-align: right">{point.custom.value}</td></tr>` +
          `${
            shouldShowNOfDay
              ? `<tr><td><b>${translate('userSelectionNOfDays')}:</b> </td>` +
                `<td style="text-align: right">{point.custom.nOfDays}</td></tr>`
              : ''
          }`,
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: (event) => {
              const yAxisIndex =
                event.target.name === translate('userSelectionResultLevel')
                  ? 0
                  : 1;
              chartRef.current?.chart.series.forEach((serie) => {
                if (serie.name === event.target.name) {
                  if (serie.visible) {
                    //@ts-expect-error:ignora
                    serie.update({ visible: false }, true);
                    chartRef.current?.chart.yAxis[yAxisIndex].update({
                      visible: false,
                    });
                  } else {
                    //@ts-expect-error:ignora
                    serie.update({ visible: true }, true);
                    chartRef.current?.chart.yAxis[yAxisIndex].update({
                      visible: true,
                    });
                  }
                }
              });

              return false; // Impede o comportamento padrão do clique na legenda
            },
          },
        },
      },
    };
  }, [
    dataResults?.level,
    frequency,
    shouldShowNOfDay,
    transformations,
    translate,
  ]);

  return (
    <>
      {isLoading ? (
        <ContainerSkeleton data-testid="result-loading" />
      ) : (
        <ContainerChart
          data-testid="result-container-chart"
          className="result-container-chart"
        >
          <div>
            <h4>
              {transformations.includes('Level')
                ? translate('userSelectionResultLevel')
                : ''}
            </h4>

            {transformations.includes('Variation') && (
              <h4>{translate('userSelectionResultVariation')}</h4>
            )}
          </div>

          <HCharts
            series={dataChart}
            options={options}
            ref={chartRef}
            frequency={
              frequency === 'original' ? selectedY?.info?.frequency : frequency
            }
            dataCy="results-chart"
            immutable={false}
          />
        </ContainerChart>
      )}
    </>
  );
};
