import React, { useEffect, useMemo, useRef } from 'react';

import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { getChartColor } from 'src/utils/getChartColor';
import { useTranslation } from 'react-i18next';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import HighchartsReact from 'highcharts-react-official';

import { VariablesCharts } from '..';

interface SummaryVariablesChartProps {
  variablesCharts: VariablesCharts[];
  hasZoom: boolean;
}

export const SummaryVariablesChart: React.FC<SummaryVariablesChartProps> = ({
  variablesCharts,
  hasZoom,
}) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const series: HChartsSeries[] = useMemo(
    () =>
      variablesCharts.flatMap((variableChart, index) => [
        {
          type: 'line',
          name: variableChart.name,
          color: getChartColor(index),
          dashStyle: 'Solid',
          data: variableChart.historical.date.map((date, dateIndex) => ({
            x: new Date(date).getTime(),
            y: variableChart.historical.value[dateIndex],
            custom: {
              value: formatCompactNotation(
                variableChart.historical.value[dateIndex],
              ),
              keyValue: translate('Historical'),
            },
          })),
          id: String(index),
          marker: {
            enabledThreshold: 2,
            radius: 4,
            symbol: 'circle',
          },
        },
        {
          type: 'line',
          name: variableChart.name,
          color: getChartColor(index),
          dashStyle: 'Dash',
          data:
            variableChart.forecast?.date.map((date, dateIndex) => ({
              x: new Date(date).getTime(),
              y: variableChart.forecast?.value[dateIndex],
              custom: {
                value: formatCompactNotation(
                  variableChart.forecast?.value[dateIndex] ?? 0,
                ),
                keyValue: translate('Forecast'),
              },
            })) ?? [],
          linkedTo: String(index),
          marker: {
            enabledThreshold: 2,
            radius: 4,
            symbol: 'circle',
          },
        },
      ]),
    [translate, variablesCharts],
  );

  useEffect(() => {
    if (chartRef) {
      const chart = chartRef.current?.chart;

      if (chart) {
        const { min, max } = chart.xAxis[0].getExtremes();

        chart.zoomOut();

        if (hasZoom) {
          chart.showResetZoom();

          chart.xAxis[0].setExtremes(min, max, true, false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variablesCharts]);

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 500,
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: %d/%m/%Y}</td></tr>` +
          `<tr><td><b>{point.custom.keyValue}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
      navigator: {
        enabled: true,
        xAxis: {
          gridLineWidth: 0,
        },
      },
    }),
    [translate],
  );

  return (
    <HCharts
      ref={chartRef}
      series={series}
      options={options}
      dataCy="chart-dependent-variables"
      zoomOutAfterChangingSeries={false}
    />
  );
};
