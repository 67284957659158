export const workspaceProjectionsENUSTranslate = {
  workspaceProjectionsMessageErrorServerCanNotFind:
    'The server can not find the requested resource.',
  workspaceProjectionsMessageErrorServerRequestedResource:
    'The requested resource does not exist.',
  workspaceProjectionsMessageErrorEngineNotSupported:
    'Project engine not supported.',
  workspaceProjectionsMessageErrorAnInternalError:
    'An internal error has occurred while processing the request.',
  workspaceProjectionsLoadingTitle: 'We are working on it!',
  workspaceProjectionsLoadingDescription:
    'This might take a while... But don’t worry, your Projections model is being processed and will be done soon!',
  workspaceProjectionsQuarterlySeriesSummaryIsOnlyAvailable:
    'Quarterly series summary is only available for series with at least 1 quarter of observation.',
  workspaceProjectionsNOfDays: 'N. of days',
};
