import styled from 'styled-components';

type ActiveCard = {
  isActive: boolean;
};

export const FavoriteContainer = styled.div<ActiveCard>`
  display: flex;
  padding: 1rem;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};
`;

export const FavoriteIcon = styled.div`
  img {
    width: 2.5rem;
    height: 2.5rem;

    border-radius: 4.8px;
  }
`;

export const FavoriteContent = styled.div`
  display: flex;
  gap: 0.5rem;

  cursor: pointer;

  width: 180px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  flex: 1 0 0;

  h5 {
    color: ${({ theme }) => theme.colors.gray6};

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    word-wrap: anywhere;
  }

  p {
    color: ${({ theme }) => theme.colors.gray6};

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const ActionDots = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  color: ${({ theme }) => theme.colors.gray4};
`;
