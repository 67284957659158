import React, {
  useCallback,
  useEffect,
  forwardRef,
  useRef,
  useMemo,
} from 'react';

import HighchartsLib, { Options, SeriesOptionsType, merge } from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import CustomEvents from 'highcharts-custom-events';
import HighchartsStock from 'highcharts/modules/stock';
import light from 'src/styles/themes/light';
import { getChartColor } from 'src/utils/getChartColor';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { Frequency } from 'src/models/redux/reducers/Project';
import ms from 'ms';

HighchartsStock(HighchartsLib);
HighchartsMore(HighchartsLib);
//@ts-expect-error:ignora
CustomEvents(HighchartsLib);

export type HChartsSeries = SeriesOptionsType & Record<string, any>;

export type HChartsOptions = Options;
interface HChartsProps {
  series: HChartsSeries[];
  options?: HChartsOptions;
  frequency?: Frequency | 'yearly';
  connectedGaps?: boolean;
  useStockChart?: boolean;
  immutable?: boolean;
  zoomLimited?: boolean;
  zoomOutAfterChangingSeries?: boolean;
  dataCy: string;
}

export const HCharts = forwardRef<HighchartsReact.RefObject, HChartsProps>(
  (
    {
      series,
      options,
      frequency = 'monthly',
      connectedGaps = false,
      useStockChart = false,
      immutable = false,
      dataCy,
      zoomLimited = true,
      zoomOutAfterChangingSeries = true,
      ...rest
    },
    ref,
  ) => {
    const { language } = useSelector((state: RootState) => state.auth.user);

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const { t: translate } = useTranslation();

    const removeConnectGaps = useCallback(
      (highchartsSeries: Highcharts.Series) => {
        const legendClicked = highchartsSeries.name;
        const isShowing = highchartsSeries.visible;

        if (
          series.length &&
          legendClicked === series[0].name &&
          connectedGaps
        ) {
          series.slice(1).forEach((serie, index) => {
            if (Array.isArray(series[0].data) && Array.isArray(serie.data)) {
              const lastFirstSerieDate = series[0].data.at(-1)[0];
              const lastFirstSerieValue = series[0].data.at(-1)[1];

              if (lastFirstSerieDate && lastFirstSerieValue) {
                if (isShowing) {
                  highchartsSeries.chart.series[index + 1].removePoint(
                    0,
                    false,
                  );
                } else {
                  highchartsSeries.chart.series[index + 1].addPoint({
                    x: lastFirstSerieDate,
                    y: lastFirstSerieValue,
                  });
                }
              }
            }
          });
        }
      },
      [series, connectedGaps],
    );

    function zoomChart(event: Highcharts.AxisSetExtremesEventObject) {
      //@ts-expect-error: nao reconhe a propriedade chart
      if (event.target.chart?.yAxis?.length) {
        //@ts-expect-error: nao reconhe a propriedade chart
        event.target.chart?.yAxis.forEach((yaxis) => {
          const min = yaxis?.dataMin;
          const max = yaxis?.dataMax;

          if (typeof min === 'number' && typeof max === 'number') {
            yaxis.setExtremes(min > 0 ? min - 0.0001 : min, max);
          }
        });
      }
    }

    const seriesAdjusted: HChartsSeries[] = useMemo(
      () =>
        [...series].map((serie, index) => ({
          color: serie.color ? serie.color : getChartColor(index),
          dataGrouping: {
            enabled: false, // Desativa o agrupamento automático
            ...serie.dataGrouping,
          },
          zIndex: serie?.zIndex ? serie.zIndex : index === 0 ? 100 : undefined,
          ...serie,
        })),
      [series],
    );

    useEffect(() => {
      const chart = (
        (ref || chartRef) as React.RefObject<HighchartsReact.RefObject>
      ).current?.chart;

      if (series.length && chart && connectedGaps) {
        series.slice(1).forEach((_, index) => {
          const firstSerieIsVisible = chart.series[0].visible;

          if (!firstSerieIsVisible) {
            chart.series[index + 1].removePoint(0, true);
          }
        });
      }
    }, [series, connectedGaps, ref]);

    useEffect(() => {
      const chart = (
        (ref || chartRef) as React.RefObject<HighchartsReact.RefObject>
      ).current?.chart;

      if (chart && zoomOutAfterChangingSeries) {
        chart.zoomOut();
      }
    }, [ref, series, zoomOutAfterChangingSeries]);

    const optionsMerged: Options = useMemo(() => {
      let minTickInterval = ms('30 days');

      if (frequency === 'daily' || frequency === 'weekly') {
        minTickInterval = ms('1 day');
      } else if (frequency === 'quarterly') {
        minTickInterval = 3 * ms('30 days');
      } else if (frequency === 'half-year') {
        minTickInterval = 6 * ms('30 days');
      } else if (frequency === 'annual' || frequency === 'yearly') {
        minTickInterval = ms('365 days');
      }

      const optionsAdjusted: Options = {
        time: {
          useUTC: false,
          timezoneOffset: -1 * -180, //UTC-3:00 time zone
        },
        chart: {
          spacingTop: 32,
          spacingLeft: 1,
          zooming: {
            type: 'xy',
            resetButton: {
              theme: {
                stroke: '#f1f7ff',
                'stroke-width': 1,
                padding: 8,
                fill: '#f1f7ff',
                style: {
                  color: light.colors.primary,
                  fontStyle: 'normal',
                  fontSize: '12px',
                  fontWeight: '500',
                  fontFamily: "'Inter', sans-serif",
                },
                states: {
                  hover: {
                    fill: '#edf4ff',
                    stroke: '#edf4ff',
                    style: {
                      color: light.colors.primaryDark,
                      fontWeight: '500',
                    },
                  },
                },
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 1,
          gridLineColor: light.colors.gray2,
          showLastLabel: true,
          allowDecimals: true,
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: "'Inter', sans-serif",
            },
          },
          opposite: false,
          title: {
            text: '',
          },
        },
        xAxis: {
          events: {
            afterSetExtremes(event) {
              if (zoomLimited) {
                zoomChart(event);
              }
            },
          },
          type: 'datetime',
          minTickInterval,
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: "'Inter', sans-serif",
              textAlign: 'center',
            },
            y: 26,
          },
          dateTimeLabelFormats: {
            day: '%b %d <br/> %Y',
            week: '%b %d <br/> %Y',
            month: '%b %Y',
            year: '%Y',
          },
        },
        title: {
          text: '',
        },
        tooltip: {
          valueDecimals: 2,
          split: false,
          shared: false,
          style: {
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '400',
            color: light.colors.gray7,
            fontFamily: "'Inter', sans-serif",
          },
          borderRadius: 8,
          useHTML: true,
          headerFormat: '<table><tr><th colspan="2">{series.name}</th></tr>',
          pointFormat:
            `<tr><td><b>${translate('date')}:</b> </td>` +
            `<td style="text-align: right">{point.x: ${
              frequency === 'annual' ? '%Y' : ' %d/%m/%Y'
            }}</td></tr>` +
            `<tr><td><b>${translate('value')}:</b> </td>` +
            '<td style="text-align: right">{point.y}</td></tr>',
          footerFormat: '</table>',
        },
        legend: {
          enabled: true,
          align: 'center',
          verticalAlign: 'bottom',
          layout: 'horizontal',
          itemStyle: {
            marginTop: '10px',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '400px',
            fontFamily: "'Inter', sans-serif",
          },
          //@ts-expect-error:ignora
          itemEvents: {
            dblclick() {
              //@ts-expect-error:ignora
              // eslint-disable-next-line react/no-this-in-sfc
              const allSeries = this.chart.series;
              const temp = allSeries.filter(
                (seriesAux: any) =>
                  //@ts-expect-error:ignora
                  // eslint-disable-next-line react/no-this-in-sfc
                  seriesAux.name !== this.name && !!seriesAux?.legendItem,
              );

              if (temp.some((legend: any) => legend.visible)) {
                temp.forEach((seriesAux: any) => seriesAux.hide());
              } else {
                allSeries.forEach((seriesAux: any) => seriesAux.show());
              }
            },
          },
        },
        rangeSelector: {
          inputEnabled: false,
          enabled: false,
          labelStyle: {
            display: 'none',
          },
        },
        scrollbar: {
          enabled: false,
        },
        navigator: {
          enabled: false,
          xAxis: {
            gridLineWidth: 0,
          },
        },
        series: seriesAdjusted,
        plotOptions: {
          line: {
            lineWidth: 3,
            connectNulls: true,
          },
          area: {
            lineWidth: 3,
          },
          column: {
            pointPadding: 0.1,
            groupPadding: 0.17,
            grouping: false,
          },
          series: {
            turboThreshold: 0,
            showInNavigator: true,
            events: {
              legendItemClick() {
                removeConnectGaps(this);
              },
            },
          },
        },
      };

      return merge(optionsAdjusted, options);
    }, [
      frequency,
      options,
      removeConnectGaps,
      seriesAdjusted,
      translate,
      zoomLimited,
    ]);

    const ptBrLang: Highcharts.LangOptions = {
      shortMonths: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      decimalPoint: '.',
      thousandsSep: ',',
    };

    const enUsLang: Highcharts.LangOptions = {
      shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      decimalPoint: '.',
      thousandsSep: ',',
    };

    HighchartsLib.setOptions({
      lang: language === 'pt-br' ? ptBrLang : enUsLang,
    });

    //@ts-expect-error:ignora
    HighchartsLib.Chart.prototype.customEvent.eventElement.renderItem =
      // eslint-disable-next-line func-names
      function (item: any) {
        return {
          // eslint-disable-next-line react/no-this-in-sfc
          events: this.options.itemEvents,
          element: item.legendGroup || item.legendItem.group,
          eventObject: item,
        };
      };

    return (
      <HighchartsReact
        highcharts={HighchartsLib}
        options={optionsMerged}
        constructorType={useStockChart ? 'stockChart' : undefined}
        ref={ref || chartRef}
        immutable={immutable}
        containerProps={{
          'data-cy': dataCy,
          'data-testid': dataCy,
        }}
        {...rest}
      />
    );
  },
);
