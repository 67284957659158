import React from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { HCharts, HChartsSeries, HChartsOptions } from 'src/components/HCharts';
import { getChartColor } from 'src/utils/getChartColor';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import light from 'src/styles/themes/light';

interface BreakdownChartProps {
  line: {
    data: string[];
    y: number[];
  };
  bars: {
    data: string[];
    y: number[];
    type: string;
  }[];
  lastHistoricalDate: string | null;
  isAnnual: boolean;
}

export const BreakdownChart: React.FC<BreakdownChartProps> = ({
  line,
  bars,
  lastHistoricalDate,
  isAnnual,
}) => {
  function getHeight(quantityLegends: number) {
    return quantityLegends * 15 + 300;
  }

  const { t: translate } = useTranslation();

  const series: HChartsSeries[] = [];

  const initialForecastIndex = line.data.findIndex(
    (date) => date === lastHistoricalDate,
  );

  series.push({
    type: 'line',
    name: translate('modelInProductionYVariation'),
    showInLegend: false,
    color: light.colors.gray6,
    dashStyle: 'Solid',
    zIndex: 1,
    data: line.data
      .filter((_, index) =>
        initialForecastIndex === -1 ? true : index <= initialForecastIndex,
      )
      .map((x, index) => ({
        x: isAnnual
          ? new Date(`01-01-${new Date(x).getFullYear()}`).getTime()
          : new Date(`${x}T00:00`).getTime(),
        y: Number(line.y[index]),
        custom: {
          date: format(
            new Date(`${x}T00:00`),
            isAnnual ? 'yyyy' : 'dd/MM/yyyy',
          ),
          value: `${formatCompactNotation(Number(line.y[index]))}%`,
        },
      })),
  });

  if (initialForecastIndex !== -1) {
    const lineYForecast = line.y.filter(
      (_, index) => index >= initialForecastIndex,
    );

    series.push({
      type: 'line',
      name: translate('modelInProductionYVariation'),
      showInLegend: false,
      color: light.colors.gray6,
      dashStyle: 'Dash',
      zIndex: 1,

      marker: {
        symbol: 'Circle',
      },
      data: line.data
        .filter((_, index) => index >= initialForecastIndex)
        .map((x, index) => ({
          x: isAnnual
            ? new Date(`01-01-${new Date(x).getFullYear()}`).getTime()
            : new Date(`${x}T00:00`).getTime(),
          y: Number(lineYForecast[index]),
          custom: {
            date: format(
              new Date(`${x}T00:00`),
              isAnnual ? 'yyyy' : 'dd/MM/yyyy',
            ),
            value: `${formatCompactNotation(Number(lineYForecast[index]))}%`,
          },
        })),
    });
  }

  bars.forEach((bar, index) => {
    series.push({
      type: 'column',
      name: bar.type,
      color: getChartColor(index),
      data: bar.data.map((x, xIndex) => ({
        x: isAnnual
          ? new Date(`01-01-${new Date(x).getFullYear()}`).getTime()
          : new Date(`${x}T00:00`).getTime(),
        y: Number(bar.y[xIndex]),
        custom: {
          date: format(
            new Date(`${x}T00:00`),
            isAnnual ? 'yyyy' : 'dd/MM/yyyy',
          ),
          value: `${formatCompactNotation(Number(bar.y[xIndex]))}%`,
        },
      })),
    });
  });

  const options: HChartsOptions = {
    chart: {
      height: getHeight(bars.length),
    },
    yAxis: {
      labels: {
        format: '{text}%',
      },
    },
    tooltip: {
      pointFormat:
        `<tr><td><b>${translate('date')}:</b></td>` +
        `<td style="text-align: right">{point.custom.date}</td></tr>` +
        `<tr><td><b>${translate('value')}:</b> </td>` +
        `<td style="text-align: right">{point.custom.value}</td></tr>`,
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      line: {
        lineWidth: 2,
      },
      column: {
        stacking: 'normal',
        pointPadding: 0.1,
        groupPadding: 0.12,
      },
    },
  };

  return (
    <HCharts
      series={series}
      options={options}
      dataCy="breakdown-chart"
      zoomLimited={false}
      frequency={isAnnual ? 'annual' : 'monthly'}
    />
  );
};
