import React, { memo, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { ModalOverlay } from 'src/components/Modal/Overlay';
import { Card } from 'src/components/Card';
import { PencilSimple, Plus, Question, Warning, X } from 'phosphor-react';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Tutorial } from 'src/models/TimeSeries/DependentVariablesConfig/components/Tutorial';
import { sleep } from 'src/utils/sleep';
import { Modal } from 'src/components/Modal';
import { Select } from 'src/components/Select';
import Maintenance from 'src/assets/maintenance.svg';
import { Tooltip } from 'react-tooltip';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

import {
  ActionButtons,
  Container,
  Content,
  CreateFilterContainer,
  CategorizationContainer,
  ModalContainer,
  ModalContent,
  ConfirmCloseCategorizationContainer,
  CategorizationResultContainer,
  TitleContainer,
} from './styles';
import {
  Filters,
  Option,
  SelectOption,
  VariableCategorizationProps,
} from './types';
import { ConfigFilters } from './ConfigFilter';

export const VariableCategorization: React.FC<VariableCategorizationProps> =
  memo(
    ({
      ys: { ids, yIdLabel },
      currentFilters,
      handleSaveVariableCategorization,
      variableError,
      isLoading = false,
    }) => {
      const [showVariableCategorization, setShowVariableCategorization] =
        useState(false);
      const [showTutorial, setShowTutorial] = useState(false);
      const [showConfirmCloseCategorization, setShowConfirmCategorization] =
        useState(false);

      const [filters, setFilters] = useState<Filters>([]);

      const [selectedFiltersOption, setSelectedFiltersOption] = useState<
        SelectOption[]
      >([]);
      const [filtersOptions, setFiltersOptions] = useState<SelectOption[][]>(
        [],
      );
      const [selectedVariable, setSelectedVariable] = useState<SelectOption>(
        {} as SelectOption,
      );
      const [variableOptions, setVariableOptions] = useState<SelectOption[]>(
        [],
      );

      const { t: translate } = useTranslation();

      const hasChanges = useMemo(
        () => JSON.stringify(currentFilters) !== JSON.stringify(filters),
        [filters, currentFilters],
      );

      const hasFilterError = filters.some(
        (filter) =>
          filter.error || filter.options.some((option) => option.error),
      );

      const handleAddNewFilter = async () => {
        setFilters([
          ...filters,
          {
            name: `${translate('configFiltersFilter')} ${filters.length + 1}`,
            options: [],
          },
        ]);

        await sleep(100);

        const container = document.getElementById('config-filters-container');

        if (container && container.scrollTo) {
          container.scrollTo({
            top: container.scrollHeight - 840,
            behavior: 'smooth',
          });
        }
      };

      const handleCloseCategorization = () => {
        if (hasChanges) {
          setShowConfirmCategorization(true);
        } else {
          setShowVariableCategorization(false);
        }
      };

      const configFilters = (optionSelected?: string, level?: number) => {
        const firstFilterOptionSelected = currentFilters[0].options.find(
          (option) =>
            optionSelected && level === 0
              ? option.name === optionSelected
              : optionSelected && level && level > 0
              ? option.name === selectedFiltersOption[0].value
              : option.yIds.length,
        );
        let yIds = firstFilterOptionSelected?.yIds ?? [];

        const newSelectedFilterOptions: SelectOption[] = [];
        newSelectedFilterOptions.push({
          value: firstFilterOptionSelected?.name ?? '',
          label: firstFilterOptionSelected?.name ?? '',
        });

        const newFiltersOptions: SelectOption[][] = [];
        newFiltersOptions.push(
          currentFilters[0].options.map((option) => ({
            label: option.name,
            value: option.name,
          })),
        );

        for (let i = 1; i < currentFilters.length; i++) {
          // eslint-disable-next-line no-loop-func
          const options = currentFilters[i].options.filter((option) =>
            option.yIds.some((id) => yIds.includes(id)),
          );

          newFiltersOptions.push(
            options?.map((option) => ({
              label: option.name,
              value: option.name,
            })),
          );

          let selectedOption =
            optionSelected && level && level >= i
              ? options.find((option) =>
                  level === i
                    ? option.name === optionSelected
                    : option.name === selectedFiltersOption[i].value,
                )
              : options[0];

          selectedOption =
            selectedOption ?? ({ name: '', yIds: [] as string[] } as Option);

          newSelectedFilterOptions.push({
            value: selectedOption.name,
            label: selectedOption.name,
          });

          yIds = yIds.filter((id) => selectedOption?.yIds?.includes(id));
        }

        const yOptions = yIds
          ?.map((id) => ({
            label: yIdLabel[id],
            value: id,
          }))
          .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));

        setSelectedVariable(yOptions[0]);
        setVariableOptions(yOptions);
        setSelectedFiltersOption(newSelectedFilterOptions);
        setFiltersOptions(newFiltersOptions);
      };

      useEffect(() => {
        const openTutorial = async () => {
          await sleep(2100);

          setShowTutorial(true);

          localStorage.setItem(
            'tutorialConfigVariablesWorkspaceAccessed',
            'true',
          );
        };

        const alreadyAccessed = localStorage.getItem(
          'tutorialConfigVariablesWorkspaceAccessed',
        );

        if (alreadyAccessed !== 'true') {
          openTutorial();
        }
      }, []);

      useEffect(() => {
        if (currentFilters.length) {
          configFilters();
        }

        setFilters(JSON.parse(JSON.stringify(currentFilters)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentFilters, ids, showVariableCategorization]);

      useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
          showVariableCategorization
            ? (body.style.overflow = 'hidden')
            : (body.style.overflow = 'auto');
        }
      }, [showVariableCategorization]);

      return (
        <>
          <Container data-testid="variable-categorization-container">
            <TitleContainer>
              <h3>{translate('createWorkspaceVariableCategorization')}</h3>

              {!!filters.length && (
                <Button
                  buttonType="naked"
                  icon={<PencilSimple />}
                  onClick={() => setShowVariableCategorization(true)}
                  disabled={variableError || !ids.length}
                  data-tooltip-html={
                    variableError || !ids.length
                      ? translate(
                          'createWorkspaceVariableCategorizationDisabled',
                        )
                      : ''
                  }
                  data-tooltip-id="variable-categorization-tooltip"
                  data-testid="edit-categorization"
                >
                  {translate('createWorkspaceEditVariableCategorization')}
                </Button>
              )}
            </TitleContainer>

            <p>
              {translate('createWorkspaceVariableCategorizationDescription')}
            </p>

            {isLoading ? (
              <ContainerSkeleton
                data-testid="loading-filters"
                style={{ height: '10rem' }}
              />
            ) : !currentFilters.length ? (
              <Content>
                <div>
                  <Select
                    label={translate('type')}
                    style={{
                      width: '15rem',
                      pointerEvents: 'none',
                      userSelect: 'none',
                    }}
                  />
                  <Select
                    label={translate('variable')}
                    style={{
                      width: '15rem',
                      pointerEvents: 'none',
                      userSelect: 'none',
                    }}
                  />
                </div>

                <Button
                  buttonType="tertiary"
                  onClick={() => setShowVariableCategorization(true)}
                  disabled={variableError || !ids.length}
                  data-tooltip-html={
                    variableError || !ids.length
                      ? translate(
                          'createWorkspaceVariableCategorizationDisabled',
                        )
                      : ''
                  }
                  data-tooltip-id="variable-categorization-tooltip"
                  data-testid="create-categorization-button"
                >
                  {translate('createWorkspaceCreateVariableCategorization')}
                </Button>
              </Content>
            ) : (
              <CategorizationResultContainer
                disabled={variableError || !ids.length}
              >
                {currentFilters.map((filter, index) => (
                  <Select
                    key={filter.name}
                    label={filter.name}
                    value={selectedFiltersOption[index]}
                    options={filtersOptions[index]}
                    onChange={(input) => {
                      configFilters((input as SelectOption).value, index);
                    }}
                    maxMenuHeight={160}
                  />
                ))}

                <Select
                  label={translate('variable')}
                  value={selectedVariable}
                  options={variableOptions}
                  onChange={(input) =>
                    setSelectedVariable(input as SelectOption)
                  }
                  maxMenuHeight={160}
                />
              </CategorizationResultContainer>
            )}
          </Container>

          <ModalContainer
            visible={showVariableCategorization}
            data-testid="variable-categorization-modal"
          >
            <ModalOverlay
              data-testid="variable-categorization-modal-overlay"
              onClick={handleCloseCategorization}
            />
            <ModalContent visible={showVariableCategorization}>
              <Card
                textCard={translate('dependentVariablesConfigTitle')}
                textDescription={translate(
                  'dependentVariablesConfigDescription',
                )}
                style={{ padding: '0 1.5rem' }}
              />

              <ActionButtons>
                <Button
                  buttonType="naked"
                  icon={<Question />}
                  onClick={() => setShowTutorial(true)}
                  data-testid="open-tutorial-button"
                />

                <Button
                  buttonType="naked"
                  icon={<X />}
                  onClick={handleCloseCategorization}
                  data-testid="close-variable-categorization-button"
                />
              </ActionButtons>

              {!filters.length ? (
                <CreateFilterContainer>
                  <div>
                    <img src={Maintenance} alt="maintenance" />

                    <p>{translate('dependentVariablesConfigNoConfig')}</p>

                    <Button
                      buttonType="tertiary"
                      onClick={handleAddNewFilter}
                      data-testid="create-filter"
                    >
                      {translate('dependentVariablesConfigStartConfig')}
                    </Button>
                  </div>
                </CreateFilterContainer>
              ) : (
                <CategorizationContainer id="config-filters-container">
                  {Array.from(Array(filters.length).keys()).map(
                    (filterLevel) => (
                      <ConfigFilters
                        key={filterLevel}
                        filterLevel={filterLevel}
                        filters={filters}
                        setFilters={setFilters}
                        yIds={ids}
                        yIdLabel={yIdLabel}
                      />
                    ),
                  )}
                </CategorizationContainer>
              )}

              <ModalFooter className="categorization-modal-footer">
                {!!filters.length && filters.length < 3 && (
                  <Button
                    buttonType="secondary"
                    icon={<Plus size="1.25rem" />}
                    onClick={handleAddNewFilter}
                    data-testid="add-new-filter"
                  >
                    {translate('createFiltersAddNew')}
                  </Button>
                )}

                <Button
                  buttonType="primary"
                  onClick={() => {
                    handleSaveVariableCategorization(
                      JSON.parse(JSON.stringify(filters)),
                    );

                    setShowVariableCategorization(false);
                  }}
                  disabled={!hasChanges || hasFilterError}
                  data-tooltip-html={
                    hasFilterError
                      ? translate('createWorkspaceSaveChangesError')
                      : ''
                  }
                  data-tooltip-id="variable-categorization-tooltip"
                  data-testid="save-changes"
                >
                  {translate('createWorkspaceSaveVariableCategorization')}
                </Button>
              </ModalFooter>

              <Tutorial
                visible={showTutorial}
                setVisible={setShowTutorial}
                showLastPage={false}
              />
            </ModalContent>
          </ModalContainer>

          {showConfirmCloseCategorization && (
            <Modal
              visible
              setVisible={setShowConfirmCategorization}
              dataCy="confirm-close-categorization"
            >
              <ConfirmCloseCategorizationContainer>
                <Warning size="3rem" data-testid="x-circle-icon" />

                <h1 data-testid="warning-modal-title">
                  {translate('createWorkspaceConfirmCloseCategorizationTitle')}
                </h1>
                <p data-testid="warning-modal-description">
                  {translate(
                    'createWorkspaceConfirmCloseCategorizationDescription',
                  )}
                </p>
              </ConfirmCloseCategorizationContainer>

              <ModalFooter>
                <Button
                  buttonType="naked"
                  onClick={() => setShowConfirmCategorization(false)}
                  data-testid="cancel-button"
                >
                  {translate('cancel')}
                </Button>
                <Button
                  buttonType="primary"
                  onClick={() => {
                    setShowConfirmCategorization(false);
                    setShowVariableCategorization(false);
                  }}
                  data-testid="continue-button"
                >
                  {translate('exit')}
                </Button>
              </ModalFooter>
            </Modal>
          )}

          <Tooltip
            id="variable-categorization-tooltip"
            className="customTooltipTheme"
          />
        </>
      );
    },
    (prevProps, nextProps) =>
      JSON.stringify(prevProps.ys) === JSON.stringify(nextProps.ys) &&
      JSON.stringify(prevProps.currentFilters) ===
        JSON.stringify(nextProps.currentFilters) &&
      prevProps.variableError === nextProps.variableError,
  );
