import {
  addMonths,
  format,
  isAfter,
  isBefore,
  parseISO,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { frequencyLatestData } from 'src/utils/getLatestData';

interface ChartData {
  dates: string[];
  values: number[];
  label: string;
}

export const getLatestData = (
  data: ChartData[] | undefined,
  frequency:
    | 'daily'
    | 'weekly'
    | 'fortnightly'
    | 'monthly'
    | 'bimonthly'
    | 'quarterly'
    | 'half-year'
    | 'annual',
): ChartData[] => {
  if (data) {
    const frequencyCount = frequencyLatestData[frequency];

    let latestData = '';

    data.forEach((info) => {
      if (latestData === '') {
        latestData = info.dates[info.dates?.length - 1];
      } else if (
        isAfter(
          parseISO(info.dates[info.dates?.length - 1]),
          parseISO(latestData),
        )
      ) {
        latestData = info.dates[info.dates?.length - 1];
      }
    });

    let startDate = '';
    let endDate = '';

    switch (frequency) {
      case 'daily':
        startDate = format(
          subDays(parseISO(latestData), frequencyCount),
          'yyyy-MM-dd',
        );
        break;
      case 'weekly':
        startDate = format(
          subDays(parseISO(latestData), frequencyCount * 7),
          'yyyy-MM-dd',
        );
        endDate = format(addMonths(parseISO(latestData), 1), 'yyyy-MM-dd');
        break;
      case 'fortnightly':
        startDate = format(
          subDays(parseISO(latestData), frequencyCount * 15),
          'yyyy-MM-dd',
        );
        endDate = format(addMonths(parseISO(latestData), 1), 'yyyy-MM-dd');
        break;
      case 'monthly':
        startDate = format(
          subMonths(parseISO(latestData), frequencyCount),
          'yyyy-MM-dd',
        );
        endDate = format(addMonths(parseISO(latestData), 1), 'yyyy-MM-dd');
        break;
      case 'bimonthly':
        startDate = format(
          subMonths(parseISO(latestData), frequencyCount * 2),
          'yyyy-MM-dd',
        );
        endDate = format(addMonths(parseISO(latestData), 1), 'yyyy-MM-dd');
        break;
      case 'quarterly':
        startDate = format(
          subMonths(parseISO(latestData), frequencyCount * 3),
          'yyyy-MM-dd',
        );
        endDate = format(addMonths(parseISO(latestData), 1), 'yyyy-MM-dd');
        break;
      case 'half-year':
        startDate = format(
          subMonths(parseISO(latestData), frequencyCount * 6),
          'yyyy-MM-dd',
        );
        endDate = format(addMonths(parseISO(latestData), 1), 'yyyy-MM-dd');
        break;
      case 'annual':
        startDate = format(
          subYears(parseISO(latestData), frequencyCount),
          'yyyy-MM-dd',
        );
        endDate = format(addMonths(parseISO(latestData), 1), 'yyyy-MM-dd');
        break;
      default:
        break;
    }

    const result = data.map((info) => {
      let startIndex = -1;
      let endIndex = -1;

      startIndex = info.dates.findIndex((date) =>
        isAfter(parseISO(date), parseISO(startDate)),
      );

      for (let i = info.dates?.length - 1; i > 0 && endIndex === -1; i--) {
        if (isBefore(parseISO(info.dates[i]), parseISO(endDate))) {
          endIndex = i;
        }
      }

      return {
        dates: info.dates.filter(
          (date) =>
            isBefore(parseISO(date), parseISO(endDate)) &&
            isAfter(parseISO(date), parseISO(startDate)),
        ),
        values: isBefore(parseISO(info.dates[0]), parseISO(startDate))
          ? info.values.slice(startIndex, endIndex + 1)
          : info.values,
        label: info.label,
      };
    });

    return result;
  }

  return [];
};
