import styled from 'styled-components';

export const Container = styled.div`
  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    > div {
      width: 100%;

      display: flex;
      flex-direction: column;

      .rc-slider-mark {
        span:first-child {
          margin-left: 0.313rem;
        }

        span:last-child {
          padding-right: 0.625rem;
        }
      }

      .rc-slider-mark-text {
        font-size: 0.688rem;
      }
    }

    .first-container {
      min-width: 20rem;
      max-width: 20rem;
    }

    .second-container {
      width: calc(100% - 21.5rem);
    }

    h5 {
      word-wrap: break-word;
    }
  }
`;

export const ContainerSecundary = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  @media only screen and (max-width: 700px) {
    max-width: 100%;
  }

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colors.gray6};
    opacity: 0.8;

    margin: 2.25rem 0 1rem;
  }

  h5.no-simulations {
    margin: 0 0 1rem;
    font-weight: normal;
    opacity: 1;
  }
`;

export const SelectModelListInformation = styled.div`
  > div {
    display: flex;
    margin-bottom: 1rem;

    max-width: 20rem;
    @media only screen and (max-width: 700px) {
      max-width: 100%;
    }

    > div {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;

      h4 {
        color: ${({ theme }) => theme.colors.gray6};
        opacity: 0.8;
        font-weight: 600;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
      }
      p {
        color: ${({ theme }) => theme.colors.gray5};
        font-size: 0.875rem;
      }
    }
  }
`;

export const ChartDiv = styled.div`
  display: flex;
  margin-top: 0.625rem;
`;

export const ModelDiv = styled.div`
  display: flex;
  div {
    margin: 0.375rem 0;
    margin-right: 0.75rem;
  }
`;

export const VariableCard = styled.div`
  width: auto;
  min-width: auto;
  /* height: 110px; */
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  p {
    word-wrap: break-word;
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    text-align: right;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3.25rem;
    color: ${({ theme }) => theme.colors.secondary};
    text-align: right;
    word-wrap: break-word;
    opacity: 0.8;
  }
`;

export const HalfContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

export const DivButtons = styled.div`
  button {
    display: flex;
    width: 100%;
    justify-content: center;

    &:first-child {
      margin-bottom: 1rem;
    }
  }
`;

export const DownloadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ContentLatestData = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > div {
    > div {
      text-align: right;

      p {
        margin-right: 0px;
      }
    }
  }

  input {
    display: block !important;
    margin-left: auto !important;
  }
`;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 1.5rem;
`;
