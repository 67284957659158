import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { getChartColor } from 'src/utils/getChartColor';

interface ResidualHistogramChartProps {
  histogram: {
    y: number[];
    min: number;
    max: number;
    bin: number;
    type: string;
  };
}

export const ResidualHistogramChart: React.FC<ResidualHistogramChartProps> = ({
  histogram,
}) => {
  const { t: translate } = useTranslation();

  const histogramData = useMemo(() => {
    const binWidth = (histogram.max - histogram.min) / histogram.bin;

    const histogramDataAUX: number[][] = [];

    // Inicializa os bins com frequência zero
    for (let i = 0; i < histogram.bin; i++) {
      const start = histogram.min + i * binWidth;
      const end = start + binWidth;
      let frequency = 0;

      // Conta quantas observações estão dentro do bin
      for (let j = 0; j < histogram.y.length; j++) {
        if (histogram.y[j] >= start && histogram.y[j] < end) {
          frequency++;
        }
      }

      // Armazena os dados do bin
      histogramDataAUX.push([start, end, frequency]);
    }

    return histogramDataAUX;
  }, [histogram]);

  const series: HChartsSeries = useMemo(
    () => ({
      name: translate('histogram'),
      type: 'column',
      marker: {
        symbol: 'circle',
      },
      showInLegend: false,
      color: getChartColor(0),
      data: histogramData.map((bin) => {
        const x = (bin[0] + bin[1]) / 2;

        return {
          x,
          y: bin[2],
          custom: {
            value: `${formatCompactNotation(
              bin[0],
              4,
            )}  -  ${formatCompactNotation(bin[1], 4)}`,
            count: bin[2],
          },
        };
      }),
    }),
    [histogramData, translate],
  );

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
      },
      xAxis: {
        type: 'linear',
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            return formatCompactNotation(Number(this.value)); // Define o número de casas decimais desejado (2 neste caso)
          },
        },
        minTickInterval: undefined,
        title: {
          text: translate('modelSpecResidualsHistogramLabel'),
        },
      },
      yAxis: {
        title: {
          text: translate('modelSpecResidualsCountChartLabel'),
        },
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate(
            'modelSpecResidualsHistogramLabel',
          )}:</b> </td>` +
          `<td style="text-align: right">{point.custom.value}</td></tr>` +
          `<tr><td><b>${translate(
            'modelSpecResidualsCountChartLabel',
          )}:</b> </td>` +
          '<td style="text-align: right">{point.custom.count}</td></tr>',
      },
      plotOptions: {
        column: {
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0,
        },
      },
    }),
    [translate],
  );

  return (
    <HCharts
      series={[series]}
      options={options}
      dataCy="chart-check-residual-3"
    />
  );
};
