const indicatorsENTranslate = {
  indicatorsTitle: 'Indicators',
  indicatorsDescription: 'Select a indicator to send series to your groups',
  indicatorsFilters: 'Filters',
  indicatorsSource: 'Source',
  indicatorsProjections: 'Forecast',
  indicatorsPaginationName: 'indicators',
  indicatorsUnableToLoadIndicators: 'Unable to load indicators.',
  indicatorSearchFail: "We couldn't find any indicator containing",
  indicatorFindAnyCategory: "We couldn't find any indicator on this category",
  indicatorTryBrowsingCategoriesOrSearch:
    'Try browsing categories or search for another term',
  indicatorsQuickSearchTitle: 'Quick Search',
  indicatorsQuickSearchDescription:
    'Select a filter or type a text to find what you want more quickly.',
  indicatorQuickSearchInputDefaultPlaceholders: 'Search indicators',
  indicatorQuickSearchInputSelectOneCategoryPlaceholders: 'Search in',
  indicatorsQuickSearchClearFilters: 'Clear Filters',
  indicatorsQuickSearchInputErrorLeast3Characters:
    'Search must be at least 3 characters.',
  indicatorsQuickSearchInputErrorMaxCharacters:
    'Search must not be more than 50 characters',
  indicatorsQuickSearchNoSubCategories: 'No sub-categories available.',
  indicatorsQuickSearchUnableLoadCategories: 'Unable to load categories.',
  modalInfoGroupError: 'Unable to load this group.',
};

export default indicatorsENTranslate;
