import React from 'react';

import { Info } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';

import { Container, InputToogle } from './styles';
import { ToggleSwitchProps } from './types';

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  label,
  information,
  style,
  checked,
  useYesOrNo = false,
  isPTBR = false,
  className,
  ...rest
}) => {
  const { t: translate } = useTranslation();
  const { language } = useSelector((state: RootState) => state.auth.user);

  const optionName = {
    true: 'ON',
    false: 'OFF',
  };

  if (useYesOrNo) {
    optionName.true = isPTBR ? 'SIM' : translate('yes').toUpperCase();
    optionName.false = isPTBR ? 'NÃO' : translate('no').toUpperCase();
  }

  if (label) {
    return (
      <Container
        className={'columnWidth'.concat(className ? ` ${className}` : '')}
        style={style}
      >
        <div>
          {language === 'pt-br' && label === translate('allowDrift') ? (
            <p>
              Permitir <em>drift</em>
            </p>
          ) : (
            <p>{label}</p>
          )}

          {information && (
            <>
              <Info
                data-testid="toggleInfo"
                size="1.125rem"
                data-tooltip-id="toggle-tooltip"
                data-tooltip-html={information}
              />
              <Tooltip id="toggle-tooltip" className="customTooltipTheme" />
            </>
          )}
        </div>
        <InputToogle
          type="checkbox"
          checked={checked}
          optionName={optionName}
          {...rest}
        />
      </Container>
    );
  }

  return (
    <InputToogle
      type="checkbox"
      checked={checked}
      optionName={optionName}
      {...rest}
    />
  );
};
