import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MagnifyingGlass, X } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { useInfiniteQuery, useQuery } from 'react-query';
import apiWorkspace from 'src/models/service/apiWorkspace';
import ms from 'ms';
import {
  WorkspaceRelease,
  WorkspaceReleaseSelected,
  updateReleaseSelected,
} from 'src/models/redux/reducers/Workspace';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

import { WorkspaceSideBarContext } from '..';
import {
  CloseSidebarButton,
  Container,
  ContentTitle,
  Overlay,
  UpdateHistoryList,
  Version,
} from './styles';

type ReleaseMenuProps = {
  workspaceId: string;
};

interface ReleasesResponse {
  skip: number;
  limit: number;
  total: number;
  records: WorkspaceRelease[];
}

export const ReleaseMenu: React.FC<ReleaseMenuProps> = ({ workspaceId }) => {
  const { releaseSelected } = useSelector(
    (state: RootState) => state.workspace,
  );

  const [releaseCurrentId, setReleaseCurrentId] = useState(
    releaseSelected?.id ?? '',
  );
  const [releaseIndex, setReleaseCurrentIndex] = useState(
    releaseSelected?.index ?? 0,
  );

  const { menuReleaseVisible, closeMenuSelectRelease } = useContext(
    WorkspaceSideBarContext,
  );
  const { t: translate } = useTranslation();

  const translateFormat = useFormatDateLanguage();

  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  const QUANTITY_ITEMS_PAGE = 25;

  useEffect(() => {
    function isElementVisible(element: HTMLElement) {
      const rect = element.getBoundingClientRect();
      return rect.bottom >= 0 && rect.top <= window.innerHeight;
    }

    function handleScroll() {
      const footer = document.getElementById('footer');

      if (footer) {
        if (isElementVisible(footer)) {
          if (containerRef.current) {
            containerRef.current.style.height = `calc(100vh - (100vh - ${
              footer.getBoundingClientRect().top
            }px) - 74px)`;
          }
        } else if (containerRef.current) {
          containerRef.current.style.height = `calc(100vh - 74px)`;
        }
      }
    }

    handleScroll(); //executa a primeira vez pra definir a
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const isBottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
      event.currentTarget.clientHeight;

    if (
      isBottom &&
      !isLoadingReleases &&
      !isFetchingReleases &&
      !isErrorReleases &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  };

  const {
    data: releasesData,
    isLoading: isLoadingReleases,
    isFetching: isFetchingReleases,
    isError: isErrorReleases,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['workspace', 'releases on sidebar', workspaceId],
    async ({ pageParam = 0 }) => {
      const { data } = await apiWorkspace.get<ReleasesResponse>(
        `/workspaces/${workspaceId}/releases?skip=${
          pageParam * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      staleTime: ms('5 min'),
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * QUANTITY_ITEMS_PAGE < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
    },
  );

  const {
    data: releaseSelectedData,
    isLoading: isLoadingSelectedRelease,
    isFetching: isFetchingSelectedRelease,
  } = useQuery(
    ['workspace', workspaceId, 'releases', releaseCurrentId],
    async () => {
      const response = await apiWorkspace.get<WorkspaceReleaseSelected>(
        `/workspaces/${workspaceId}/releases/${releaseCurrentId}`,
      );
      return response.data;
    },
    {
      staleTime: ms('5 min'),
      enabled: !!workspaceId && !!releasesData && !!releaseCurrentId,
    },
  );

  useEffect(() => {
    if (releaseSelectedData) {
      dispatch(
        updateReleaseSelected({
          releaseSelected: { ...releaseSelectedData, index: releaseIndex },
          ySelected: releaseSelectedData.data.ys[0],
        }),
      );

      closeMenuSelectRelease();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, releaseSelectedData]);

  return (
    <>
      {menuReleaseVisible && (
        <Overlay
          visible={menuReleaseVisible}
          style={{
            cursor:
              isLoadingSelectedRelease || isFetchingSelectedRelease
                ? 'progress'
                : 'auto',
          }}
        />
      )}
      <Container
        visible={menuReleaseVisible}
        ref={containerRef}
        loading={isLoadingSelectedRelease || isFetchingSelectedRelease}
      >
        {menuReleaseVisible && (
          <CloseSidebarButton
            onClick={closeMenuSelectRelease}
            disabled={isLoadingSelectedRelease || isFetchingSelectedRelease}
            data-testid="close-history-button"
          >
            <X size="1.125rem" />
          </CloseSidebarButton>
        )}
        <div>
          <ContentTitle>
            <h3>{translate('workspaceSidebarUpdateTitle')}</h3>
            <p>{translate('workspaceSidebarUpdateDescription')}</p>
          </ContentTitle>
          <UpdateHistoryList onScroll={handleOnScroll}>
            {isLoadingReleases || isFetchingReleases || isFetchingNextPage
              ? skeleton
              : releasesData?.pages.map((releases, index) => (
                  // eslint-disable-next-line react/jsx-indent
                  <Fragment key={`${index + 1}`}>
                    {releases.records.map((release, indexReleases) =>
                      !release.preview ? (
                        <Version
                          selected={releaseSelected?.id === release.id}
                          key={`release-${release?.id.toString()}`}
                          data-testid={`release-${release?.id.toString()}`}
                          disabled={release.status !== 'success'}
                        >
                          <div>
                            <h4>
                              {release?.name?.replace(
                                'Version',
                                translate('version'),
                              )}
                            </h4>
                            <p>
                              {format(
                                new Date(release.published_at!),
                                `${translateFormat}`,
                              )}
                            </p>
                          </div>
                          <div>
                            <button
                              type="button"
                              onClick={() => {
                                setReleaseCurrentIndex(indexReleases);
                                setReleaseCurrentId(release.id);
                              }}
                              disabled={release.status !== 'success'}
                              data-testid={`navigate-release-${release?.id.toString()}`}
                            >
                              <MagnifyingGlass size="1.125rem" />
                            </button>
                          </div>
                        </Version>
                      ) : null,
                    )}
                  </Fragment>
                ))}
          </UpdateHistoryList>
        </div>
      </Container>
    </>
  );
};

const skeleton = Array.from({ length: 4 }, (_, index) => (
  <Version selected={false} key={`release-${index.toString()}`}>
    <div>
      <ContainerSkeleton
        withLoading={false}
        style={{
          height: '20px',
          width: `${Math.random() * 100 + 150}px`,
          marginBottom: '0.5rem',
        }}
      />

      <ContainerSkeleton
        withLoading={false}
        style={{
          height: '20px',
          width: `${Math.random() * 100 + 150}px`,
        }}
      />
    </div>
    <div>
      <ContainerSkeleton
        withLoading={false}
        style={{
          height: '1.25rem',
          width: `1.25rem`,
        }}
      />
    </div>
  </Version>
));
