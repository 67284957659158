import React, { Fragment, useContext, useEffect, useState } from 'react';

import { format, isMatch, parse } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import api from 'src/feature-store/service/api';
import { useInfiniteQuery } from 'react-query';
import { Card } from 'src/components/Card';
import { Heart, MagnifyingGlass, Plus } from 'phosphor-react';
import { Input } from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { GroupCard } from 'src/feature-store/pages/Groups/components/GroupCard';
import {
  MyGroupsProps,
  PreDefinedGroupsProps,
} from 'src/feature-store/pages/Groups/types';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { dateToIsoStringWithTimezone } from 'src/utils/dateToIsoStringWithTimezone';
import { useLocation } from 'react-router-dom';

import {
  ButtonsContainer,
  Container,
  FavoriteButton,
  FavoritesContainer,
  HeaderContainer,
  NewGroupButton,
  NotFoundFavorite,
  TitleHeader,
} from './styles';
import { FeatureStoreSidebarContext } from '../Contexts/NavigationContext';

export const Favorites: React.FC = () => {
  const { isFsAdmin } = useSelector((state: RootState) => state.auth.user);

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchError, setSearchError] = useState<string>('');
  const [lastSearch, setLastSearch] = useState('');
  const [searchTimer, setSearchTimer] = useState(1000);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [requestAllowed, setRequestAllowed] = useState(true);

  const { t: translate } = useTranslation();
  const translateFormat = useFormatDateLanguage();
  const location = useLocation();

  const {
    handleMyFavoriteId,
    openCreateFavoriteModal,
    handleTypeFavoriteCreate,
    favoriteType,
    setterFavoriteType,
  } = useContext(FeatureStoreSidebarContext);

  const QUANTITY_ITEMS_PAGE = 8;

  const {
    data: myFavorites,
    isFetching: isMyFavoriteFetching,
    isLoading: isMyFavoriteLoading,
    isError: isMyFavoriteErrored,
    fetchNextPage: fetchNextPageMyFavorites,
    hasNextPage: isMyFavoritesHasNextPage,
    isFetchingNextPage: isMyFavoritesFetchingNextPage,
  } = useInfiniteQuery(
    ['my favorite groups', searchValue],
    async ({ pageParam = 0 }) => {
      let searchQuery = searchValue;
      if (isMatch(searchValue, translateFormat)) {
        searchQuery = format(
          parse(searchValue, translateFormat, new Date()),
          'yyyy-MM-dd',
        );
      }

      const { data } = await api.get<MyGroupsProps>(
        searchValue.length >= 3
          ? isMatch(searchQuery, 'yyyy-MM-dd')
            ? `/groups?name=${encodeURIComponent(
                searchValue,
              )}&update_at=${dateToIsoStringWithTimezone(
                new Date(`${searchQuery}T00:00`),
              )}&created_at=${dateToIsoStringWithTimezone(
                new Date(`${searchQuery}T00:00`),
              )}&skip=${
                pageParam * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
            : `/groups?name=${encodeURIComponent(searchValue)}&skip=${
                pageParam * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
          : `/groups?skip=${
              pageParam * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      if (pageParam === 0) {
        handleMyFavoriteId([data.data[0].id, data.data[1]?.id]);
      }

      return data;
    },
    {
      staleTime: 1000 * 60,
      enabled: requestAllowed || searchValue === '',
      onSettled: () => {
        setRequestAllowed(false);
        setTimeOutActive(false);
        setSearchTimer(1000);
        setLastSearch(searchValue);
      },
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * 8 < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
    },
  );

  const {
    data: favorites4i,
    isFetching: is4iFavoriteFetching,
    isLoading: is4iFavoriteLoading,
    isError: is4iFavoriteErrored,
    fetchNextPage: fetchNextPage4iFavorites,
    hasNextPage: is4iFavoritesHasNextPage,
    isFetchingNextPage: is4iFavoritesFetchingNextPage,
  } = useInfiniteQuery(
    ['4i favorite', searchValue],
    async ({ pageParam = 0 }) => {
      let searchQuery = searchValue;
      if (isMatch(searchValue, translateFormat)) {
        searchQuery = format(
          parse(searchValue, translateFormat, new Date()),
          'yyyy-MM-dd',
        );
      }

      const { data } = await api.get<PreDefinedGroupsProps>(
        searchValue.length >= 3
          ? isMatch(searchQuery, 'yyyy-MM-dd')
            ? `/groups/predefineds?name=${encodeURIComponent(
                searchValue,
              )}&update_at=${dateToIsoStringWithTimezone(
                new Date(`${searchQuery}T00:00`),
              )}&created_at=${dateToIsoStringWithTimezone(
                new Date(`${searchQuery}T00:00`),
              )}&skip=${
                pageParam * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
            : `/groups/predefineds?name=${encodeURIComponent(
                searchValue,
              )}&skip=${
                pageParam * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
          : `/groups/predefineds?skip=${
              pageParam * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60,
      enabled: requestAllowed || searchValue === '',
      onSettled: () => {
        setRequestAllowed(false);
        setTimeOutActive(false);
        setSearchTimer(1000);
        setLastSearch(searchValue);
      },
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * 8 < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
    },
  );

  const handleSearchGroups = (value: string) => {
    setSearchValue(value);

    if (value.length > 30) {
      setSearchError('groupSeriesErrorMessageInputMax30Caracteres');
      return;
    }

    if (value.length < 3 && value.length > 0) {
      setSearchError('groupSeriesErrorMessageInput3Caracteres');
      return;
    }

    setSearchError('');

    if (value !== searchValue) {
      setSearchTimer(1000);
      setTimeOutActive(true);
    }

    if (value !== searchValue) {
      setTimeOutActive(true);
    }
  };

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const isBottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
      event.currentTarget.clientHeight;

    if (
      isBottom &&
      !isMyFavoriteLoading &&
      !isMyFavoriteFetching &&
      !isMyFavoriteErrored &&
      isMyFavoritesHasNextPage &&
      favoriteType === 'myFavorite'
    ) {
      fetchNextPageMyFavorites();
    }

    if (
      isBottom &&
      !is4iFavoriteLoading &&
      !is4iFavoriteFetching &&
      !is4iFavoriteErrored &&
      is4iFavoritesHasNextPage &&
      favoriteType === '4iFavorite'
    ) {
      fetchNextPage4iFavorites();
    }
  };

  useEffect(() => {
    if (timeOutActive && searchValue.length >= 3) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 1000);
        } else {
          setTimeOutActive(false);
        }
      }, 1000);
    } else {
      searchTimer === 0 && setRequestAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  useEffect(() => {
    if (favorites4i || myFavorites) {
      setRequestAllowed(false);
      setTimeOutActive(false);
      setSearchTimer(1000);
      setLastSearch(searchValue);
    }
  }, [favorites4i, myFavorites, searchValue]);

  return (
    <Container onScroll={handleOnScroll}>
      <Card
        textCard={translate('favoritesTitle')}
        textDescription={
          (favoriteType === 'myFavorite' &&
            translate('groupsMyFavoritesDescription')) ||
          translate('groups4iFavoritesDescription')
        }
        style={{ height: '112px' }}
      />

      <Input
        icon={<MagnifyingGlass size="1.25rem" />}
        data-testid="input-search-group"
        placeholder={translate('groupsSeriesGroupsButtonPlaceholder')}
        style={{ width: '100%', marginBottom: '0.625rem' }}
        value={searchValue}
        onChange={({ target: { value } }) => handleSearchGroups(value)}
        error={translate(searchError)}
      />

      <ButtonsContainer>
        <FavoriteButton
          data-testid="button-my-favorites"
          active={favoriteType === 'myFavorite'}
          type="button"
          onClick={() => {
            setterFavoriteType('myFavorite');
          }}
        >
          {translate('createdGroupByMe')}
        </FavoriteButton>

        <FavoriteButton
          data-testid="button-4i-favorites"
          active={favoriteType === '4iFavorite'}
          type="button"
          onClick={() => {
            setterFavoriteType('4iFavorite');
          }}
        >
          {translate('preDefinedTitleTab')}
        </FavoriteButton>
      </ButtonsContainer>

      <HeaderContainer>
        <TitleHeader>
          <Heart size="1rem" />
          <p>{translate('favoritesTitle')}</p>
        </TitleHeader>

        {isFsAdmin && favoriteType === '4iFavorite' && (
          <NewGroupButton
            data-testid="button-create-group"
            disabled={favoriteType === '4iFavorite' && !isFsAdmin}
            onClick={() => {
              openCreateFavoriteModal(true);
              handleTypeFavoriteCreate(favoriteType);
            }}
          >
            <Plus size="1rem" />
            <p>{translate('favoriteSidebarNewGroup')}</p>
          </NewGroupButton>
        )}

        {isFsAdmin && favoriteType === 'myFavorite' && (
          <NewGroupButton
            data-testid="button-create-group"
            onClick={() => {
              openCreateFavoriteModal(true);
              handleTypeFavoriteCreate(favoriteType);
            }}
          >
            <Plus size="1rem" />
            <p>{translate('favoriteSidebarNewGroup')}</p>
          </NewGroupButton>
        )}

        {!isFsAdmin && favoriteType === 'myFavorite' && (
          <NewGroupButton
            data-testid="button-create-group"
            onClick={() => {
              openCreateFavoriteModal(true);
              handleTypeFavoriteCreate(favoriteType);
            }}
          >
            <Plus size="1rem" />
            <p>{translate('favoriteSidebarNewGroup')}</p>
          </NewGroupButton>
        )}
      </HeaderContainer>

      <FavoritesContainer data-testid="favovites-container">
        {(timeOutActive ||
          isMyFavoriteFetching ||
          is4iFavoriteFetching ||
          isMyFavoriteLoading ||
          is4iFavoriteLoading) &&
          Array.from(Array(5), (_, index) => (
            <GroupCard
              key={index}
              id={index.toString()}
              name={index.toString()}
              iconUrl={index.toString()}
              updatedAt={index.toString()}
              description=""
              isLoading
            />
          ))}

        {favoriteType === 'myFavorite' &&
          myFavorites?.pages?.map((group, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={i + 1}>
              {group.data.map((myFavorite) => (
                <GroupCard
                  key={myFavorite.id}
                  id={myFavorite.id}
                  name={myFavorite.name}
                  iconUrl={myFavorite.icon_url}
                  updatedAt={myFavorite.update_at}
                  description={myFavorite.description}
                  isLoading={
                    is4iFavoriteLoading ||
                    is4iFavoriteFetching ||
                    is4iFavoritesFetchingNextPage ||
                    timeOutActive ||
                    isMyFavoriteLoading ||
                    isMyFavoriteFetching ||
                    isMyFavoritesFetchingNextPage
                  }
                />
              ))}
            </Fragment>
          ))}

        {favoriteType === '4iFavorite' &&
          favorites4i?.pages?.map((group, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={i + 1}>
              {group.data.map((favorite4i) => (
                <GroupCard
                  key={favorite4i.id}
                  id={favorite4i.id}
                  name={favorite4i.name}
                  iconUrl={favorite4i.icon_url}
                  updatedAt={favorite4i.update_at}
                  predefined
                  description={favorite4i.description}
                  isLoading={
                    is4iFavoriteLoading ||
                    is4iFavoriteFetching ||
                    is4iFavoritesFetchingNextPage ||
                    timeOutActive ||
                    isMyFavoriteLoading ||
                    isMyFavoriteFetching ||
                    isMyFavoritesFetchingNextPage
                  }
                />
              ))}
            </Fragment>
          ))}

        {favoriteType === 'myFavorite' &&
          searchValue !== '' &&
          myFavorites === undefined &&
          !timeOutActive &&
          !isMyFavoriteFetching &&
          !isMyFavoriteLoading &&
          searchValue.length >= 3 && (
            <NotFoundFavorite>
              <p>
                {`${translate(
                  'groupSeriesErrorSearchGroupFail',
                )} "${lastSearch}"`}
              </p>
            </NotFoundFavorite>
          )}

        {favoriteType === '4iFavorite' &&
          favorites4i?.pages[0].total === 0 &&
          !timeOutActive &&
          !isMyFavoriteFetching &&
          !isMyFavoriteLoading &&
          searchValue.length >= 3 && (
            <NotFoundFavorite>
              <p>
                {`${translate(
                  'groupSeriesErrorSearchGroupFail',
                )} "${lastSearch}"`}
              </p>
            </NotFoundFavorite>
          )}

        {favoriteType === 'myFavorite' &&
          searchValue === '' &&
          myFavorites === undefined &&
          !location.pathname.includes(
            '/feature-store/favorites/create-first-favorite',
          ) && (
            <NotFoundFavorite>
              <p>{translate('groupSeriesErrorNoGroupFound')}</p>
            </NotFoundFavorite>
          )}
      </FavoritesContainer>
    </Container>
  );
};
