import React from 'react';

import { Eye, PencilSimple, X } from 'phosphor-react';

import { UserRoleOptionLabelProps } from './types';
import { Container } from './styles';

export const UserRoleOptionLabel: React.FC<UserRoleOptionLabelProps> = ({
  label,
  value,
  tooltipMessage,
}) => (
  <Container
    data-tooltip-content={tooltipMessage}
    data-tooltip-id="workspace-control-panel-tooltip"
  >
    {value.toString() === 'reader' ? (
      <Eye />
    ) : value.toString() === 'editor' ? (
      <PencilSimple />
    ) : (
      <X />
    )}
    <p>{label}</p>
  </Container>
);
