/* eslint-disable react/no-this-in-sfc */
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import light from 'src/styles/themes/light';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { getTextWidth } from 'src/utils/getTextWidth';
import { truncateStrings } from 'src/utils/truncateStrings';

type VariableImportance = {
  y_value: number[];
  x_name: string[];
};

interface VariableImportanceChartProps {
  variableImportanceData: VariableImportance;
  variablesQuantity: number;
}

export const VariableImportanceChart: React.FC<
  VariableImportanceChartProps
> = ({ variableImportanceData, variablesQuantity }) => {
  const { t: translate } = useTranslation();

  const filterVariableImportanceValues = useCallback(
    (value: number) => {
      if (variableImportanceData?.x_name && variableImportanceData.y_value) {
        const newNameValues = variableImportanceData?.x_name.filter(
          (_, index) => index < value,
        );
        const newYValues = variableImportanceData?.y_value.filter(
          (_, index) => index < value,
        );

        const newVarImportanceChartData = {
          y_value: newYValues,
          x_name: newNameValues,
        };

        return newVarImportanceChartData;
      }

      return null;
    },
    [variableImportanceData],
  );

  const varsToDisplay: VariableImportance = useMemo(
    () =>
      filterVariableImportanceValues(variablesQuantity) ?? {
        x_name: variableImportanceData?.x_name ?? [],
        y_value: variableImportanceData?.y_value ?? [],
      },
    [filterVariableImportanceValues, variableImportanceData, variablesQuantity],
  );

  const serie: HChartsSeries = useMemo(() => {
    const chartColors = varsToDisplay.y_value.map((value) =>
      value >= 0 ? `${light.colors.primary}3d` : `${light.colors.red4}3d`,
    );

    const YsColumn = truncateStrings(varsToDisplay.x_name ?? []);

    return {
      type: 'bar',
      data: varsToDisplay.y_value.map((value, index) => ({
        name: YsColumn[index],
        color: chartColors[index],
        y: value,
        value,
        borderWidth: 0,
        custom: {
          key: varsToDisplay.x_name[index],
          value: formatCompactNotation(value),
        },
        dataLabels: {
          enabled: true,
          borderWidth: 0,
          verticalAlign: 'middle',
          inside: false,
          format: formatCompactNotation(value), // Formato do texto a ser exibido
          style: {
            color: value >= 0 ? light.colors.primary : light.colors.red4,
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: "'Inter', sans-serif",
            textOutline: '0',
          },
        },
      })),
    };
  }, [varsToDisplay]);

  const options: HChartsOptions = useMemo(() => {
    const chartTextWidth = varsToDisplay.x_name
      .map((text) => getTextWidth(text, '400 14px Inter'))
      .reduce((previous, current) => {
        if (previous > current) {
          return previous;
        }
        return current + 50;
      }, 0);

    return {
      chart: {
        type: 'bar',
        height:
          60 * varsToDisplay.y_value.length + 8 > 500
            ? '508px'
            : `${60 * varsToDisplay.y_value.length + 8}px`,
        marginTop: 0,
        zooming: {
          type: undefined,
        },
        animation: false,
        events: {
          render() {
            const series = this.series[0];
            series.points.forEach((point) => {
              if (typeof point.y === 'number') {
                //@ts-expect-error:ignora
                const pointBox = point.graphic.getBBox();

                const x =
                  point.y < 0
                    ? this.plotWidth -
                      pointBox.y -
                      pointBox.height -
                      //@ts-expect-error:ignora
                      point.dataLabel.width
                    : this.plotWidth - pointBox.y;
                //@ts-expect-error:ignora
                point.dataLabels[0].attr({
                  x,
                });
              }
            });
          },
        },
      },
      xAxis: {
        type: 'category',
        minTickInterval: undefined,
        grid: {
          enabled: false,
        },
        labels: {
          y: undefined,
        },

        gridLineWidth: 0,
        lineWidth: 0,
      },
      yAxis: {
        tickPositioner() {
          const positions: number[] = []; // Inicia com a posição 0

          // Adiciona outras posições, se necessário
          let max = this.chart.series[0].dataMax ?? 0; // Evita valores negativos no máximo
          let min = this.chart.series[0].dataMin ?? 0; // Evita valores positivos no mínimo

          const quantity = Math.abs(max - min) + 1;

          const width = this.chart.plotWidth;

          const widthByPosition = width / quantity;

          const decrementMin = chartTextWidth / widthByPosition;

          const incrementMax = chartTextWidth / widthByPosition;

          if (min < 0) {
            min -= decrementMin;
            positions.push(min);
          }

          positions.push(0);

          if (max > 0) {
            max += incrementMax;
            positions.push(max);
          }

          return positions;
        },
        visible: true,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        title: {
          text: translate('textTitleAxisIncreaseOfTheMeanSquaredError'),
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '',
        pointFormat:
          `<table><tr><th colspan="2">{point.custom.key}</th></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
        useHTML: true,
      },
      plotOptions: {
        bar: {
          borderWidth: 0,
          pointPadding: 0.0,
          groupPadding: 0.05,
        },
      },
    };
  }, [translate, varsToDisplay.x_name, varsToDisplay.y_value.length]);

  return (
    <HCharts
      series={[serie]}
      options={options}
      dataCy="variable-importance-chart"
    />
  );
};
