import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  margin-bottom: -24px;

  @media (min-width: 1450px) {
    justify-content: center;
  }
`;

export const IndicatorsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 63rem;
  max-width: 63.125rem;
`;

export const ContainerWithoutIndicators = styled.div`
  width: 100%;
  height: 37.188rem;
  padding: 1.5rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
