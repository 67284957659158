import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 30rem;
  /* height: 415px; */

  padding: 2rem 2rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > svg {
    color: ${({ theme }) => theme.colors.red5};
    margin-bottom: 1.875rem;
  }

  > h1 {
    font-size: 1.5rem;
    line-height: 150%;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.75rem;
    margin-top: -0.5rem;
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 3rem;
  }
`;

export const ContentButton = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.gray0};
  width: 30rem;
  height: 5.5rem;
  padding: 1.5rem 1.5rem;
  border-radius: 0 0 8px 8px;

  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    justify-content: center;
    padding: 0.75rem 2rem;
    height: 2.5rem;

    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
  }
`;
