/* eslint-disable react/no-this-in-sfc */
import React, { useLayoutEffect, useState } from 'react';

import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import light from 'src/styles/themes/light';
import { useTranslation } from 'react-i18next';
import { truncateStrings } from 'src/utils/truncateStrings';
import { getTextWidth } from 'src/utils/getTextWidth';
import { HCharts, HChartsSeries } from 'src/components/HCharts';

import { ResultsWaterfallContainer, SeeOthersVars } from './styles';

interface ResultsWaterfallProps {
  topVars: {
    [key: string]: number;
  };
  highlighVars: {
    [key: string]: number;
  };
  otherVars: {
    [key: string]: number;
  };
  loadingResult: boolean;
}

export const ResultsWaterfall: React.FC<ResultsWaterfallProps> = ({
  topVars,
  highlighVars,
  otherVars,
  loadingResult,
}) => {
  const [seeOthersVariables, setSeeOtherVariables] = useState(false);

  const { t: translate } = useTranslation();

  const otherVarsKeys = Object.keys(otherVars);
  const otherVarsValues = Object.keys(otherVars).map((key) => otherVars[key]);

  useLayoutEffect(() => {
    if (otherVarsKeys.length <= 1) {
      setSeeOtherVariables(true);
    }
  }, [otherVarsKeys.length]);

  if (loadingResult) {
    return (
      <ContainerSkeleton
        data-testid="results-waterfall-loading"
        style={{
          width: '100%',
          height: '400px',
        }}
      />
    );
  }

  function handleSeeOtherVariables() {
    setSeeOtherVariables((state) => !state);
  }

  const sunOtherVars = Object.keys(otherVars)
    .map((key) => otherVars[key])
    .reduce((acc, value) => acc + value, 0);

  const topVarsKeys = Object.keys(topVars);
  const topVarsValues = Object.keys(topVars).map((key) => topVars[key]);

  const highlighVarsKeys = Object.keys(highlighVars);
  const highlighVarsValues = Object.keys(highlighVars).map(
    (key) => highlighVars[key],
  );

  const keys = [
    ...topVarsKeys.slice(1),
    ...highlighVarsKeys,
    otherVarsKeys.length === 0
      ? []
      : seeOthersVariables
      ? otherVarsKeys
      : translate('workspaceProjectionsResultsUnderstandingOthers'),
  ].flat();

  if (topVarsKeys.length) {
    keys.push(topVarsKeys[0]);
  }

  const keyAdjusted = truncateStrings(keys);

  const values = [
    ...topVarsValues.slice(1),
    ...highlighVarsValues,
    otherVarsValues.length === 0
      ? []
      : seeOthersVariables
      ? otherVarsValues
      : sunOtherVars,
  ].flat();

  if (topVarsValues.length) {
    values.push(topVarsValues[0]);
  }

  const texts = values.map((value, index) =>
    index === values.length - 1
      ? `${value.toFixed(2)}%`
      : `${value.toFixed(2)}p.p`,
  );

  const textWidth = texts
    .map((key) => getTextWidth(key, '400 14px Inter'))
    .reduce((previous, current) => {
      if (previous > current) {
        return previous;
      }
      return current + 50;
    }, 0);

  const barColor = values.map((value, index) =>
    value >= 0
      ? index !== values.length - 1
        ? `${light.colors.primary}3d`
        : light.colors.primary
      : index !== values.length - 1
      ? `${light.colors.red4}3d`
      : light.colors.red4,
  );

  const serie: HChartsSeries = {
    type: 'waterfall',
    data: values.map((value, index) => ({
      name: keyAdjusted[index],
      color: barColor[index],
      y: value,
      value,
      isSum: index === values.length - 1,
      borderWidth: 0,
      custom: {
        key: keys[index],
        value: texts[index],
      },
      dataLabels: {
        enabled: true,
        borderWidth: 0,
        verticalAlign: 'middle',
        inside: false,
        format: texts[index], // Formato do texto a ser exibido
        style: {
          color: value >= 0 ? light.colors.primary : light.colors.red4,
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: "'Inter', sans-serif",
          textOutline: '0',
        },
      },
    })),
  };

  return (
    <ResultsWaterfallContainer data-testid="results-waterfall-container">
      {otherVarsKeys.length > 1 && (
        <SeeOthersVars>
          <ToggleSwitch
            label={translate(
              'AISelectionResultsUnderstandingSeeOtherVariables',
            )}
            checked={seeOthersVariables}
            onChange={handleSeeOtherVariables}
            useYesOrNo
            data-testid="results-waterfall-see-other-variables"
          />
        </SeeOthersVars>
      )}

      <HCharts
        series={[serie]}
        options={{
          chart: {
            type: 'waterfall',
            inverted: true,
            height: 60 * keys.length > 500 ? '500px' : `${60 * keys.length}px`,
            marginTop: otherVarsKeys.length > 1 ? 24 : 0,
            marginBottom: 24,
            zooming: {
              type: undefined,
            },
            animation: false,
            events: {
              render() {
                const series = this.series[0];
                series.points.forEach((point) => {
                  if (typeof point.y === 'number') {
                    //@ts-expect-error:ignora
                    const pointBox = point.graphic.getBBox();

                    const x =
                      point.y < 0
                        ? this.plotWidth -
                          pointBox.y -
                          pointBox.height -
                          //@ts-expect-error:ignora
                          point.dataLabel.width
                        : this.plotWidth - pointBox.y;
                    //@ts-expect-error:ignora
                    point.dataLabels[0].attr({
                      x,
                    });
                  }
                });
              },
            },
          },
          xAxis: {
            type: 'category',
            minTickInterval: undefined,
            grid: {
              enabled: false,
            },
            labels: {
              y: undefined,
            },
            gridLineWidth: 0,
            lineWidth: 0,
          },
          yAxis: {
            labels: {
              formatter() {
                return this.value === 0 ? '0' : ''; // Mostra o rótulo 0, oculta os demais
              },
              style: {
                fontSize: '14px',
                color: light.colors.gray5,
              },
            },
            tickPositioner() {
              const positions: number[] = []; // Inicia com a posição 0

              // Adiciona outras posições, se necessário
              let max = Math.ceil(
                //@ts-expect-error:ignora
                this.chart.series[0]?.yData.reduce((maxAux, value) => {
                  if (maxAux < value) {
                    return value;
                  }
                  return maxAux;
                }, Number.MIN_VALUE),
              );

              if (!max) {
                max = Math.ceil(this.chart.series[0].dataMax ?? 0);
              }

              let min = Math.floor(
                //@ts-expect-error:ignora
                this.chart.series[0]?.yData.reduce((minAux, value) => {
                  if (minAux > value) {
                    return value;
                  }
                  return minAux;
                }, Number.MAX_VALUE),
              );

              if (!min) {
                min = Math.floor(this.chart.series[0].dataMin ?? 0);
              }

              const quantity = Math.abs(max - min) + 1;

              const width = this.chart.plotWidth;

              const widthByPosition = width / quantity;

              const decrementMin = textWidth / widthByPosition;

              const incrementMax = textWidth / widthByPosition;

              if (min < 0) {
                min -= decrementMin;
                positions.push(min);
              }

              positions.push(0);

              if (max > 0) {
                max += incrementMax;
                positions.push(max);
              }

              return positions;
            },
            gridLineWidth: 0,
            lineWidth: 0,
            title: {
              text: '',
            },
            plotLines: [
              {
                color: light.colors.gray3,
                width: 2,
                value: 0,
                dashStyle: 'LongDash',
              },
            ],
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            headerFormat: '',
            pointFormat:
              `<table><tr><th colspan="2">{point.custom.key}</th></tr>` +
              `<tr><td><b>${translate('value')}:</b> </td>` +
              '<td style="text-align: right">{point.custom.value}</td></tr>',
            useHTML: true,
          },
          plotOptions: {
            waterfall: {
              borderWidth: 0,
              lineWidth: 0,
              pointPadding: 0.0,
              groupPadding: 0.05,
            },
          },
        }}
        dataCy="waterfall-chart"
      />
    </ResultsWaterfallContainer>
  );
};
