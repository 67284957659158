import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { getChartColor } from 'src/utils/getChartColor';
import { CheckBox } from 'src/components/CheckBox';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import { format } from 'date-fns';
import { Tooltip } from 'react-tooltip';

import { Options } from './styles';

interface PercentageErrorMetricsProps {
  type:
    | 'arima'
    | 'regularized-regression'
    | 'forecast-combination'
    | 'random-forest';
  modelId: number;
  isLoadingModelInfo: boolean;
  isMAPEDisabled: boolean;
  isWMAPEDisabled: boolean;
  isMPEDisabled: boolean;
  isErrorModelInfo: boolean;
}

interface ErrorPercentage {
  data: string[][2];
  type: 'MAPE' | 'WMAPE' | 'MPE' | 'RMSE';
  x: number[];
  y: number[];
}

type Accuracy = 'MAPE' | 'WMAPE' | 'MPE';

export const PercentageErrorMetrics: React.FC<PercentageErrorMetricsProps> = ({
  type,
  modelId,
  isLoadingModelInfo,
  isMAPEDisabled,
  isWMAPEDisabled,
  isMPEDisabled,
  isErrorModelInfo,
}) => {
  const { project } = useSelector((state: RootState) => state);

  const [accuracySelected, setAccuracySelected] = useState<Accuracy[]>([]);

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (accuracySelected.length === 0) {
      if (!isMAPEDisabled) {
        setAccuracySelected(['MAPE']);
      } else if (!isWMAPEDisabled) {
        setAccuracySelected(['WMAPE']);
      } else if (!isMPEDisabled) {
        setAccuracySelected(['MPE']);
      }
    }
  }, [accuracySelected.length, isMAPEDisabled, isMPEDisabled, isWMAPEDisabled]);

  const {
    data: dataErrorPercentageMAPE,
    isLoading: isLoadingErrorPercentageMAPE,
    isFetching: isFetchingErrorPercentageMAPE,
    isError: isErrorErrorPercentageMAPE,
  } = useQuery(
    [
      `${type} percentage error`,
      project.id,
      project.selectedY?.id,
      modelId,
      'MAPE',
    ],
    async () => {
      const { data } = await api.get<ErrorPercentage>(
        `/projects/${project.id}/${project.selectedY?.id}/models/${type}/${modelId}/accuracy/MAPE`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const {
    data: dataErrorPercentageWMAPE,
    isLoading: isLoadingErrorPercentageWMAPE,
    isFetching: isFetchingErrorPercentageWMAPE,
    isError: isErrorErrorPercentageWMAPE,
  } = useQuery(
    [
      `${type} percentage error`,
      project.id,
      project.selectedY?.id,
      modelId,
      'WMAPE',
    ],
    async () => {
      const { data } = await api.get<ErrorPercentage>(
        `/projects/${project.id}/${project.selectedY?.id}/models/${type}/${modelId}/accuracy/WMAPE`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const {
    data: dataErrorPercentageMPE,
    isLoading: isLoadingErrorPercentageMPE,
    isFetching: isFetchingErrorPercentageMPE,
    isError: isErrorErrorPercentageMPE,
  } = useQuery(
    [
      `${type} percentage error`,
      project.id,
      project.selectedY?.id,
      modelId,
      'MPE',
    ],
    async () => {
      const { data } = await api.get<ErrorPercentage>(
        `/projects/${project.id}/${project.selectedY?.id}/models/${type}/${modelId}/accuracy/MPE`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  useEffect(() => {
    if (isMAPEDisabled || isErrorErrorPercentageMAPE) {
      setAccuracySelected((state) =>
        state.filter((accuracy) => accuracy !== 'MAPE'),
      );
    } else if (isWMAPEDisabled || isErrorErrorPercentageWMAPE) {
      setAccuracySelected((state) =>
        state.filter((accuracy) => accuracy !== 'WMAPE'),
      );
    } else if (isMPEDisabled || isErrorErrorPercentageMPE) {
      setAccuracySelected((state) =>
        state.filter((accuracy) => accuracy !== 'MPE'),
      );
    }
  }, [
    isMAPEDisabled,
    isMPEDisabled,
    isWMAPEDisabled,
    isErrorErrorPercentageMAPE,
    isErrorErrorPercentageWMAPE,
    isErrorErrorPercentageMPE,
  ]);

  useEffect(() => {
    if (accuracySelected.length === 0) {
      if (!isMAPEDisabled && !isErrorErrorPercentageMAPE) {
        setAccuracySelected(['MAPE']);
      } else if (!isWMAPEDisabled && !isErrorErrorPercentageWMAPE) {
        setAccuracySelected(['WMAPE']);
      } else if (!isMPEDisabled && !isErrorErrorPercentageMPE) {
        setAccuracySelected(['MPE']);
      }
    }
  }, [
    isMAPEDisabled,
    isMPEDisabled,
    isWMAPEDisabled,
    isErrorErrorPercentageMAPE,
    isErrorErrorPercentageWMAPE,
    isErrorErrorPercentageMPE,
    accuracySelected.length,
  ]);

  function handleAccuracy(value: Accuracy) {
    if (accuracySelected.includes(value)) {
      setAccuracySelected((state) =>
        state.filter((accuracy) => accuracy !== value),
      );
    } else {
      setAccuracySelected((state) => [...state, value]);
    }
  }

  function checkIsLoading() {
    if (isLoadingModelInfo) {
      return true;
    }

    if (accuracySelected.includes('MAPE')) {
      if (
        isLoadingErrorPercentageMAPE ||
        isFetchingErrorPercentageMAPE ||
        !dataErrorPercentageMAPE
      ) {
        return true;
      }
    }

    if (accuracySelected.includes('WMAPE')) {
      if (
        isLoadingErrorPercentageWMAPE ||
        isFetchingErrorPercentageWMAPE ||
        !dataErrorPercentageWMAPE
      ) {
        return true;
      }
    }

    if (accuracySelected.includes('MPE')) {
      if (
        isLoadingErrorPercentageMPE ||
        isFetchingErrorPercentageMPE ||
        !dataErrorPercentageMPE
      ) {
        return true;
      }
    }

    return false;
  }

  function checkIsError() {
    if (project.projectError || isErrorModelInfo) {
      return true;
    }

    if (isLoadingModelInfo) {
      return false;
    }

    if (isMAPEDisabled && isWMAPEDisabled && isMPEDisabled) {
      return true;
    }

    if (
      isErrorErrorPercentageMAPE &&
      isErrorErrorPercentageWMAPE &&
      isErrorErrorPercentageMPE
    ) {
      return true;
    }

    if (accuracySelected.includes('MAPE') && isErrorErrorPercentageMAPE) {
      return true;
    }

    if (accuracySelected.includes('WMAPE') && isErrorErrorPercentageWMAPE) {
      return true;
    }

    if (accuracySelected.includes('MPE') && isErrorErrorPercentageMPE) {
      return true;
    }

    return false;
  }

  const series: HChartsSeries[] = useMemo(() => {
    const seriesAux: HChartsSeries[] = [];

    if (accuracySelected.includes('MAPE')) {
      seriesAux.push({
        type: 'line',
        name: 'MAPE',
        color: getChartColor(0),
        data: dataErrorPercentageMAPE?.x.map((x, xIndex) => ({
          x,
          y: dataErrorPercentageMAPE.y[xIndex],

          custom: {
            start: format(
              new Date(`${dataErrorPercentageMAPE.data[xIndex][0]}T00:00`),
              project.selectedY?.info?.frequency === 'annual'
                ? 'yyyy'
                : 'dd/MM/yyyy',
            ),
            end: format(
              new Date(`${dataErrorPercentageMAPE.data[xIndex][1]}T00:00`),
              project.selectedY?.info?.frequency === 'annual'
                ? 'yyyy'
                : 'dd/MM/yyyy',
            ),
            value: `${formatCompactNotation(
              dataErrorPercentageMAPE.y[xIndex],
            )}%`,
          },
        })),
        marker: {
          symbol: 'Circle',
        },
      });
    }

    if (accuracySelected.includes('WMAPE')) {
      seriesAux.push({
        type: 'line',
        name: 'WMAPE',
        color: getChartColor(1),
        data: dataErrorPercentageWMAPE?.x.map((x, xIndex) => ({
          x,
          y: dataErrorPercentageWMAPE.y[xIndex],
          custom: {
            start: format(
              new Date(`${dataErrorPercentageWMAPE.data[xIndex][0]}T00:00`),
              project.selectedY?.info?.frequency === 'annual'
                ? 'yyyy'
                : 'dd/MM/yyyy',
            ),
            end: format(
              new Date(`${dataErrorPercentageWMAPE.data[xIndex][1]}T00:00`),
              project.selectedY?.info?.frequency === 'annual'
                ? 'yyyy'
                : 'dd/MM/yyyy',
            ),
            value: `${formatCompactNotation(
              dataErrorPercentageWMAPE.y[xIndex],
            )}%`,
          },
        })),
        marker: {
          symbol: 'Circle',
        },
      });
    }

    if (accuracySelected.includes('MPE')) {
      seriesAux.push({
        type: 'line',
        name: 'MPE',
        color: getChartColor(2),
        data: dataErrorPercentageMPE?.x.map((x, xIndex) => ({
          x,
          y: dataErrorPercentageMPE.y[xIndex],
          custom: {
            start: format(
              new Date(`${dataErrorPercentageMPE.data[xIndex][0]}T00:00`),
              project.selectedY?.info?.frequency === 'annual'
                ? 'yyyy'
                : 'dd/MM/yyyy',
            ),
            end: format(
              new Date(`${dataErrorPercentageMPE.data[xIndex][1]}T00:00`),
              project.selectedY?.info?.frequency === 'annual'
                ? 'yyyy'
                : 'dd/MM/yyyy',
            ),
            value: `${formatCompactNotation(
              dataErrorPercentageMPE.y[xIndex],
            )}%`,
          },
        })),
        marker: {
          symbol: 'Circle',
        },
      });
    }

    return seriesAux;
  }, [
    accuracySelected,
    dataErrorPercentageMAPE,
    project.selectedY?.info?.frequency,
    dataErrorPercentageWMAPE,
    dataErrorPercentageMPE,
  ]);

  const options: HChartsOptions = {
    chart: {
      height: 300,
    },
    xAxis: {
      type: 'linear',
      minTickInterval: undefined,
      title: {
        text: translate('crossValidationXAXISTitleWindow'),
      },
    },
    yAxis: {
      labels: {
        format: '{text}%',
      },
    },
    tooltip: {
      pointFormat:
        `<tr><td><b>${translate('start')}:</b> </td>` +
        `<td style="text-align: right">{point.custom.start}</td></tr>` +
        `<tr><td><b>${translate('end')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.end}</td></tr>' +
        `<tr><td><b>${translate('value')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.value}</td></tr>',
    },
  };

  const isLoading = checkIsLoading();
  const isError = checkIsError();

  const canDisableCheckbox = accuracySelected.length <= 1;

  const isCheckboxMAPEDisabled =
    (accuracySelected.includes('MAPE') && canDisableCheckbox) ||
    isMAPEDisabled ||
    isErrorErrorPercentageMAPE;

  const isCheckboxWMAPEDisabled =
    (accuracySelected.includes('WMAPE') && canDisableCheckbox) ||
    isWMAPEDisabled ||
    isErrorErrorPercentageWMAPE;

  const isCheckboxMPEDisabled =
    (accuracySelected.includes('MPE') && canDisableCheckbox) ||
    isMPEDisabled ||
    isErrorErrorPercentageMPE;

  return (
    <div
      className="containerLinear"
      data-testid="percentage-error-metrics-container"
      data-cy="percentage-error-metrics-container"
    >
      <Tooltip
        id="percentage-error-metrics-tooltip"
        className="customTooltipTheme"
      />
      <Card
        textCard={translate('crossValidationPercentageErrorMetrics')}
        textDescription={translate(
          'crossValidationPercentageErrorMetricsDescription',
        )}
      />

      <Options>
        <span>{translate('crossValidationMetrics')}</span>
        <div>
          <div
            data-tooltip-id="percentage-error-metrics-tooltip"
            data-tooltip-html={
              isMAPEDisabled || isErrorErrorPercentageMAPE
                ? translate('notAvailable')
                : undefined
            }
          >
            <CheckBox
              label="MAPE"
              onChange={() => handleAccuracy('MAPE')}
              checked={accuracySelected.includes('MAPE')}
              disabled={isCheckboxMAPEDisabled}
              data-cy="percentage-error-metrics-checkbox-mape"
              data-testid="percentage-error-metrics-checkbox-mape"
            />
          </div>

          <div
            data-tooltip-id="percentage-error-metrics-tooltip"
            data-tooltip-html={
              isWMAPEDisabled || isErrorErrorPercentageWMAPE
                ? translate('notAvailable')
                : undefined
            }
          >
            <CheckBox
              label="WMAPE"
              onChange={() => handleAccuracy('WMAPE')}
              checked={accuracySelected.includes('WMAPE')}
              disabled={isCheckboxWMAPEDisabled}
              data-cy="percentage-error-metrics-checkbox-wmape"
              data-testid="percentage-error-metrics-checkbox-wmape"
            />
          </div>

          <div
            data-tooltip-id="percentage-error-metrics-tooltip"
            data-tooltip-html={
              isMPEDisabled || isErrorErrorPercentageMPE
                ? translate('notAvailable')
                : undefined
            }
          >
            <CheckBox
              label="MPE"
              onChange={() => handleAccuracy('MPE')}
              checked={accuracySelected.includes('MPE')}
              disabled={isCheckboxMPEDisabled}
              data-cy="percentage-error-metrics-checkbox-mpe"
              data-testid="percentage-error-metrics-checkbox-mpe"
            />
          </div>
        </div>
      </Options>

      {isError ? (
        <ContainerMaintenance
          content="chart"
          data-testid="percentage-error-metrics-error"
          data-cy="percentage-error-metrics-error"
        />
      ) : isLoading ? (
        <ContainerSkeleton
          data-testid="percentage-error-metrics-loading"
          data-cy="percentage-error-metrics-loading"
        />
      ) : (
        <HCharts
          series={series}
          options={options}
          dataCy="percentage-error-metrics-chart"
        />
      )}
    </div>
  );
};
