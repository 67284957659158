import React, { useContext, useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { format, isMatch, parse } from 'date-fns';
import ms from 'ms';
import {
  DownloadSimple,
  FileText,
  FileX,
  MagnifyingGlass,
  Trash,
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Button } from 'src/components/Button';
import { ButtonCopyToClipboard } from 'src/components/ButtonCopyToClipboard';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Input } from 'src/components/Input';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import { FailedModal } from 'src/components/Modal/Failed';
import { ModalLoading } from 'src/components/Modal/Loading';
import { WarningModal } from 'src/components/Modal/Warning';
import { PaginationV2 } from 'src/components/Pagination/V2';
import { TableV2 } from 'src/components/TableV2';
import { FeatureStoreSidebarContext } from 'src/feature-store/components/Layout/Contexts/NavigationContext';
import { HeaderContainer } from 'src/feature-store/components/Layout/Favorites/styles';
import { setInfoToViewTheSerie } from 'src/feature-store/redux/reducers/SerieToView';
import api from 'src/feature-store/service/api';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { DataError } from 'src/interface/axios';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { dateToIsoStringWithTimezone } from 'src/utils/dateToIsoStringWithTimezone';
import { arrayIcon, checkIconURL } from 'src/utils/handleProjectIcon';

import { CreateGroupModal } from '../Groups/components/CreateGroupModal';
import { DeleteGroupModal } from '../Groups/components/DeleteGroupModal';
import { Group } from '../Groups/types';
import { DeleteSeriesModal } from './components/DeleteSeriesModal';
import { TableLoading } from './components/Loading';
import { SectionButton } from './components/SectionButton';
import {
  ButtonIcon,
  ButtonsContainer,
  Container,
  ContainerWithoutGroups,
  Content,
  HeaderContent,
  IconContainer,
  InfoContainer,
  MenuOverlay,
} from './styles';
import {
  GroupSeries,
  IResponseHeadersDownload,
  Serie,
  SummaryProps,
} from './types';

type ParamsProps = {
  id: string;
};

export type ErrorObject = {
  title?: string;
  description?: string;
  cloudIcon?: boolean;
};

const QUANTITY_ITEMS_PAGE = 8;

export const GroupView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<ParamsProps>();

  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const translateFormat = useFormatDateLanguage();

  const [page, setPage] = useState(1);
  const [groupSeries, setGroupSeries] = useState<GroupSeries>();
  const [serieToDelete, setSerieToDelete] = useState<string>();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [failModalVisible, setFailModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchError, setSearchError] = useState<string>('');
  const [searchTimer, setSearchTimer] = useState(1000);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [requestAllowed, setRequestAllowed] = useState(true);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [showOptionsDownload, setShowOptionsDownload] =
    useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [lastSearch, setLastSearch] = useState('');
  const [summaryModalVisible, setSummaryModalVisible] = useState(false);

  const { language } = useSelector((state: RootState) => state.auth.user);

  const { t: translate } = useTranslation();

  const {
    openFavoriteFilters,
    setterIdFavoriteViewing,
    modalCreateFavoriteVisible,
    openCreateFavoriteModal,
    favoriteTypeToCreate,
    favoriteToDelete,
    isDeleteFavoriteModalOpen,
    openDeleteFavoriteModal,
    isEditFavoriteModalOpen,
    openEditFavoriteModal,
    favoriteToEdit,
    setterFavoriteType,
  } = useContext(FeatureStoreSidebarContext);

  const {
    data: groupData,
    isLoading: isLoadingGroup,
    isError: isGroupErrored,
  } = useQuery(
    ['group', id],
    async () => {
      const { data } = await api.get<Group>(`/groups/${id}`);

      setterFavoriteType(data.is_predefined ? '4iFavorite' : 'myFavorite');

      return data;
    },
    {
      enabled: !!id,
      staleTime: 5000 * 60,
    },
  );

  const {
    data: groupSeriesData,
    isLoading: isLoadingGroupSeries,
    isFetching: isFetchingGroupSeries,
    isError: isGroupSeriesErrored,
  } = useQuery(
    ['group series', id, page, searchValue],
    async () => {
      let searchQuery = searchValue;
      if (isMatch(searchValue, translateFormat)) {
        searchQuery = format(
          parse(searchValue, translateFormat, new Date()),
          'yyyy-MM-dd',
        );
      }

      const { data } = await api.get<GroupSeries>(
        searchValue.length >= 3
          ? isMatch(searchQuery, 'yyyy-MM-dd')
            ? `/groups/${id}/series?last_updated=${dateToIsoStringWithTimezone(
                new Date(`${searchQuery}T00:00`),
              )}&skip=${
                (page - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
            : `/groups/${id}/series?name=${encodeURIComponent(
                searchValue,
              )}&source=${encodeURIComponent(
                searchValue,
              )}&region=${encodeURIComponent(searchValue)}&skip=${
                (page - 1) * QUANTITY_ITEMS_PAGE
              }&limit=${QUANTITY_ITEMS_PAGE}`
          : `/groups/${id}/series?skip=${
              (page - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      enabled: requestAllowed || searchValue === '',
      staleTime: 1000 * 60,
      onSuccess: (value) => {
        value.data.length === 0 &&
          page > 1 &&
          setPage((oldValue) => oldValue - 1);
      },
      onSettled: () => {
        setRequestAllowed(false);
        setTimeOutActive(false);
        setSearchTimer(1000);
        setLastSearch(searchValue);
      },
    },
  );

  const { data: summaryData } = useQuery(
    ['groups summary', id],
    async () => {
      const { data } = await api.get<SummaryProps>(
        `/groups/${id}/series/summary`,
      );
      return data;
    },
    {
      staleTime: ms('5 min'),
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (groupSeriesData) {
      setGroupSeries(groupSeriesData);
    }
  }, [groupSeriesData]);

  useEffect(() => {
    if (timeOutActive && searchValue.length >= 3) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 1000);
        } else {
          setTimeOutActive(false);
        }
      }, 1000);
    } else {
      searchTimer === 0 && setRequestAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  const navigateToIndicator = (serie: Serie) => {
    if (serie) {
      dispatch(
        setInfoToViewTheSerie({
          id: id ?? '',
          serie: {
            code: serie.code,
            indicatorCode: serie.indicator_code,
            region: serie.region['en-us'],
            frequency: serie.aggregation['en-us'],
            primaryTransformation: serie.primary_transformation['en-us'],
            secondaryTransformation: serie.second_transformation['en-us'],
          },
          page,
        }),
      );

      navigate(`/feature-store/indicators/${serie.indicator_code}`);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length < 3 && searchValue.length > 0) {
        setSearchError(
          translate('searchMinCharactersError').replace('XX', '03'),
        );
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchValue.length, translate]);

  useEffect(() => {
    openFavoriteFilters(true);
  }, [openFavoriteFilters]);

  useEffect(() => {
    if (id) {
      setterIdFavoriteViewing(id);
    }
  }, [setterIdFavoriteViewing, id]);

  const handleSearchSerie = (value: string) => {
    setSearchValue(value);

    if (value.length > 30) {
      setSearchError(
        translate('preDefinedSearchMaxCharactersError').replace('XX', '30'),
      );

      return;
    }

    if (
      value.length < lastSearch.length &&
      value.length < 3 &&
      value.length > 0
    ) {
      setSearchError(translate('searchMinCharactersError').replace('XX', '03'));

      return;
    }

    setSearchError('');

    if (value !== searchValue) {
      setSearchTimer(1000);
      setTimeOutActive(true);

      value.length >= 3 && setPage(1);
    }
  };

  const checkSummarySeries = () => {
    const hasMaintenanceContent = !!summaryData?.total_maintenance;

    if (hasMaintenanceContent) {
      setSummaryModalVisible(true);
    }
  };

  const handleDownload = async (typeDownload: string) => {
    setDownloadLoading(true);
    setShowOptionsDownload(false);

    if (typeDownload === 'excel') {
      try {
        const { data, headers } = await api.get(`/groups/${id}/download/xlsx`, {
          responseType: 'blob',
        });

        if (data) {
          const fileURL = window.URL.createObjectURL(
            new Blob([data], { type: 'application/gzip' }),
          );

          const contentDisposition = (headers as IResponseHeadersDownload)[
            'content-disposition'
          ];

          const name = contentDisposition.slice(
            contentDisposition.indexOf('filename="') + 10,
            contentDisposition.length - 1,
          );

          const link = document.createElement('a');

          if (link.download !== undefined) {
            link.setAttribute('href', fileURL);
            link.setAttribute('download', name);
            link.setAttribute('data-testid', 'download-start-excel');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
          }
        }

        checkSummarySeries();
        setDownloadLoading(false);
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error?.response?.data?.detail?.detail ??
          error?.response?.data?.detail?.description ??
          translate('preDefinedDownloadError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: errorMessage,
        });
        setDownloadLoading(false);
        setFailedModalVisible(true);
      }
    }

    if (typeDownload === 'csv') {
      setDownloadLoading(true);
      setShowOptionsDownload(false);

      try {
        const { data, headers } = await api.get(`/groups/${id}/download/csv`, {
          responseType: 'blob',
        });

        if (data) {
          const fileURL = window.URL.createObjectURL(
            new Blob([data], { type: 'application/gzip' }),
          );

          const contentDisposition = (headers as IResponseHeadersDownload)[
            'content-disposition'
          ];

          const name = contentDisposition.slice(
            contentDisposition.indexOf('filename="') + 10,
            contentDisposition.length - 1,
          );

          const link = document.createElement('a');
          if (link.download !== undefined) {
            link.setAttribute('href', fileURL);
            link.setAttribute('download', name);
            link.setAttribute('data-testid', 'download-start-csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
          }

          checkSummarySeries();
          setDownloadLoading(false);
        }
      } catch (err) {
        const error: AxiosError<DataError> | any = err;
        const errorMessage =
          error?.response?.data?.detail?.detail ??
          error?.response?.data?.detail?.description ??
          translate('preDefinedDownloadError');

        setFailedModalInfo({
          title: translate('requestFailed'),
          description: errorMessage,
        });
        setDownloadLoading(false);
        setFailedModalVisible(true);
      }
    }
  };

  const showMessageExportLimit = () => {
    if (groupSeriesData?.total !== undefined && groupSeriesData?.total > 100) {
      return true;
    }
    return false;
  };

  if (id === 'create-first-favorite') {
    return (
      <Container>
        <div
          className="containerLinear"
          style={{ height: '100vh', marginBottom: '1rem' }}
        >
          <ContainerWithoutGroups>
            <ContainerMaintenance
              content="group"
              text={translate('groupSeriesErrorNoGroupFound')}
            />
          </ContainerWithoutGroups>

          {modalCreateFavoriteVisible && (
            <CreateGroupModal
              visible
              setVisible={() => openCreateFavoriteModal(false)}
              predefined={favoriteTypeToCreate === '4iFavorite'}
            />
          )}

          {isDeleteFavoriteModalOpen && (
            <DeleteGroupModal
              visible={isDeleteFavoriteModalOpen}
              setVisible={openDeleteFavoriteModal}
              setErrorVisible={setFailModalVisible}
              setError={() => false}
              group={{
                id: favoriteToDelete.group.id,
                name: favoriteToDelete.group.name,
              }}
            />
          )}

          {isEditFavoriteModalOpen && (
            <CreateGroupModal
              visible={isEditFavoriteModalOpen}
              setVisible={openEditFavoriteModal}
              groupToEdit={favoriteToEdit}
              setPage={setPage}
              predefined={favoriteToEdit.is_predefined}
            />
          )}
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div
        className="containerLinear"
        // style={{ height: 'calc(100vh - 126px)' }}
      >
        {isLoadingGroup || id === 'create-first-favorite' ? (
          <>
            <HeaderContainer
              data-testid="header-container"
              style={{ marginTop: '0px' }}
            >
              <HeaderContent data-testid="header-content">
                <IconContainer data-testid="header-icon">
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '64px',
                      height: '64px',
                      borderRadius: '8px',
                    }}
                  />
                </IconContainer>

                <InfoContainer data-testid="info-container">
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: `${Math.random() * (180 - 100) + 100}px`,
                      height: '16px',
                    }}
                  />
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: `${Math.random() * (180 - 100) + 200}px`,
                      height: '16px',
                    }}
                  />
                </InfoContainer>

                <Input
                  icon={
                    <MagnifyingGlass
                      size="1.25rem"
                      style={{ marginLeft: '2px', color: light.colors.gray4 }}
                    />
                  }
                  testid="input-search-series-loading"
                  placeholder={translate('preDefinedSearchSeries')}
                  style={{ maxWidth: '280px' }}
                  disabled
                />

                <ButtonRounded
                  data-testid="button-export-series"
                  data-cy="button-export-series"
                  onClick={() => {
                    setShowOptionsDownload(true);
                  }}
                  disabled
                  icon={<DownloadSimple size="1.125rem" />}
                />
              </HeaderContent>
            </HeaderContainer>

            <ButtonsContainer showCopyToClipboardButton>
              <ButtonCopyToClipboard
                isDisabled={isLoadingGroup || isLoadingGroupSeries}
                textToCopy=""
                messageBeforeCopy={translate(
                  'viewFeatureCopySeriesToClipboard',
                )}
                messageAfterCopy={translate('copied')}
              />

              {groupData?.is_predefined && (
                <SectionButton
                  isLoading={isLoadingGroup || isLoadingGroupSeries}
                />
              )}
            </ButtonsContainer>
          </>
        ) : (
          <>
            <HeaderContainer
              data-testid="header-container"
              style={{ marginTop: '0px' }}
            >
              <HeaderContent data-testid="header-content">
                <IconContainer data-testid="header-icon">
                  <img
                    data-testid="group-image"
                    data-cy="group-image"
                    src={
                      groupData?.is_predefined
                        ? 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png'
                        : checkIconURL(groupData?.icon_url)
                        ? groupData?.icon_url
                        : arrayIcon[0]
                    }
                    alt="group-icon"
                  />
                </IconContainer>

                <InfoContainer data-testid="info-container">
                  <h4>{groupData?.name}</h4>
                  <p>{groupData?.description}</p>
                </InfoContainer>

                <Input
                  icon={
                    <MagnifyingGlass
                      size="1.25rem"
                      style={{ marginLeft: '2px', color: light.colors.gray4 }}
                    />
                  }
                  testid="input-search-series"
                  placeholder={translate('preDefinedSearchSeries')}
                  value={searchValue}
                  onChange={({ target: { value } }) => handleSearchSerie(value)}
                  style={{ maxWidth: '280px' }}
                  error={searchError}
                />

                <Tooltip
                  id="feature-store-tooltip-export"
                  className="customTooltipTheme"
                />
                <div
                  data-tooltip-id="feature-store-tooltip-export"
                  data-tooltip-html={
                    showMessageExportLimit()
                      ? translate('exportSeriesLimitExport')
                      : ''
                  }
                >
                  <ButtonRounded
                    data-testid="button-export-series"
                    data-cy="button-export-series"
                    onClick={() => {
                      setShowOptionsDownload(true);
                    }}
                    disabled={
                      groupData?.series?.length === 0 ||
                      groupData?.series?.length === undefined ||
                      groupData?.series?.length >= 100
                    }
                    icon={<DownloadSimple size="1.125rem" />}
                  />
                </div>

                <MenuContainer
                  visible={showOptionsDownload}
                  style={{ top: '3.5rem', right: '3rem' }}
                >
                  {showOptionsDownload && (
                    <MenuOverlay
                      data-testid="menu-overlay"
                      visible={showOptionsDownload}
                      onClick={() => setShowOptionsDownload(false)}
                    />
                  )}
                  <MenuOption
                    type="button"
                    data-testid="buttonXLXS"
                    onClick={() => handleDownload('excel')}
                    position="start"
                  >
                    <FileX size="1.25rem" />
                    <p>Excel</p>
                  </MenuOption>
                  <MenuOption
                    type="button"
                    data-testid="buttonCSV"
                    onClick={() => handleDownload('csv')}
                    position="end"
                  >
                    <FileText size="1.25rem" />
                    <p>CSV</p>
                  </MenuOption>
                </MenuContainer>
              </HeaderContent>
            </HeaderContainer>

            <ButtonsContainer
              showCopyToClipboardButton={!!groupData?.series.length}
            >
              {groupData?.series && groupData?.series.length > 0 && (
                <ButtonCopyToClipboard
                  isDisabled={false}
                  textToCopy={groupData?.series.toString() ?? ''}
                  messageBeforeCopy={
                    groupData?.series.length > 1
                      ? translate('viewFeatureCopySeriesToClipboard')
                      : translate('viewFeatureCopySerieToClipboard')
                  }
                  messageAfterCopy={translate('copied')}
                />
              )}

              {groupData?.is_predefined && <SectionButton />}
            </ButtonsContainer>
          </>
        )}

        {isGroupSeriesErrored || isGroupErrored ? (
          <ContainerMaintenance
            content="table"
            text={translate('preDefinedUnableGroup')}
          />
        ) : !isLoadingGroup &&
          groupSeries?.data?.length === 0 &&
          !searchValue ? (
          // eslint-disable-next-line react/jsx-indent
          <Content>
            <ContainerMaintenance
              content="table"
              text={translate('preDefinedAddSeries')}
              description={translate('preDefinedAddSeriesDescription')}
            />
            <Button
              buttonType="primary"
              onClick={() => navigate('/feature-store/indicators')}
              data-cy="button-series-search-indicators"
              data-testid="button-series-search-indicators"
              disabled={!user.isFsAdmin && groupData && groupData.is_predefined}
            >
              {translate('preDefinedSearchIndicators')}
            </Button>
          </Content>
        ) : groupSeries?.total === 0 &&
          !isLoadingGroupSeries &&
          !isFetchingGroupSeries ? (
          // eslint-disable-next-line react/jsx-indent
          <Content>
            <ContainerMaintenance
              content="table"
              text={`${translate('preDefinedAnySeries')} "${lastSearch}".`}
            />
          </Content>
        ) : (
          <TableV2.Container style={{ marginTop: 8 }}>
            <TableV2.Root data-testid="series-table">
              <TableV2.Thead>
                <TableV2.Tr>
                  <TableV2.Th>{translate('preDefinedName')}</TableV2.Th>
                  <TableV2.Th>{translate('preDefinedSource')}</TableV2.Th>
                  <TableV2.Th>{translate('preDefinedLastUpdate')}</TableV2.Th>
                  <TableV2.Th>{translate('preDefinedStartDate')}</TableV2.Th>
                  <TableV2.Th>{translate('preDefinedEndDate')}</TableV2.Th>
                  <TableV2.Th>{translate('preDefinedFrequency')}</TableV2.Th>
                  <TableV2.Th>{translate('preDefinedRegion')}</TableV2.Th>
                  <TableV2.Th>{translate('preDefinedPrimaryTr')}</TableV2.Th>
                  <TableV2.Th>{translate('preDefinedSecondaryTr')}</TableV2.Th>
                  <TableV2.Th hasBorderLeftLastColl />
                </TableV2.Tr>
              </TableV2.Thead>
              <TableV2.Tbody>
                {isLoadingGroupSeries ||
                isFetchingGroupSeries ||
                isLoadingGroup ? (
                  <>
                    {Array.from({
                      length: QUANTITY_ITEMS_PAGE,
                    }).map((_, index) => (
                      <TableLoading
                        key={`${index + 1}`}
                        actions={
                          (groupData?.is_predefined === true &&
                            user.isFsAdmin) ||
                          groupData?.is_predefined === false
                            ? 2
                            : 1
                        }
                      />
                    ))}
                  </>
                ) : (
                  groupSeries &&
                  groupSeries.data.length !== 0 && (
                    <>
                      {groupSeries?.data?.map((serie) => (
                        <TableV2.Tr key={serie.code}>
                          <TableV2.Td>
                            {serie.name[language] ?? serie.name['en-us']}
                          </TableV2.Td>
                          <TableV2.Td>{serie.source}</TableV2.Td>
                          <TableV2.Td>
                            {format(
                              new Date(serie.last_updated),
                              `${translateFormat}, HH:mm`,
                            )}
                          </TableV2.Td>
                          <TableV2.Td>
                            {format(
                              new Date(serie.date_start),
                              translateFormat,
                            )}
                          </TableV2.Td>
                          <TableV2.Td>
                            {format(new Date(serie.date_end), translateFormat)}
                          </TableV2.Td>
                          <TableV2.Td>
                            {serie.aggregation[language] ??
                              serie.aggregation['en-us']}
                          </TableV2.Td>
                          <TableV2.Td>
                            {serie.region[language] ?? serie.region['en-us']}
                          </TableV2.Td>
                          <TableV2.Td>
                            {serie.primary_transformation[language] ??
                              serie.primary_transformation['en-us']}
                          </TableV2.Td>
                          <TableV2.Td>
                            {serie.second_transformation[language] ??
                              serie.second_transformation['en-us']}
                          </TableV2.Td>
                          <TableV2.Td hasBorderLeftLastColl>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <ButtonIcon
                                onClick={() => {
                                  navigateToIndicator(serie);
                                }}
                              >
                                <MagnifyingGlass
                                  data-testid={`button-go-to-serie-${serie.indicator_code}`}
                                  size="1.25rem"
                                />
                              </ButtonIcon>
                              {((groupData?.is_predefined === true &&
                                user.isFsAdmin) ||
                                groupData?.is_predefined === false) && (
                                <ButtonIcon
                                  onClick={() => {
                                    setSerieToDelete(serie.code);
                                    setDeleteModalVisible(true);
                                  }}
                                  data-testid={`button-delete-series-${serie.code}`}
                                  data-cy={`button-delete-series-${serie.code}`}
                                >
                                  <Trash size="1.25rem" />
                                </ButtonIcon>
                              )}
                            </div>
                          </TableV2.Td>
                        </TableV2.Tr>
                      ))}
                      {Array.from({
                        length: QUANTITY_ITEMS_PAGE - groupSeries?.data?.length,
                      }).map((_, index) => (
                        <TableV2.Tr
                          key={`tr-empty-${index + 1}`}
                          data-testid="tr-empty"
                          height="24px"
                        >
                          {Array.from({ length: 10 }).map((__, tdIndex) => (
                            <TableV2.Td
                              key={`tr-empty-${index + 1}-td-${tdIndex + 1}`}
                            />
                          ))}
                        </TableV2.Tr>
                      ))}
                    </>
                  )
                )}
              </TableV2.Tbody>
            </TableV2.Root>
            <TableV2.Footer>
              <PaginationV2
                page={page}
                setPage={setPage}
                total={groupSeries?.total || 0}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name={translate('preDefinedSeriesTitle').toLocaleLowerCase()}
                isLoading={isLoadingGroupSeries}
              />
            </TableV2.Footer>
          </TableV2.Container>
        )}
      </div>

      {!!groupSeries?.total && (
        <div className="containerLinear">
          <Card
            textCard={translate('preDefinedSearchIndicators')}
            textDescription={translate('preDefinedDescriptionIndicator')}
            style={{ width: '70%' }}
          />
          <Button
            buttonType="primary"
            className="right-button"
            onClick={() => navigate('/feature-store/indicators')}
            data-cy="button-search-indicators"
            data-testid="button-search-indicators"
            disabled={!user.isFsAdmin && groupData && groupData.is_predefined}
          >
            {translate('preDefinedSearch')}
          </Button>
        </div>
      )}

      {deleteModalVisible && serieToDelete && (
        <DeleteSeriesModal
          serieCode={serieToDelete}
          visible={deleteModalVisible}
          setVisible={setDeleteModalVisible}
          setError={setFailModalVisible}
        />
      )}

      {failedModalVisible && (
        <FailedModal
          visible={failedModalVisible}
          setVisible={setFailedModalVisible}
          errorInfo={failedModalInfo}
        />
      )}

      {failModalVisible && (
        <FailedModal
          visible={failModalVisible}
          setVisible={setFailModalVisible}
          errorInfo={{
            title: translate('requestFailed'),
            description: translate('preDefinedExcludedError'),
          }}
        />
      )}

      <ModalLoading
        visible={downloadLoading}
        message="Downloading..."
        data-testid="download-start"
      />

      <WarningModal
        visible={summaryModalVisible}
        setVisible={(prevValue) => {
          setSummaryModalVisible(prevValue);
        }}
        errorInfo={{
          description: translate('exportSeriesMaintenance'),
        }}
      />

      {modalCreateFavoriteVisible && (
        <CreateGroupModal
          visible
          setVisible={() => openCreateFavoriteModal(false)}
          predefined={favoriteTypeToCreate === '4iFavorite'}
        />
      )}

      {isDeleteFavoriteModalOpen && (
        <DeleteGroupModal
          visible={isDeleteFavoriteModalOpen}
          setVisible={openDeleteFavoriteModal}
          setErrorVisible={setFailModalVisible}
          setError={() => false}
          group={{
            id: favoriteToDelete.group.id,
            name: favoriteToDelete.group.name,
          }}
        />
      )}

      {isEditFavoriteModalOpen && (
        <CreateGroupModal
          visible={isEditFavoriteModalOpen}
          setVisible={openEditFavoriteModal}
          groupToEdit={favoriteToEdit}
          setPage={setPage}
          predefined={favoriteToEdit.is_predefined}
        />
      )}
    </Container>
  );
};
