import styled from 'styled-components';

export const Container = styled.div`
  .customRangePicker {
    padding: 0;
  }

  .react-datepicker {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.gray2};
    overflow: hidden;
    font-family: 'Inter', sans-serif !important;

    button {
      .react-datepicker__navigation-icon::before {
        border-color: ${({ theme }) => theme.colors.gray4} !important;
        border-width: 1px 1px 0 0;
      }

      .react-datepicker__navigation-icon {
        top: 4px;
      }

      &:hover {
        .react-datepicker__navigation-icon::before {
          border-color: ${({ theme }) => theme.colors.gray5} !important;
        }
      }
    }
  }

  .react-datepicker__header {
    padding: 12px 0 12px;
    border-top-right-radius: unset;
    border-top-left-radius: unset;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

    background-color: ${({ theme }) => theme.colors.gray0};

    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.875rem;
    font-weight: 600;
    font-family: 'Inter', sans-serif !important;
  }

  .react-datepicker__year-wrapper {
    justify-content: center;
    max-width: 240px;
  }

  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 0.875rem;
    font-weight: 400;
    font-family: 'Inter', sans-serif !important;
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .react-datepicker__current-month {
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 0.875rem;
    font-weight: 600;
    font-family: 'Inter', sans-serif !important;
    text-transform: capitalize;
  }

  .react-datepicker__year-text {
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 0.875rem;
    font-weight: 400;
    font-family: 'Inter', sans-serif !important;
  }

  .react-datepicker__day {
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 0.875rem;
    font-family: 'Inter', sans-serif !important;
    outline: unset;
  }

  .react-datepicker__triangle {
    stroke: ${({ theme }) => theme.colors.gray2} !important;
    fill: ${({ theme }) => theme.colors.gray0};
    color: ${({ theme }) => theme.colors.gray0};
  }

  .react-datepicker__month-text,
  .react-datepicker__year-text {
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 0.875rem;
    outline: unset;
    margin: 4px;
    padding: 16px 8px;
    text-transform: capitalize;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: ${({ theme }) => `${theme.colors.primary}14`}!important;
    color: ${({ theme }) => theme.colors.primary}!important;
    font-weight: 600 !important;
  }

  .react-datepicker__month-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__month-text--keyboard-selected {
    background-color: ${({ theme }) => `${theme.colors.primary}14`}!important;
  }

  .react-datepicker__year--selecting-range
    .react-datepicker__year-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ) {
    background-color: ${({ theme }) => `${theme.colors.primary}14`}!important;
    color: ${({ theme }) => theme.colors.primary}!important;
  }

  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.colors.gray4};
    font-weight: 400;
    background: ${({ theme }) => theme.colors.white};
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today {
    font-weight: 400;
  }

  .react-datepicker-popper {
    z-index: 100;
  }
`;
