import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;

  display: flex;
  flex-direction: column;

  position: relative;

  > div:nth-child(2) {
    display: flex;
    flex: 1;
    background: ${({ theme }) => theme.colors.gray0};
    margin-bottom: auto;
  }
`;

interface ContainerCookie {
  visible?: boolean;
}

export const ContainerCookie = styled.div<ContainerCookie>`
  position: fixed;
  display: ${({ visible }) => (visible ? 'unset' : 'none')};
  bottom: 2rem;
  width: 90%;
  max-width: 76rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 64px rgba(10, 32, 66, 0.16);
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 8px;
  padding: 2rem;

  z-index: 1000000;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      padding: 0.75rem 2rem;
      margin-left: 2rem;
      > div {
        width: 7rem;
      }
    }
  }

  img {
    width: 3.48244rem;
    height: 4rem;

    margin-right: 2rem;
  }
`;

export const ContainerCookieText = styled.div`
  overflow-y: auto;

  p {
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.gray5};

    a {
      position: relative;
      color: ${({ theme }) => theme.colors.secondary};
      &:after {
        content: '';

        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0.125rem;

        border-bottom: ${({ theme }) => `1px solid ${theme.colors.secondary}`};
      }
    }
  }
`;
