import React, { useEffect, useState } from 'react';

import { CaretLeft, CaretRight } from 'phosphor-react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StylesConfig } from 'react-select';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { ChartPloty } from 'src/components/ChartPloty';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { Select } from 'src/components/Select';
import { ContainerWarning } from 'src/models/components/ContainerWarning';
import api from 'src/models/service/api';
import { getChartColor } from 'src/utils/getChartColor';

import { ProgressInDots } from '../../../TimeSeries/components/ProgressInDots';
import {
  ContainerSelectVariables,
  ContentButtons,
} from '../../../TimeSeries/styles';
import { ClassValuesCount, YDetails } from '../../types';
import { DependentVariableChartContainer, ContentItens } from './styles';
import {
  Explanatories,
  ClassificationSendVariablesProps,
  Validate,
} from './types';

export const ClassificationSendVariables: React.FC<
  ClassificationSendVariablesProps
> = ({
  control,
  getValues,
  setValue,
  errors,
  trigger,
  setStep,
  setValidateWarning,
}) => {
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();
  const [sendVariablesRequestIsRunning, setSendVariablesRequestIsRunning] =
    useState(false);
  const [dependentVariableChartInfo, setDependentVariableChartInfo] =
    useState<YDetails>();

  const { t: translate } = useTranslation();

  useEffect(() => {
    getValues('datasetFile').y_details &&
      setDependentVariableChartInfo(
        getValues('datasetFile').y_details.find(
          (variableInfo: YDetails) =>
            variableInfo.y_name === getValues('dependentVariable'),
        ),
      );
  }, [getValues]);

  const explanatoryColorStyles: StylesConfig<Explanatories, true> = {
    multiValue: (base, state) => {
      const item = state.options.find(
        // @ts-expect-error tipagem da lib errada
        (option) => option.value === state.data.value,
      );

      // @ts-expect-error problema com tipagem da lib
      return item && item.isFixed
        ? {
            ...base,
            background: '#DEE0F7',
            borderRadius: '16px',
            padding: '4px 8px',
            opacity: 0.8,
          }
        : base ?? (state.data && base);
    },
    multiValueRemove: (base, state) => {
      const item = state.options.find(
        // @ts-expect-error tipagem da lib errada
        (option) => option.value === state.data.value,
      );

      // @ts-expect-error problema com tipagem da lib
      return item && item.isFixed
        ? { ...base, display: 'none' }
        : base ?? (state.data && base);
    },
  };

  const selectExplanatory = document.querySelector(
    '.select-explanatory-variables',
  );
  if (selectExplanatory) {
    const deleteExplanatoryVariablesButton = selectExplanatory.querySelector(
      '.css-tlfecz-indicatorContainer',
    );

    if (deleteExplanatoryVariablesButton) {
      deleteExplanatoryVariablesButton.setAttribute(
        'data-cy',
        'button-delete-explanatory-variables',
      );
    }
  }

  async function handleSubmitVariables() {
    const hasErrorOnInputs = await Promise.all([
      trigger('dependentVariable'),
      trigger('explanatoryVariables'),
    ]);

    if (!hasErrorOnInputs.includes(false)) {
      setSendVariablesRequestIsRunning(true);

      try {
        const requestPayload = {
          var_y: getValues('dependentVariable'),
          var_x: getValues('explanatoryVariables'),
        };
        const { data } = await api.post(
          `/classification/variables/${getValues('datasetFile').dataset_id}`,
          requestPayload,
        );

        if (data) {
          const validateResponse = await api.get<Validate>(
            `/classification/validate/${getValues('datasetFile').dataset_id}`,
          );

          if (validateResponse?.data) {
            setValidateWarning(validateResponse.data);
            setStep(3);
          } else {
            throw new Error();
          }
        }
      } catch {
        setFailedModalInfo({
          title: translate('unknownRequestErrorTitle'),
          description: translate('unknownRequestErrorDescription'),
        });
      }
    }
    setSendVariablesRequestIsRunning(false);
  }

  return (
    <>
      <ContainerSelectVariables>
        <Card
          data-testid="step2Renderization"
          textCard={translate('step2SelectVariablesTitle')}
          textDescription={translate('step2SelectVariablesTitleClaas')}
          style={{ marginBottom: '32px' }}
        />
        <ContentItens>
          <div>
            <Controller
              name="dependentVariable"
              key="dependentVariable"
              control={control}
              defaultValue={(() => {
                const firstOption =
                  getValues('datasetFile').variables.y_options[0];
                if (firstOption) {
                  return firstOption;
                }
                return undefined;
              })()}
              render={({ field: { onChange, value } }) => (
                <Select
                  data-testid="select-dependent-var"
                  label={translate('step2ClaasDependentVariableInputLabel')}
                  style={{ marginBottom: '24px' }}
                  information={translate(
                    'step2DependentVariableInputTooltipClaas',
                  )}
                  value={{
                    label: value,
                    value,
                  }}
                  onChange={(val: any) => {
                    if (val.value !== getValues('dependentVariable')) {
                      setValue(
                        'explanatoryVariables',
                        [
                          ...getValues('explanatoryVariables'),
                          getValues('dependentVariable'),
                        ].filter((variable) => variable !== val.value),
                      );
                      trigger('explanatoryVariables');

                      onChange(val.value);
                      getValues('datasetFile').y_details &&
                        setDependentVariableChartInfo(
                          getValues('datasetFile').y_details.find(
                            (variableInfo: YDetails) =>
                              variableInfo.y_name === val.value,
                          ),
                        );
                    }
                  }}
                  error={translate(errors?.dependentVariable?.message)}
                  options={getValues('datasetFile').variables.y_options.map(
                    (variable: string) => ({
                      label: variable,
                      value: variable,
                    }),
                  )}
                />
              )}
            />

            <Controller
              name="explanatoryVariables"
              key="explanatoryVariables"
              control={control}
              defaultValue={getValues('datasetFile').variables.x_options.filter(
                (variable: string) =>
                  variable !== getValues('datasetFile').variables.y_options[0],
              )}
              render={({ field: { onChange, value } }) => (
                <Select
                  label={translate('step2ExplanatoryVariablesLabel')}
                  isMulti
                  placeholder={translate('select')}
                  className="select-explanatory-variables"
                  options={[
                    { label: translate('selectAll'), value: 'Select all' },
                    ...getValues('datasetFile')
                      .variables.x_options.filter(
                        (variable: string) =>
                          variable !== getValues('dependentVariable'),
                      )
                      .map((variable: string) => ({
                        label: variable,
                        value: variable,
                        //TODO: remover quando for liberado alterações
                        // isFixed: true,
                      })),
                  ]}
                  value={value.map((val: string) => ({
                    label: val,
                    value: val,
                  }))}
                  onChange={(valArray: any) => {
                    valArray.find(
                      (option: any) => option.value === 'Select all',
                    )
                      ? onChange([
                          ...getValues(
                            'datasetFile',
                          ).variables.x_options.filter(
                            (variable: string) =>
                              variable !== getValues('dependentVariable'),
                          ),
                        ])
                      : onChange(valArray.map((val: any) => val.value));

                    trigger('explanatoryVariables');
                  }}
                  error={translate(errors?.explanatoryVariables?.message)}
                  // @ts-expect-error tipagem errada na lib
                  styles={explanatoryColorStyles}
                />
              )}
            />
          </div>
          <div>
            {dependentVariableChartInfo && (
              <DependentVariableChartContainer
                warningCount={
                  dependentVariableChartInfo.balanced === false &&
                  dependentVariableChartInfo.excluded_classes === true
                    ? 2
                    : dependentVariableChartInfo.balanced === false ||
                      dependentVariableChartInfo.excluded_classes === true
                    ? 1
                    : 0
                }
                // warningCount={1}
                heightMultiplier={
                  dependentVariableChartInfo?.values_count?.sort(
                    (a, b) => b.class.length - a.class.length,
                  )[0].class.length > 10
                    ? dependentVariableChartInfo?.values_count?.sort(
                        (a, b) => b.class.length - a.class.length,
                      )[0].class.length - 10
                    : 0
                }
              >
                <div>
                  {dependentVariableChartInfo.balanced === false && (
                    <ContainerWarning visible hasIcon>
                      <p>{translate('step2BalanceWarning')}</p>
                    </ContainerWarning>
                  )}
                  {dependentVariableChartInfo.excluded_classes === true && (
                    <ContainerWarning visible hasIcon>
                      <p>
                        {translate('step2ClassExclusionWarning').replace(
                          '"TARGET"',
                          dependentVariableChartInfo.y_name,
                        )}
                      </p>
                    </ContainerWarning>
                  )}
                </div>
                <ChartPloty
                  data={dependentVariableChartInfo?.values_count
                    ?.sort((a, b) => b.count - a.count)
                    ?.map((value: ClassValuesCount, index: number) => ({
                      // Temos um "unicoded character" invisível no final para evitar range no gráfico
                      x: [`${value.class}𝅺`],
                      y: [value.count],
                      type: 'bar',
                      marker: {
                        color: getChartColor(index),
                      },
                      text: value.class,
                      showlegend: false,
                      name: value.class,
                      hovertemplate: `<b>${translate(
                        'class',
                      )}:</b> %{x}<br><b>${translate(
                        'step2DependentVariableChartObservations',
                      )}:</b> %{y}<br><b>${translate('percentage')}:</b> ${
                        value.percent
                      }%`,
                    }))}
                  layout={{
                    yaxis: {
                      title: translate('step2DependentVariableChartYAxis'),
                      automargin: true,
                    },
                    xaxis: {
                      title: dependentVariableChartInfo?.y_name,
                      automargin: true,
                    },
                  }}
                  style={
                    dependentVariableChartInfo.balanced &&
                    !dependentVariableChartInfo.excluded_classes
                      ? {
                          width: 'auto ',
                          height: `${
                            (dependentVariableChartInfo?.values_count?.sort(
                              (a, b) => b.class.length - a.class.length,
                            )[0].class.length > 10
                              ? dependentVariableChartInfo?.values_count?.sort(
                                  (a, b) => b.class.length - a.class.length,
                                )[0].class.length - 10
                              : 0) *
                              5 +
                            369
                          }px`,
                        }
                      : {
                          width: 'auto ',
                          height: `${
                            (dependentVariableChartInfo?.values_count?.sort(
                              (a, b) => b.class.length - a.class.length,
                            )[0].class.length > 10
                              ? dependentVariableChartInfo?.values_count?.sort(
                                  (a, b) => b.class.length - a.class.length,
                                )[0].class.length - 10
                              : 0) *
                              5 +
                            300
                          }px`,
                        }
                  }
                  dataCy={`dependent-variable-chart-${dependentVariableChartInfo.y_name
                    .replaceAll(' ', '-')
                    .toLowerCase()}`}
                />
              </DependentVariableChartContainer>
            )}
          </div>
        </ContentItens>
        <ContentButtons>
          <Button
            data-testid="step2BackButton"
            onClick={() => setStep(1)}
            buttonType="naked"
            data-cy="button-back-step-2"
            disabled={sendVariablesRequestIsRunning}
            icon={<CaretLeft size="1.125rem" />}
          >
            {translate('step1BeginProject')}
          </Button>
          <ProgressInDots
            step={2}
            backStep1={() => {
              setStep(1);
            }}
            isLoadingAnotherStep={sendVariablesRequestIsRunning}
          />
          <Button
            buttonType="primary"
            data-testid="button-send-variables"
            data-cy="button-final-settings"
            onClick={handleSubmitVariables}
            disabled={sendVariablesRequestIsRunning}
            loading={sendVariablesRequestIsRunning}
            iconInvert
            icon={
              !sendVariablesRequestIsRunning ? (
                <CaretRight size="1.125rem" />
              ) : undefined
            }
          >
            {translate('step2FinalSettingsButton')}
          </Button>
        </ContentButtons>
      </ContainerSelectVariables>

      <FailedModal
        visible={!!failedModalInfo}
        setVisible={(visible) =>
          visible === false && setFailedModalInfo(undefined)
        }
        errorInfo={failedModalInfo}
      />
    </>
  );
};
