import React, { useContext, useEffect, useMemo } from 'react';

import { Card } from 'src/components/Card';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import api from 'src/models/service/api';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { useTranslation } from 'react-i18next';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { AxiosError } from 'axios';
import { Select } from 'src/components/Select';
import { AISelectionContext } from 'src/models/contexts/AISelectionContext';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { RadioButton } from 'src/components/RadioButton';
import { CheckBox } from 'src/components/CheckBox';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import {
  AIUserSelectionFrequency,
  AIUserSelectionTransformation,
  changeAIUserSelectionExplanatoryVariablesFrequency,
  changeAIUserSelectionExplanatoryVariablesIsLatestDataActive,
  changeAIUserSelectionExplanatoryVariablesOptionSelected,
  changeAIUserSelectionExplanatoryVariablesTransformations,
} from 'src/models/redux/reducers/AIUserSelectionOptions';
import { frequencyLatestData, getLatestData } from 'src/utils/getLatestData';
import { Tooltip } from 'react-tooltip';

import {
  ChartHeader,
  ContainerChart,
  ContentLatestData,
  ContentSelectExplanatoryVariable,
  ExplanatoryVariableContainer,
  Options,
} from './styles';
import { ExplanatoryVariablesChart } from './ExplanatoryVariablesChart';

interface Chart {
  date: (string | number)[];
  value: number[];
  aggregation_count: number[];
}
export interface ResponseVariables {
  forecast: Chart;
  historical: Chart;
  simulated: Chart;
}

interface Error {
  detail?: {
    description?: string;
    detail?: string;
  };
}

export const ExplanatoryVariables: React.FC = () => {
  const {
    auth: { user },
    project: { id, selectedY },
    AIUserSelectionOptions: {
      AISelection: {
        explanatoryVariables: {
          frequency,
          transformations,
          isLatestDataActive,
          optionSelected,
        },
      },
    },
  } = useSelector((state: RootState) => state);

  const { listOfVariables, isLoadingListOfVariables, errorListOfVariables } =
    useContext(AISelectionContext);

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (frequency === 'monthly' && selectedY?.info?.frequency !== 'daily') {
      dispatch(
        changeAIUserSelectionExplanatoryVariablesFrequency({
          selection: 'AISelection',
          value: 'original',
        }),
      );
    }
  }, [frequency, dispatch, selectedY?.info?.frequency]);

  useEffect(() => {
    function setFirstOption() {
      if (listOfVariables && listOfVariables.explanatories.length > 0) {
        if (!listOfVariables.explanatories.includes(optionSelected ?? '')) {
          dispatch(
            changeAIUserSelectionExplanatoryVariablesOptionSelected({
              selection: 'AISelection',
              value: listOfVariables.explanatories[0],
            }),
          );
        }
      } else {
        dispatch(
          changeAIUserSelectionExplanatoryVariablesOptionSelected({
            selection: 'AISelection',
            value: null,
          }),
        );
      }
    }

    setFirstOption();
  }, [dispatch, listOfVariables, optionSelected]);

  const {
    data: dataVariation,
    isLoading: isLoadingVariation,
    error: errorVariation,
  } = useQuery<ResponseVariables, AxiosError<Error>>(
    [
      'AI-selection',
      'explanatory-variables',
      id,
      selectedY?.id,
      optionSelected,
      frequency,
      'variation',
    ],
    async () => {
      const response = await api.get<ResponseVariables>(
        `/projects/${id}/${selectedY?.id}/models/business/variables/${optionSelected}?frequency=${frequency}&transformation=variation`,
      );

      const historical = response.data.historical;
      const forecast = response.data.forecast;

      if (forecast.date.length && forecast.value.length) {
        if (historical.date.length && historical.date.length) {
          forecast.date = [
            historical.date[historical.date.length - 1],
            ...forecast.date,
          ];

          forecast.value = [
            historical.value[historical.value.length - 1],
            ...forecast.value,
          ];

          forecast.aggregation_count = [
            historical.aggregation_count[
              historical.aggregation_count.length - 1
            ],
            ...forecast.aggregation_count,
          ];
        }
      }

      return {
        historical,
        forecast,
        simulated: response.data.simulated,
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!id &&
        !!selectedY?.id &&
        !!selectedY?.name &&
        !!listOfVariables &&
        !!optionSelected,
    },
  );

  const {
    data: dataLevel,
    isLoading: isLoadingLevel,
    error: errorLevel,
  } = useQuery<ResponseVariables, AxiosError<Error>>(
    [
      'AI-selection',
      'explanatory-variables',
      id,
      selectedY?.id,
      optionSelected,
      frequency,
      'level',
    ],
    async () => {
      const response = await api.get<ResponseVariables>(
        `/projects/${id}/${selectedY?.id}/models/business/variables/${optionSelected}?frequency=${frequency}&transformation=level`,
      );

      const historical = response.data.historical;
      const forecast = response.data.forecast;

      return {
        historical,
        forecast,
        simulated: response.data.simulated,
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!id &&
        !!selectedY?.id &&
        !!selectedY?.name &&
        !!listOfVariables &&
        !!optionSelected,
    },
  );

  const isLatestDataDisabled = useMemo(() => {
    const total =
      (dataLevel?.historical.value.length ?? 0) +
      (dataLevel?.forecast.value.length ?? 0);

    if (frequency === 'original') {
      return (
        total <=
        frequencyLatestData[
          selectedY?.info?.frequency as keyof typeof frequencyLatestData
        ]
      );
    }
    if (frequency === 'monthly') {
      return total <= frequencyLatestData.monthly;
    }
    if (frequency === 'quarterly') {
      return total <= frequencyLatestData.quarterly;
    }

    if (frequency === 'yearly') {
      return total <= frequencyLatestData.annual;
    }

    return true;
  }, [
    dataLevel?.forecast.value.length,
    dataLevel?.historical.value.length,
    frequency,
    selectedY?.info?.frequency,
  ]);

  const error = useMemo(
    () =>
      (!!errorLevel && !!errorVariation) ||
      !!errorListOfVariables ||
      listOfVariables?.explanatories.length === 0,
    [
      errorLevel,
      errorListOfVariables,
      errorVariation,
      listOfVariables?.explanatories.length,
    ],
  );

  const isLoadingChart = useMemo(
    () =>
      isLoadingLevel ||
      isLoadingVariation ||
      isLoadingListOfVariables ||
      (!dataLevel && !dataVariation),
    [
      dataLevel,
      dataVariation,
      isLoadingLevel,
      isLoadingListOfVariables,
      isLoadingVariation,
    ],
  );

  useEffect(() => {
    if ((!isLoadingChart && isLatestDataDisabled) || error) {
      dispatch(
        changeAIUserSelectionExplanatoryVariablesIsLatestDataActive({
          selection: 'AISelection',
          value: false,
        }),
      );
    }
  }, [dispatch, error, isLatestDataDisabled, isLoadingChart]);

  function handleSelectFrequency(frequencyAux: AIUserSelectionFrequency) {
    if (frequency !== frequencyAux) {
      dispatch(
        changeAIUserSelectionExplanatoryVariablesFrequency({
          selection: 'AISelection',
          value: frequencyAux,
        }),
      );
    }
  }

  function handleSelectTransformation(
    transformationAux: AIUserSelectionTransformation,
  ) {
    if (transformations.includes(transformationAux)) {
      dispatch(
        changeAIUserSelectionExplanatoryVariablesTransformations({
          selection: 'AISelection',
          value: transformations.filter(
            (transformation) => transformation !== transformationAux,
          ),
        }),
      );
      return;
    }
    dispatch(
      changeAIUserSelectionExplanatoryVariablesTransformations({
        selection: 'AISelection',
        value: [...transformations, transformationAux],
      }),
    );
  }

  function handleActiveLatestData(value: boolean) {
    dispatch(
      changeAIUserSelectionExplanatoryVariablesIsLatestDataActive({
        selection: 'AISelection',
        value,
      }),
    );
  }

  function handleSelectOption(value: string | null) {
    dispatch(
      changeAIUserSelectionExplanatoryVariablesOptionSelected({
        selection: 'AISelection',
        value,
      }),
    );
  }

  function returnMessageError() {
    let messageError = errorLevel?.response?.data?.detail?.detail;

    if (
      messageError ===
        'Annual series summary is only available for series with at least 1 year of observation.' ||
      messageError ===
        'Quarterly series summary is only available for series with at least 1 quarter of observation.' ||
      messageError ===
        'Monthly aggregation is not available for dataset with missing values.'
    ) {
      return messageError;
    }

    messageError =
      errorListOfVariables?.response?.data?.detail?.description ??
      errorLevel?.response?.data?.detail?.description ??
      errorVariation?.response?.data?.detail?.description;

    if (
      messageError === 'No data is available.' ||
      messageError === 'The requested resource does not exist.'
    ) {
      if (frequency === 'quarterly') {
        return 'Quarterly Series Rate is not available for this model';
      }
      if (frequency === 'yearly') {
        return 'Annual Series Rate is not available for this model';
      }
    }

    return messageError;
  }

  const { dataLevelAdjusted, dataVariationAdjusted } = useMemo(() => {
    let dataLevelAdjustedAux: ResponseVariables = {
      historical: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      forecast: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      simulated: {
        date: [],
        value: [],
        aggregation_count: [],
      },
    };

    let dataVariationAdjustedAux: ResponseVariables = {
      historical: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      forecast: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      simulated: {
        date: [],
        value: [],
        aggregation_count: [],
      },
    };

    if (!isLatestDataActive) {
      if (dataLevel) {
        dataLevelAdjustedAux = dataLevel;
      }
      if (dataVariation) {
        dataVariationAdjustedAux = dataVariation;
      }
    } else if (frequency === 'original') {
      switch (selectedY?.info?.frequency) {
        case 'daily':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'daily');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'daily',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'weekly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'weekly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'weekly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'fortnightly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'fortnightly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'fortnightly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'monthly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'monthly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'monthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'bimonthly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'bimonthly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'bimonthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'quarterly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'quarterly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'quarterly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'half-year':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'half-year');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'half-year',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'annual':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'annual');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'annual',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        default:
          if (dataLevel) {
            dataLevelAdjustedAux = dataLevel;
          }
          if (dataVariation) {
            dataVariationAdjustedAux = dataVariation;
          }
          break;
      }
    } else if (frequency === 'monthly') {
      dataLevelAdjustedAux = getLatestData(dataLevel, 'monthly');
      dataVariationAdjustedAux = getLatestData(
        dataVariation,
        'monthly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else if (frequency === 'quarterly') {
      dataLevelAdjustedAux = getLatestData(dataLevel, 'quarterly');
      dataVariationAdjustedAux = getLatestData(
        dataVariation,
        'quarterly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else {
      dataLevelAdjustedAux = getLatestData(dataLevel, 'annual');
      dataVariationAdjustedAux = getLatestData(
        dataVariation,
        'annual',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    }

    return {
      dataLevelAdjusted: dataLevelAdjustedAux,
      dataVariationAdjusted: dataVariationAdjustedAux,
    };
  }, [
    dataLevel,
    dataVariation,
    frequency,
    isLatestDataActive,
    selectedY?.info?.frequency,
  ]);

  const options = listOfVariables
    ? listOfVariables.explanatories.map((explanatory) => ({
        value: explanatory,
        label: explanatory,
      }))
    : [];

  const selectDisable =
    !listOfVariables ||
    isLoadingListOfVariables ||
    !!errorListOfVariables ||
    error;

  const isQuarterlyFrequencyAvailable =
    selectedY?.info?.frequency !== 'bimonthly' &&
    selectedY?.info?.frequency !== 'half-year' &&
    selectedY?.info?.frequency !== 'annual';

  const isAnnualFrequencyAvailable = selectedY?.info?.frequency !== 'annual';

  const hasLevel = !!(
    (dataLevel?.historical.date.length ?? 0) +
    (dataLevel?.forecast?.date.length ?? 0)
  );

  const hasVariation = !!(
    (dataVariation?.historical.date.length ?? 0) +
    (dataVariation?.forecast?.date.length ?? 0)
  );

  return (
    <ExplanatoryVariableContainer className="containerLinear">
      <Card textCard={translate('AISelectionExplanatoryVariablesTitle')} />
      <ContentSelectExplanatoryVariable>
        <Select
          label={translate('AISelectionExplanatoryVariablesVariable')}
          options={options}
          isLoading={isLoadingListOfVariables}
          isDisabled={selectDisable}
          //@ts-expect-error:ignora
          onChange={(option) => handleSelectOption(option.value ?? null)}
          value={
            optionSelected
              ? {
                  label: optionSelected,
                  value: optionSelected,
                }
              : null
          }
        />
      </ContentSelectExplanatoryVariable>

      <ChartHeader>
        <Options>
          <Tooltip
            id="explanatory-variables-variation-tooltip"
            className="customTooltipTheme"
          />
          <div>
            <span>{translate('AISelectionExplanatoryVariablesFrequency')}</span>
            <div>
              <RadioButton
                label={translate('AISelectionExplanatoryVariablesOriginal')}
                onChange={() => handleSelectFrequency('original')}
                checked={frequency === 'original'}
                disabled={listOfVariables?.explanatories.length === 0}
              />

              {selectedY?.info?.frequency === 'daily' && (
                <RadioButton
                  label={translate('AISelectionExplanatoryVariablesMonthly')}
                  onChange={() => handleSelectFrequency('monthly')}
                  checked={frequency === 'monthly'}
                  disabled={listOfVariables?.explanatories.length === 0}
                />
              )}

              {isQuarterlyFrequencyAvailable && (
                <RadioButton
                  label={translate('AISelectionExplanatoryVariablesQuarterly')}
                  onChange={() => handleSelectFrequency('quarterly')}
                  checked={frequency === 'quarterly'}
                  disabled={listOfVariables?.explanatories.length === 0}
                />
              )}

              {isAnnualFrequencyAvailable && (
                <RadioButton
                  label={translate('AISelectionExplanatoryVariablesAnnual')}
                  onChange={() => handleSelectFrequency('yearly')}
                  checked={frequency === 'yearly'}
                  disabled={listOfVariables?.explanatories.length === 0}
                />
              )}
            </div>
          </div>

          <div>
            <span>
              {translate('AISelectionExplanatoryVariablesTransformation')}
            </span>
            <div>
              <CheckBox
                label={translate('AISelectionExplanatoryVariablesLevel')}
                onChange={() => handleSelectTransformation('Level')}
                checked={transformations.includes('Level')}
                disabled={
                  (transformations.includes('Level') &&
                    transformations.length === 1) ||
                  !hasLevel
                }
              />
              <div
                data-tooltip-id="explanatory-variables-variation-tooltip"
                data-tooltip-html={
                  !hasVariation
                    ? translate(
                        'AISelectionExplanatoryVariablesNotEnoughObservationsVariation',
                      )
                    : undefined
                }
              >
                <CheckBox
                  label={translate('AISelectionExplanatoryVariablesVariation')}
                  onChange={() => handleSelectTransformation('Variation')}
                  checked={transformations.includes('Variation')}
                  disabled={
                    (transformations.includes('Variation') &&
                      transformations.length === 1) ||
                    !hasVariation
                  }
                />
              </div>
            </div>
          </div>
        </Options>
        <ContentLatestData>
          <ToggleSwitch
            label={translate('AISelectionResultLatestData')}
            checked={isLatestDataActive}
            onChange={(e) => handleActiveLatestData(e.target.checked)}
            disabled={isLatestDataDisabled || !!error || !!isLoadingChart}
            data-cy="explanatory-variables-latest-data"
          />
        </ContentLatestData>
      </ChartHeader>

      {error ? (
        <ContainerMaintenance
          content="chart"
          text={
            listOfVariables?.explanatories.length === 0
              ? translate('AISelectionExplanatoryNoVariables')
              : translateSomeMessagesFromBackend(
                  returnMessageError() ?? '',
                  user.language,
                )
          }
          data-testid="explanatory-variables-chart-error"
          size="sm"
          style={{
            marginTop: '40px',
          }}
        />
      ) : isLoadingChart ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton
          data-testid="explanatory-variables-loading"
          style={{
            marginTop: '40px',
            height: '400px',
          }}
        />
      ) : (
        <ContainerChart data-testid="explanatory-variables-container-chart">
          <div>
            <h4>
              {transformations.includes('Level')
                ? translate('AISelectionExplanatoryVariablesLevel')
                : ''}
            </h4>

            {transformations.includes('Variation') && (
              <h4>{translate('AISelectionExplanatoryVariablesVariation')}</h4>
            )}
          </div>

          <ExplanatoryVariablesChart
            dataLevel={dataLevelAdjusted}
            dataVariation={dataVariationAdjusted}
          />
        </ContainerChart>
      )}
    </ExplanatoryVariableContainer>
  );
};
