import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ProjectDetails = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 1rem;

  cursor: pointer;

  img {
    width: 3.313rem;
    height: 3.313rem;

    border-radius: 8px;
  }

  div {
    margin-left: 1rem;

    h4 {
      color: ${({ theme }) => theme.colors.gray6};
      font-size: 1.125rem;
      line-height: 150%;
      font-weight: 600;
      max-width: 11.25rem;

      word-break: break-all;
    }

    p {
      color: ${({ theme }) => theme.colors.gray4};
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.375rem;

      margin-top: 0.25rem;
    }
  }
`;

interface ProjectMenuButton {
  projectIsChild: boolean;
  disabled?: boolean;
}

export const ProjectMenuButton = styled.button<ProjectMenuButton>`
  width: 1.25rem;
  height: 1.25rem;

  position: absolute;
  top: ${({ projectIsChild }) => (projectIsChild ? '2.25rem' : '0.25rem')};
  right: 0px;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }

  :disabled {
    cursor: not-allowed;
  }
`;

export const MenuOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const ModelUpdateReturnButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-bottom: 1rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }

  p {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
