import { TableV2 } from 'src/components/TableV2';
import styled from 'styled-components';

export const ContainerTabs = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  background: #fff;

  flex-wrap: wrap;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  padding: 16px 0px 8px;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  justify-content: space-between;
`;

export const ContainerTable = styled(TableV2.Container)`
  margin-top: 0.5rem;

  table {
    overflow: unset;
  }

  th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 99;
  }

  tbody {
    td:first-child {
      background-color: ${({ theme }) => theme.colors.white};
    }
    > tr:nth-child(even) {
      td:first-child {
        background-color: ${({ theme }) => theme.colors.gray0} !important;
      }
    }
  }
`;
