import React, { useCallback, useEffect, useState } from 'react';

import {
  ArrowDown,
  ArrowUp,
  ArrowsDownUp,
  Eye,
  EyeClosed,
  MagnifyingGlass,
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Input } from 'src/components/Input';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import light from 'src/styles/themes/light';
import { getChartColor } from 'src/utils/getChartColor';
import { escapeRegExp } from 'src/utils/escapeRegExp';
import { formatCompactNotation } from 'src/utils/formatCompactNotation';
import {
  WorkspaceColumn,
  WorkspaceY,
} from 'src/models/redux/reducers/Workspace';
import { translateTransformationText } from 'src/utils/translateTransformationText';
import apiWorkspace from 'src/models/service/apiWorkspace';

import { SummaryVariablesChart } from './SummaryVariablesChart';
import computer from '../../../../../../assets/computer.svg';
import {
  ButtonIcon,
  ContainerDependentVariable,
  ContentChart,
  ContentTableDependentVariables,
  SelectVariable,
  TableSortButton,
} from './styles';
import { sortedColumn } from '../..';

interface SelectedVariables {
  id: string;
  label: string;
  modelId: string;
}

export type ColumnsAsObject = {
  [column in WorkspaceColumn]: number | string | null | undefined;
};

interface DependentVariable extends ColumnsAsObject {
  label: string;
  id: string;
  modelId: string;
}

interface Chart {
  historical: {
    date: string[];
    value: number[];
  };
  forecast:
    | {
        date: string[];
        value: number[];
      }
    | undefined;
}
export interface VariablesCharts extends Chart {
  name: string;
}

interface ErrorProps {
  message: string;
  quantityLetters: number;
}

type TableColumns = 'MAPE' | 'WMAPE' | 'MPE' | 'RMSE' | 'MASE' | 'MASEs';

interface SortTable {
  type: 'asc' | 'desc';
  column: TableColumns;
}

export interface SelectedModel {
  row_code: string | undefined;
  model_code: string | undefined;
  type: string | undefined;
  transformation: number | string | null | undefined;
  accuracy: {
    MASE: number | string | null | undefined;
    MAPE: number | string | null | undefined;
    MASEs: number | string | null | undefined;
    WMAPE: number | string | null | undefined;
    RMSE: number | string | null | undefined;
    MPE: number | string | null | undefined;
  };
}

export const WorkspaceSummary: React.FC = () => {
  const {
    workspace,
    auth: { user },
  } = useSelector((state: RootState) => state);
  const [dependentVariables, setDependentVariables] = useState<
    DependentVariable[]
  >([]);
  const [selectedVariables, setSelectedVariables] = useState<
    SelectedVariables[]
  >([]);
  const [variableCharts, setVariableCharts] = useState<VariablesCharts[]>([]);
  const [loadingChart, setLoadingChart] = useState(false);
  const [errorChart, setErrorChart] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [searchTimer, setSearchTimer] = useState(250);
  const [searchError, setSearchError] = useState<ErrorProps>();
  const [searchAllowed, setSearchAllowed] = useState(false);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [lastSearch, setLastSearch] = useState('');

  const [dependentVariablesSearched, setDependentVariablesSearched] = useState<
    WorkspaceY[]
  >([]);

  const [sortTable, setSortTable] = useState<SortTable>({} as SortTable);

  const [hasChartZoom, setHasChartZoom] = useState(false);

  const { t: translate } = useTranslation();

  const loadDependentVariables = useCallback(
    async (index: number): Promise<SelectedModel> => {
      let dataAux: SelectedModel;

      const contentQuery = queryClient.getQueryData<SelectedModel>([
        'workspace overview',
        'dependent-variable',
        workspace.id,
        workspace.releaseSelected?.id,
        dependentVariablesSearched[index].y,
      ]);

      try {
        if (contentQuery) {
          dataAux = contentQuery;
        } else {
          dataAux = await queryClient.fetchQuery<SelectedModel>(
            [
              'workspace overview',
              'dependent-variable',
              workspace.id,
              workspace.releaseSelected?.id,
              dependentVariablesSearched[index].y_label,
              dependentVariablesSearched[index].model_id,
            ],
            // eslint-disable-next-line no-loop-func
            async () => {
              const { data } = await apiWorkspace.get(
                `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${dependentVariablesSearched[index].y_label}/models/${dependentVariablesSearched[index].model_id}`,
              );

              return {
                row_code: data?.row_code,
                model_code: data?.model_code,
                type: data?.type,
                transformation: data?.transformation,
                accuracy: {
                  MASE: data?.accuracy?.mase,
                  MAPE: data?.accuracy?.mape,
                  MASEs: data?.accuracy?.mases,
                  WMAPE: data?.accuracy?.wmape,
                  RMSE: data?.accuracy?.rmse,
                  MPE: data?.accuracy?.mpe,
                },
              };
            },
            {
              staleTime: 1000 * 60 * 20,
            },
          );
        }
      } catch (err) {
        return {
          row_code: undefined,
          model_code: undefined,
          type: undefined,
          transformation: undefined,
          accuracy: {
            MASE: undefined,
            MAPE: undefined,
            MASEs: undefined,
            WMAPE: undefined,
            RMSE: undefined,
            MPE: undefined,
          },
        };
      }

      return dataAux;
    },
    [workspace.id, workspace.releaseSelected?.id, dependentVariablesSearched],
  );

  useEffect(() => {
    let canLoad = true;

    async function load() {
      for (let i = 0; i < dependentVariablesSearched.length; i++) {
        const dataAux = await loadDependentVariables(i);

        const dependentVariable: ColumnsAsObject = {
          MAPE: '--',
          MASE: '--',
          MASEs: '--',
          Model: '--',
          MPE: '--',
          RMSE: '--',
          Transformation: '--',
          WMAPE: '--',
        };

        if (!canLoad) {
          return;
        }

        setDependentVariables((state) => {
          workspace.columns!.forEach((column) => {
            if (column === 'Transformation') {
              if (dataAux?.transformation) {
                dependentVariable.Transformation = dataAux.transformation;
              }
            } else if (column === 'Model') {
              if (dataAux?.type) {
                dependentVariable.Model = dataAux.type;
              }
            } else if (dataAux.accuracy?.[column])
              dependentVariable[column] = dataAux.accuracy?.[column];
          });

          return [
            ...state,
            {
              label: dependentVariablesSearched[i].y_label,
              id: dependentVariablesSearched[i].y,
              modelId: dependentVariablesSearched[i].model_id,
              ...dependentVariable,
            },
          ];
        });
      }
    }

    load();

    return () => {
      canLoad = false;
      setDependentVariables([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace.columns, workspace.id, dependentVariablesSearched]);

  useEffect(() => {
    (async () => {
      if (!selectedVariables.length) {
        return;
      }

      setErrorChart(false);
      setLoadingChart(true);
      const variableChartsAux: VariablesCharts[] = [];
      for (let i = 0; i < selectedVariables.length; i++) {
        let dataAux: Chart;

        const contentQuery = queryClient.getQueryData<Chart>([
          'workspace overview',
          'dependent-variable',
          'actual_forecast',
          workspace.id,
          workspace.releaseSelected?.id,
          selectedVariables[i].label,
        ]);

        try {
          if (contentQuery) {
            dataAux = contentQuery;
          } else {
            dataAux = await queryClient.fetchQuery<Chart>(
              [
                'workspace overview',
                'dependent-variable',
                'actual_forecast',
                workspace.id,
                workspace.releaseSelected?.id,
                selectedVariables[i].label,
                selectedVariables[i].modelId,
              ],
              async () => {
                const { data } = await apiWorkspace.get<Chart>(
                  `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${selectedVariables[i].label}/models/${selectedVariables[i].modelId}/data/variables/${selectedVariables[i].label}?frequency=original&transformation=level`,
                );

                const historical = data.historical;
                const forecast = data.forecast;

                if (forecast?.date.length && forecast.value.length) {
                  if (historical?.date.length && historical.date.length) {
                    forecast.date = [
                      historical.date[historical.date.length - 1],
                      ...forecast.date,
                    ];

                    forecast.value = [
                      historical.value[historical.value.length - 1],
                      ...forecast.value,
                    ];
                  }
                }

                return {
                  historical,
                  forecast,
                };
              },
              {
                staleTime: 1000 * 60 * 20,
              },
            );
          }

          variableChartsAux.push({
            ...dataAux,
            name: selectedVariables[i].label,
          });
        } catch {
          setErrorChart(true);
        }
      }

      setVariableCharts(variableChartsAux);
      setLoadingChart(false);
    })();

    return () => {
      setLoadingChart(true);
    };
  }, [workspace.id, selectedVariables, workspace.releaseSelected?.id]);

  useEffect(() => {
    (() => {
      if (!workspace.releaseSelected?.data.ys.length) {
        return 0;
      }

      setSelectedVariables([
        {
          id: workspace.releaseSelected?.data.ys[0].y,
          label: workspace.releaseSelected?.data.ys[0].y_label,
          modelId: workspace.releaseSelected?.data.ys[0].model_id,
        },
      ]);
    })();
  }, [workspace.releaseSelected?.data.ys]);

  function addTooltipSomeColumns(column: string): string | undefined {
    if (column === 'MAPE') {
      return translate('MAPEExplanationTooltip');
    }

    if (column === 'WMAPE') {
      return translate('WMAPEExplanationTooltip');
    }

    if (column === 'MPE') {
      return translate('MPEExplanationTooltip');
    }

    if (column === 'RMSE') {
      return translate('RMSEExplanationTooltip');
    }

    if (column === 'MASE') {
      return translate('MASEExplanationTooltip');
    }

    if (column === 'MASEs') {
      return translate('MASEsExplanationTooltip');
    }

    return undefined;
  }

  function adjustColumnName(column: string): string {
    if (column === 'Transformation') {
      return translate('projectOverviewTransformation');
    }
    if (column === 'Model') {
      return translate('projectOverviewModel');
    }
    if (column === 'MAPE' || column === 'WMAPE' || column === 'MPE') {
      return `${column} (%)`;
    }
    return column;
  }

  function isVariableSelected(id: string): boolean {
    return !!selectedVariables.find(
      (selectedVariable) => selectedVariable.id === id,
    );
  }

  function selectOrDeselectVariable(
    id: string,
    label: string,
    modelId: string,
  ): void {
    const zoomButton = document.getElementsByClassName('highcharts-reset-zoom');

    if (zoomButton.length) {
      setHasChartZoom(true);
    } else {
      setHasChartZoom(false);
    }

    const aux = selectedVariables;
    if (isVariableSelected(id)) {
      setSelectedVariables([
        ...aux.filter((selectVariable) => selectVariable.id !== id),
      ]);
    } else if (aux.length < 4) {
      setSelectedVariables([...aux, { id, label, modelId }]);
    }
  }

  function getColorOfSelectedVariableById(id: string): string {
    const index = selectedVariables.findIndex(
      (selectedVariable) => selectedVariable.id === id,
    );
    return getChartColor(index) ?? light.colors.primaryLight;
  }

  function handleSearchDependentVariable(value: string) {
    setSortTable({} as SortTable);
    setSearchValue(value);

    if (value.length > 50) {
      setSearchError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });
      return;
    }

    setSearchError({
      message: '',
      quantityLetters: 0,
    });

    if (value !== searchValue) {
      setSearchTimer(250);
      setTimeOutActive(true);
    }
  }

  useEffect(() => {
    if (timeOutActive) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 250);
        } else {
          setTimeOutActive(false);
        }
      }, 250);
    } else {
      searchTimer === 0 && setSearchAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  useEffect(() => {
    if (!searchError?.message && searchAllowed) {
      const regex = new RegExp(escapeRegExp(searchValue), 'i');

      const updatedVariables = searchValue.length
        ? workspace.releaseSelected?.data.ys.filter(({ y_label }) =>
            regex.test(y_label),
          )
        : workspace.releaseSelected?.data.ys;

      setDependentVariablesSearched(updatedVariables || []);

      setSearchAllowed(false);
      setTimeOutActive(false);
      setSearchTimer(250);
      setLastSearch(searchValue);
    }
  }, [
    searchError,
    searchAllowed,
    searchValue,
    workspace.releaseSelected?.data.ys,
  ]);

  useEffect(() => {
    setDependentVariablesSearched(workspace.releaseSelected?.data.ys || []);
  }, [workspace.releaseSelected?.data.ys]);

  const handleSortTable = useCallback(
    (column: TableColumns) => {
      const { type: currentType, column: currentColumn } = sortTable;

      const type = column !== currentColumn ? 'asc' : 'desc';

      const sortOption = {} as SortTable;
      let sortedValues: DependentVariable[];

      if (type !== currentType || column !== currentColumn) {
        sortOption.type = type;
        sortOption.column = column;

        sortedValues = dependentVariables.sort((a, b) => {
          if (a[column] === '--') return 1;
          if (b[column] === '--') return -1;

          let valueA = Number(a[column]);
          let valueB = Number(b[column]);

          if (column === 'MPE') {
            valueA = Math.abs(valueA);
            valueB = Math.abs(valueB);
          }

          if (valueA > valueB) {
            return type === 'asc' ? 1 : -1;
          }
          if (valueA < valueB) {
            return type === 'asc' ? -1 : 1;
          }
          return 0;
        });
      } else {
        const labels =
          workspace.releaseSelected?.data.ys.map(({ y_label }) => y_label) ||
          [];

        sortedValues = dependentVariables.sort((a, b) => {
          const labelA = a.label;
          const labelB = b.label;

          const indexA = labels.indexOf(labelA);
          const indexB = labels.indexOf(labelB);

          return indexA - indexB;
        });
      }

      setDependentVariables(sortedValues);
      setSortTable(sortOption);
    },
    [sortTable, dependentVariables, workspace.releaseSelected?.data.ys],
  );

  useEffect(() => {
    setSortTable({} as SortTable);
  }, [workspace.columns]);

  const tableIsLoading =
    dependentVariablesSearched.length - dependentVariables.length > 0;

  return (
    <ContainerDependentVariable
      className="containerLinear"
      data-testid="container-dependent-variable"
    >
      <div>
        <Card textCard={translate('workspaceOverviewTitleTable')} />

        <Input
          icon={<MagnifyingGlass size="1.25rem" />}
          testid="search-dependent-variables"
          placeholder={translate(
            'workspaceOverviewSearchDependentVariablePlaceholder',
          )}
          onChange={(event) => {
            handleSearchDependentVariable(event.target.value);
          }}
          error={
            searchError?.message &&
            translate(searchError.message).replace(
              'XX',
              String(searchError.quantityLetters),
            )
          }
        />
      </div>

      {!workspace?.id && !workspace.releaseSelected?.data.ys.length ? (
        <ContainerSkeleton />
      ) : (
        <>
          <ContentTableDependentVariables data-cy="div-table-dependent-variables">
            <Tooltip id="dependents-tooltip" className="customTooltipTheme" />

            {searchValue.length >= 1 &&
            !dependentVariablesSearched.length &&
            workspace.releaseSelected?.data.ys.length ? (
              <ContainerMaintenance
                content="projections"
                text={`${translate(
                  'workspaceSearchDependentVariableNotFound',
                )} "${lastSearch}".`}
                data-testid="dependent-variable-not-found"
              />
            ) : (
              <Table data-cy="table-dependent-variables">
                <Thead>
                  <Tr>
                    <Th
                      key="th-column-dependent-variable"
                      // style={{ width: '40%' }}
                    >
                      {translate('workspaceOverviewFirstColumn')}
                    </Th>
                    {sortedColumn.map((column) =>
                      workspace.columns!.includes(column) ? (
                        <Th key={`th-column-${column}`}>
                          <div>
                            <p
                              data-tooltip-id="dependents-tooltip"
                              data-tooltip-html={addTooltipSomeColumns(column)}
                            >
                              {adjustColumnName(column)}
                            </p>

                            {(column === 'MAPE' ||
                              column === 'WMAPE' ||
                              column === 'MPE' ||
                              column === 'MASE' ||
                              column === 'MASEs' ||
                              column === 'RMSE') && (
                              <TableSortButton
                                active={sortTable?.column === column}
                                onClick={() => handleSortTable(column)}
                                data-testid={`button-sort-by-${column
                                  .replaceAll(' ', '-')
                                  .toLocaleLowerCase()}`}
                                data-cy={`button-sort-by-${column
                                  .replaceAll(' ', '-')
                                  .toLocaleLowerCase()}`}
                                disabled={tableIsLoading}
                              >
                                {sortTable?.type === 'asc' &&
                                sortTable.column === column ? (
                                  <ArrowDown
                                    size="1rem"
                                    weight="bold"
                                    data-cy="sorted-by-asc"
                                    data-testid="sorted-by-asc"
                                  />
                                ) : sortTable?.type === 'desc' &&
                                  sortTable.column === column ? (
                                  // eslint-disable-next-line react/jsx-indent
                                  <ArrowUp
                                    size="1rem"
                                    weight="bold"
                                    data-cy="sorted-by-desc"
                                    data-testid="sorted-by-desc"
                                  />
                                ) : (
                                  <ArrowsDownUp
                                    size="1rem"
                                    weight="bold"
                                    data-cy="unsorted"
                                    data-testid="unsorted"
                                  />
                                )}
                              </TableSortButton>
                            )}
                          </div>
                        </Th>
                      ) : (
                        <></>
                      ),
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {dependentVariables.map((dependentVariable, i) => (
                    <Tr
                      key={`tr-${dependentVariable.id}`}
                      data-cy={`dependent-variable-line-${i}`}
                      data-testid={`dependent-variable-line-${i}`}
                    >
                      <Td
                        data-cy={`dependent-variable-${dependentVariable.label
                          .toLowerCase()
                          .replaceAll(' ', '-')}-variable`}
                        data-testid={`dependent-variable-${dependentVariable.label
                          .toLowerCase()
                          .replaceAll(' ', '-')}-variable`}
                      >
                        <div>
                          <ButtonIcon
                            type="button"
                            data-testid={`button-select-or-deselect-variable-${dependentVariable.label}`}
                            data-cy={`button-select-or-deselect-variable-${dependentVariable.label}`}
                            color={
                              isVariableSelected(dependentVariable.id)
                                ? getColorOfSelectedVariableById(
                                    dependentVariable.id,
                                  )
                                : light.colors.gray4
                            }
                            disabled={
                              selectedVariables.length >= 4
                                ? !isVariableSelected(dependentVariable.id)
                                : false
                            }
                            onClick={() =>
                              selectOrDeselectVariable(
                                dependentVariable.id,
                                dependentVariable.label,
                                dependentVariable.modelId,
                              )
                            }
                          >
                            {isVariableSelected(dependentVariable.id) ? (
                              <Eye size="1.125rem" />
                            ) : (
                              <EyeClosed
                                size="1.125rem"
                                data-tooltip-id="dependents-tooltip"
                                data-tooltip-html={
                                  selectedVariables.length >= 4 &&
                                  !isVariableSelected(dependentVariable.id)
                                    ? translate(
                                        'workspaceOverviewOnly4Variables',
                                      )
                                    : undefined
                                }
                              />
                            )}
                          </ButtonIcon>

                          {dependentVariable.label}
                        </div>
                      </Td>
                      {sortedColumn.map((column, index) =>
                        workspace.columns!.includes(column) ? (
                          <Td
                            key={`td-row-${
                              dependentVariable.id
                            }-${index.toString()}`}
                          >
                            {typeof dependentVariable[column] === 'number'
                              ? formatCompactNotation(
                                  dependentVariable[column] as number,
                                )
                              : column === 'Transformation'
                              ? translateTransformationText(
                                  (dependentVariable?.[column] ??
                                    '--') as string,
                                  user.language,
                                )
                              : dependentVariable[column]}
                          </Td>
                        ) : (
                          <></>
                        ),
                      )}
                    </Tr>
                  ))}

                  {Array.from(
                    {
                      length:
                        dependentVariablesSearched.length -
                        dependentVariables.length,
                    },
                    (_, index) => (
                      <Tr
                        key={`tr-loading-${index.toString()}`}
                        data-testid={`tr-loading-${index.toString()}`}
                      >
                        {Array.from(
                          { length: workspace.columns!.length + 1 },
                          (__, indexAux) => (
                            <Td
                              key={`td-loading-${index.toString()}-${indexAux.toString()}`}
                            >
                              <ContainerSkeleton
                                withLoading={false}
                                style={{
                                  height: '16px',
                                }}
                              />
                            </Td>
                          ),
                        )}
                      </Tr>
                    ),
                  )}
                </Tbody>
              </Table>
            )}
          </ContentTableDependentVariables>
          <ContentChart>
            {errorChart ? (
              <ContainerMaintenance
                content={translate('workspaceOverviewChart')}
              />
            ) : !selectedVariables.length ? (
              <SelectVariable>
                <img src={computer} alt="" />
                <h4>{translate('workspaceOverviewSelectAVariable')}</h4>
                <p>
                  {translate('workspaceOverviewSelectAVariableDescription')}
                </p>
              </SelectVariable>
            ) : loadingChart && selectedVariables.length === 1 ? (
              <ContainerSkeleton />
            ) : (
              <SummaryVariablesChart
                variablesCharts={variableCharts}
                hasZoom={hasChartZoom}
              />
            )}
          </ContentChart>
        </>
      )}
    </ContainerDependentVariable>
  );
};
