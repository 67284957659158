import styled, { css } from 'styled-components';

export const ContainerWithoutGroups = styled.div`
  width: 100%;
  height: 37.188rem;
  padding: 1.5rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  position: relative;

  margin-bottom: -24px !important;

  .containerLinear {
    /* width: 63rem !important; */
    position: relative;

    @media (max-width: 1336px) {
      width: 100% !important;
    }
  }

  @media (min-width: 1140px) {
    justify-content: center !important;
    align-items: center;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    margin-bottom: 1.5rem;
  }

  > div:last-child,
  > section:last-child {
    margin-bottom: 0;
  }

  .right-button {
    padding: 0.75rem 4.188rem;

    @media (min-width: 700px) {
      position: absolute;
      right: 1.5rem;
      top: 2.375rem;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};
`;

type ButtonsContainerProps = {
  showCopyToClipboardButton: boolean;
};

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  padding: 16px 0px 8px;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  justify-content: ${({ showCopyToClipboardButton }) =>
    showCopyToClipboardButton ? 'space-between' : 'flex-end'};
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  flex: 1;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;

  h4 {
    color: ${({ theme }) => theme.colors.gray6};

    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const IconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 8px;

  img {
    width: 100%;
    border-radius: 8px;
  }
`;

interface ButtonIconProps {
  disabled?: boolean;
}

export const ButtonIcon = styled.button<ButtonIconProps>`
  float: right;
  ${({ disabled }) =>
    !disabled
      ? css`
          color: ${({ theme }) => theme.colors.gray5};
          transition: 0.2s color;
          &:hover {
            color: ${({ theme }) => theme.colors.gray6};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.gray4};
          cursor: not-allowed;
        `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface MenuOverlayProps {
  visible: boolean;
}

export const MenuOverlay = styled.div<MenuOverlayProps>`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const ContentTable = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
`;
