import React, { useContext, useState } from 'react';

import { Heart, CrownSimple, Database } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { clearIndicatorsPage } from 'src/feature-store/redux/reducers/IndicatorsPage';
import logoWhite from 'src/assets/logo_without_text_white.svg';
import { AIChat } from 'src/feature-store/pages/AIChat';
import { RootState } from 'src/redux/store';

import {
  MenuChatIA,
  MenuDivisor,
  MenuItem,
  SideBar,
  SideMenu,
  StickyContainer,
} from './styles';
import { Premium } from '../Premium';
import { Categories } from '../Categories';
import { FeatureStoreSidebarContext } from '../Contexts/NavigationContext';
import { Favorites } from '../Favorites';

type PrimaryOptionNavigation = 'favorites' | 'search' | 'premium';

export const SideBarFS: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const {
    indicatorFiltersVisible,
    openIndicatorFilters,
    searchIndicators,
    premiumFiltersVisible,
    openPremiumFilters,
    favoriteFilterVisible,
    openFavoriteFilters,
    firstMyFavoriteId,
    idFavoriteViewing,
  } = useContext(FeatureStoreSidebarContext);
  const { permissions } = useSelector((state: RootState) => state.auth.user);

  const [openAIChat, setOpenAIChat] = useState(false);

  let isSearchSelected = false;
  let isFavoritesSelected = false;
  let isGroupPremiumSelected = false;

  if (
    pathname.includes('indicators') &&
    !window.history?.state?.usr?.isPremium
  ) {
    isSearchSelected = true;
  } else if (pathname.includes('favorites')) {
    isFavoritesSelected = true;
  } else if (
    pathname.includes('premium') ||
    window.history?.state?.usr?.isPremium
  ) {
    isGroupPremiumSelected = true;
  }

  const isFavoritePage = pathname.split('/')[2] === 'favorites';

  function handlePageChange(option: PrimaryOptionNavigation): void {
    if (option === 'search' && !pathname.includes('indicators')) {
      dispatch(clearIndicatorsPage());
      navigate('/feature-store/indicators');
    }
    if (option === 'favorites' && !isFavoritePage) {
      if (firstMyFavoriteId[0] === '' && idFavoriteViewing === '') {
        navigate('/feature-store/favorites/create-first-favorite');
      } else if (idFavoriteViewing === '') {
        navigate(`/feature-store/favorites/${firstMyFavoriteId[0]}`);
      } else {
        navigate(`/feature-store/favorites/${idFavoriteViewing}`);
      }
    }
    if (option === 'premium' && !pathname.includes('/premium')) {
      navigate('/feature-store/premium');
    }

    openIndicatorFilters(false);
    openPremiumFilters(false);
  }

  return (
    <>
      <SideMenu>
        <StickyContainer>
          <MenuItem
            selected={isSearchSelected}
            onClick={() => {
              searchIndicators();
              openIndicatorFilters(true);
              openFavoriteFilters(false);
            }}
            data-cy="li-select-search"
            data-testid="li-select-search"
          >
            <Database size={24} />
            <p data-testid="indicators-tab">{translate('indicator')}</p>
          </MenuItem>

          <MenuItem
            selected={isFavoritesSelected}
            onClick={() => {
              handlePageChange('favorites');
              openIndicatorFilters(false);
              openFavoriteFilters(true);
            }}
            data-cy="li-select-favorites"
            data-testid="li-select-favorites"
          >
            <Heart size={24} />
            <p data-testid="favorites-tab">{translate('favoritesTitle')}</p>
          </MenuItem>

          <MenuItem
            selected={isGroupPremiumSelected}
            onClick={() => {
              handlePageChange('premium');
              openIndicatorFilters(false);
              openFavoriteFilters(false);
              openPremiumFilters(true);
            }}
            data-cy="li-select-premium"
            data-testid="li-select-premium"
          >
            <CrownSimple size={24} />
            <p data-testid="premium-tab">Premium</p>
          </MenuItem>

          <MenuDivisor />

          {permissions.includes('ask:questions') && (
            <>
              <MenuChatIA>
                <button
                  type="button"
                  onClick={() => setOpenAIChat(true)}
                  data-testid="ai-chat-button"
                  data-cy="ai-chat-button"
                >
                  <img src={logoWhite} alt="" />
                </button>
                <p>Chat 4i</p>
              </MenuChatIA>
            </>
          )}
        </StickyContainer>
      </SideMenu>

      {permissions.includes('ask:questions') && (
        <AIChat visible={openAIChat} setVisible={setOpenAIChat} />
      )}

      <SideBar visible={indicatorFiltersVisible}>
        <Categories />
      </SideBar>

      <SideBar visible={premiumFiltersVisible}>
        <Premium />
      </SideBar>

      <SideBar visible={favoriteFilterVisible}>
        <Favorites />
      </SideBar>
    </>
  );
};
