/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { DownloadSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { Select } from 'src/components/Select';
import { SliderComponent as Slider } from 'src/components/Slider';
import { DataError } from 'src/interface/axios';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { insertArray } from 'src/utils/insertArray';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import {
  changeAIUserSelectionExplanatoryVariablesIsLatestDataActive,
  changeAIUserSelectionExplanatoryVariablesOptionSelected,
} from 'src/models/redux/reducers/AIUserSelectionOptions';

import api from '../../service/api';
import ModelBreakdown from './components/ModelBreakdown';
import {
  ChartHeader,
  Container,
  ContainerSecundary,
  ContentLatestData,
  DownloadContainer,
  // VariableCard,
  // DivButtons,
  // DivDownloadButton,
  VariableCard,
} from './styles';
import {
  ExplanatoryProjections,
  ExplanatoryVariables,
  SelectedModel,
  Simulations,
  SimulationSliderObject,
} from './types';
import { AnnualSummary } from './components/AnnualSummary';
import { Results } from './components/Results';
import { ExplanatoryVariablesChart } from './components/ExplanatoryVariablesChart';
import { Information } from './components/Information';

export const frequencyLatestData = {
  daily: 180,
  weekly: 52,
  fortnightly: 24,
  monthly: 36,
  bimonthly: 18,
  quarterly: 12,
  'half-year': 12,
  annual: 6,
};

export const ModelInProduction: React.FC = () => {
  const [isSimulating, setIsSimulating] = useState(false);
  const [isDownloadRunning, setIsDownloadRunning] = useState(false);
  const [simulationYear, setSimulationYear] = useState('');
  const [simulationSliderValues, setSimulationSliderValues] = useState<
    SimulationSliderObject[]
  >([]);
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();
  const [
    projectionOfExplanatoryErrorVisible,
    setProjectionOfExplanatoryErrorVisible,
  ] = useState(false);
  const [projectionOfExplanatoryVisible, setProjectionOfExplanatoryVisible] =
    useState(false);

  const {
    project,
    AIUserSelectionOptions: {
      userSelection: {
        explanatoryVariables: { optionSelected, isLatestDataActive },
      },
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const {
    data: selectedModelData,
    isLoading: selectedModelLoading,
    isError: selectedModelError,
  } = useQuery(
    ['model production', 'selected model', project.id, project.selectedY?.id],
    async () => {
      const response = await api.get<SelectedModel>(
        `/projects/${project.id}/${project.selectedY?.id}/model-in-production/selected_model`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!project.id && !!project.selectedY?.id,
    },
  );

  const {
    data: simulationsData,
    isLoading: simulationsLoading,
    isFetching: simulationsFetching,
    isError: simulationsError,
  } = useQuery(
    ['model production', 'simulations', project.id, project.selectedY?.id],
    async () => {
      const response = await api.get<Simulations>(
        `/projects/${project.id}/${project.selectedY?.id}/model-in-production/simulations`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      onSuccess: () =>
        dispatch(
          changeAIUserSelectionExplanatoryVariablesOptionSelected({
            selection: 'userSelection',
            value: null,
          }),
        ),
      enabled:
        !!project.id &&
        !!project.selectedY &&
        selectedModelData?.selected_model?.model_type === 'ARIMA',
    },
  );

  const {
    data: projectionExplanatoryData,
    isLoading: projectionExplanatoryLoading,
    isError: projectionExplanatoryError,
  } = useQuery<ExplanatoryProjections>(
    [
      'model production',
      'projection explanatory',
      project.id,
      project.selectedY?.id,
      optionSelected,
    ],
    async () => {
      const response = await api.get<ExplanatoryProjections>(
        `/projects/${project.id}/${project.selectedY?.id}/model-in-production/${optionSelected}/projection_explanatory`,
      );

      const historical = response.data.historical;
      const original = response.data.original;
      const simulated = response.data.simulated;

      if (
        original.x[0] === historical.x[historical.x.length - 1] &&
        original.y[0] === historical.y[historical.y.length - 1]
      ) {
        original.x.splice(0, 1);
        original.y.splice(0, 1);
      }

      if (
        simulated.x[0] === historical.x[historical.x.length - 1] &&
        simulated.y[0] === historical.y[historical.y.length - 1]
      ) {
        simulated.x.splice(0, 1);
        simulated.y.splice(0, 1);
      }

      return {
        historical,
        original,
        simulated,
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        selectedModelData?.selected_model?.model_type === 'ARIMA' &&
        !!optionSelected,
    },
  );

  const {
    data: explanatoryVariables,
    isLoading: explanatoryVariablesLoading,
    isError: explanatoryVariablesError,
  } = useQuery<ExplanatoryVariables>(
    [
      'model production',
      'explanatory variables',
      project.id,
      project.selectedY?.id,
    ],
    async () => {
      const response = await api.get<ExplanatoryVariables>(
        `/projects/${project.id}/${project.selectedY?.id}/model-in-production/explanatory-variables`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id &&
        !!project.selectedY?.id &&
        selectedModelData?.selected_model?.model_type === 'ARIMA',
    },
  );

  useEffect(() => {
    if (
      explanatoryVariables &&
      explanatoryVariables.explanatories.length > 0 &&
      !optionSelected
    ) {
      dispatch(
        changeAIUserSelectionExplanatoryVariablesOptionSelected({
          selection: 'userSelection',
          value: explanatoryVariables?.explanatories[0],
        }),
      );
    }
  }, [dispatch, explanatoryVariables, optionSelected]);

  useEffect(() => {
    if (simulationsData?.informations.length) {
      const variablesArray: Array<SimulationSliderObject> = [];
      simulationsData.informations[0].variables.map((variable) =>
        variablesArray.push({
          variable: variable.name,
          value: variable.simulated,
        }),
      );

      setSimulationSliderValues(variablesArray);

      if (simulationYear !== '') {
        handleChangeYear(simulationYear);
      }
    } else if (simulationsData?.variables.length && optionSelected === '') {
      dispatch(
        changeAIUserSelectionExplanatoryVariablesOptionSelected({
          selection: 'userSelection',
          value: simulationsData.variables[0].name,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationsData, optionSelected, simulationYear]);

  useEffect(() => {
    if (simulationsData && simulationYear !== '') {
      const hasYearDataOnY = simulationsData.year.includes(
        Number(simulationYear),
      );

      if (hasYearDataOnY) {
        handleChangeYear(simulationYear);
      } else {
        setSimulationYear(String(simulationsData?.year[0]));
        handleChangeYear(String(simulationsData?.year[0]));
      }
    } else if (simulationsData && simulationYear === '') {
      setSimulationYear(String(simulationsData?.year[0]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulationYear, simulationsData]);

  useEffect(() => {
    if (
      simulationsData &&
      simulationsData.variables.length &&
      explanatoryVariables
    ) {
      setProjectionOfExplanatoryVisible(true);
      setProjectionOfExplanatoryErrorVisible(false);
    } else if (simulationsError || explanatoryVariablesError) {
      setProjectionOfExplanatoryErrorVisible(true);
    } else {
      setProjectionOfExplanatoryVisible(false);
    }

    if (selectedModelData?.selected_model?.model_type !== 'ARIMA') {
      setProjectionOfExplanatoryErrorVisible(false);
    }
  }, [
    explanatoryVariables,
    explanatoryVariablesError,
    selectedModelData?.selected_model?.model_type,
    simulationsData,
    simulationsError,
  ]);

  const isLatestDataDisabled = useMemo(() => {
    const total =
      (projectionExplanatoryData?.historical.y.length ?? 0) +
      (projectionExplanatoryData?.original.y.length ?? 0);

    return (
      total <=
      frequencyLatestData[
        project.selectedY?.info?.frequency as keyof typeof frequencyLatestData
      ]
    );
  }, [project, projectionExplanatoryData]);

  useEffect(() => {
    if (
      (!projectionExplanatoryLoading &&
        !simulationsFetching &&
        projectionExplanatoryData &&
        isLatestDataDisabled) ||
      projectionExplanatoryError
    ) {
      dispatch(
        changeAIUserSelectionExplanatoryVariablesIsLatestDataActive({
          selection: 'userSelection',
          value: false,
        }),
      );
    }
  }, [
    dispatch,
    isLatestDataDisabled,
    projectionExplanatoryData,
    projectionExplanatoryError,
    projectionExplanatoryLoading,
    simulationsFetching,
  ]);

  const getSimulatorSliderMarks = useCallback((range: number) => {
    const sliderMarks = [range * -1];

    let divisor = 3;

    if (range >= 1000) {
      divisor = 1;
    } else if (range >= 100) {
      divisor = 2;
    }

    const rest = range / divisor;

    for (let index = 0; index < divisor * 2; index++) {
      sliderMarks.push(sliderMarks[index] + rest);
    }

    return sliderMarks
      .map((number) => ({ [number]: number.toFixed(2) }))
      .reduce((obj, number) => ({
        ...obj,
        ...number,
      }));
  }, []);

  const restoreDefaultDisabled = useMemo(() => {
    const selectedYearData = simulationsData?.informations.filter(
      (info) => info.year === Number(simulationYear),
    );

    if (selectedYearData) {
      const defaultValues = selectedYearData.flatMap((info) =>
        info.variables.map((variable) => ({
          variable: variable.name,
          value: variable.default,
        })),
      );

      const valuesIsEqual = defaultValues.map((defaultValue) =>
        simulationSliderValues.some(
          (sliderValue) =>
            sliderValue.value === defaultValue.value &&
            sliderValue.variable === defaultValue.variable,
        ),
      );

      return valuesIsEqual.every((value) => value === true);
    }

    return false;
  }, [simulationSliderValues, simulationYear, simulationsData?.informations]);

  const handleSliderChanges = (variable: string, value: number) => {
    const sliderValue = {
      variable,
      value,
    };

    const newSimulationSliderValues = simulationSliderValues;

    const variableExistsInArray = newSimulationSliderValues.some(
      (variableObject) => variableObject?.variable === variable,
    );

    if (variableExistsInArray) {
      const sliderValueIndex = newSimulationSliderValues.findIndex(
        (simulationSliderValue) => simulationSliderValue?.variable === variable,
      );
      newSimulationSliderValues.splice(sliderValueIndex, 1);

      setSimulationSliderValues(
        insertArray(newSimulationSliderValues, sliderValueIndex, sliderValue),
      );
    }
  };

  function handleRestoreDefaultValues() {
    const selectedYearData = simulationsData?.informations.filter(
      (info) => info.year === Number(simulationYear),
    );

    if (selectedYearData) {
      const defaultValues = selectedYearData.flatMap((info) =>
        info.variables.map((variable) => ({
          variable: variable.name,
          value: variable.default,
        })),
      );

      setSimulationSliderValues(defaultValues);
    }
  }

  function handleChangeYear(year: string) {
    setSimulationYear(year);

    const yearInformations = simulationsData?.informations.filter(
      (info) => info.year === Number(year),
    );

    if (yearInformations) {
      const simulationsValuesOfYear = yearInformations.flatMap((info) =>
        info.variables.map((variable) => ({
          variable: variable.name,
          value: variable.simulated,
        })),
      );

      setSimulationSliderValues(simulationsValuesOfYear);
    }
  }

  async function handleSimulate() {
    setIsSimulating(true);

    const simulationData = {
      year: String(simulationYear),
      variables: simulationSliderValues.map(
        (sliderValue) => sliderValue.variable,
      ),
      values: simulationSliderValues.map((sliderValue) => sliderValue.value),
    };

    try {
      const { data } = await api.post(
        `/projects/${project.id}/${project.selectedY?.id}/model-in-production/apply_simulation`,
        simulationData,
      );

      if (data) {
        setIsSimulating(false);

        await queryClient.invalidateQueries('model production');
      } else {
        throw new Error();
      }
    } catch (err) {
      const error: AxiosError<DataError> | any = err;
      const errorMessage =
        error?.response?.data?.detail?.description ??
        translate('modelInProductionSimulationError');

      setFailedModalInfo({
        title: translate('requestFailed'),
        description: errorMessage,
      });

      setFailedModalVisible(true);

      setIsSimulating(false);
    }
  }

  async function handleDownloadBestModel() {
    setIsDownloadRunning(true);

    try {
      const { data } = await api.get(
        `/projects/${project?.id}/${project?.selectedY?.id}/model-in-production/export`,
        {
          responseType: 'blob',
        },
      );

      if (data) {
        const fileURL = window.URL.createObjectURL(
          new Blob([data], { type: 'text/xlsx' }),
        );

        const link = document.createElement('a');
        if (link.download !== undefined) {
          link.setAttribute('href', fileURL);
          if (selectedModelData?.selected_model?.model_type === 'ARIMA') {
            link.setAttribute(
              'download',
              `${project.selectedY?.label}_simulated_${format(
                new Date(),
                'yyyy-MM-dd_HH-mm_s',
              )}.xlsx`,
            );
          } else {
            link.setAttribute('download', `${project.selectedY?.label}.xlsx`);
          }
          link.setAttribute('data-testid', 'download-start');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
        }
      }

      setIsDownloadRunning(false);
    } catch (err) {
      const error: AxiosError<DataError> | any = err;
      const errorMessage =
        error?.response?.data?.detail?.detail ??
        error?.response?.data?.detail?.description ??
        translate('modelInProductionDownloadError');

      setFailedModalInfo({
        title: translate('requestFailed'),
        description: errorMessage,
      });
      setFailedModalVisible(true);

      setIsDownloadRunning(false);
    }
  }

  function handleActiveLatestData(value: boolean) {
    dispatch(
      changeAIUserSelectionExplanatoryVariablesIsLatestDataActive({
        selection: 'userSelection',
        value,
      }),
    );
  }

  function handleChangeExplanatoryVariableOption(value: string) {
    dispatch(
      changeAIUserSelectionExplanatoryVariablesOptionSelected({
        selection: 'userSelection',
        value,
      }),
    );
  }

  const defaultEqualSimulated = simulationsData?.informations.every((info) =>
    info.variables.every((variable) => variable.simulated === variable.default),
  );

  return (
    <Container>
      {selectedModelData && (
        <Head
          title={`${translate(
            selectedModelData?.selected_model?.model_type.replace(' ', '_'),
          )} - ${translate('modelInProductionTitle')}`}
        />
      )}

      <Information
        selectedModelData={selectedModelData}
        selectedModelLoading={selectedModelLoading}
        selectedModelError={selectedModelError}
      />

      <AnnualSummary />

      <section>
        {selectedModelData?.selected_model?.model_type === 'ARIMA' && (
          <div className="first-container">
            <ContainerSecundary
              className="containerLinear"
              data-testid="container-elasticities"
            >
              <Card
                textCard={translate('modelInProductionEstimatedCoefficients')}
              />
              {project.projectError ||
              simulationsError ||
              !simulationsData?.variables.every(
                (variable) => variable.coef && variable.name,
              ) ? (
                <h5 className="no-simulations">
                  {translate('modelInProductionErrorMessage')}
                </h5>
              ) : simulationsLoading || !simulationsData ? (
                <ContainerSkeleton />
              ) : (
                simulationsData && (
                  <>
                    {simulationsData?.variables.map((variable) => (
                      <VariableCard
                        key={`${variable.name}`}
                        data-cy={`elasticities-${variable.name}`}
                        data-testid={`elasticities-${variable.name}`}
                      >
                        <p>{variable.name}</p>
                        <h3>{variable.coef?.toFixed(2)}</h3>
                      </VariableCard>
                    ))}
                  </>
                )
              )}
            </ContainerSecundary>

            <ContainerSecundary
              className="containerLinear"
              data-testid="container-simulations"
            >
              <Card textCard={translate('modelInProductionSimulations')} />

              {project.projectError ||
              simulationsError ||
              !simulationsData?.year.length ||
              !simulationsData.informations.length ||
              !simulationsData.variables.every(
                (variable) => variable.slider_range,
              ) ? (
                <>
                  <h5 className="no-simulations">
                    {translate('modelInProductionErrorMessage')}
                  </h5>
                </>
              ) : simulationsLoading || !simulationsData ? (
                <ContainerSkeleton />
              ) : (
                simulationsData && (
                  <>
                    <Select
                      label={translate('modelInProductionYear')}
                      value={{
                        label: simulationYear,
                        value: simulationYear,
                      }}
                      isDisabled={!!(isSimulating || simulationsFetching)}
                      options={simulationsData?.year.map((year) => ({
                        label: year,
                        value: year,
                      }))}
                      onChange={(opt: any) => handleChangeYear(opt.value)}
                    />
                    {simulationsData?.informations
                      .filter((info) => info.year === Number(simulationYear))
                      .map((variableInfo) =>
                        variableInfo.variables.map((variable) => (
                          <div key={variable.name}>
                            <h5>{variable.name}</h5>
                            <Slider
                              dataCy={`slider-${variable.name}`}
                              disabled={!!(isSimulating || simulationsFetching)}
                              min={
                                (simulationsData?.variables.find(
                                  (varToBeFiltered) =>
                                    variable.name === varToBeFiltered.name,
                                )?.slider_range ?? 1) * -1
                              }
                              max={
                                simulationsData?.variables.find(
                                  (varToBeFiltered) =>
                                    variable.name === varToBeFiltered.name,
                                )?.slider_range ?? 1
                              }
                              marks={getSimulatorSliderMarks(
                                simulationsData?.variables.find(
                                  (varToBeFiltered) =>
                                    variable.name === varToBeFiltered.name,
                                )?.slider_range ?? 1,
                              )}
                              step={0.01}
                              value={
                                simulationSliderValues.find(
                                  (sliderValue) =>
                                    sliderValue.variable === variable.name,
                                )?.value
                              }
                              defaultValue={variable.simulated}
                              onChange={(value) =>
                                handleSliderChanges(variable.name, value)
                              }
                            />
                          </div>
                        )),
                      )}

                    <Button
                      data-testid="restore-simulation"
                      data-cy="restore-simulation"
                      onClick={handleRestoreDefaultValues}
                      disabled={
                        !!(
                          restoreDefaultDisabled ||
                          isSimulating ||
                          simulationsFetching
                        )
                      }
                      buttonType="secondary"
                      style={{ marginTop: '24px' }}
                    >
                      {translate('modelInProductionRestoreDefaultValues')}
                    </Button>
                    <Button
                      buttonType="primary"
                      data-testid="send-simulation"
                      data-cy="send-simulation"
                      onClick={handleSimulate}
                      style={{ marginTop: '16px' }}
                      disabled={!!(isSimulating || simulationsFetching)}
                      loading={!!(isSimulating || simulationsFetching)}
                    >
                      {translate('modelInProductionSimulate')}
                    </Button>
                  </>
                )
              )}
            </ContainerSecundary>
            <ContainerSecundary className="containerLinear">
              <Card
                textCard={translate('modelInProductionExport')}
                textDescription={translate(
                  'modelInProductionExportDescription',
                )}
              />

              <Button
                buttonType="secondary"
                icon={<DownloadSimple size="1.125rem" />}
                onClick={handleDownloadBestModel}
                disabled={isDownloadRunning}
                loading={isDownloadRunning}
                data-testid="btn-download-bestmodel"
                data-cy="btn-download-bestmodel"
              >
                Download
              </Button>
            </ContainerSecundary>
          </div>
        )}

        <div
          className={
            selectedModelData?.selected_model?.model_type === 'ARIMA'
              ? 'second-container'
              : ''
          }
        >
          <Results />
          {projectionOfExplanatoryVisible && (
            <div
              className="containerLinear"
              data-testid="container-explanatory-projection"
            >
              <Card
                textCard={translate(
                  'modelInProductionProjectionExplanatoryVariables',
                )}
              />

              <ChartHeader>
                <Select
                  label={translate('modelInProductionVariable')}
                  options={explanatoryVariables?.explanatories.map(
                    (variable) => ({
                      label: variable,
                      value: variable,
                    }),
                  )}
                  isLoading={explanatoryVariablesLoading}
                  value={{
                    label:
                      optionSelected ??
                      explanatoryVariables?.explanatories[0] ??
                      '',
                    value:
                      optionSelected ??
                      explanatoryVariables?.explanatories[0] ??
                      '',
                  }}
                  onChange={(option: any) => {
                    handleChangeExplanatoryVariableOption(option.value);
                  }}
                  style={{
                    width: '300px',
                  }}
                />

                <ContentLatestData>
                  <ToggleSwitch
                    label={translate(
                      'modelInProductionExplanatoryVariablesLatestData',
                    )}
                    checked={isLatestDataActive}
                    onChange={(e) => handleActiveLatestData(e.target.checked)}
                    disabled={
                      isLatestDataDisabled || projectionExplanatoryError
                    }
                    data-cy="explanatory-variables-latest-data"
                  />
                </ContentLatestData>
              </ChartHeader>

              {projectionExplanatoryError ? (
                <ContainerMaintenance
                  content="chart"
                  size="sm"
                  data-testid="container-originalseries-projection-error"
                />
              ) : projectionExplanatoryLoading || simulationsFetching ? (
                <ContainerSkeleton />
              ) : (
                projectionExplanatoryData && (
                  <ExplanatoryVariablesChart
                    projectionExplanatoryData={projectionExplanatoryData}
                    isLatestDataActive={isLatestDataActive}
                    defaultEqualSimulated={!!defaultEqualSimulated}
                  />
                )
              )}
            </div>
          )}
          {projectionOfExplanatoryErrorVisible && (
            <div
              className="containerLinear"
              data-testid="container-explanatory-projection"
            >
              <Card
                textCard={translate(
                  'modelInProductionProjectionExplanatoryVariables',
                )}
              />

              <ChartHeader>
                <Select
                  label={translate('modelInProductionVariable')}
                  placeholder={translate('select')}
                  isDisabled
                  style={{ width: '300px', margin: '16px 0 16px' }}
                />

                <ContentLatestData>
                  <ToggleSwitch
                    label={translate(
                      'modelInProductionExplanatoryVariablesLatestData',
                    )}
                    checked={false}
                    onChange={(e) => handleActiveLatestData(e.target.checked)}
                    disabled
                    data-cy="explanatory-variables-latest-data"
                  />
                </ContentLatestData>
              </ChartHeader>

              <ContainerMaintenance
                data-testid="projection-explanatory-error-content"
                content="chart"
                size="sm"
                text={translate('modelInProductionErrorMessage')}
              />
            </div>
          )}

          {selectedModelData?.selected_model?.model_type === 'ARIMA' &&
            simulationSliderValues &&
            simulationSliderValues.length > 3 && (
              <ModelBreakdown
                data-testid="breakdown-beside"
                selectedModelData={selectedModelData}
              />
            )}
        </div>
      </section>

      {selectedModelData?.selected_model?.model_type === 'ARIMA' &&
        simulationSliderValues &&
        simulationSliderValues.length < 4 && (
          <ModelBreakdown selectedModelData={selectedModelData} />
        )}

      {selectedModelData?.selected_model?.model_type !== 'ARIMA' && (
        <div className="containerLinear">
          <Card
            textCard={translate('modelInProductionExport')}
            textDescription={translate('modelInProductionExportDescription')}
          />
          <DownloadContainer>
            <Button
              buttonType="secondary"
              icon={<DownloadSimple size="1.125rem" />}
              onClick={handleDownloadBestModel}
              disabled={isDownloadRunning}
              loading={isDownloadRunning}
              data-testid="btn-download-bestmodel"
              data-cy="btn-download-bestmodel"
            >
              Download
            </Button>
          </DownloadContainer>
        </div>
      )}

      <FailedModal
        errorInfo={failedModalInfo}
        visible={failedModalVisible}
        setVisible={setFailedModalVisible}
      />
    </Container>
  );
};
