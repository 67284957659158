import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { Modal } from 'src/components/Modal';
import deleteImg from 'src/assets/delete.svg';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';
import {
  changeFilters,
  deletedAllFilters,
} from 'src/models/redux/reducers/DependentVariablesConfig';

import { DeleteFilterModalProps, LevelProps } from './types';
import { Container } from './styles';

export const DeleteFilterModal: React.FC<DeleteFilterModalProps> = ({
  visible,
  setVisible,
  filterLevel,
}) => {
  const {
    dependentVariablesConfig: { filters },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const handleDeleteFilter = () => {
    const { level1, level2, level3 } = filters;

    const emptyFilter = {
      name: undefined,
      options: [],
    };

    let newFilters: LevelProps = {} as LevelProps;
    let quantity = 0;

    if (filterLevel === 'level3') {
      newFilters = {
        level1,
        level2,
        level3: emptyFilter,
      };
      quantity = 2;
    } else if (filterLevel === 'level2') {
      if (level3.name === undefined) {
        newFilters = {
          level1,
          level2: emptyFilter,
          level3: emptyFilter,
        };
        quantity = 1;
      } else {
        newFilters = {
          level1,
          level2: level3,
          level3: emptyFilter,
        };
        quantity = 2;
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (level3.name === undefined && level2.name === undefined) {
        newFilters = {
          level1: emptyFilter,
          level2: emptyFilter,
          level3: emptyFilter,
        };
      } else if (level3.name === undefined) {
        newFilters = {
          level1: level2,
          level2: emptyFilter,
          level3: emptyFilter,
        };
        quantity = 1;
      } else {
        newFilters = {
          level1: level2,
          level2: level3,
          level3: emptyFilter,
        };
        quantity = 2;
      }
    }

    if (quantity === 0) {
      dispatch(deletedAllFilters(true));
    }

    dispatch(
      changeFilters({
        filters: newFilters,
        quantity,
        changed: true,
      }),
    );

    setVisible(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible} style={{ width: '30rem' }}>
      <Container data-testid={`delete-modal-${filterLevel}`}>
        <img src={deleteImg} alt="delete-icon" />
        <h2>{translate('configFiltersDeleteFilterTitle')}</h2>
        <p>
          {translate('configFiltersDeleteFilterDescription')}{' '}
          <b>{filters[filterLevel].name}</b>
        </p>
      </Container>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="delete-filter-cancel-button"
        >
          {translate('cancel')}
        </Button>

        <Button
          buttonType="primary"
          onClick={handleDeleteFilter}
          data-testid="delete-filter-confirm-button"
        >
          {translate('delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
